import React from 'react';
import { useFetch } from 'react-admin-base';
import { Card, CardBody } from 'reactstrap';
import 'beautiful-react-diagrams/styles.css';
import SummaryStandart from './SummaryStandart';
import { Breadcrumb } from 'react-admin-base-nazox';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useUser } from '../../Components/UserProvider';
import { SummaryStandartNoDocument } from './SummaryStandartNoDocument';
import { Loading } from '../../Components/Loading';

const makePascalCase = (text) => {
  const words = text.trim().split(' ');
  return words
    .map((word) => {
      if (word[0]) return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
};

export default function ReportEntity() {
  const params = useParams();
  const user = useUser();
  const slug = params.Id && window.atob(params.Id);
  const code = params.Id && params.Id;

  const [analyze, , error] = useFetch('/analyze/get/' + slug);
  const [company, loading] = useFetch(`/company/get/user/${user.id}`);
  const [financialAnalysis] = useFetch(`/analyze/types/${slug}`);
  const companyCode = company && company.code;
  const noDocument = financialAnalysis === 'Survey';
  var FirmName = '';
  if (!analyze) {
    FirmName = '';
  } else {
    FirmName = analyze && analyze.customer && analyze.customer.fullName;
  }
  if (error && error.response && error.response.status === 403) {
    return (
      <Breadcrumb
        title={<FormattedMessage id="TAB_6_TRANSFER_PURIFICATION" />}
        data={[
          {
            name: <FormattedMessage id="TAB_6_TRANSFER_PURIFICATION" />,
            href: '/analyzes/',
          },
        ]}
      >
        <Card>
          <CardBody>
            <p>
              <FormattedMessage id="403_ACCESS_BLOCK" />
            </p>
            <p>
              <Link to="/analyzes">
                {' '}
                <FormattedMessage id="PREVIOUS_CREDIT_ANALYZES" />
              </Link>
              {'  '} <FormattedMessage id="SHOW_ANALYSIS" />
            </p>
          </CardBody>
        </Card>
      </Breadcrumb>
    );
  } else {
    return (
      <Breadcrumb
        title={
          FirmName ? makePascalCase(FirmName.toLocaleLowerCase()) : 'Report'
        }
        data={[
          {
            name: FirmName ? makePascalCase(FirmName.toLowerCase()) : 'Report',
            href: '/report/' + code + '/summary',
          },
        ]}
      >
        <SummaryPage
          loading={loading}
          key={slug}
          id={slug}
          fullSize={12}
          noDocument={noDocument}
          companyCode={companyCode}
        />
      </Breadcrumb>
    );
  }
}

export function SummaryPage({ id, noDocument, companyCode, loading }) {
  return !loading && id && companyCode ? (
    noDocument ? (
      <SummaryStandartNoDocument
        key={id}
        firminfo={id}
        noDocument={noDocument}
        companyCode={companyCode}
      />
    ) : (
      <SummaryStandart
        key={id}
        firminfo={id}
        noDocument={noDocument}
        companyCode={companyCode}
      />
    )
  ) : (
    <Loading />
  );
}
