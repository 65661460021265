import React, { useCallback, useState, useEffect } from 'react';
import { useAuth, ValidatorProvider } from 'react-admin-base';
import { ApiSelect } from 'react-admin-base-bootstrap';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { UserSelect } from '../../Components/Selects';
import Swal from 'sweetalert2';
import { useIntl, FormattedMessage } from 'react-intl';

const operationOptions = [
  { id: 1, name: 'Purchase' },
  { id: 2, name: 'Transfer' },
  { id: 3, name: 'Admin Operations' },
  { id: 4, name: 'App Launch Campaign' },
];

const swalText = ({
  selectedUser,
  operation,
  selectedAmount,
  credit,
  intl,
}) => {
  const kullanici = selectedUser
    ? `${selectedUser.first_name} ${selectedUser?.last_name}`
    : '';
  const işlem = operation ? operation.name : '';
  const creditA = credit ? credit.creditAmount : 0;
  return `
  <strong>${intl.formatMessage({
    id: 'USER',
  })}:</strong> ${kullanici}<br>
  <strong>${intl.formatMessage({
    id: 'OPERATION',
  })}:</strong> ${işlem}<br>
  <strong>${intl.formatMessage({
    id: 'CREDIT_TO_ADD',
  })}:</strong> ${selectedAmount}<br>
  <strong>${intl.formatMessage({
    id: 'CREDIT_AFTER_ADD',
  })}:</strong> ${creditA + parseInt(selectedAmount, 10)}
  `;
};

const useAreYouSure = ({
  selectedUser,
  operation,
  selectedAmount,
  credit,
  intl,
}) => {
  return async () => {
    const ret = await Swal.fire({
      title: intl.formatMessage({
        id: 'APPROVE_TEXT',
      }),
      html: swalText({ selectedUser, operation, selectedAmount, credit, intl }),
      icon: 'warning',
      width: '24em',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({
        id: 'YES_AGREE',
      }),
      cancelButtonText: intl.formatMessage({
        id: 'NO_DONT_AGREE',
      }),
    });
    return !!ret.value;
  };
};

export default function AddCreditModal({ onClose }) {
  const [api] = useAuth();

  const intl = useIntl();

  const [selectedUser, setSelectedUser] = useState(null);
  const [operation, setOperation] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [credit, setCredit] = useState('');

  const selectedUserId = selectedUser && selectedUser.id;
  const selectedOperationId = operation && operation.id;

  useEffect(() => {
    if (selectedUser && api) {
      api.tokenized
        .get('/credit/get/' + selectedUserId)
        .then((a) => setCredit(a.data));
    }
  }, [selectedUser, api]);

  const areYouSure = useAreYouSure({
    selectedUser,
    operation,
    selectedAmount,
    credit,
    intl,
  });

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (await areYouSure()) {
        await api.tokenized.get(
          '/credit/add?userId=' +
            selectedUserId +
            '&amount=' +
            selectedAmount +
            '&type=' +
            selectedOperationId
        );

        onClose();
      }
    },
    [selectedUserId, selectedOperationId, selectedAmount, onClose, areYouSure]
  );
  return (
    <Modal isOpen toggle={onClose} size="xl">
      <ModalHeader toggle={onClose}>
        <FormattedMessage id="ADD_CREDIT" />
      </ModalHeader>

      <ValidatorProvider>
        <Form onSubmit={onSubmit}>
          <ModalBody>
            <Row>
              <Col md="2">
                <FormattedMessage id="MEMBER" />:
              </Col>
              <Col md="10">
                <UserSelect value={selectedUser} onChange={setSelectedUser} />
              </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <Col md="2">
                <FormattedMessage id="CREDIT_AMOUNT" />:{' '}
              </Col>
              <Col md="10">{credit && credit.creditAmount}</Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <Col md="2">
                <FormattedMessage id="OPERATION" />:
              </Col>
              <Col md="10">
                <ApiSelect
                  value={operation}
                  onChange={setOperation}
                  staticOptions={operationOptions}
                  placeholder={
                    <FormattedMessage id="NEW_ANL_PLACEHOLDER_TEXT" />
                  }
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <Col md="2">
                <FormattedMessage id="CREDIT_AMOUNT" />:
              </Col>
              <Col md="10">
                <Input
                  type="number"
                  value={selectedAmount}
                  onChange={(a) => setSelectedAmount(a.currentTarget.value)}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              disabled={selectedAmount == 0 || !operation || !selectedUser}
            >
              <FormattedMessage id="OK" />
            </Button>
          </ModalFooter>
        </Form>
      </ValidatorProvider>
    </Modal>
  );
}
