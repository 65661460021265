import React from 'react';
import { Table, Row } from 'reactstrap';
import { FormattedNumber } from 'react-intl';

//should be review
export function Qualitative({ qualitative, disable }) {
  const contentForBnpl =
    qualitative && qualitative[0] && qualitative[0].givenAnswers;
  return (
    <Table striped responsive bordered hover size="sm">
      <thead>
        <tr>
          <th>Questions</th>
          {contentForBnpl && (
            <>
              <th>Parameter</th>
              <th>Given Answer for Parameter</th>
            </>
          )}
          <th>Answer</th>
          <th>Score</th>
          {!disable && (
            <>
              {' '}
              <th>Weight</th>
              <th>Weighted Score</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {qualitative &&
          qualitative.map((ab, index) => (
            <tr key={index}>
              <td>{ab['question']}</td>
              {ab.givenAnswers && contentForBnpl && (
                <>
                  <td>
                    {ab.givenAnswers[0]?.paremeter}
                    <br></br>
                    {ab.givenAnswers[1]?.paremeter}
                  </td>
                  <td>
                    {' '}
                    {ab.givenAnswers[0] &&
                      ab.givenAnswers[0].givenAnswer &&
                      (ab.givenAnswers[0].givenAnswer.includes('/') ||
                      ab.givenAnswers[0].givenAnswer.includes('Type') ? (
                        ab.givenAnswers[0].givenAnswer
                      ) : (
                        <FormattedNumber
                          value={ab.givenAnswers[0].givenAnswer}
                          minimumFractionDigits={0}
                          maximumFractionDigits={2}
                        />
                      ))}
                    <br></br>
                    {ab.givenAnswers[1] &&
                      ab.givenAnswers[1].givenAnswer &&
                      (ab.givenAnswers[1].givenAnswer.includes('/') ||
                      ab.givenAnswers[1].givenAnswer.includes('Type') ? (
                        ab.givenAnswers[1].givenAnswer
                      ) : (
                        <FormattedNumber
                          value={ab.givenAnswers[1].givenAnswer}
                          minimumFractionDigits={0}
                          maximumFractionDigits={2}
                        />
                      ))}{' '}
                  </td>
                </>
              )}
              {ab.givenAnswers === null && contentForBnpl && (
                <>
                  <td></td>
                  <td></td>
                </>
              )}
              <td>{ab['answer']}</td>
              <td>
                {
                  <FormattedNumber
                    value={ab['score']}
                    minimumFractionDigits={0}
                    maximumFractionDigits={2}
                  />
                }
              </td>
              {!disable && (
                <>
                  {' '}
                  <td>
                    <FormattedNumber
                      value={ab['weight']}
                      minimumFractionDigits={0}
                      maximumFractionDigits={2}
                    />
                  </td>
                  <td>
                    <FormattedNumber
                      value={ab['weightedScore']}
                      minimumFractionDigits={0}
                      maximumFractionDigits={2}
                    />
                  </td>
                </>
              )}
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export function Penalty({ penalty, disable }) {
  return (
    <>
      <Table responsive bordered hover size="sm">
        <thead>
          <tr>
            <th>B. Penalty Questions</th>

            <th>Answer</th>
            <th colSpan={2}>Score</th>
            {!disable && (
              <>
                <th>Weighted Score</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              B.1. Number of Rejected Cheques{' '}
              {penalty['numberOfRejectedCheques']['question']}
            </td>

            <td>{penalty['numberOfRejectedCheques']['answer']}</td>
            <td colSpan={2}>{penalty['numberOfRejectedCheques']['score']}</td>
            {!disable && (
              <>
                <td>{penalty['numberOfRejectedCheques']['weightedScore']}</td>
              </>
            )}
          </tr>
          <tr>
            <td>
              B.2. Number of Settled Litigations{' '}
              {penalty['numberOfSettledLitigations']['question']}
            </td>

            <td>{penalty['numberOfSettledLitigations']['answer']}</td>
            <td colSpan={2}>
              {penalty['numberOfSettledLitigations']['score']}
            </td>
            {!disable && (
              <>
                <td>
                  {penalty['numberOfSettledLitigations']['weightedScore']}
                </td>
              </>
            )}
          </tr>
          <tr>
            <th>B.3. Number of Delayed Payments on Loans</th>
            <th>Answer</th>
            {!disable && (
              <>
                <th>Days of Delayed Payments</th>
                <th> Score for Each Delay</th>
                <th>Weighted Score</th>
              </>
            )}
          </tr>
          {penalty['numberOfDelayedPaymentsOnLoans'].map((ab, index) => (
            <tr key={index}>
              <td></td>
              <td>{ab['days']}</td>
              {!disable && (
                <>
                  <td>{ab['number']}</td>

                  <td>{ab['scoreForEachDelay']}</td>
                  <td>{ab['weightedScore']}</td>
                </>
              )}
            </tr>
          ))}
          <tr>
            <th>B.4. Number of Delayed Payments on Credit Cards</th>
            <th>Days of Delayed Payments</th>
            {!disable && (
              <>
                <th> Score for Each Delay</th>
                <th>Weighted Score</th>
              </>
            )}
          </tr>
          {penalty['numberOfDelayedPaymentsOnCreditCards'].map((ab, index) => (
            <tr key={index}>
              <td></td>
              <td>{ab['days']}</td>
              {!disable && (
                <>
                  <td>{ab['number']}</td>
                  <td>{ab['scoreForEachDelay']}</td>
                  <td>{ab['weightedScore']}</td>
                </>
              )}
            </tr>
          ))}
          <tr>
            <td>Total Score for Penalty Questions</td>
            <td colSpan={4}>{penalty['totalScoreOfPenaltyQuestions']}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

export function Observation({
  observationC1,
  observationC2,
  observationC3,
  disabled,
  companyCode,
}) {
  if (observationC2) {
    observationC2.statementRows.forEach((item, i) => {
      const keys = Object.keys(item);
      const nullValues = keys.every(
        (key) => item[key] === '' || item[key] === 0
      );
      if (nullValues) {
        delete observationC2.statementRows[i];
      }
    });
  }

  const ta3meedbnplContent = (
    <>
      <thead>
        <tr>
          <th> C.1. Credit History Questions</th>
          <th>Answer</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>C.1.1. Loan Duration</td>
          <td>
            {
              <FormattedNumber
                value={observationC1['loanDuration']}
                minimumFractionDigits={0}
                maximumFractionDigits={2}
              />
            }
          </td>
        </tr>
        <tr>
          <td>
            C.1.2. Is There Current Outstanding Fund Payables Due to The
            Merchant?
          </td>
          <td>{observationC1['payableMerchant']}</td>
        </tr>
        <tr>
          <td>C.1.3. Is There an Outstanding Default?</td>
          <td>{observationC1['defaultOutstanding']}</td>
        </tr>
        <tr>
          <td>C.1.4. Is There an Unsettled Litigation?</td>
          <td>{observationC1['litigationUnsettled']}</td>
        </tr>
        <tr>
          <td>C.1.5. Is There an Outstanding Delayed Payment?</td>
          <td>{observationC1['delayOutstanding']}</td>
        </tr>
        <tr>
          <td>C.1.6. Is GOSI Compliance Certificate Valid?</td>
          <td>{observationC1['gosiCompliance']}</td>
        </tr>
        <tr>
          <td>
            C.1.7. Does the Client have Certificate from The Chamber of
            Commerce?
          </td>
          <td>{observationC1['chamberCommerce']}</td>
        </tr>

        <tr>
          <td>
            C.1.8. Is the Owner&apos;s or the Delegated Person&apos;s Age is
            Less than 25 or Older 80 Years Old?
          </td>
          <td>{observationC1['ownerAge']}</td>
        </tr>
      </tbody>
    </>
  );

  const ta3meedCntent = (
    <>
      <thead>
        <tr>
          <th> C.1. Credit History Questions</th>
          <th>Answer</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>C.1.1. Requested Loan Amount</td>
          <td>{observationC1['requestedLoanAmount']}</td>
        </tr>
        <tr>
          <td>C.1.2. Project Value Amount</td>
          <td>{observationC1['projectValueAmount']}</td>
        </tr>

        <tr>
          <td>C.1.3. Outstanding Loans Amount</td>
          <td>{observationC1['outstandingLoansAmount']}</td>
        </tr>
        <tr>
          <td>C.1.4. Loan Duration</td>
          <td>{observationC1['loanDuration']}</td>
        </tr>
        <tr>
          <td>
            C.1.5. The Total Amount of Debt until Payment Due Date (+ Tameed
            Loan)
          </td>
          <td>{observationC1['totalAmountDebtUntilPaymentDueDate']}</td>
        </tr>
        <tr>
          <td>C.1.6. Total Potential Debt / Total Deposits</td>
          <td>{observationC1['totalPotentialDebt_TotalDeposits_Ratio']}</td>
        </tr>
        <tr>
          <td>C.1.7. Will There be an Assignment of Proceeds?</td>
          <td>{observationC1['anyAssignmentProceeds']}</td>
        </tr>
        <tr>
          <td>C.1.8. Is There an Outstanding Default?</td>
          <td>{observationC1['anyDefaultOnOutstanding']}</td>
        </tr>
        <tr>
          <td>C.1.9. Is There an Unsettled Litigation?</td>
          <td>{observationC1['anyUnsettledLitigation']}</td>
        </tr>
        <tr>
          <td>C.1.10. Is There an Outstanding Delayed Payment?</td>
          <td>{observationC1['anyOutstandingDelayedPayment']}</td>
        </tr>
        <tr>
          <td>C.1.11. Is the CR Valid?</td>
          <td>{observationC1['isCRValid']}</td>
        </tr>
        <tr>
          <td>C.1.12. Is GOSI Compliance Certificate Valid?</td>
          <td>{observationC1['isGOSICertificateValid']}</td>
        </tr>
        <tr>
          <td>C.1.13. Is the Saudization Certificate Valid?</td>
          <td>{observationC1['isSaudizationCertificateValid']}</td>
        </tr>
        <tr>
          <td>C.1.14. Is the Client in Compliance with ZATCA?</td>
          <td>{observationC1['isClientComplianceZATCA']}</td>
        </tr>
        <tr>
          <td>
            C.1.15. Does the Client have Certificate from The Chamber of
            Commerce?
          </td>
          <td>{observationC1['hasClientCertificateChamberCommerce']}</td>
        </tr>
        <tr>
          <td>C.1.16. Has the Company Established Less than a Year?</td>
          <td>{observationC1['hasCompanyEstablishedLessYear']}</td>
        </tr>
        <tr>
          <td>
            C.1.17. Is the Owner&apos;s or the Delegated Person&apos;s Age is
            Less than 25 or Older 80 Years Old?
          </td>
          <td>{observationC1['isOwnerLessThan25Older80']}</td>
        </tr>
      </tbody>
    </>
  );
  const observationContent = {
    ta3meedbnpl: ta3meedbnplContent,
    ta3meed: ta3meedCntent,
  };
  return (
    <>
      {observationC1 && (
        <Row>
          <Table striped responsive bordered hover size="sm">
            {observationContent[companyCode]}
          </Table>
        </Row>
      )}
      {observationC2 && (
        <Row style={{ marginTop: '2em' }}>
          <Table striped responsive bordered hover size="sm">
            <thead>
              <tr>
                <th colSpan={8}> C.3. Bank Statement Ratios</th>
              </tr>
              <tr>
                {!disabled && <th>Bank Account Names</th>}
                <th>Currency</th>
                <th>Statement Period</th>
                {!disabled && <th>Beginning Balance</th>}
                <th>Total Amount of Deposits</th>
                <th>Deposits from Contracts</th>
                {!disabled && (
                  <>
                    <th>Total Amount of Credits (Withdrawals)</th>
                    <th>Ending Balance</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {observationC2['statementRows'].map((ab, index) => (
                <tr key={index}>
                  {!disabled && <td>{ab['bankAccountName']}</td>}
                  <td>{ab['currency']}</td>
                  <td>{ab['statementPeriod']}</td>
                  {!disabled && <td>{ab['beginningBalance']}</td>}
                  <td>{ab['totalAmountOfDeposits'].toLocaleString('en-US')}</td>
                  <td>{ab['depositsFromContracts'].toLocaleString('en-US')}</td>
                  {!disabled && (
                    <>
                      <td>
                        {ab['totalAmountOfCredits'].toLocaleString('en-US')}
                      </td>
                      <td>{ab['endingBalance']}</td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      )}
      {observationC3 && (
        <Row style={{ marginTop: '2em' }}>
          <Table striped responsive bordered hover size="sm">
            <thead>
              <tr>
                <th> C.3. Bank Statement Ratios</th>
              </tr>
              <tr>
                <th> Ratios</th>
                <th> Result</th>
              </tr>
            </thead>
            <tbody>
              {!disabled && (
                <>
                  <tr>
                    <td>
                      C.3.1. Total Amount of Deposits For All Bank Accounts
                    </td>
                    <td>{observationC3['totalAmountOfDepositsForAll']}</td>
                  </tr>
                  <tr>
                    <td>
                      C.3.2. Total Amount of Deposits From Contracts for All
                      Bank Accounts
                    </td>
                    <td>
                      {
                        observationC3[
                          'totalAmountOfDepositsFromContractsForAll'
                        ]
                      }
                    </td>
                  </tr>
                </>
              )}

              <tr>
                <td>C.3.3. Desposits from Contracts / Total Deposits</td>
                <td>
                  {observationC3['depositsFromContracts_TotalDepositRatio']}
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
      )}
    </>
  );
}

export function CombinedScore({ combinedScore }) {
  return (
    <Row className="mt-2">
      <Table striped responsive bordered hover size="sm">
        <thead>
          <tr>
            <th>Combined Score</th>
            <th>Scoring Weights</th>
            <th>Weighted Score</th>
            <th>Score Before Weight</th>
          </tr>
        </thead>
        <tbody>
          {combinedScore &&
            combinedScore.map((ab, index) => (
              <tr key={index}>
                <td>{ab['scoreName']}</td>
                <td>{ab['scoringWeight']}</td>
                <td>{ab['weightedScore']}</td>
                <td>{ab['scoreWithoutWeight']}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Row>
  );
}

export function SurveyResult({ surveyResult }) {
  return (
    <Table striped responsive bordered hover size="sm">
      <thead>
        <tr></tr>
      </thead>
      <tbody>
        <tr>
          <td>{surveyResult[0].title}</td>
          <td>{surveyResult[0].result}</td>
        </tr>
        <tr>
          <td>{surveyResult[1].title}</td>
          <td>{surveyResult[1].result}</td>
        </tr>
      </tbody>
    </Table>
  );
}
