import React from 'react';
import useFetch from '../../../Hooks/useFetch';
import { Label, Row, Table } from 'reactstrap';
import { Alerts } from '../../../Components/Alerts';
import { Observation } from '../../../Common/ScoringTab';
import { FormattedNumber } from 'react-intl';

const bankStatemntHeaders = [
  'Total Debit Amount',
  'Number of Deposits',
  'Average Deposits Ratio',
];

export const ObservationBnpl = ({ firmInfo }) => {
  const [observationC1, loading] = useFetch('/bnpl/observation/' + firmInfo);
  const [bankStatement, loading2] = useFetch(
    '/bnpl/bankStatement/edit/' + firmInfo
  );
  let newBankStatement = {};
  if (loading || loading2) {
    return (
      <Alerts
        color="primary"
        className="fas fa-spin fa-spinner"
        messageId={'LOADING'}
        label=""
      />
    );
  }
  if (bankStatement) {
    newBankStatement = {
      totalDebitAmount: Number(bankStatement.totalDebitAmount),
      numberOfDeposits: Number(bankStatement.numberOfDeposits),
      totalDebitAmount_NumberOfDepositsRatio: Number(
        bankStatement.totalDebitAmount_NumberOfDepositsRatio
      ),
    };
  }

  return (
    <>
      <Row>
        <h6>Observation</h6>
        {observationC1 ? (
          <Observation
            observationC1={observationC1}
            disabled={false}
            observationC2={null}
            observationC3={null}
            companyCode={'ta3meedbnpl'}
          />
        ) : (
          <Label>No Data Available.</Label>
        )}
      </Row>

      <Row>
        <h6>Bank Statement</h6>
        {bankStatement ? (
          <Table striped responsive bordered hover size="sm">
            <thead>
              <tr>
                {bankStatemntHeaders.map((e, i) => (
                  <td key={i}>{e}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {Object.keys(newBankStatement).map((e, i) => (
                  <td key={i}>
                    <FormattedNumber
                      value={newBankStatement[e]}
                      maximumFractionDigits={2}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
        ) : (
          <Label>No Data Available.</Label>
        )}
      </Row>
    </>
  );
};
