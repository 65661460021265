import React from 'react';

export const DocxViewer = (props) => {
  const { documentContent, startIndex, endIndex } = props;

  return (
    <div>
      {' '}
      {documentContent && (
        <HighlightText
          text={documentContent}
          start={startIndex}
          end={endIndex}
        />
      )}
    </div>
  );
};

const HighlightText = ({ text, start, end }) => {
  const before = text.slice(0, start);
  const highlight = text.slice(start, end);
  const after = text.slice(end);

  return (
    <div style={{ whiteSpace: 'pre-wrap' }}>
      {before}
      <span style={{ backgroundColor: 'yellow' }}>{highlight}</span>
      {after}
    </div>
  );
};
