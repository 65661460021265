import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primereact/resources/primereact.min.css';
import { FormattedMessage } from 'react-intl';
import { FormattedDate } from 'react-intl';
import Swal from 'sweetalert2';
import { useIntl } from 'react-intl';
import { LinkButton, DeleteButton } from '../../ActionButtons/ActionButtons';
import { icons } from '../../../Icons/icons';
import { useAuth } from 'react-admin-base';
import { DownloadExcelFromUrlButton } from '../../ActionButtons/ActionButtons';
import { useState } from 'react';
import { InputField } from '../../Input';
import { useFetch } from 'react-admin-base';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../UserProvider';
import { providerTypeNums } from '../../../Util/Constants';

export const CustomersTable_ = (props) => {
  const intl = useIntl();
  const [api] = useAuth();
  const navigate = useNavigate();

  const user = useUser();

  const userId = user.id;
  const [company] = useFetch('/company/get/user/' + userId);
  const companyCode = company && company.code;
  const isAktifBank = companyCode === 'aktifbank';
  const groupId = props.groupId;

  const url = groupId
    ? `customer/get/paginate/groupCustomers/${groupId}`
    : 'customer/get/all';

  const [lazyParams, setLazyParams] = React.useState({
    first: 1,
    rows: 10,
    page: 1,
    sortField: 'id',
    sortOrder: 1,
    filters: {},
    multiSortMeta: [],
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const [loading, setLoading] = React.useState(false);
  const [customers, setCustomers] = React.useState(null);

  const onPage = (event) => {
    setLazyParams({ ...event, page: event.page + 1 });
  };

  const onFilter = (event) => {
    console.log('event', event);
    setLazyParams(event);
  };

  const onSort = (event) => {
    setLazyParams((prev) => ({
      ...event,
      page: 1,
    }));
  };

  React.useEffect(() => {
    fetchData();
  }, [lazyParams, globalFilterValue]);

  const fetchData = async () => {
    setLoading(true);
    console.log('url: ', url);
    try {
      const response = await api.tokenized.get(
        `/${url}?page=${lazyParams.page}&itemPerPage=${lazyParams.rows}&query=${globalFilterValue}&sort=${lazyParams.sortField}&desc=${lazyParams.sortOrder === 1 ? true : false}`
      );
      setCustomers(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const totalRecords = customers ? customers.itemCount : 0;

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;

    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <InputField
          name="globalFilterValue"
          type="text"
          onChange={onGlobalFilterChange}
          value={globalFilterValue}
          placeHolder={intl.formatMessage({ id: 'SEARCH' })}
          style={{ width: '15%' }}
        />
        <LinkButton
          url={'/customer/add'}
          text={intl.formatMessage({
            id: 'ADD_NEW_CUSTOMER',
          })}
          size="sm"
          color="success"
          iconClassName={icons.add}
        />
      </div>
    );
  };

  const footer = (
    <span
      style={{
        position: 'absolute',
        bottom: '20px',
        right: '5px',
      }}
    >
      <FormattedMessage id="CUSTOMER_COUNT" />: {totalRecords}
    </span>
  );
  return (
    <div className="flex flex-col">
      <DataTable
        value={customers ? [...customers.data] : []}
        className="mt-3"
        scrollable
        loading={loading}
        paginator
        rowsPerPageOptions={[5, 10, 25, 50]}
        onPage={onPage}
        first={lazyParams.first}
        rows={lazyParams.rows}
        totalRecords={totalRecords}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
        currentPageReportTemplate=" "
        emptyMessage={intl.formatMessage({ id: 'NO_DATA_IS_AVAILABLE' })}
        lazy
        header={renderHeader}
        onGlobalFilterChange={onFilter}
        footer={footer}
        onRowClick={(e) => {
          companyCode !== 'ta3meed' &&
            navigate('/customer/' + e.data.id + '/profile');
        }}
        selectionMode="single"
      >
        <Column field="id" header={<i className="fas fa-hashtag"></i>} />
        <Column
          field="name"
          header={<FormattedMessage id={'name'} />}
          sortable
          sortField="name"
        />
        <Column
          field="lastAnalyzeYear"
          header={`  ${intl.formatMessage({ id: 'LAST' })}
            ${intl.formatMessage({ id: 'PREV_ANL_ANALYSIS_DATE' })}`}
          sortField="lastAnalyzeYear"
          body={(rowData) => {
            return (
              <span>
                {rowData.lastAnalyzeYear && rowData.lastAnalyzeMonth
                  ? `${rowData.lastAnalyzeYear}/${rowData.lastAnalyzeMonth}`
                  : ''}
              </span>
            );
          }}
          sortable
        />

        <Column
          field="providerType"
          header={`  ${intl.formatMessage({ id: 'LAST' })}
            ${intl.formatMessage({ id: 'companyAnalysisType' })}`}
          sortField="providerType"
          body={(rowData) => {
            return providerTypeNums.includes(rowData.providerType) ? (
              <FormattedMessage id={rowData.providerType} />
            ) : (
              ''
            );
          }}
          sortable
        />

        <Column
          header={<i className="fas fa-water" />}
          body={(rowData) => {
            return (
              companyCode !== 'ta3meed' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '0.5rem',
                  }}
                >
                  {isAktifBank && (
                    <LinkButton
                      url={`/customer/${rowData.id}/profile?startAnalysis=true`}
                      text={intl.formatMessage({
                        id: 'NEW_ANALYSIS_TITLE',
                      })}
                      size="sm"
                      color="success"
                      iconClassName={icons.startAnalysis}
                    />
                  )}
                  <LinkButton
                    url={'/customer/' + rowData.id + '/profile'}
                    text={intl.formatMessage({
                      id: 'PROFILE',
                    })}
                    size="sm"
                    color="info"
                    iconClassName={icons.profile}
                  />
                </div>
              )
            );
          }}
          style={{
            width: '10%',
          }}
        />
      </DataTable>
    </div>
  );
};
