/* eslint-disable no-useless-escape */

import React, { useState, useEffect } from 'react';
import {
  Col,
  FormGroup,
  Row,
  Input,
  Label,
  Form,
  Card,
  CardBody,
} from 'reactstrap';
import { useAuth, useFetch } from 'react-admin-base';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  AsyncPaginateInput,
  InputField,
  PhoneNumberInput,
  CategorySelectInput,
} from '../Input';
import {
  countryList,
  cityOfTurkey,
  currencyOptions,
} from '../../Util/Constants';
import { useUser } from '../UserProvider';
import NumberFormat from 'react-number-format';
import { taxNoCheck, validTCKN } from '../../Util/Validations';
import { useFormik } from 'formik';
import { Loading } from '../Loading';
import { Button_ } from './../ActionButtons/ActionButtons';
import { icons } from './../../Icons/icons';
import { useIntl } from 'react-intl';
import { Alerts } from '../Alerts';
import Swal from 'sweetalert2';
const showSwal = async (html, intl, setError, navigate, customerId) => {
  const url = '/customer/' + customerId + '/profile';

  const content = `
     <p> ${html} </p>
     <p>  
       <a href=${url}  target="_blank" rel="noopener noreferrer">
  ${intl.formatMessage({
    id: 'GO_CUSTOMER_PROFILE',
  })}
    </a>
    </p>
    `;

  await Swal.fire({
    title: `<strong>  ${intl.formatMessage({
      id: 'ERROR',
    })}</strong>`,
    icon: 'error',
    html: content,
    confirmButtonText: intl.formatMessage({ id: 'OK' }),
  }).then(function () {
    setError &&
      setError((prev) => {
        return { ...prev, ['message']: '' };
      });
  });
};

const defaultState = {
  name: '',
  countryName: 'Türkiye',
  cityName: '',
  fullAddress: '',
  phoneNumber: '',
  number: '',
  email: '',
  taxNumber: '',
  tradeRegistrationNumber: '',
  currency: 'TRY',
  numberOfEmployees: '',
  kobiClass: '',
  sector: '',
  isGroupCompany: false,
  groupId: 0,
  companyType: 0,
  countryShortName: 'TR',
  loading: false,
  done: false,
};

const regex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const findCountryShortName = (value) => {
  const country = countryList.find((c) => c.countryEn === value);

  return country ? country.iso.toLowerCase() : '';
};

export const kobiOptions = [
  {
    enName: 'General',
    name: 'Genel',
    value: 0,
  },
  {
    enName: 'Micro',
    name: 'Mikro',
    value: 1,
  },
  {
    enName: 'Small',
    name: 'Küçük',
    value: 2,
  },
  {
    enName: 'Medium',
    name: 'Orta',
    value: 3,
  },
  {
    enName: 'Large',
    name: 'Büyük',
    value: 4,
  },
];

export const CustomerForm = (props) => {
  const { isGroup, id, companyCode } = props;
  const [api] = useAuth();
  const navigate = useNavigate();
  const user = useUser();
  const userId = user && user.id;
  const [company] = useFetch('/company/get/user/' + userId);

  const intl = useIntl();

  const formik = useFormik({
    initialValues: {
      ...defaultState,
    },
    validateOnChange: true,
    validate: (data) => {
      const error = {};

      if (!data.sector && companyCode !== 'global') {
        error.sector = 'REQUIRED';
      }
      if (!data.name) {
        error.name = 'REQUIRED';
      }
      if (data.name?.length > 150) {
        error.name = 'VALID150';
      }
      if (!data.countryName) {
        error.countryName = 'REQUIRED';
      }

      if (!data.cityName) {
        error.cityName = 'REQUIRED';
      } else if (data.cityName?.length > 50) {
        error.cityName = 'VALID50';
      }
      if (!data.fullAddress) {
        error.fullAddress = 'REQUIRED';
      } else if (data.fullAddress?.length > 50) {
        error.fullAddress = 'VALID50';
      }

      if (!data.email) {
        error.email = 'REQUIRED';
      } else if (regex.test(data.email) === false) {
        error.email = 'VALID';
      }
      if (!data.currency) {
        error.currency = 'REQUIRED';
      }

      if (!data.taxNumber && companyCode === 'aktifbank' && !isGroup) {
        error.taxNumber = 'REQUIRED';
      }
      if (
        data.countryName === 'Türkiye' &&
        data.taxNumber &&
        (data.companyType === 0
          ? !taxNoCheck(data.taxNumber)
          : !validTCKN(data.taxNumber))
      ) {
        error.taxNumber = 'VALID';
      }

      if (
        !data.tradeRegistrationNumber &&
        companyCode === 'aktifbank' &&
        !isGroup
      ) {
        error.tradeRegistrationNumber = 'REQUIRED';
      }

      if (
        !data.numberOfEmployees &&
        companyCode === 'aktifbank' &&
        !data.isGroupCompany
      ) {
        error.numberOfEmployees = 'REQUIRED';
      }

      if (
        data.kobiClass === '' &&
        companyCode === 'aktifbank' &&
        !data.isGroupCompany
      ) {
        error.kobiClass = 'REQUIRED';
      }

      if (data.groupId === 1) {
        error.groupId = 'REQUIRED';
      }

      return error;
    },

    onSubmit: async (data, { resetForm }) => {
      try {
        formik.setFieldValue('loading', true);
        const { loading, countryShortName, done, ...rest } = data;
        const data_ = rest;
        const response = await api.tokenized.post('/customer/add', {
          ...data_,
          groupId: !data_.groupId ? 0 : data_.groupId.value,
          sector: data_.sector ? { code: data_.sector.code } : '',
          groupName: !data_.groupId ? '' : data_.groupId.label,
          kobiClass: data_.kobiClass ? data_.kobiClass : 0,

          numberOfEmployees:
            Number(
              data_.numberOfEmployees
                .toString()
                .replaceAll('.', '')
                .replace(',', '.')
            ) || 0,
          countryName:
            data_.countryName === 'Türkiye' ? 'Turkey' : data_.countryName,
        });

        if (response && response.status === 200) {
          formik.setFieldValue('done', true);

          {
            /*  setTimeout(async () => {
            const customerId = response?.data?.requestModel;

            if (customerId) {
              navigate(`/customer/${customerId}/profile`, {
                state: { from: '/customer/add' },
              });
              */
          }
          setTimeout(() => {
            if (userId) {
              navigate('/customers');
            } else {
              navigate('/customer/add');
            }
          }, 2000);

          //  setData(response.data);
        } else {
          throw new Error();
        }
      } catch (e) {
        const response = e?.response;
        const message = response?.data?.message;
        const customerId = response?.data?.requestModel?.id;
        const message_ = message
          ? localStorage.frayjet_frayjet_language === 'en'
            ? message.eng
            : message.tr
          : '';

        showSwal(message_, intl, formik.setErrors, navigate, customerId);
      } finally {
        formik.setFieldValue('loading', false);
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      formik.setFieldValue('loading', true);
      await api.tokenized.get(`/customer/get/${id}`).then((a) => {
        formik.setValues(
          a.data.phoneNumber && a.data.taxNumber
            ? {
                ...a.data,
                phoneNumber: a.data.phoneNumber.toString().replace('+', ''),
                number: a.data.number.toString().replace('+', ''),
                sector: a.data.sector?.code ? { ...a.data.sector } : null,
                groupId: { label: a.data.groupName, value: a.data.groupId },
              }
            : {
                ...a.data,
                sector: a.data.sector?.code ? { ...a.data.sector } : null,
                groupId: { label: a.data.groupName, value: a.data.groupId },
              }
        );
      });

      try {
        const response = await api.tokenized.get('/customer/get/' + id);
        if (response && response.status === 200) {
          formik.setValues(
            response.data.phoneNumber && response.data.taxNumber
              ? {
                  ...response.data,
                  phoneNumber: response.data.phoneNumber
                    .toString()
                    .replace('+', ''),
                  number: response.data.number.toString().replace('+', ''),
                  sector: response.data.sector?.code
                    ? { ...response.data.sector }
                    : null,
                  groupId: {
                    label: response.data.groupName,
                    value: response.data.groupId,
                  },
                }
              : {
                  ...response.data,
                  sector: response.data.sector?.code
                    ? { ...response.data.sector }
                    : null,
                  groupId: {
                    label: response.data.groupName,
                    value: response.data.groupId,
                  },
                }
          );
          //  setData(response.data);
        } else {
          throw new Error('Bir hata meydana geldi.');
        }
      } catch (e) {
        console.log(e);
        //    setData(null);
        //    swal.fire({
        //      icon: 'error',
        //      title: 'Bir Hata Meydana Geldi.',
        //      showConfirmButton: false,
        //      width: '300px',
        //    });
      } finally {
        formik.setFieldValue('loading', false);
      }
    };

    if (id) fetchData();
  }, [id]);

  useEffect(() => {
    if (isGroup) handleChangeValue('isGroupCompany', isGroup);
  }, [isGroup]);

  useEffect(() => {
    if (formik.values.countryName) {
      handleChangeValue('phoneNumber', '');
    }
    handleChangeValue(
      'countryShortName',
      findCountryShortName(formik.values.countryName)
    );
  }, [formik.values.countryName]);

  const handleChangeValue = (field, value) => {
    formik.setFieldValue(field, value);
  };
  React.useEffect(() => {
    const item = document.getElementsByClassName(' css-13cymwt-control');
    if (item.length > 0)
      if (formik.errors.sector && item[0]) {
        item[0].style.border = '1px solid #ff0000';
      } else {
        item[0].style.border = '1px solid #d2d2d2';
      }
  }, [formik.errors.sector]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Card>
        <CardBody>
          <Row>
            <Form>
              {!formik.values.isGroupCompany && (
                <>
                  <Row>
                    <Label>
                      <FormattedMessage id="TYPE_OF_COMPANY" />
                    </Label>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <Label>
                        <FormattedMessage id="TYPE_OF_COMPANY1" />
                      </Label>
                      <InputField
                        tooltipId="TYPE_OF_COMPANY1"
                        style={{ marginLeft: '1em' }}
                        name="companyType"
                        value={0}
                        // onChange={(e) =>
                        //   handleChangeOptions({
                        //     target: {
                        //       name: 'companyType',
                        //       value: e.target.checked ? 0 : 1,
                        //     },
                        //   })
                        // }
                        onChange={(e) =>
                          handleChangeValue(
                            e.target.name,
                            e.target.checked ? 0 : 1
                          )
                        }
                        type="checkbox"
                        checked={formik.values.companyType === 0}
                        tooltip={
                          <FormattedMessage id="TYPE_OF_COMPANY1_TOOLTIP" />
                        }
                      />
                    </Col>
                    <Col sm="5">
                      <Label>
                        <FormattedMessage id="TYPE_OF_COMPANY2" />
                      </Label>

                      <InputField
                        tooltipId="TYPE_OF_COMPANY2"
                        style={{ marginLeft: '1em' }}
                        name="companyType"
                        value={1}
                        // onChange={(e) =>
                        //   handleChangeOptions({
                        //     target: {
                        //       name: 'companyType',
                        //       value: e.target.checked ? 1 : 0,
                        //     },
                        //   })
                        // }
                        onChange={(e) =>
                          handleChangeValue(
                            e.target.name,
                            e.target.checked ? 1 : 0
                          )
                        }
                        type="checkbox"
                        checked={formik.values.companyType === 1}
                        tooltip={
                          <FormattedMessage id="TYPE_OF_COMPANY2_TOOLTIP" />
                        }
                      />
                    </Col>
                  </Row>

                  <Row></Row>
                  <hr />
                </>
              )}

              <FormGroup>
                <Row>
                  <Col md={3}>
                    <InputField
                      name="name"
                      id="analysisCustomerId"
                      type="text"
                      onChange={(e) =>
                        handleChangeValue(e.target.name, e.target.value)
                      }
                      validation={formik.touched.name ? formik.errors : {}}
                      value={formik.values['name']}
                    />
                  </Col>
                  <Col md={3}>
                    <InputField
                      type="select"
                      name="countryName"
                      id="COUNTRY_NAME"
                      onChange={(e) =>
                        handleChangeValue(e.target.name, e.target.value)
                      }
                      validation={
                        formik.touched.countryName ? formik.errors : {}
                      }
                      value={formik.values.countryName}
                    >
                      {countryList.map((o, i) => (
                        <option value={o.countryEn} key={`${i}c`}>
                          {localStorage.frayjet_frayjet_language === 'en'
                            ? o.countryEn
                            : o.countryTr}
                        </option>
                      ))}
                    </InputField>
                  </Col>
                  <Col md={3}>
                    {formik.values.countryName === 'Türkiye' ? (
                      <InputField
                        name="cityName"
                        id="CITY_NAME"
                        type="select"
                        onChange={(e) =>
                          handleChangeValue(e.target.name, e.target.value)
                        }
                        value={formik.values['cityName']}
                        validation={
                          formik.touched.cityName ? formik.errors : {}
                        }
                      >
                        <option value="">
                          <FormattedMessage id="NEW_ANL_PLACEHOLDER_TEXT" />
                        </option>
                        {cityOfTurkey.map((o) => (
                          <option value={o} key={`${o}c`}>
                            {o}
                          </option>
                        ))}
                      </InputField>
                    ) : (
                      <InputField
                        name="cityName"
                        id="CITY_NAME"
                        type="text"
                        onChange={(e) =>
                          handleChangeValue(e.target.name, e.target.value)
                        }
                        value={formik.values['cityName']}
                        validation={
                          formik.touched.cityName ? formik.errors : {}
                        }
                      />
                    )}
                  </Col>
                  <Col md={3}>
                    <InputField
                      name="fullAddress"
                      id="ADDRESS"
                      onChange={(e) =>
                        handleChangeValue(e.target.name, e.target.value)
                      }
                      value={formik.values.fullAddress}
                      validation={
                        formik.touched.fullAddress ? formik.errors : {}
                      }
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md={3}>
                    <PhoneNumberInput
                      id="PHONE_NUMBER"
                      country={formik.values.countryShortName}
                      value={formik.values['phoneNumber']}
                      onChange={(e) => {
                        handleChangeValue('phoneNumber', e);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <InputField
                      name="number"
                      id="CUSTOMER_NUMBER"
                      onChange={(e) => {
                        if (!/\D/g.test(e.target.value))
                          handleChangeValue(e.target.name, e.target.value);
                      }}
                      value={formik.values.number}
                    />
                  </Col>
                  <Col md={3}>
                    <InputField
                      name="email"
                      id="ADD_USER_EMAIL"
                      onChange={(e) =>
                        handleChangeValue(e.target.name, e.target.value)
                      }
                      value={formik.values['email']}
                      placeHolder="example@fray.com"
                      validation={formik.touched.email ? formik.errors : {}}
                    />
                  </Col>
                  {!formik.values.isGroupCompany && (
                    <Col md={3}>
                      <Label>
                        <FormattedMessage id="taxNo" /> / TCKN
                      </Label>
                      <InputField
                        name="taxNumber"
                        onChange={(e) => {
                          if (!/\D/g.test(e.target.value))
                            handleChangeValue(e.target.name, e.target.value);
                        }}
                        value={formik.values.taxNumber}
                        style={{
                          WebkitAppearance: 'none',
                          border:
                            formik.touched.taxNumber &&
                            formik.errors.taxNumber &&
                            '1px solid #ff0000',
                        }}
                      />
                      {formik.errors.taxNumber && formik.touched.taxNumber && (
                        <p
                          style={{
                            color: 'red',
                            opacity: 0.6,
                            position: 'absolute',
                            fontSize: '12px',
                          }}
                        >
                          {<FormattedMessage id={'taxNo2'} />}{' '}
                          {<FormattedMessage id={formik.errors.taxNumber} />}
                        </p>
                      )}
                    </Col>
                  )}
                </Row>
              </FormGroup>

              {!formik.values.isGroupCompany && (
                <FormGroup>
                  <Row>
                    <Col md={3}>
                      <InputField
                        name="tradeRegistrationNumber"
                        id="TRADE_REGISTRATION_NUMBER"
                        value={formik.values['tradeRegistrationNumber']}
                        onChange={(e) => {
                          if (/^[0-9\-]*$/.test(e.target.value))
                            handleChangeValue(e.target.name, e.target.value);
                        }}
                        validation={
                          formik.touched.tradeRegistrationNumber
                            ? formik.errors
                            : {}
                        }
                      />
                    </Col>
                    <Col md={3}>
                      <InputField
                        name="currency"
                        id="CURRENCY"
                        onChange={(e) =>
                          handleChangeValue(e.target.name, e.target.value)
                        }
                        value={formik.values.currency}
                        type="select"
                      >
                        <option value="">
                          <FormattedMessage id="NEW_ANL_PLACEHOLDER_TEXT" />
                        </option>
                        {currencyOptions.map((o) => (
                          <option value={o} key={`${o}c`}>
                            {o}
                          </option>
                        ))}
                      </InputField>
                    </Col>{' '}
                    <Col md={3}>
                      {' '}
                      <Label>
                        <FormattedMessage id="NUMBER_OF_EMPLOYEES" />
                      </Label>
                      <NumberFormat
                        allowNegative={false}
                        name={'numberOfEmployees'}
                        className="form-control"
                        thousandSeparator={'.'}
                        decimalSeparator={false}
                        value={formik.values.numberOfEmployees}
                        onChange={(e) =>
                          handleChangeValue(e.target.name, e.target.value)
                        }
                        decimalScale={0}
                        isAllowed={(values) => {
                          const { formattedValue, floatValue } = values;
                          return (
                            formattedValue === '' ||
                            (floatValue > 0 && floatValue <= 1000000000)
                          );
                        }}
                        style={{
                          WebkitAppearance: 'none',
                          border:
                            formik.errors.numberOfEmployees &&
                            formik.touched.numberOfEmployees &&
                            '1px solid #ff0000',
                        }}
                      />
                      {formik.errors.numberOfEmployees &&
                        formik.touched.numberOfEmployees && (
                          <p
                            style={{
                              color: 'red',
                              opacity: 0.6,
                              position: 'absolute',
                              fontSize: '12px',
                            }}
                          >
                            {<FormattedMessage id={'NUMBER_OF_EMPLOYEES'} />}{' '}
                            {
                              <FormattedMessage
                                id={formik.errors.numberOfEmployees}
                              />
                            }
                          </p>
                        )}
                    </Col>
                    <Col md={3}>
                      {' '}
                      <InputField
                        name="kobiClass"
                        id="KOBI_CLASS"
                        onChange={(e) =>
                          handleChangeValue(
                            e.target.name,
                            e.target.value ? Number(e.target.value) : ''
                          )
                        }
                        value={formik.values.kobiClass}
                        type="select"
                        validation={
                          formik.touched.kobiClass ? formik.errors : {}
                        }
                      >
                        <option value="">
                          <FormattedMessage id="NEW_ANL_PLACEHOLDER_TEXT" />
                        </option>
                        {kobiOptions.map((o, i) => (
                          <option value={o.value} key={i}>
                            {localStorage &&
                            localStorage.frayjet_frayjet_language === 'en'
                              ? o.enName
                              : o.name}
                          </option>
                        ))}
                      </InputField>
                    </Col>
                  </Row>
                </FormGroup>
              )}

              <FormGroup>
                <Row>
                  {company && company.code !== 'global' && (
                    <Col md={3}>
                      <CategorySelectInput
                        id="SECTOR_TYPE"
                        validation={formik.touched.sector ? formik.errors : {}}
                        name={'sector'}
                        value={formik.values.sector || ''}
                        onChange={(e) => {
                          handleChangeValue('sector', e);
                        }}
                        tooltip={<FormattedMessage id="SECTOR_TYPE_INFO" />}
                      />
                    </Col>
                  )}
                  {!formik.values.isGroupCompany && (
                    <Col md={3}>
                      <Row>
                        <Col>
                          <Label>
                            <FormattedMessage id="GROUP?" />
                          </Label>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Label for="grupCheck">
                            <FormattedMessage id="YES" />
                          </Label>
                          <Input
                            style={{ marginLeft: '1em' }}
                            id="grupCheck"
                            name="grupCheck"
                            type="checkbox"
                            checked={
                              formik.values.groupId &&
                              formik.values.groupId.value !== 0
                            }
                            onChange={(e) => {
                              const checked = e.target.checked;
                              if (!checked) handleChangeValue('groupId', 0);
                              else {
                                handleChangeValue('groupId', 1);
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {formik.values.groupId &&
                  formik.values.groupId.value !== 0 ? (
                    <Col md={3}>
                      <AsyncPaginateInput
                        id="GROUP_TITLE"
                        name="groupId"
                        value={formik.values['groupId']}
                        onChange={(e) => {
                          e &&
                            handleChangeValue(
                              e.target.name,
                              e.target.value || null
                            );

                          !e && handleChangeValue('groupId', 1);
                        }}
                        api={api}
                        url="/customer/get/groups"
                        label=""
                        validation={formik.touched.groupId ? formik.errors : {}}
                        groups
                      ></AsyncPaginateInput>
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>
              </FormGroup>
            </Form>
          </Row>
        </CardBody>
      </Card>

      <Row>
        <Col>
          <Button_
            text={intl.formatMessage({
              id: 'SEND',
            })}
            color="primary"
            iconClassName={icons.save}
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
            type="submit"
            onClick={formik.handleSubmit}
          />
        </Col>
      </Row>

      <Row>
        {formik.submitCount !== 0 && Object.keys(formik.errors).length > 0 && (
          <Alerts
            className="fas fa-times-circle"
            color="danger"
            messageId={'REQUIRED_FIELDS'}
          />
        )}
        {formik.values.done && (
          <Alerts
            className="fas fa-spin fa-spinner"
            color="success"
            messageId={
              id
                ? 'REDIRECT_CUSTOMERS'
                : isGroup
                  ? 'REDIRECT_ADD_CUSTOMERS'
                  : 'COMPLETED'
            }
          />
        )}
      </Row>
      {formik.values.loading && <Loading />}
    </div>
  );
};
