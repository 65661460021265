import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Breadcrumb } from 'react-admin-base-nazox';
import { StartupScoringForm } from '../../Components/StartupScoringForm';
import { FormattedMessage } from 'react-intl';

export const StartupScoringPage = () => {
  return (
    <Breadcrumb
      title={<FormattedMessage id="STARTUP_SCORING" />}
      data={[
        {
          href: `/startup-scoring`,
          name: <FormattedMessage id="STARTUP_SCORING" />,
        },
      ]}
    >
      <Card>
        <CardBody>
          <StartupScoringForm />
        </CardBody>
      </Card>
    </Breadcrumb>
  );
};
