import 'react-datetime/css/react-datetime.css';

import React, { useCallback } from 'react';
import { Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuth } from 'react-admin-base';
import {
  CheckBox,
  SingleFilePicker,
  Validator,
} from 'react-admin-base-bootstrap';
import { Breadcrumb } from 'react-admin-base-nazox';
import { UserSelect } from '../../Components/Selects';
import Datetime from 'react-datetime';
import { useFormObject } from '../Analysis/AnalysisTaemeed';
import { CustomForm } from '../Analysis/AnalysisEntity';
import Swal from 'sweetalert2';
import moment from 'moment';

export default function ExtraPage() {
  const [data, setData] = useFormObject({ isCreditBurn: false });
  const [api] = useAuth();
  const intl = useIntl();

  const onSubmit = useCallback(
    async function (e) {
      e.preventDefault();

      await api.tokenized.post('/admin/analyze/add', {
        userId: data.user.id,
        documentId: data.document.id,
        customerName: data.customerName,
        statementYear: data.statementYear,
        isCreditBurn: data.isCreditBurn,
      });

      Swal.fire('Success!', 'Credit has been added.', 'success');
    },
    [api, data]
  );

  const currentYear = moment().year() - 1;

  return (
    <>
      <Breadcrumb
        data={[
          {
            href: '/extra-page',
            name: <FormattedMessage id="ADMIN PANEL" />,
          },
        ]}
      />
      <CustomForm onSubmit={onSubmit}>
        <Card>
          <CardBody>
            <Row className="mb-3">
              <Col md="2">
                <Label>
                  <FormattedMessage id="USER" />
                </Label>
              </Col>
              <Col md="10">
                <Validator name="user" type="required">
                  <UserSelect
                    value={data.user}
                    onChange={(a) => setData({ user: a })}
                  />
                </Validator>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md="2">
                <Label>
                  <FormattedMessage id="ADD_DOCUMENT" />{' '}
                </Label>{' '}
              </Col>
              <Col md="4">
                <Validator name="document" type="required">
                  <SingleFilePicker
                    value={data.document}
                    onChange={(a) => setData({ document: a })}
                  />
                </Validator>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md="2">
                <Label htmlFor="text-input">
                  <FormattedMessage id="COMPANY_ANALYZED" />
                </Label>
              </Col>
              <Col md="4">
                <Validator name="company" type="required">
                  <Input
                    type="text"
                    value={data.customerName || ''}
                    onChange={(a) =>
                      setData({ customerName: a.currentTarget.value })
                    }
                  />
                </Validator>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md="2">
                <Label htmlFor="text-input">
                  <FormattedMessage id="STATEMENT_YEAR" />
                </Label>
              </Col>
              <Col md="4">
                <Validator
                  name="year"
                  type={
                    'required|numeric|min:2000,num|max:' + currentYear + ',num'
                  }
                >
                  <Datetime
                    dateFormat="YYYY"
                    value={data.statementYear || ''}
                    onChange={(a) =>
                      setData({
                        statementYear:
                          (a && a.year && a.year().toString()) || a,
                      })
                    }
                  />
                </Validator>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={'2'}>
                <Label>
                  <FormattedMessage id="CREDIT_BURN" />
                </Label>
              </Col>

              <Col md={'3'}>
                <CheckBox
                  type="radio"
                  id="isCreditBurn_1"
                  label={intl.formatMessage({ id: 'YES_CHECK' })}
                  className="mb-3"
                  checked={!!data.isCreditBurn}
                  onChange={() => setData({ isCreditBurn: true })}
                />
              </Col>
              <Col md={'3'}>
                <CheckBox
                  type="radio"
                  id="isCreditBurn_2"
                  label={intl.formatMessage({ id: 'NO_CHECK' })}
                  className="mb-3"
                  checked={!data.isCreditBurn}
                  onChange={() => setData({ isCreditBurn: false })}
                />
              </Col>
              <Col md={'4'}></Col>
            </Row>
          </CardBody>
        </Card>
      </CustomForm>
    </>
  );
}
