export const taxNoCheck = (kno) => {
  var v1 = 0;
  var v2 = 0;
  var v3 = 0;
  var v4 = 0;
  var v5 = 0;
  var v6 = 0;
  var v7 = 0;
  var v8 = 0;
  var v9 = 0;
  var v11 = 0;
  var v22 = 0;
  var v33 = 0;
  var v44 = 0;
  var v55 = 0;
  var v66 = 0;
  var v77 = 0;
  var v88 = 0;
  var v99 = 0;
  var v_last_digit = 0;
  var toplam = 0;

  if (kno.length == 10) {
    v1 = (Number(kno.charAt(0)) + 9) % 10;

    v2 = (Number(kno.charAt(1)) + 8) % 10;

    v3 = (Number(kno.charAt(2)) + 7) % 10;

    v4 = (Number(kno.charAt(3)) + 6) % 10;

    v5 = (Number(kno.charAt(4)) + 5) % 10;

    v6 = (Number(kno.charAt(5)) + 4) % 10;
    v7 = (Number(kno.charAt(6)) + 3) % 10;
    v8 = (Number(kno.charAt(7)) + 2) % 10;
    v9 = (Number(kno.charAt(8)) + 1) % 10;
    v_last_digit = Number(kno.charAt(9));

    v11 = (v1 * 512) % 9;
    v22 = (v2 * 256) % 9;
    v33 = (v3 * 128) % 9;
    v44 = (v4 * 64) % 9;
    v55 = (v5 * 32) % 9;
    v66 = (v6 * 16) % 9;
    v77 = (v7 * 8) % 9;
    v88 = (v8 * 4) % 9;
    v99 = (v9 * 2) % 9;

    if (v1 != 0 && v11 == 0) v11 = 9;
    if (v2 != 0 && v22 == 0) v22 = 9;
    if (v3 != 0 && v33 == 0) v33 = 9;
    if (v4 != 0 && v44 == 0) v44 = 9;
    if (v5 != 0 && v55 == 0) v55 = 9;
    if (v6 != 0 && v66 == 0) v66 = 9;
    if (v7 != 0 && v77 == 0) v77 = 9;
    if (v8 != 0 && v88 == 0) v88 = 9;
    if (v9 != 0 && v99 == 0) v99 = 9;
    toplam = v11 + v22 + v33 + v44 + v55 + v66 + v77 + v88 + v99;

    if (toplam % 10 == 0) toplam = 0;
    else toplam = 10 - (toplam % 10);

    if (toplam == v_last_digit) {
      return true;
    } else return false;
  } else return false;
};

export function validTCKN(value) {
  value = String(value);

  // T.C. identity number should have 11 digits and first should be non-zero.
  if (!/^[1-9]\d{10}$/.test(value)) return false;

  const digits = value.split('');
  // store last 2 digits (10th and 11th) which are actually used for validation
  const d10 = Number(digits[9]);
  const d11 = Number(digits[10]);
  // we'll also need the sum of first 10 digits for validation
  let sumOf10 = 0;
  let evens = 0;
  let odds = 0;

  digits.forEach((d, index) => {
    d = Number(d);
    if (index < 10) sumOf10 += d;
    if (index < 9) {
      if ((index + 1) % 2 === 0) {
        evens += d;
      } else {
        odds += d;
      }
    }
  });

  // check if the unit-digit of the sum of first 10 digits equals to the 11th digit.
  if (sumOf10 % 10 !== d11) return false;

  // check if unit-digit of the sum of odds * 7 and evens * 9 is equal to 10th digit.
  if ((odds * 7 + evens * 9) % 10 !== d10) return false;

  // check if unit-digit of the sum of odds * 8 is equal to 11th digit.
  if ((odds * 8) % 10 !== d11) return false;

  return true;
}
