import React, { useState } from 'react';
import { CardBody, Table, Label } from 'reactstrap';
import DisableClipoard from '../Components/DisableClipoard';
import { Tab, Tabs } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { formatNumber } from '../Util/formatNumber';
import { useFetch } from 'react-admin-base';

export const FinansalOranlar = ({ value, slug, companyCode, sectorCode }) => {
  const [key4, setKey] = useState(slug + 0);
  const [sector] = useFetch('sector/get/details/' + sectorCode);

  var ratios = value.ratios;

  const isGlobal = companyCode === 'global';

  const isCompanySee =
    companyCode !== 'ta3meedbnpl' &&
    companyCode !== 'ta3meed' &&
    companyCode !== 'global';

  const tableBodyContent = (b) => {
    const quartile = b.quartile.toLocaleString();
    const color = isGlobal
      ? ''
      : b.quartile == 0
        ? 'red'
        : b.quartile == 1
          ? '#d4ba26'
          : quartile == 2
            ? '#1aab26'
            : '';
    return (
      <>
        <td
          style={{
            color: color,
          }}
        >
          {formatNumber(b.yearValue)}
        </td>
        {isCompanySee && <td>{formatNumber(b.q2Value)}</td>}
      </>
    );
  };
  const naceCode = sector && sector.code;

  const sectorName = sector
    ? localStorage.frayjet_frayjet_language === 'en'
      ? sector.enName
      : sector.name
    : '';

  return (
    <DisableClipoard>
      <CardBody>
        {naceCode && sectorCode && (
          <div
            style={{
              marginBottom: '0.5rem',
            }}
          >
            <Label>
              <FormattedMessage id="SECTOR_TYPE" />
            </Label>
            : {naceCode} - {sectorName}
          </div>
        )}
        <Tabs activeKey={key4} onSelect={(key4) => setKey(key4)}>
          {ratios.map((row, i) => (
            <Tab eventKey={slug + i} title={row.title} key={i}>
              <Table striped responsive bordered hover size="sm">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="SUBTAB_4_1_TITLE1" />
                    </th>
                    {row.title !== 'Growth' ? (
                      row.subRatios &&
                      row.subRatios[0] &&
                      row.subRatios[0].values.map((a, x) => (
                        <React.Fragment key={x}>
                          <th>{a.year}</th>
                          {isCompanySee && (
                            <th>
                              {a.year}{' '}
                              <FormattedMessage id="SUBTAB_2_1_TITLE3" />
                            </th>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <th>
                        {row.subRatios[0].values[1].year +
                          ' vs ' +
                          row.subRatios[0].values[0].year}
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {row.subRatios.map((a, i) => (
                    <tr key={i}>
                      <td>{a.name}</td>
                      {row.title !== 'Growth' ? (
                        a.values.map((b, v) => (
                          <React.Fragment key={v}>
                            {tableBodyContent(b)}
                          </React.Fragment>
                        ))
                      ) : (
                        <td>{formatNumber(a.values[0].yearValue)}</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />
              <p className="my-0 text-center">
                <i>
                  <FormattedMessage id="TAB_3_EXPLANATION" />
                </i>
              </p>
            </Tab>
          ))}
        </Tabs>
      </CardBody>
    </DisableClipoard>
  );
};
