import React from 'react';
import '../pages/Summarys/energy.scss';
import { FormattedMessage } from 'react-intl';
import { CombinedScore } from '../Common/ScoringTab';

export const CreditAndCombinedScoring = ({
  scoring,
  scoringVal,
  combinedScore,
  socar,
}) => {
  const scoringData =
    scoringVal &&
    scoringVal.dataList.find(
      (i) =>
        Math.round(scoring.scorePoint) >= i.lowerLimit &&
        Math.round(scoring.scorePoint) <= i.upperLimit
    );

  return (
    <>
      <h4>
        <b>
          <u>
            <FormattedMessage id="SUBTAB_1_6_CREDIT_SCORING_AND_SUMMARY" />
          </u>
        </b>
      </h4>
      <div
        style={{
          border: '1px black solid',
          margin: 'auto',
          width: '50%',
          marginBottom: '1em',
        }}
      >
        <h4>
          <b>
            <FormattedMessage
              id={socar ? 'FINANCIAL_SCORE' : 'COMBINED_SCORE'}
            />
          </b>
        </h4>

        <p
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: `#${scoringData?.colorCode?.slice(2)}`,
          }}
        >
          {scoringData?.combined} : {scoring.scorePoint}
        </p>
      </div>

      {combinedScore && <CombinedScore combinedScore={combinedScore} />}
    </>
  );
};

export default CreditAndCombinedScoring;
