import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Form, Row } from 'reactstrap';
import { Alerts } from './Alerts';
import { Loading } from './Loading';
import { useIntl, FormattedMessage } from 'react-intl';
import SampleImage from '../FileTemplatesAndImages/Sample_Image.png';
import SampleStatement from '../FileTemplatesAndImages/Sample_Statement.pdf';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { AktifErrorContent } from '../pages/Analysis/AktifErrorContent';
import { useUser } from './UserProvider';
import { useFetch } from 'react-admin-base';

export const showSwal = async (html, setError, intl, icon) => {
  await Swal.fire({
    title: setError
      ? `<strong>  ${intl.formatMessage({
          id: 'ERROR',
        })}</strong>`
      : '',
    icon: icon ? 'success' : setError ? 'error' : 'warning',
    html: html,
  }).then(function () {
    setError && setError('');
  });
};

const e500 = (intl) => {
  return `
    <h6>
      ${intl.formatMessage({
        id: 'START_NEW_ANL_ERROR_1',
      })}
      Lütfen aşağıdaki adımları izleyerek yeniden yükleme işlemi
      gerçekleştiriniz:
    </h6>
    <p>
      1- Bağımsız denetim raporları için girilen sayfa numaralarını kontrol
      ediniz. Bazı raporlar giriş kapağı, içindekiler vb. bölümler içerdiğinden
      finansal sayfa numaraları ile raporların altında yer alan sayfa numaraları
      uyuşmayabiliyor. Görseli incelemek için 
      
        <a href=${SampleImage} target="_blank" download>
          tıklayınız
        </a>
      
      .
    </p>
    <p>
      2- Kurumlar vergi beyannamesi için yüklenmiş olan dokümanın, Gelir İdaresi
      Başkanlığı&apos;nın formatında olmasını kontrol ediniz. Örnek beyanname
      için
      
         <a href=${SampleStatement} target="_blank" download>
          tıklayınız
        </a>
      
      .
    </p>
    <p>
      3- Yüklene pdf dokümanında damga, imza sirküsü vb. kısımlar okunmayı
      zorlaştıracak durumda ise lütfen teknik ekibimize iletiniz.
    </p>
   <p> 4-Yüklenecek excel dosyaları sadece .xlsx formatında olabilir. Diğer excel
    formatları (.xls .xlsm .csv) desteklenmemektedir.</p>
  `;
};

const e403 = (intl) => {
  return ` <p>
      ${intl.formatMessage({
        id: 'LICENSE_ERROR',
      })}
    </p>`;
};

const eOthers = (message, intl) => {
  const content = message
    ? localStorage.frayjet_frayjet_language === 'en'
      ? message.eng
      : message.tr
    : intl.formatMessage({
        id: 'TIMEOUT_ERROR',
      });
  return `<p>${content}</p>`;
};

const e524 = (intl) => {
  return ` <p>
      ${intl.formatMessage({
        id: 'TIMEOUT_ERROR2',
      })}
    </p>`;
};

export const showSwal2 = async (html, intl, setError) => {
  await Swal.fire({
    title: `<strong>  ${intl.formatMessage({
      id: 'ERROR',
    })}</strong>`,
    icon: 'error',
    html: html,
  }).then(function () {
    setError &&
      setError((prev) => {
        return { ...prev, ['message']: '' };
      });
  });
};

export function useFormSubmit(save, validateOptions, defaultNewState) {
  const [errors, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [optionsState, setOptionState] = useState(defaultNewState);
  const [firstSubmit, setFirstSbumit] = useState(false);
  const intl = useIntl();
  const x = validateOptions ? validateOptions(optionsState) : {};
  useEffect(() => {
    if (firstSubmit) {
      setError((prev) => ({
        ...prev,
        validation: { ...x },
        message: '',
      }));
    }
  }, [optionsState, firstSubmit]);

  const handleChangeOptions = (e) => {
    const { value, name } = e.target;
    let newState;
    newState = { ...optionsState };
    newState[name] = value;
    setOptionState(newState);
  };
  const handleChangeSector = (e, name) => {
    let newState = { ...optionsState };
    newState[name] = e;
    setOptionState(newState);
  };
  const handleFieldsValue = (e, field) => {
    const { value, name } = e.target;
    let newState;
    newState = { ...optionsState };
    newState[field][name] = value;

    setOptionState(newState);
  };
  const handleChangeDocuments = (e, i) => {
    const { value, name } = e.target;
    let newState;

    newState = { ...optionsState };
    newState.documents[i][name] = value;

    setOptionState(newState);
  };
  const handleChangeAnalysis = (e) => {
    const { value, name } = e.target;
    let newState;
    newState = { ...optionsState };

    newState.analysisDetail[name] = value;
    setOptionState(newState);
  };
  const handleFilePicker = (a, i, isDetection) => {
    let newState = { ...optionsState };
    if (!a && !isDetection) {
      newState.documents[i].pgnum_first = '';
      newState.documents[i].pgnum_last = '';
    }
    newState.documents[i]['financialFile'] = a;
    setOptionState(newState);
  };
  const handleYearChange = (e) => {
    const { value, name } = e.target;
    let newState;
    newState = { ...optionsState };
    const numberValue = Number(value);
    newState[name] = numberValue;
    newState.documents[0].year = numberValue - 1;
    newState.documents[1].year = numberValue;
    newState.documents[2].year = numberValue + 1;
    newState.documents[3].year = numberValue + 1;
    setOptionState(newState);
  };

  const newSave = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      setFirstSbumit(true);
      try {
        Object.values(x).every((v) => Object.keys(v).length === 0) &&
          (await save(e));
      } catch (e) {
        if (e.response) {
          const status = e.response.status;
          if (status === 500 || status === 403 || status === 524) {
            setError((prev) => ({
              ...prev,
              message: status,
            }));
          } else if (status === 406) {
            setError((prev) => ({
              ...prev,
              message: {
                [status]: {
                  data: e.response.data.requestModel,
                  message:
                    localStorage.frayjet_frayjet_language === 'en'
                      ? e.response.data.message.eng
                      : e.response.data.message.tr,
                },
              },
            }));
          } else {
            setError((prev) => ({
              ...prev,
              message: e.response.data.message,
            }));
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [save, errors, setError, optionsState]
  );

  return {
    loading,
    newSave,
    errors,
    setError,
    optionsState,
    setOptionState,
    handleChangeOptions,
    handleChangeSector,
    handleChangeDocuments,
    handleChangeAnalysis,
    handleFilePicker,
    handleFieldsValue,
    setFirstSbumit,
    handleYearChange,
    firstSubmit,
  };
}

export const CustomForm = ({
  submit,
  errors,
  loading,
  children,
  done,
  id,
  setError,
  errorMessage,
  disableButton,
  buttonText,
}) => {
  const user = useUser();
  const [company] = useFetch('/company/get/user/' + user.id);

  const companyCode = company && company.code;

  if (errors && errors.message) {
    if (errors.message === 500) {
      showSwal2(e500(useIntl()), useIntl(), setError);
    } else if (errors.message === 403) {
      showSwal2(e403(useIntl()), useIntl(), setError);
    } else if (errors.message === 524) {
      showSwal2(e524(useIntl()), useIntl(), setError);
    } else if (errors.message['406']) {
      null;
    } else {
      showSwal2(eOthers(errors.message, useIntl()), useIntl(), setError);
    }
  }

  return (
    <Form onSubmit={submit}>
      {errors.message && errors.message['406'] && (
        <AktifErrorContent
          setError={setError}
          content={errors.message['406']}
          companyCode={companyCode}
        />
      )}
      <Card>
        <CardBody>{children}</CardBody>
      </Card>
      <Row>
        <Col md="4">
          <Button
            disabled={loading || disableButton}
            type="submit"
            className="d-block righted"
            color="primary"
          >
            {buttonText ? buttonText : <FormattedMessage id="SEND" />}
            <i className="fas fa-save fa-lg" />
          </Button>
        </Col>
      </Row>
      <div style={{ marginTop: '1em' }}>
        {' '}
        {/* {errors && errors.message && (
          <Alerts
            className="fas fa-times-circle"
            color="danger"
            messageId="ERROR"
          />
        )} */}
        {errors.validation &&
          Object.values(errors.validation).some(
            (v) => Object.keys(v).length !== 0
          ) && (
            <Alerts
              className="fas fa-times-circle"
              color="danger"
              messageId={!errorMessage && 'REQUIRED_FIELDS'}
              label={errorMessage}
            />
          )}
        {loading && <Loading />}
        {done && (
          <Alerts
            className="fas fa-spin fa-spinner"
            color="success"
            messageId={id}
          />
        )}
      </div>
    </Form>
  );
};
