import React from 'react';
import { TaemedCustomerForm } from './TaemedCustomerForm';
import { CustomerFormPage } from './CustomerFormPage';

export const CustomerFormGeneral = ({ companyCode }) => {
  if (companyCode === 'ta3meed') {
    return <TaemedCustomerForm />;
  }

  return <CustomerFormPage />;
};
