import React from 'react';
import { Breadcrumb } from 'react-admin-base-nazox';
import { useParams } from 'react-router-dom';
import { useFetch } from 'react-admin-base';
import { Loading } from '../../Components/Loading';
import { Card, CardBody, Table } from 'reactstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useIntl } from 'react-intl';
import { DownloadFileButton } from '../../Components/DownloadFileButton';
import { FormattedMessage } from 'react-intl';

//faturalar null mı yoksa boş array mi geliyor sor.

const headers = [
  'Tarih',
  'Operasyon Birimi',
  'Departman',
  'Müşteri',
  'Kredi',
  'Hesap Numarası',
  'Borç',
  'Gelir',
  'Borç TL',
  'Gelir TL',
  'Para Birimi',
  'Detaylı Açıklama',
  'Genel Açıklama',
  'Alt Birim',
  'Personel',
  'Fatura Numarası',
];

export const BillSummaryMisyon = () => {
  const params = useParams();
  const intl = useIntl();
  const code = params && params.Id && window.atob(params.Id);
  const [analyze, loading, error] = useFetch(`/process/inovice/misyon/${code}`);
  return (
    <Breadcrumb
      title={'Faturalar'}
      data={[
        {
          href: `/bill/${window.btoa(code)}/summary`,
          name: 'Faturalar',
        },
      ]}
    >
      <Card>
        <CardBody>
          {error && <h6>Bir hata meydana geldi.</h6>}
          {loading && <Loading />}

          {analyze && analyze.length > 0 ? (
            <div>
              <span
                style={{
                  justifyContent: 'right',
                  display: 'flex',
                  marginBottom: '5px',
                }}
              >
                <DownloadFileButton
                  style={{ float: 'right' }}
                  dname={'Misyon'}
                  messageId={'Fatura İndir'}
                  url={`"process/invoice/misyonExcel/${code}`}
                  className=""
                  method="get"
                />
              </span>
              <Table
                striped
                responsive
                bordered
                hover
                id={'fatura-1'}
                size="sm"
              >
                <thead>
                  <tr>
                    {headers.map((e, i) => (
                      <th key={i}>{e}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {analyze.map((e, j) => (
                    <tr key={j}>
                      {Object.entries(e).map(([t2, e2], i) => (
                        <td key={i}>{t2 === 'date' ? e2.split('T')[0] : e2}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <h6>Data bulunamadı.</h6>
          )}
        </CardBody>
      </Card>
    </Breadcrumb>
  );
};
