import { makeSlug } from 'react-admin-base';
import React, { useState } from 'react';
import FirmLoadingAlert from '../Components/Alerts';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledCollapse,
  TabContent,
  TabPane,
} from 'reactstrap';
import DisableClipoard from '../Components/DisableClipoard';
import { CustomBarChart } from './BarChartComponent';
import { PieCharts } from './PieChartComponent';
import { FormattedMessage } from 'react-intl';
import { Pdf } from '../pages/PdfPage/Pdf';
import SessionTableSocar from '../Socar/SessionTableSocar';
import { PdfAktif } from '../pages/PdfPage/PdfForAktif';

const TabPaneContent = (props) => {
  return props.children;
};

export function TemelAnaliz({
  code,
  scoringVal,
  value,
  trialBalance,
  firminfo,
  scoring,
  altmanzscoring,
  dupont,
  assessment,
  incomestatement,
  dname,
  assets,
  resources,
  prediction,
  logo,
  combinedScore,
  customer,
  surveyResult,
  statement,
  companyCode,
  qualitative,
  observationC1,
}) {
  const [index, setIndex] = useState(0);
  const [index2, setIndex2] = useState(0);
  const [active, setActive] = useState(1);

  const aktiv = value && value[index];
  if (!aktiv) {
    return <FirmLoadingAlert />;
  }
  console.log('code: ', code);
  var aktiv2 = [];

  if (trialBalance) {
    aktiv2 = trialBalance.titles && trialBalance.titles[index2];
  }

  if (!aktiv2) {
    return <FirmLoadingAlert />;
  }

  const pdfContent = React.useMemo(() => {
    return (
      <div style={{ marginLeft: '40%' }}>
        {code !== 'aktifbank' ? (
          <Pdf
            assessment={assessment}
            scoringVal={scoringVal}
            scoring={scoring}
            dupont={dupont}
            incomestatement={incomestatement}
            assets={assets}
            resources={resources}
            altmanzscoring={altmanzscoring}
            prediction={prediction}
            customer={customer}
            statement={statement}
            companyCode={companyCode}
            combinedScore={combinedScore}
            surveyResult={surveyResult}
          />
        ) : (
          <PdfAktif
            assessment={assessment}
            scoringVal={scoringVal}
            scoring={scoring}
            dupont={dupont}
            altmanzscoring={altmanzscoring}
          />
        )}
      </div>
    );
  }, []);

  return (
    <Card className={'mt-2'}>
      <CardHeader>
        <b>
          <FormattedMessage id="SUBTAB_REPORT_TITLE" />
        </b>
      </CardHeader>
      <DisableClipoard>
        <CardBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                href="#"
                active={active === 1}
                style={{
                  marginTop: '10px',
                  marginLeft: '10px',
                  border: '2px solid #21557c',
                  borderRadius: '10px',
                  WebkitBoxShadow: '6px 8px 11px -5px rgba(0,0,0,1)',
                  boxShadow: '6px 8px 11px -5px rgba(0,0,0,1)',
                  MozBoxShadow: ' 6px 8px 11px -5px rgba(0,0,0,1)',
                  marginBottom: '20px',
                }}
                onClick={() => setActive(1)}
              >
                <FormattedMessage id="SUBTAB_DECLARATION" />
              </NavLink>
            </NavItem>
            {trialBalance && (
              <NavItem onClick={() => setActive(2)}>
                <NavLink
                  href="#"
                  style={{
                    marginTop: '10px',
                    marginLeft: '10px',
                    border: '2px solid #21557c',
                    borderRadius: '10px',
                    WebkitBoxShadow: '6px 8px 11px -5px rgba(0,0,0,1)',
                    boxShadow: '6px 8px 11px -5px rgba(0,0,0,1)',
                    MozBoxShadow: ' 6px 8px 11px -5px rgba(0,0,0,1)',
                    marginBottom: '20px',
                  }}
                  active={active === 2}
                >
                  <FormattedMessage id="Mizan" />
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={active}>
            <TabPane tabId={1}>
              <TabPaneContent>
                <Row>
                  <Col sm="3">
                    <Nav pills className="flex-column">
                      {value.map((a, i) => (
                        <NavItem key={a.name}>
                          <NavLink
                            href="#"
                            key={i}
                            active={i === index}
                            onClick={() => setIndex(i)}
                          >
                            {a.name}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  </Col>
                  <Col sm="9">
                    <p>
                      <strong>{aktiv.name}</strong>
                    </p>

                    {aktiv.subTitles.map((b, j) => (
                      <React.Fragment key={j}>
                        <p
                          className={
                            b.isPositive === true
                              ? 'assessment-positive'
                              : b.isPositive === false
                                ? 'assessment-negative'
                                : 'assessment-neutral'
                          }
                          key={j + index * 100}
                        >
                          {b && b.isPositive === true ? (
                            <i className="fas fa-check-circle"></i>
                          ) : b.isPositive === false ? (
                            <i className="fas fa-exclamation-circle"></i>
                          ) : (
                            <i className="fas fa-equals"></i>
                          )}

                          <a
                            className="d-inline btn btn-sm text"
                            id={'v-' + makeSlug(aktiv.name) + '-' + j}
                          >
                            {b.name}
                            {'  '}
                            <i className="far fa-caret-square-down fa-sm"></i>
                          </a>
                          <UncontrolledCollapse
                            toggler={'#v-' + makeSlug(aktiv.name) + '-' + j}
                          >
                            {b.description}
                          </UncontrolledCollapse>
                        </p>
                      </React.Fragment>
                    ))}
                  </Col>
                </Row>
                {/* assets, resources and dname are removed in condition.  */}
              </TabPaneContent>
            </TabPane>

            {trialBalance ? (
              <TabPane tabId={2}>
                <TabPaneContent>
                  <div>
                    <div style={{ marginLeft: '40%', marginTop: '10px' }}>
                      <SessionTableSocar
                        scoringVal={scoringVal}
                        id={firminfo}
                        scoring={scoring}
                        altmanzscoring={altmanzscoring}
                        dupont={dupont}
                        assessment={assessment}
                        incomestatement={incomestatement}
                        dname={dname || ''}
                        assets={assets}
                        resources={resources}
                        prediction={prediction}
                        type={'mizan'}
                        trialBalance={trialBalance}
                        logo={logo}
                      />
                    </div>

                    <Row>
                      <Col sm="3">
                        <Nav pills className="flex-column">
                          {trialBalance.titles.map((x, y) => (
                            <NavItem key={x.name}>
                              <NavLink
                                href="#"
                                key={y}
                                active={y === index2}
                                onClick={() => setIndex2(y)}
                              >
                                {x.name}
                              </NavLink>
                            </NavItem>
                          ))}
                        </Nav>
                      </Col>
                      <Col sm="9">
                        <p>
                          <strong>{aktiv2.name}</strong>
                        </p>

                        {aktiv2.subTitles.map((k, t) => (
                          <React.Fragment key={t}>
                            <div>
                              <hr />
                              {k.name &&
                              k.name.split(':')[0] == 'Faaliyet Giderleri' ? (
                                <div>
                                  <PieCharts
                                    array={k.comparisonList}
                                    type={undefined}
                                  />
                                </div>
                              ) : (
                                <div>
                                  <CustomBarChart
                                    scoring={scoring}
                                    title={
                                      (k.name && k.name.split(':')[0]) || ''
                                    }
                                    value={10}
                                    array={k.comparisonList}
                                  />
                                </div>
                              )}
                            </div>
                            <p
                              className={
                                k.isPositive === true
                                  ? 'assessment-positive'
                                  : k.isPositive === false
                                    ? 'assessment-negative'
                                    : 'assessment-neutral'
                              }
                              key={t + index2 * 100}
                            >
                              {k && k.isPositive === true ? (
                                <i className="fas fa-check-circle"></i>
                              ) : k.isPositive === false ? (
                                <i className="fas fa-exclamation-circle"></i>
                              ) : (
                                <i className="fas fa-equals"></i>
                              )}

                              <a
                                className="d-inline btn btn-sm text"
                                id={'v-' + makeSlug(aktiv2.name) + '-' + t}
                              >
                                {k.name}
                                {'  '}
                                <i className="far fa-caret-square-down fa-sm"></i>
                              </a>
                              <UncontrolledCollapse
                                toggler={
                                  '#v-' + makeSlug(aktiv2.name) + '-' + t
                                }
                              >
                                {k.description}
                              </UncontrolledCollapse>
                            </p>
                          </React.Fragment>
                        ))}
                      </Col>
                    </Row>
                  </div>
                </TabPaneContent>
              </TabPane>
            ) : (
              ''
            )}
          </TabContent>
        </CardBody>
      </DisableClipoard>
      {pdfContent}

      <hr />

      <p className="text-center">
        <i>
          <i className="fas fa-check-circle"></i> - &quot;
          <FormattedMessage id="SUBTAB_FINANCIAL_SUCCESS" />
          &quot;, <i className="fas fa-exclamation-circle"></i> - &quot;
          <FormattedMessage id="SUBTAB_FINANCIAL_RISK" /> &quot;
        </i>
      </p>
    </Card>
  );
}
