import React from 'react';
import { Input, Button, Row, Label, Form, FormGroup } from 'reactstrap';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuth } from 'react-admin-base';
import { useNavigate } from 'react-router-dom';
import Layout from '../../Common/Layout';
import { fireSwal } from '../../Components/SwalFire';
import { Loading } from '../../Components/Loading';

const defaultState = {
  email: '',
  code: '',
  step: 1,
  signature: '',
  password: '',
  password2: '',
  error: '',
};

export const Reset = () => {
  const navigate = useNavigate();
  const [api] = useAuth();
  const intl = useIntl();

  const formik = useFormik({
    initialValues: {
      ...defaultState,
    },
    validateOnChange: true,
    validate: (data) => {
      const errors = {};

      if (data.step === 1) {
        if (data.email.length === 0) errors.email = 'FILL_EMAIL';
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email))
          errors.email = 'VALID_EMAIL';
      }

      return errors;
    },

    onSubmit: async (data, { resetForm }) => {
      try {
        switch (data.step) {
          case 1: {
            await await api.free
              .post('/reset', { email: data.email })
              .then((signature) => {
                formik.setFieldValue('signature', signature.data);
                formik.setFieldValue('step', 2);
              })
              .catch((e) => {
                formik.errors.email = 'NO_USER';
              });

            break;
          }
          case 2: {
            if (!data.code || data.code.toString().length !== 5) {
              formik.errors.code = 'CHECK_AUTH_CODE';
              break;
            }
            await api.free
              .get(data.signature, { params: { code: data.code } })

              .then(() => {
                formik.setFieldValue('step', 3);
              })
              .catch((e) => {
                formik.errors.code = 'WRONG_AUTH_CODE';
              });

            break;
          }
          case 3: {
            if (data.password.length === 0) {
              formik.errors.password = 'NO_PASSWORD';
              break;
            }
            if (data.password2.length === 0) {
              formik.errors.password2 = 'NO_PASSWORD';
              break;
            }
            if (data.password) {
              if (data.password.length < 8) {
                formik.errors.password = 'PASSWORD_EIGHT';
                break;
              } else if (
                !/[A-Z]/.test(data.password) ||
                !/\d/.test(data.password)
              ) {
                formik.errors.password = 'PASSWORD_LEAST';
                break;
              }
            }
            if (data.password2) {
              if (data.password2.length < 8) {
                formik.errors.password2 = 'PASSWORD_EIGHT';
                break;
              } else if (
                !/[A-Z]/.test(data.password2) ||
                !/\d/.test(data.password2)
              ) {
                formik.errors.password2 = 'PASSWORD_LEAST';
                break;
              }
            }
            if (data.password2 !== data.password) {
              formik.errors.password2 = 'PASSWORD_NOMATCH';
              break;
            }
            // check code
            if (!data.code || data.code.toString().length !== 5) {
              formik.errors.code = 'CHECK_AUTH_CODE';
              break;
            }

            await api.free
              .post(
                data.signature,
                { email: data.email, password: data.password },
                { params: { code: data.code } }
              )
              .then(() => {
                fireSwal({
                  background: '#90EE90',
                  icon: 'success',
                  text: intl.formatMessage({ id: 'EMAIL_CHANGE_COMPLETED' }),
                });

                setTimeout(() => {
                  api.log_in(data.email, data.password).then(() => {
                    navigate('/');
                  });
                }, 2000);
              })
              .catch(() => {
                formik.errors.error = 'SOMETHING_WRONG';
              });

            break;
          }
          default:
            break;
        }
      } catch (err) {
        formik.errors.error = 'SOMETHING_WRONG';
      }
    },
  });

  const getFormErrorMessage = (name) => {
    if (formik.touched[name] && formik.errors[name]) {
      return (
        <span
          style={{
            color: '#ff0000',
            position: 'absolute',
            fontSize: '12px',
          }}
        >
          {<FormattedMessage id={formik.errors[name]} />}
        </span>
      );
    }

    return null;
  };
  return (
    <Layout>
      <Form onSubmit={formik.handleSubmit}>
        {formik.values.step === 1 && (
          <FormGroup>
            <h2
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <FormattedMessage id={'RESET_PASSWORD'} />
            </h2>
            <FormGroup className="auth-form-group-custom">
              <i className="ri-user-2-line auti-custom-input-icon" />
              <Label>
                <FormattedMessage id="EMAIL" />
              </Label>
              <Input
                type="text"
                value={formik.values.email}
                onChange={formik.handleChange}
                name="email"
              />
              {getFormErrorMessage('email')}
            </FormGroup>
          </FormGroup>
        )}

        {formik.values.step === 2 && (
          <FormGroup>
            <h2
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <FormattedMessage id={'ENTER_AUTH_CODE'} />
            </h2>
            <Label style={{ width: '100%' }}>
              <FormattedMessage id={'AUTH_CODE'} />
              <Input
                type="number"
                id="code"
                name="code"
                value={formik.values.code}
                onChange={formik.handleChange}
              />
              {getFormErrorMessage('code')}
            </Label>
          </FormGroup>
        )}

        {formik.values.step === 3 && (
          <FormGroup>
            <h2
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <FormattedMessage id="ENTER_PASSWORD" />
            </h2>

            <Row style={{ marginBottom: '6px' }}>
              <Label>
                <FormattedMessage id="PASSWORD" />
                <Input
                  type="password"
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                {getFormErrorMessage('password')}
              </Label>
            </Row>

            <Row>
              <Label>
                <FormattedMessage id="NEW_PASSWORD_AGAIN" />
                <Input
                  type="password"
                  id="password2"
                  name="password2"
                  value={formik.values.password2}
                  onChange={formik.handleChange}
                />
                {getFormErrorMessage('password2')}
              </Label>
            </Row>
          </FormGroup>
        )}

        <FormGroup
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => navigate('/login')}
            color="secondary"
            disabled={formik.isSubmitting}
            style={{ width: '100px' }}
          >
            <FormattedMessage id="BACK" />
          </Button>

          <Button
            onMouseDown={(e) => e.preventDefault()}
            type="submit"
            color="primary"
            disabled={formik.isSubmitting}
            style={{ width: '100px' }}
          >
            <FormattedMessage id="SEND" />
          </Button>
        </FormGroup>
        <div style={{ marginBottom: '30px' }}>
          {getFormErrorMessage('error')}
        </div>
      </Form>
      {formik.isSubmitting && <Loading />}
    </Layout>
  );
};
