// @ts-nocheck

import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { BootstrapDataTable, Column } from 'react-admin-base-bootstrap';
import { FormattedMessage } from 'react-intl';
import { DownloadFileButton } from '../../Components/DownloadFileButton';
import useFetch from '../../Hooks/useFetch';
import { Alerts } from '../../Components/Alerts';

export const AktifDetectionSummary = ({ id }) => {
  const params = useParams();
  const [data, loading] = useFetch(`/detect/trialbalance/doubtfuls/${id}`);
  const headerMap = ['DEBT', 'REVENUE', 'DEBT_BALANCE', 'REVENUE_BALANCE'];
  const dates =
    (data &&
      data.data &&
      data.data[0] &&
      data.data[0].doubtfulValues.map((e) => e.year)) ||
    [];

  return (
    <Card>
      <CardBody>
        {!loading ? (
          <>
            <div className="d-flex flex-row-reverse">
              {' '}
              <DownloadFileButton
                messageId={<FormattedMessage id="DETECTION_DOWNLOAD" />}
                style={{ height: '37px', marginBottom: '1em' }}
                url={`detect/trialbalance/${id}`}
                dname={'Borç ve Alacak Bakiyesi Hareketsiz'}
                className={'btn btn-info btn btn-secondary'}
                method="get"
              />
            </div>

            <div className="detection-summary-table">
              <BootstrapDataTable
                noStrip
                url={`/detect/trialbalance/doubtfuls/${id}`}
                defaultParams={params}
              >
                <thead>
                  {' '}
                  <tr>
                    <th colSpan="2"></th>

                    {dates.map((e, i) => (
                      <th
                        colSpan="4"
                        key={i}
                        style={{
                          textAlign: 'center',
                          borderLeft: '1px solid black',
                        }}
                      >
                        {e}
                      </th>
                    ))}
                  </tr>
                  <tr>
                    <th>
                      <FormattedMessage id="ACCOUNT_CODE" />
                    </th>
                    <th>
                      <FormattedMessage id="ACCOUNT_NAME" />
                    </th>
                    {dates.map((e2) =>
                      headerMap.map((e, i) => (
                        <th key={i}>
                          <FormattedMessage id={e} />
                        </th>
                      ))
                    )}
                  </tr>
                </thead>
                <tbody>
                  {(row) => {
                    return (
                      <tr key={row.id}>
                        <td>{row.accountCode}</td>
                        <td>{row.accountName}</td>
                        {row.doubtfulValues.map((e) => {
                          return (
                            <>
                              <td>
                                {new Intl.NumberFormat('tr-TR', {}).format(
                                  Number(e.debt)
                                )}
                              </td>
                              <td>
                                {' '}
                                {new Intl.NumberFormat('tr-TR', {}).format(
                                  Number(e.revenue)
                                )}
                              </td>
                              <td>
                                {new Intl.NumberFormat('tr-TR', {}).format(
                                  Number(e.debtBalance)
                                )}
                              </td>

                              <td>
                                {new Intl.NumberFormat('tr-TR', {}).format(
                                  Number(e.revenueBalance)
                                )}
                              </td>
                            </>
                          );
                        })}
                      </tr>
                    );
                  }}
                </tbody>
              </BootstrapDataTable>
            </div>
          </>
        ) : (
          <Alerts
            color="primary"
            className="fas fa-spin fa-spinner"
            messageId={'LOADING'}
            label=""
          />
        )}
      </CardBody>
    </Card>
  );
};
