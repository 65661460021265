import React from 'react';
import { Card, CardHeader, Col, Row } from 'reactstrap';
import { FaSquare } from 'react-icons/fa';
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { FormattedMessage, useIntl } from 'react-intl';
import './pieCharts.scss';
import { formatNumber } from '../Util/formatNumber';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export const IncomeStatementDataSocar = ({ incomestatement }) => {
  const y1 =
    (incomestatement[0] &&
      incomestatement[0].values[0] &&
      incomestatement[0].values[0].year) ||
    '';
  const y2 =
    (incomestatement[0] &&
      incomestatement[0].values[1] &&
      incomestatement[0].values[1].year) ||
    '';

  const d1 =
    incomestatement[0] &&
    incomestatement[0].values[0] &&
    incomestatement[0].values[0].value;

  const d2 =
    incomestatement[0] &&
    incomestatement[0].values[1] &&
    incomestatement[0].values[1].value;

  const d3 =
    incomestatement[1] &&
    incomestatement[1].values[0] &&
    incomestatement[1].values[0].value;
  const d4 =
    incomestatement[1] &&
    incomestatement[1].values[1] &&
    incomestatement[1].values[1].value;

  const d5 =
    incomestatement[2] &&
    incomestatement[2].values[0] &&
    incomestatement[2].values[0].value;
  const d6 =
    incomestatement[2] &&
    incomestatement[2].values[1] &&
    incomestatement[2].values[1].value;

  const d7 =
    incomestatement[3] &&
    incomestatement[3].values[0] &&
    incomestatement[3].values[0].value;
  const d8 =
    incomestatement[3] &&
    incomestatement[3].values[1] &&
    incomestatement[3].values[1].value;

  const data_1 = [
    {
      date: incomestatement[0] && incomestatement[0].title,

      y1: d1,
      y2: d2,
    },
  ];

  const data_2 = [
    {
      date: incomestatement[1] && incomestatement[1].title,

      y1: d3,
      y2: d4,
    },
  ];
  const data_3 = [
    {
      date: incomestatement[2] && incomestatement[2].title,

      y1: d5,
      y2: d6,
    },
  ];
  const data_4 = [
    {
      date: incomestatement[3] && incomestatement[3].title,

      y1: d7,
      y2: d8,
    },
  ];
  return [data_1, data_2, data_3, data_4, y1, y2];
};
export const IncomeStatementCharts = ({
  incomestatement,
  containerRef,
  containerRef2,
  containerRef3,
  containerRef4,
}) => {
  const [data_1, data_2, data_3, data_4, y1, y2] = IncomeStatementDataSocar({
    incomestatement,
  });

  return (
    <div
      style={{
        marginTop: '100px',
      }}
    >
      <Row>
        <Col>
          {' '}
          <div ref={containerRef} id="tableElement">
            <Row style={{ marginBottom: '10px' }}>
              {
                <>
                  <Col
                    style={{
                      marginLeft: '75px',
                      display: 'flex',
                    }}
                  >
                    <FaSquare
                      style={{
                        color: '#007FB2',
                        width: '20px',
                        height: '20px',
                        margin: '2px 2px 0 0',
                      }}
                    />{' '}
                    <h4>{y1}</h4>
                  </Col>
                  <Col
                    style={{
                      display: 'flex',
                    }}
                  >
                    <FaSquare
                      style={{
                        color: '#795D9C',
                        width: '20px',
                        height: '20px',
                        margin: '2px 2px 0 0',
                      }}
                    />{' '}
                    <h4>{y2}</h4>
                  </Col>
                </>
              }
            </Row>
            <div
              style={{
                padding: '30px 60px',
                backgroundColor: '#F3F3F5',
              }}
            >
              <BarChartAndTooltip data={data_1} y1={y1} y2={y2} />
            </div>
          </div>
        </Col>
        <Col>
          <div ref={containerRef2} id="tableElement2">
            <Row style={{ height: '44px' }}></Row>
            <div
              style={{
                padding: '30px 60px',
                backgroundColor: '#E9E7E8',
              }}
            >
              {' '}
              <BarChartAndTooltip data={data_2} y1={y1} y2={y2} />
            </div>
          </div>
        </Col>
      </Row>
      <hr style={{ marginTop: 0 }} />

      <Row>
        <Col>
          {' '}
          <div ref={containerRef3} id="tableElement3">
            <Row style={{ marginBottom: '10px' }}>
              {
                <>
                  <Col
                    style={{
                      marginLeft: '75px',
                      display: 'flex',
                    }}
                  >
                    <FaSquare
                      style={{
                        color: '#007FB2',
                        width: '20px',
                        height: '20px',
                        margin: '2px 2px 0 0',
                      }}
                    />{' '}
                    <h4>{y1}</h4>
                  </Col>
                  <Col
                    style={{
                      display: 'flex',
                    }}
                  >
                    <FaSquare
                      style={{
                        color: '#795D9C',
                        width: '20px',
                        height: '20px',
                        margin: '2px 2px 0 0',
                      }}
                    />{' '}
                    <h4>{y2}</h4>
                  </Col>
                </>
              }
            </Row>
            <div
              style={{
                padding: '30px 60px',
                backgroundColor: '#F3F3F5',
              }}
            >
              <BarChartAndTooltip data={data_3} y1={y1} y2={y2} />
            </div>
          </div>
        </Col>
        <Col>
          <div ref={containerRef4} id="tableElement4">
            {' '}
            <Row style={{ height: '44px' }}></Row>
            <div
              style={{
                padding: '30px 60px',
                backgroundColor: '#E9E7E8',
              }}
            >
              <BarChartAndTooltip data={data_4} y1={y1} y2={y2} />
            </div>
          </div>
        </Col>
      </Row>
      <hr />
    </div>
  );
};

export const AssetsAndResourcesCharts = ({
  assets,
  resources,
  containerRef5,
  containerRef6,
  containerRef7,
  containerRef8,
}) => {
  const [data01, data02, data03, data04, y1, y2] = AssetsAndResourcesDataSocar({
    assets,
    resources,
  });

  return (
    <>
      <Card>
        <CardHeader
          style={{
            textAlign: 'left',
          }}
        >
          <FormattedMessage id="SUBTAB_ASSET_DISTRIBUTION_TITLE" />
        </CardHeader>
        <Row
          style={{
            textAlign: 'left',
          }}
        >
          <Col>
            <div ref={containerRef5} id="tableElement5">
              <PieChartsSocar data={data01} y={y1} color={'#F3F3F5'} />
            </div>
          </Col>
          <Col>
            <div ref={containerRef6} id="tableElement6">
              <PieChartsSocar data={data02} y={y2} color={'#E9E7E8'} />
            </div>
          </Col>
        </Row>
      </Card>

      <Card>
        <CardHeader
          style={{
            textAlign: 'left',
          }}
        >
          <FormattedMessage id="SUBTAB_RESOURCES_DISTRIBUTION" />
        </CardHeader>

        <Row
          style={{
            textAlign: 'left',
          }}
        >
          <Col>
            <div ref={containerRef7} id="tableElement7">
              <PieChartsSocar data={data03} y={y1} color={'#F3F3F5'} />
            </div>
          </Col>
          <Col>
            <div ref={containerRef8} id="tableElement8">
              <PieChartsSocar data={data04} y={y2} color={'#E9E7E8'} />
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export const AssetsAndResourcesDataSocar = ({ assets, resources }) => {
  const intl = useIntl();
  const y1 =
    assets && assets.titles && assets.titles[0]
      ? assets.titles[0].values[0].year.toString()
      : '';
  const y2 =
    assets && assets.titles && assets.titles[0]
      ? assets.titles[0].values[1].year.toString()
      : '';

  var asset_1 = assets.titles[0].values[0].value.toFixed(2) * 100;
  var asset_2 = assets.titles[0].values[1].value.toFixed(2) * 100;

  var asset_3 = assets.titles[1].values[0].value.toFixed(2) * 100;
  var asset_4 = assets.titles[1].values[1].value.toFixed(2) * 100;

  var resource_1 = resources.titles[0].values[0].value.toFixed(2) * 100;
  var resource_2 = resources.titles[0].values[1].value.toFixed(2) * 100;

  var resource_3 = resources.titles[1].values[0].value.toFixed(2) * 100;
  var resource_4 = resources.titles[1].values[1].value.toFixed(2) * 100;

  var equity_1 = resources.titles[2].values[0].value.toFixed(2) * 100;
  var equity_2 = resources.titles[2].values[1].value.toFixed(2) * 100;

  const data01 = [
    { name: intl.formatMessage({ id: 'CURRENT_ASSETS' }), value: asset_1 },
    {
      name: intl.formatMessage({ id: 'NON_CURRENT_ASSETS' }),
      value: asset_3,
    },
  ];

  const data02 = [
    { name: intl.formatMessage({ id: 'CURRENT_ASSETS' }), value: asset_2 },
    {
      name: intl.formatMessage({ id: 'NON_CURRENT_ASSETS' }),
      value: asset_4,
    },
  ];

  const data03 = [
    {
      name: intl.formatMessage({ id: 'CURRENT_LIABILITIES' }),
      value: resource_1,
    },
    {
      name: intl.formatMessage({ id: 'LONG_TERM_LIABILITIES' }),
      value: resource_3,
    },
    { name: intl.formatMessage({ id: 'EQUITY' }), value: equity_1 },
  ];

  const data04 = [
    {
      name: intl.formatMessage({ id: 'CURRENT_LIABILITIES' }),
      value: resource_2,
    },
    {
      name: intl.formatMessage({ id: 'LONG_TERM_LIABILITIES' }),
      value: resource_4,
    },
    { name: intl.formatMessage({ id: 'EQUITY' }), value: equity_2 },
  ];

  return [data01, data02, data03, data04, y1, y2];
};

export const CustomTooltip = (data, year1, year2) => {
  return (
    <div
      style={{
        textAlign: 'center',
        padding: '15px 60px',
        borderRadius: '0.5em',
        border: '1px solid black',
      }}
    >
      {data && data[0] && (
        <>
          {' '}
          <p style={{ color: '#007FB2', marginBottom: 0, fontWeight: 'bold' }}>
            {year1}: {new Intl.NumberFormat('tr').format(data[0].y1)}
          </p>
          <p style={{ color: '#795D9C', marginBottom: 0, fontWeight: 'bold' }}>
            {year2}: {new Intl.NumberFormat('tr').format(data[0].y2)}
          </p>
        </>
      )}
    </div>
  );
};

export const ModuleGrafiklerSocar = ({
  incomestatement,
  assets,
  resources,
  containerRef,
  containerRef2,
  containerRef3,
  containerRef4,
  containerRef5,
  containerRef6,
  containerRef7,
  containerRef8,
}) => {
  return (
    <div className="mt-2">
      {incomestatement && (
        <IncomeStatementCharts
          incomestatement={incomestatement.titles}
          containerRef={containerRef}
          containerRef2={containerRef2}
          containerRef3={containerRef3}
          containerRef4={containerRef4}
        />
      )}
      {assets && resources && (
        <AssetsAndResourcesCharts
          assets={assets}
          resources={resources}
          containerRef5={containerRef5}
          containerRef6={containerRef6}
          containerRef7={containerRef7}
          containerRef8={containerRef8}
        />
      )}
    </div>
  );
};

export const BarChartAndTooltip = ({ data, y1, y2 }) => {
  return (
    <div>
      {CustomTooltip(data, y1, y2)}
      <BarChart
        width={320}
        height={370}
        data={data}
        margin={{ left: 60, top: 30, bottom: 50, right: 20 }}
      >
        <XAxis dataKey="date" interval={0} />

        <YAxis
          tickFormatter={(value) => new Intl.NumberFormat('tr').format(value)}
        />

        <Bar dataKey="y1" fill="#007FB2" barSize={50} name={y1} />
        <Bar dataKey="y2" fill="#795D9C" barSize={50} name={y2} />
      </BarChart>
    </div>
  );
};

export default ModuleGrafiklerSocar;

export const PieChartsSocar = ({ data, y, color }) => {
  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <ul
        style={{
          display: 'grid',
          gridTemplateRows: '1fr 1fr',
          listStyle: 'none',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FaSquare
                style={{
                  color: COLORS[index % COLORS.length],
                  width: '15px',
                  height: '15px',
                }}
              />{' '}
              <span
                style={{
                  color: COLORS[index % COLORS.length],
                }}
              >
                {entry.value} : {formatNumber(entry.payload.value)}
              </span>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="pie-chart-wrapper">
      <Row>
        <Col>
          <h4
            style={{
              margin: '10px 0px',
            }}
          >
            {y}
          </h4>
        </Col>
      </Row>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: color,
        }}
      >
        <PieChart width={280} height={300}>
          <Pie
            data={data}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            cornerRadius={20}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend content={renderLegend} />
        </PieChart>
      </div>
    </div>
  );
};
