import React from 'react';
import FirmLoadingAlert from '../Components/Alerts';
import { Card, CardHeader, Table } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export function CustomFinancialRatiosPDF({ customAssessment, value }) {
  var ratios = value.ratios;
  if (!customAssessment) return <FirmLoadingAlert />;
  if (!value || !value.ratios) return <FirmLoadingAlert />;
  return (
    <div>
      <Card style={{ textAlign: 'justify' }}>
        {customAssessment &&
          customAssessment.titles.map((x, t) => (
            <div key={t}>
              <h5>{x.name}</h5>
              {x.subTitles.map((b) => (
                <>{b.description}</>
              ))}
            </div>
          ))}
      </Card>
      <Card>
        <CardHeader>
          <h4 style={{ textAlign: 'center' }}>
            <u>
              <b>Özelleştirilmiş Finansal Oranlar</b>
            </u>
          </h4>
          <br />
        </CardHeader>
        {ratios.map((row, i) => {
          return (
            row.title !== 'Toplam Skorlar' && (
              <div>
                <h4>
                  <b>{row.title}</b>
                </h4>
                <Table striped responsive bordered hover key={i} size="sm">
                  <thead>
                    <tr>
                      <th style={{ width: '400px' }}>
                        <FormattedMessage id="SUBTAB_4_1_TITLE1" />
                      </th>
                      {row.subRatios &&
                        row.subRatios[0] &&
                        row.subRatios[0].values.map((a) => (
                          <>
                            <th style={{ width: '20%' }}>{a.year}</th>
                          </>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {row.subRatios.map((a, i) => (
                      <tr key={i}>
                        <td key={i}>{a.name}</td>
                        {a.values.map((b) => (
                          <>
                            <td style={{ color: '' }}>
                              {b.yearValue === 0
                                ? ''
                                : parseFloat(b.yearValue).toLocaleString()}
                            </td>
                          </>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <br style={{ marginBottom: '20px' }} />
              </div>
            )
          );
        })}
      </Card>
    </div>
  );
}
