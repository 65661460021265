import React from 'react';
import { Table } from 'reactstrap';
import { FormattedNumber } from 'react-intl';

export const BeneishMScoreRatiosTable = ({ beneishMScoringValues }) => {
  const headers = [
    'Beneish M - Score Ratios',
    beneishMScoringValues[0].mainYear,
    beneishMScoringValues[0].prevYear,
  ];
  return (
    <Table striped responsive bordered hover size="sm">
      <thead>
        <tr>
          {headers.map((header, key) => (
            <th key={key}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {beneishMScoringValues.map((e, key) => (
          <tr key={key}>
            <td>{e.title}</td>
            <td>{<FormattedNumber value={e.mainYearValue} />}</td>
            <td>{<FormattedNumber value={e.prevYearValue} />}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
