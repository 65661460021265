import React from 'react';
import { Image, StyleSheet, Text, View, Page } from '@react-pdf/renderer';
import { FormattedMessage } from 'react-intl';
import { colors, fontSizes } from '../Common';
import frayLogo2 from '../../../FileTemplatesAndImages/jetscoring.png';

export const PdfLastPage = () => {
  const styles = StyleSheet.create({
    page: { fontFamily: 'Open Sans' },
    dash: {
      height: '100%',
      width: '100%',
      backgroundColor: colors.secondary,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 40px',
    },
    logo: {
      height: '34px',
      width: '325px',
    },
    text1: {
      marginTop: '83px',
      color: colors.white,
      fontSize: fontSizes.title,
      textAlign: 'center',
    },
    text2: {
      marginTop: '20px',
      color: colors.white,
      textAlign: 'center',
      fontSize: fontSizes.title,
    },
  });

  return (
    <Page style={styles.page}>
      <View>
        <View style={styles.dash}>
          <Image src={frayLogo2} style={styles.logo} />
          <Text style={styles.text1}>
            <FormattedMessage id="PDF_INFORMATION_TEXT_2" />
          </Text>
          <Text style={styles.text2}>
            <FormattedMessage id="PDF_INFORMATION_TEXT_3" />
          </Text>
        </View>
      </View>
    </Page>
  );
};
