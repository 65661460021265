import React from 'react';
import { fireSwal } from '../Components/SwalFire';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import { useAuth } from 'react-admin-base';
import { Loading } from './Loading';

const applicationTypes = {
  'application/pdf': 'pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/vnd.ms-excel.sheet.macroEnabled.12': 'xlsm',
};

export const downloadFile = async (setLoading, method, url, dname, intl) => {
  //use useFetch
  setLoading(true);
  let url2 = process.env.REACT_APP_ENDPOINT + url;
  const blob1 = await fetch(url2, {
    method: method,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('frayjet_access_token')}`,
    },
  }).then(async (response) => {
    setLoading(false);

    if (response.status !== 200) {
      const error = await response.json();
      const message =
        error && error.message && localStorage.frayjet_frayjet_language === 'en'
          ? error.message.eng
          : error.message.tr;
      fireSwal({
        background: '#ffd8df',
        icon: 'error',
        text: message || intl.formatMessage({ id: 'FILE_NOT_FOUND' }),
      });

      return 'error';
    }
    return response.blob();
  });

  if (blob1 === 'error') {
    return;
  }

  const type = blob1 && blob1.type;
  const filesExtension = applicationTypes[type] || 'xlsm';
  var url3 = window.URL.createObjectURL(blob1);
  var a = document.createElement('a');
  a.href = url3;
  a.download = dname + '.' + filesExtension;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove(); //afterwards we remove the element again
};

export const DownloadFileButton = ({
  dname,
  messageId,
  url,
  style,
  className,
  method,
}) => {
  const intl = useIntl();

  const [loading, setLoading] = React.useState(false);

  return (
    <>
      {loading && <Loading />}
      <Button
        style={style}
        className={className || 'btn btn-info'}
        onClick={(e) => {
          e.preventDefault();
          downloadFile(setLoading, method, url, dname, intl);
        }}
        disabled={loading}
      >
        {messageId} <i className="fa fa-download" aria-hidden="true"></i>
      </Button>
    </>
  );
};
