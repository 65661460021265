import React from 'react';
import { FormattedNumber } from 'react-intl';

export const formatNumber = (value, allowZero) => {
  if (value === 0 && !allowZero) return '';
  return <FormattedNumber value={value} maximumFractionDigits={2} />;
};

export const formatPercentage = (value, allowZero, isPercentage) => {
  if (value === 0 && !allowZero) return '';
  return (
    <FormattedNumber
      value={isPercentage ? value / 100 : value}
      style="percent"
      maximumFractionDigits={2}
    />
  );
};
