import React from 'react';
import { DataTable } from 'primereact/datatable';
import { useIntl } from 'react-intl';
import { SelectInput } from '../../../Components/Selects';
import { Column } from 'primereact/column';
import { FormattedNumber } from 'react-intl';

const getYearOptions = (years) => {
  const options = years.map((e) => ({
    label: e,
    value: e,
  }));

  return options;
};

export const VolumeTable = (props) => {
  const intl = useIntl();
  const { data, headersMapping, numberFormatKey } = props;
  const years = data.map((item) => item.year);
  const [year, setYear] = React.useState(years[0]);

  const selectedYearData = data.find((v) => v.year === year);
  const tableData = selectedYearData ? selectedYearData.tbData : [];

  const getColumns = (mappingData) => {
    return Object.entries(mappingData).map(([field, header]) => (
      <Column
        key={field}
        field={field}
        header={intl.formatMessage({ id: header })}
        body={(rowData) => {
          const data = rowData[field];
          const formattedData =
            numberFormatKey === field ? <FormattedNumber value={data} /> : data;
          return formattedData;
        }}
      />
    ));
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <SelectInput
          value={getYearOptions(years).find((e) => e.value === year)}
          options={getYearOptions(years)}
          onChange={(a) => {
            setYear(a.value);
          }}
          isMulti={false}
        />
      </div>
      <DataTable
        value={tableData}
        className="mt-3"
        scrollable
        emptyMessage={intl.formatMessage({ id: 'NO_DATA_IS_AVAILABLE' })}
      >
        {getColumns(headersMapping)}
      </DataTable>
    </div>
  );
};
