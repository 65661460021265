import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledAccordion,
} from 'reactstrap';
import { MultiValue, useAuth } from 'react-admin-base';
import {
  Validator,
  SingleFilePicker,
  CheckBox,
} from 'react-admin-base-bootstrap';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { CustomForm } from './AnalysisEntity';
import FinancialStatementTemplate from '../../FileTemplatesAndImages/FinancialStatementTemplate.xlsx';
import { FormattedMessage } from 'react-intl';
import { AsyncPaginate } from 'react-select-async-paginate';
import { InputField } from '../../Components/Input';
import './Analysis.scss';
import { checkFileIsPdf } from '../../Util/checkFileIsPdf';
import NumberFormat from 'react-number-format';

export function useFormObject() {
  const [data, setData] = useState({});
  const applyData = useCallback(
    function (newData) {
      setData(newData.apply ? newData : (data) => ({ ...data, ...newData }));
    },
    [setData]
  );

  return useMemo(() => [data, applyData, setData], [data, applyData, setData]);
}

export function MultiDelayedB({ value, onChange, title }) {
  const len = value && value.length;
  const exists = value && value.length > 0;
  const selectedDays = (value || []).map((a) => a.days);

  return (
    <Table responsive hover size="sm">
      <thead>
        <tr>
          <th colSpan={3}>
            <Label>{title}</Label>
          </th>
          <th className="text-end min"></th>
        </tr>
      </thead>
      <tbody>
        <MultiValue value={value} onChange={onChange} add={len !== 6}>
          {(row, onChange, i) => (
            <tr key={i}>
              <td>
                <Input
                  type="select"
                  value={(row && row.days) || '180 days'}
                  onChange={(a) =>
                    onChange(
                      a.currentTarget.value === 'No delay'
                        ? null
                        : {
                            ...(row || {}),
                            days: a.currentTarget.value,
                            number: row ? row.number : '0',
                          }
                    )
                  }
                >
                  <option value="No delay">
                    {exists ? 'Add more' : 'No delay'}
                  </option>
                  <option
                    value="30"
                    style={{
                      display: selectedDays.indexOf('30') !== -1 && 'none',
                    }}
                  >
                    30 days
                  </option>
                  <option
                    value="60"
                    style={{
                      display: selectedDays.indexOf('60') !== -1 && 'none',
                    }}
                  >
                    60 days
                  </option>
                  <option
                    value="90"
                    style={{
                      display: selectedDays.indexOf('90') !== -1 && 'none',
                    }}
                  >
                    90 days
                  </option>
                  <option
                    value="120"
                    style={{
                      display: selectedDays.indexOf('120') !== -1 && 'none',
                    }}
                  >
                    120 days
                  </option>
                  <option
                    value="150"
                    style={{
                      display: selectedDays.indexOf('150') !== -1 && 'none',
                    }}
                  >
                    150 days
                  </option>
                  <option
                    style={{
                      display: selectedDays.indexOf('180') !== -1 && 'none',
                    }}
                    value="180"
                  >
                    180 days
                  </option>
                </Input>
              </td>
              <td>
                <NumberFormat
                  allowNegative={false}
                  className="form-control"
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  value={(row && row.number && Number(row.number)) || ''}
                  decimalScale={2}
                  onValueChange={(a) => {
                    const { value } = a;
                    onChange({
                      ...(row || {}),
                      number: value,
                      days: row ? row.days : 'No delay',
                    });
                  }}
                  disabled={!row || row.days === 'No delay'}
                />
                {/* <Input
                  onBlur={(e) => onBlur(e, row)}
                  onFocus={(e) => onFocus(e, row)}
                  value={(row && row.number) || "0"}
                  onChange={(a) =>
                    onChange({
                      ...(row || {}),
                      number: a.currentTarget.value || "0",
                      days: row ? row.days : "No delay",
                    })
                  }
                  disabled={!row || row.days === "No delay"}
                  type="number"
                  onKeyPress={(event) => {
                    if (event.key === "e" || event.key === "E") {
                      event.preventDefault();
                    }
                  }}
                  onPaste={(event) => {
                    let pastedValue = event.clipboardData.getData("text");
                    if (pastedValue === "e") {
                      event.preventDefault();
                    }
                  }}
                /> */}
              </td>
              <td className="text-end min">
                {!!row && (
                  <Button
                    type="button"
                    size="sm"
                    color="danger"
                    onClick={() => onChange(null)}
                  >
                    Del
                  </Button>
                )}
              </td>
            </tr>
          )}
        </MultiValue>
      </tbody>
    </Table>
  );
}
// should be review

const durationSinceLastDefaultOptions = [
  'No default',
  '5 years',
  '4 years',
  '3 years',
  'Less than 3 years',
];

const outstandingDebt_TotalDeposits_RatioOptions = [
  'Less than 3%',
  '3% to 5%',
  '5% to 9%',
  '9% to 15%',
  '15% to 20%',
  '20% to 25%',
  '25% to 33%',
  '33% to 45%',
  '45% to 55%',
  'Over 55%',
];

const requestedLoan_WholeProject_RatioOptions = [
  'Less than 5%',
  '5% to 10%',
  '10% to 15%',
  '15% to 20%',
  '20% to 25%',
  '25% to 35%',
  '35% to 45%',
  '45% to 50%',
  '50% to 60%',
  '60% to 70%',
  'More than 70%',
];

const numberOfEmployeesOptions = [
  'More than 70',
  '56 to 70',
  '45 to 55',
  '35 to 45',
  '30 to 34',
  '25 to 29',
  '20 to 24',
  '15 to 19',
  '10 to 14',
  '5 to 9',
  'Less than 5',
];

const companyAgeOptions = [
  'Less than 3 years',
  '3 years',
  '4 years',
  '5 to 6 years',
  '7 to 8 years',
  '9 to 10 years',
  '11 to 12 years',
  '13 to 15 years',
  '16 to 17 years',
  '18 to 20 years',
  '21 to 25 years',
  'More than 25 years',
];

const numberOfFinishedProjectsOptions = [
  '2 and less',
  '3',
  '4',
  '5',
  '6 to 8',
  '9 to 10',
  '11 to 12',
  '13 to 15',
  'More than 15',
];
const requestedLoan_TotalDeposits_RatioOptions = [
  '0.01% to 3%',
  '3% to 5%',
  '5% to 10%',
  '10% to 15%',
  '15% to 20%',
  '20% to 25%',
  '25% to 30%',
  '30% to 37%',
  '37% to 45%',
  'More than 45%',
];

const totalSettledDefaultsDebt_TotalOutstandingDebt_RatioOptions = [
  'No default',
  'Less than 3%',
  '3% to 7%',
  '7% to 12%',
  '12% to 15%',
  '15% to 20%',
  '20% to 30%',
  'More than 30%',
];

const typeOfProjectOptions = [
  'Supply',
  'Special Supply',
  'Supply and Implement',
  'Services',
  'Supply and Installation',
  'Consultation',
  'Media',
  'Operation and Maintenance',
  'Constructions',
  'Supply for Sales on Credit (Business)',
];

const rejectedChequesOptions = [
  'More than 10',
  '8 to 10',
  '6 to 7',
  '3 to 5',
  2,
  'Less than 2',
];

const settledLitigationsOptions = [0, 1, 2, 3, 4, 5, 6, 7, '8 & more'];
export const AccordionItems = ({
  data,
  setData,
  rejected,
  setRejected,
  delayed,
  setDelayed,
  defaultOpen,
}) => {
  return (
    <div>
      <UncontrolledAccordion defaultOpen={defaultOpen}>
        <AccordionItem>
          <AccordionHeader targetId="1">
            A. Qualitative Analysis Questions
          </AccordionHeader>
          <AccordionBody accordionId="1">
            <Row>
              <FormGroup>
                <Label>A.1. Duration Since Last Settled Default Debt</Label>
                <Input
                  type="select"
                  value={data.durationSinceLastDefault || 'No default'}
                  onChange={(a) =>
                    setData({
                      durationSinceLastDefault: a.currentTarget.value,
                    })
                  }
                >
                  {durationSinceLastDefaultOptions.map((e, i) => (
                    <option value={e} key={i}>
                      {' '}
                      {e}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>A.2. Outsanding Debt / Total Deposits</Label>
                <Input
                  type="select"
                  value={
                    data.outstandingDebt_TotalDeposits_Ratio || 'Less than 3%'
                  }
                  onChange={(a) =>
                    setData({
                      outstandingDebt_TotalDeposits_Ratio:
                        a.currentTarget.value,
                    })
                  }
                >
                  {outstandingDebt_TotalDeposits_RatioOptions.map((e, i) => (
                    <option value={e} key={i}>
                      {e}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>A.3. Requested Loan / The Whole Project Value</Label>
                <Input
                  type="select"
                  value={
                    data.requestedLoan_WholeProject_Ratio || 'Less than 5%'
                  }
                  onChange={(a) =>
                    setData({
                      requestedLoan_WholeProject_Ratio: a.currentTarget.value,
                    })
                  }
                >
                  {requestedLoan_WholeProject_RatioOptions.map((e) => (
                    <option key={e}>{e}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>A.4. Number of Employees</Label>
                <Input
                  type="select"
                  value={data.numberOfEmployees || 'More than 70'}
                  onChange={(a) =>
                    setData({ numberOfEmployees: a.currentTarget.value })
                  }
                >
                  {numberOfEmployeesOptions.map((e) => (
                    <option key={e}>{e}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>A.5 Company Age</Label>
                <Input
                  type="select"
                  value={data.companyAge || 'Less than 3 years'}
                  onChange={(a) =>
                    setData({ companyAge: a.currentTarget.value })
                  }
                >
                  {companyAgeOptions.map((e) => (
                    <option key={e}>{e}</option>
                  ))}{' '}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>A.6. Number of Finished Projects (Experience)</Label>
                <Input
                  type="select"
                  value={data.numberOfFinishedProjects || '2 and less'}
                  onChange={(a) =>
                    setData({
                      numberOfFinishedProjects: a.currentTarget.value,
                    })
                  }
                >
                  {numberOfFinishedProjectsOptions.map((e) => (
                    <option key={e}>{e}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>A.7. Requested Loan / Total Deposits</Label>
                <Input
                  type="select"
                  value={
                    data.requestedLoan_TotalDeposits_Ratio || '0.01% to 3%'
                  }
                  onChange={(a) =>
                    setData({
                      requestedLoan_TotalDeposits_Ratio: a.currentTarget.value,
                    })
                  }
                >
                  {requestedLoan_TotalDeposits_RatioOptions.map((e) => (
                    <option key={e}>{e}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>
                  A.8. Total Settled Defaults on Debt / Total Outstanding Debt
                </Label>
                <Input
                  type="select"
                  value={
                    data.totalSettledDefaultsDebt_TotalOutstandingDebt_Ratio ||
                    'No default'
                  }
                  onChange={(a) =>
                    setData({
                      totalSettledDefaultsDebt_TotalOutstandingDebt_Ratio:
                        a.currentTarget.value,
                    })
                  }
                >
                  {totalSettledDefaultsDebt_TotalOutstandingDebt_RatioOptions.map(
                    (e) => (
                      <option key={e}>{e}</option>
                    )
                  )}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>A.9. Type of Project</Label>
                <Input
                  type="select"
                  value={data.typeOfProject || 'Supply'}
                  onChange={(a) =>
                    setData({ typeOfProject: a.currentTarget.value })
                  }
                >
                  {typeOfProjectOptions.map((e) => (
                    <option key={e}>{e}</option>
                  ))}
                </Input>
              </FormGroup>
            </Row>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="2">B. Penalty Questions</AccordionHeader>
          <AccordionBody accordionId="2">
            <Row>
              <FormGroup>
                <Label>B.1. Number of Rejected Cheques</Label>
                <Input
                  type="select"
                  value={data.numberOfRejectedCheques || 'Less than 2'}
                  onChange={(a) =>
                    setData({
                      numberOfRejectedCheques: a.currentTarget.value,
                    })
                  }
                >
                  {rejectedChequesOptions.map((e, i) => (
                    <option value={e} key={i}>
                      {e}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label>B.2. Number of Settled Litigations</Label>
                <Input
                  type="select"
                  value={data.numberOfSettledLitigations || '8 & more'}
                  onChange={(a) =>
                    setData({
                      numberOfSettledLitigations: a.currentTarget.value,
                    })
                  }
                >
                  {settledLitigationsOptions.map((e, i) => (
                    <option value={e} key={i}>
                      {e}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Row>

            <MultiDelayedB
              title="B.3. Number of Delayed Payments on Loans"
              value={delayed}
              onChange={setDelayed}
            />
            <MultiDelayedB
              title="B.4. Number of Delayed Payments on Credit Cards"
              value={rejected}
              onChange={setRejected}
            />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="3">
            C. Observation Questions (Not for Scoring)
          </AccordionHeader>
          <AccordionBody accordionId="3">
            <Row>
              <Col className="mb-2" md="12">
                C.1. Credit History Questions
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="12">
                <Label>C.1.1. Requested Loan Amount</Label>
              </Col>
              <Col>
                <Input
                  type="text"
                  value={data.requestedLoanAmount || ''}
                  onChange={(a) =>
                    setData({
                      requestedLoanAmount: a.currentTarget.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="12">
                <Label>C.1.2. Project Value Amount</Label>
              </Col>
              <Col>
                <Input
                  type="text"
                  value={data.projectValueAmount || ''}
                  onChange={(a) =>
                    setData({ projectValueAmount: a.currentTarget.value })
                  }
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="12">
                <Label>C.1.3. Outstanding Loans Amount</Label>
              </Col>
              <Col>
                <Input
                  type="text"
                  value={data.outstandingLoansAmount || ''}
                  onChange={(a) =>
                    setData({ outstandingLoansAmount: a.currentTarget.value })
                  }
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={'12'}>
                <Label>C.1.4. Loan Duration</Label>
              </Col>
              <Col md={'12'}>
                <Input
                  type="text"
                  value={data.loanDuration || ''}
                  onChange={(a) =>
                    setData({ loanDuration: a.currentTarget.value })
                  }
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="12">
                <Label>
                  C.1.5. The Total Amount of Debt until Payment Due Date
                  (+Tameed Loan)
                </Label>
              </Col>

              <Col>
                <Input
                  type="text"
                  value={data.totalAmountDebtUntilPaymentDueDate || ''}
                  onChange={(a) =>
                    setData({
                      totalAmountDebtUntilPaymentDueDate: a.currentTarget.value,
                    })
                  }
                />{' '}
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="12">
                <Label>C.1.6. Total Potential Debt / Total Deposits</Label>
              </Col>
              <Col md="12">
                <Input
                  type="text"
                  value={data.totalPotentialDebt_TotalDeposits_Ratio || ''}
                  onChange={(a) =>
                    setData({
                      totalPotentialDebt_TotalDeposits_Ratio:
                        a.currentTarget.value,
                    })
                  }
                />{' '}
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="12">
                <Label>C.1.7. Will There be an Assignment of Proceeds? </Label>
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="anyAssignmentProceeds_1"
                  label="Yes"
                  className="mb-3"
                  checked={data.anyAssignmentProceeds === 'Yes'}
                  onChange={() =>
                    setData({
                      anyAssignmentProceeds: 'Yes',
                    })
                  }
                />
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="anyAssignmentProceeds_2"
                  label="No"
                  className="mb-3"
                  checked={data.anyAssignmentProceeds === 'No'}
                  onChange={() => setData({ anyAssignmentProceeds: 'No' })}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={'12'}>
                <Label>C.1.8. Is There an Outstanding Default?</Label>
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="anyDefaultOnOutstanding_1"
                  label="Yes"
                  className="mb-3"
                  checked={data.anyDefaultOnOutstanding === 'Yes'}
                  onChange={() =>
                    setData({
                      anyDefaultOnOutstanding: 'Yes',
                    })
                  }
                />
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="anyDefaultOnOutstanding_2"
                  label="No"
                  className="mb-3"
                  checked={data.anyDefaultOnOutstanding === 'No'}
                  onChange={() => setData({ anyDefaultOnOutstanding: 'No' })}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={'12'}>
                <Label>C.1.9. Is There an Unsettled Litigation?</Label>
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="anyUnsettledLitigation_1"
                  label="Yes"
                  className="mb-3"
                  checked={data.anyUnsettledLitigation === 'Yes'}
                  onChange={() => setData({ anyUnsettledLitigation: 'Yes' })}
                />
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="anyUnsettledLitigation_2"
                  label="No"
                  className="mb-3"
                  checked={data.anyUnsettledLitigation === 'No'}
                  onChange={() => setData({ anyUnsettledLitigation: 'No' })}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={'12'}>
                <Label>C.1.10. Is There an Outstanding Delayed Payment?</Label>
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="anyOutstandingDelayedPayment_1"
                  label="Yes"
                  className="mb-3"
                  checked={data.anyOutstandingDelayedPayment === 'Yes'}
                  onChange={() =>
                    setData({ anyOutstandingDelayedPayment: 'Yes' })
                  }
                />
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="anyOutstandingDelayedPayment_2"
                  label="No"
                  className="mb-3"
                  checked={data.anyOutstandingDelayedPayment === 'No'}
                  onChange={() =>
                    setData({ anyOutstandingDelayedPayment: 'No' })
                  }
                />
              </Col>
            </Row>

            <Row className="mb-2">
              <Col md={'12'}>
                <Label>C.1.11. Is the CR Valid?</Label>
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="isCRValid_1"
                  label="Yes"
                  className="mb-3"
                  checked={data.isCRValid === 'Yes'}
                  onChange={() => setData({ isCRValid: 'Yes' })}
                />
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="isCRValid_2"
                  label="No"
                  className="mb-3"
                  checked={data.isCRValid === 'No'}
                  onChange={() => setData({ isCRValid: 'No' })}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={'12'}>
                <Label>C.1.12. Is GOSI Compliance Certificate Valid?</Label>
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="isGOSICertificateValid_1"
                  label="Yes"
                  className="mb-3"
                  checked={data.isGOSICertificateValid === 'Yes'}
                  onChange={() => setData({ isGOSICertificateValid: 'Yes' })}
                />
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="isGOSICertificateValid_2"
                  label="No"
                  className="mb-3"
                  checked={data.isGOSICertificateValid === 'No'}
                  onChange={() => setData({ isGOSICertificateValid: 'No' })}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={'12'}>
                <Label>C.1.13. Is the Saudization Certificate Valid?</Label>
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="isSaudizationCertificateValid_1"
                  label="Yes"
                  className="mb-3"
                  checked={data.isSaudizationCertificateValid === 'Yes'}
                  onChange={() =>
                    setData({ isSaudizationCertificateValid: 'Yes' })
                  }
                />
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="isSaudizationCertificateValid_2"
                  label="No"
                  className="mb-3"
                  checked={data.isSaudizationCertificateValid === 'No'}
                  onChange={() =>
                    setData({ isSaudizationCertificateValid: 'No' })
                  }
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={'12'}>
                <Label>C.1.14. Is the Client in Compliance with ZATCA?</Label>
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="isClientComplianceZATCA_1"
                  label="Yes"
                  className="mb-3"
                  checked={data.isClientComplianceZATCA === 'Yes'}
                  onChange={() => setData({ isClientComplianceZATCA: 'Yes' })}
                />
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="isClientComplianceZATCA_2"
                  label="No"
                  className="mb-3"
                  checked={data.isClientComplianceZATCA === 'No'}
                  onChange={() => setData({ isClientComplianceZATCA: 'No' })}
                />
              </Col>
            </Row>

            <Row className="mb-2">
              <Col md={'12'}>
                <Label>
                  C.1.15. Does the Client have Certificate from The Chamber of
                  Commerce?
                </Label>
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="hasClientCertificateChamberCommerce_1"
                  label="Yes"
                  className="mb-3"
                  checked={data.hasClientCertificateChamberCommerce === 'Yes'}
                  onChange={() =>
                    setData({ hasClientCertificateChamberCommerce: 'Yes' })
                  }
                />
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="hasClientCertificateChamberCommerce_2"
                  label="No"
                  className="mb-3"
                  checked={data.hasClientCertificateChamberCommerce === 'No'}
                  onChange={() =>
                    setData({ hasClientCertificateChamberCommerce: 'No' })
                  }
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={'12'}>
                <Label>
                  C.1.16. Has the Company Established Less than a Year?
                </Label>
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="hasCompanyEstablishedLessYear_1"
                  label="Yes"
                  className="mb-3"
                  checked={data.hasCompanyEstablishedLessYear === 'Yes'}
                  onChange={() =>
                    setData({ hasCompanyEstablishedLessYear: 'Yes' })
                  }
                />
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="hasCompanyEstablishedLessYear_2"
                  label="No"
                  className="mb-3"
                  checked={data.hasCompanyEstablishedLessYear === 'No'}
                  onChange={() =>
                    setData({ hasCompanyEstablishedLessYear: 'No' })
                  }
                />
              </Col>
            </Row>

            <Row className="mb-2">
              <Col md={'12'}>
                <Label>
                  C.1.17. Is the Owner&apos;s or the Delegated Person&apos;s Age
                  is Less than 25 or Older 80 Years Old?
                </Label>
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="isOwnerLessThan25Older80_1"
                  label="Yes"
                  className="mb-3"
                  checked={data.isOwnerLessThan25Older80 === 'Yes'}
                  onChange={() => setData({ isOwnerLessThan25Older80: 'Yes' })}
                />
              </Col>
              <Col md={'4'}>
                <CheckBox
                  type="radio"
                  id="isOwnerLessThan25Older80_2"
                  label="No"
                  className="mb-3"
                  checked={data.isOwnerLessThan25Older80 === 'No'}
                  onChange={() => setData({ isOwnerLessThan25Older80: 'No' })}
                />
              </Col>
            </Row>
          </AccordionBody>
        </AccordionItem>
      </UncontrolledAccordion>
    </div>
  );
};
//should be review
export function Ta3meed() {
  const [done, setDone] = useState(false);

  const [docID, setDocID] = useState([]);

  const [data, setData] = useFormObject();
  const [delayed, setDelayed] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [analysisCustomerId, setAnalysisCustomerId] = useState(null);
  const [projectName, setProjectName] = useState('');
  const [entityName, setEntityName] = useState('');
  const [companyAnalysisSubType, setCompanyAnalysisType] =
    useState('manufacturing');
  const [statementFile, setStatementFile] = useState('Financial');
  const navigate = useNavigate();
  const [api] = useAuth();
  const onSubmit = useCallback(
    async function (e) {
      e.preventDefault();
      if (data.financial_file) {
        await api.tokenized.post('/statement', {
          sectorCode: 'C',
          analysisDetail: {
            analysisCustomerId: analysisCustomerId.value,
            analysisCompanyTitle: analysisCustomerId.label,
            projectName,
            entityName,
            companyAnalysisSubType,
          },

          documents: [
            {
              documentId: data.financial_file.id,
              fileExtension: data.financial_file.name.match(/\.(....?)$/)[1], // . olacaksa [1] kismi [0] olacak
              type: statementFile === 'Simah' ? 13 : 11,
              useAsMain: true,
              pageNums:
                (data.pgnum_first ? data.pgnum_first : '') +
                (data.pgnum_last ? '-' + data.pgnum_last : ''), //"3-5",
            },
          ],
        });
      }

      var _docID = await api.tokenized.post('/survey', {
        documentId: data.financial_file ? data.financial_file.id : 0,
        analysisCustomerId: analysisCustomerId.value,
        analysisDetail: {
          analysisCompanyTitle: analysisCustomerId.label,
          projectName,
          entityName,
          companyAnalysisSubType,
        },

        qualitative: {
          durationSinceLastDefault:
            data.durationSinceLastDefault || 'No default',
          outstandingDebt_TotalDeposits_Ratio:
            data.outstandingDebt_TotalDeposits_Ratio || 'Less than 3%',
          requestedLoan_WholeProject_Ratio:
            data.requestedLoan_WholeProject_Ratio || 'Less than 5%',
          numberOfEmployees: data.numberOfEmployees || 'More than 70',
          companyAge: data.companyAge || 'Less than 3 years',
          numberOfFinishedProjects:
            data.numberOfFinishedProjects || '2 and less',
          requestedLoan_TotalDeposits_Ratio:
            data.requestedLoan_TotalDeposits_Ratio || '0.01% to 3%',
          totalSettledDefaultsDebt_TotalOutstandingDebt_Ratio:
            data.totalSettledDefaultsDebt_TotalOutstandingDebt_Ratio ||
            'No default',
          typeOfProject: data.typeOfProject || 'Supply',
        },
        penalty: {
          numberOfRejectedCheques:
            data.numberOfRejectedCheques || 'Less than 2',
          numberOfSettledLitigations: data.numberOfSettledLitigations || '0',
          numberOfDelayedPaymentsOnLoans: delayed || '',
          numberOfDelayedPaymentsOnCreditCards: rejected || '',
        },
        history: {
          requestedLoanAmount: data.requestedLoanAmount || '',
          projectValueAmount: data.projectValueAmount || '',
          outstandingLoansAmount: data.outstandingLoansAmount || '',
          loanDuration: data.loanDuration || '',
          totalAmountDebtUntilPaymentDueDate:
            data.totalAmountDebtUntilPaymentDueDate || '',
          totalPotentialDebt_TotalDeposits_Ratio:
            data.totalPotentialDebt_TotalDeposits_Ratio || '',
          anyAssignmentProceeds: data.anyAssignmentProceeds || 'No',
          anyDefaultOnOutstanding: data.anyDefaultOnOutstanding || 'No',
          anyUnsettledLitigation: data.anyUnsettledLitigation || 'No',
          anyOutstandingDelayedPayment:
            data.anyOutstandingDelayedPayment || 'No',
          isCRValid: data.isCRValid || 'No',
          isGOSICertificateValid: data.isGOSICertificateValid || 'No',
          isSaudizationCertificateValid:
            data.isSaudizationCertificateValid || 'No',
          isClientComplianceZATCA: data.isClientComplianceZATCA || 'No',
          hasClientCertificateChamberCommerce:
            data.hasClientCertificateChamberCommerce || 'No',
          hasCompanyEstablishedLessYear:
            data.hasCompanyEstablishedLessYear || 'No',
          isOwnerLessThan25Older80: data.isOwnerLessThan25Older80 || 'No',
        },
      });
      if (data.bankstatement_file) {
        await api.tokenized.post('/bankstatement', {
          documentId: data.bankstatement_file.id,
        });
      }

      setDocID(_docID);
      setDone(true);
    },
    [
      data,
      setDone,
      delayed,
      rejected,
      setDocID,
      analysisCustomerId,
      projectName,
      entityName,
      companyAnalysisSubType,
    ]
  );

  useEffect(() => {
    const titleDiv = document.getElementsByClassName(
      'page-title-box d-sm-flex align-items-center justify-content-between'
    );
    if (
      titleDiv &&
      titleDiv[0] &&
      titleDiv[0].firstChild &&
      titleDiv[0].firstChild.style
    ) {
      titleDiv[0].firstChild.style.textTransform = 'initial';
      titleDiv[0].firstChild.style.fontSize = '20px';
    }
  }, []);
  const loadOptions = async (search, loadedOptions, { page }) => {
    const res = await api.tokenized.get(
      `/customer/get/all?query=${search}&${page}`
    );

    const options =
      res &&
      res.data &&
      res.data.data.map((d) => {
        return {
          label: `${d.tradeRegistrationNumber}-${d.name}`,
          value: d.id,
          target: {
            name: 'analysisCustomerId',
            value: {
              value: d.id,
              label: `${d.tradeRegistrationNumber}-${d.name}`,
            },
          },
        };
      });

    return {
      options: options || [],
      hasMore: false,
    };
  };
  if (done) {
    return (
      <Navigate
        to={
          '/analysis/' +
          (data.financial_file
            ? window.btoa(data.financial_file.id)
            : window.btoa(docID?.data.requestModel.documentId)) +
          '/edit/' +
          (data.bankstatement_file
            ? window.btoa(data.bankstatement_file.id)
            : 'empty')
        }
      />
    );
  }
  return (
    <CustomForm onSubmit={onSubmit}>
      <Card>
        <CardBody>
          <AccordionItems
            data={data}
            setData={setData}
            rejected={rejected}
            setRejected={setRejected}
            delayed={delayed}
            setDelayed={setDelayed}
            defaultOpen={'1'}
          />

          <Row className="mb-2">
            <Col md={'12'}>
              <hr style={{ marginTop: '30px' }} />
            </Col>
            <Col md={6} className={'mt-3'}>
              <Label for="analysisCustomerId">
                {<FormattedMessage id="analysisCustomerId" />}
              </Label>
              <Button
                style={{ marginLeft: '1em' }}
                size="sm"
                color="success"
                className="fas fa-plus"
                onClick={() => navigate('/customer/add')}
              />
              <Validator name="customerTitle" type="required">
                <AsyncPaginate
                  name="analysisCustomerId"
                  value={analysisCustomerId}
                  onChange={(e) =>
                    setAnalysisCustomerId(e ? e.target.value : null)
                  }
                  loadOptions={loadOptions}
                  additional={{
                    page: 1,
                  }}
                  isClearable
                  placeholder={
                    <FormattedMessage id={'NEW_ANL_PLACEHOLDER_TEXT'} />
                  }
                  loadingMessage={() => <FormattedMessage id={'LOADING'} />}
                  noOptionsMessage={() => (
                    <FormattedMessage id={'NO_OPTIONS'} />
                  )}
                />
              </Validator>
            </Col>

            <Col md={6} className={'mt-3'}>
              <InputField
                label="Project Name"
                value={projectName}
                onChange={(e) => setProjectName(e.currentTarget.value)}
              />
            </Col>
            <Col md={6} className={'mt-3'}>
              <InputField
                label="Entity Name"
                value={entityName}
                onChange={(e) => setEntityName(e.currentTarget.value)}
              />
            </Col>
            <FormGroup className={'mt-3'}>
              <Label>Add a bank statement: </Label>
              <SingleFilePicker
                accepts=".pdf"
                value={data.bankstatement_file}
                onChange={(a) => setData({ bankstatement_file: a })}
              />
            </FormGroup>

            <hr />
            <Row>
              <Label>Statement Type</Label>
            </Row>
            <Row>
              <Col sm={3}>
                <Label for="statementFile1">Financial Statement</Label>
                <Input
                  style={{ marginLeft: '1em' }}
                  name="statementFile"
                  type="checkbox"
                  value="Financial"
                  checked={statementFile === 'Financial'}
                  onChange={(e) => setStatementFile(e.target.value)}
                  id="statementFile1"
                />
              </Col>

              <Col sm={3}>
                <Label for="statementFile2">Simah Statement </Label>
                <Input
                  style={{ marginLeft: '1em' }}
                  name="statementFile"
                  type="checkbox"
                  value="Simah"
                  checked={statementFile === 'Simah'}
                  onChange={(e) => setStatementFile(e.target.value)}
                  id="statementFile2"
                />
              </Col>
            </Row>
            <hr />

            <Row>
              <Label>Firm Type</Label>
            </Row>

            <Row>
              <Col sm={3}>
                <Label for="manufacturing">Manufacturing Firm</Label>
                <Input
                  style={{ marginLeft: '1em' }}
                  type="checkbox"
                  value="manufacturing"
                  checked={companyAnalysisSubType === 'manufacturing'}
                  onChange={(e) => setCompanyAnalysisType(e.target.value)}
                  id="manufacturing"
                />
              </Col>

              <Col sm={3}>
                <Label for="nonManufacturing">Non-Manufacturing Firm </Label>
                <Input
                  style={{ marginLeft: '1em' }}
                  type="checkbox"
                  value="nonManufacturing"
                  checked={companyAnalysisSubType === 'nonManufacturing'}
                  onChange={(e) => setCompanyAnalysisType(e.target.value)}
                  id="nonManufacturing"
                />
              </Col>
            </Row>

            <FormGroup className={'mt-3'}>
              <Label>Upload {statementFile} Statement</Label>
              {statementFile === 'Financial' && (
                <Label>
                  You can upload financial statements in pdf or xlsx formats. If
                  you would like to upload the financial statements in excel,
                  please download{' '}
                  <Link to={FinancialStatementTemplate} target="_blank">
                    <u>this</u>{' '}
                  </Link>
                  excel template to your computer, fill it using the financials
                  and upload back to the platform. Do not upload any excel files
                  other than the provided excel template to the platform as it
                  will cause errors.
                </Label>
              )}
              <SingleFilePicker
                accepts={
                  statementFile === 'Simah' ? '.pdf,.xlsx,' : '.pdf,.xlsx,.csv'
                }
                value={data.financial_file}
                onChange={(a) => setData({ financial_file: a })}
              />
            </FormGroup>

            {data &&
              data.financial_file &&
              data.financial_file.name &&
              checkFileIsPdf(data.financial_file) && (
                <FormGroup className={'mt-3 row'}>
                  <Label>Financial File Page Numbers*</Label>
                  <Col md="2">
                    <Label>Start</Label>
                    <Input
                      onInvalid={(e) =>
                        e.target.setCustomValidity('This field is required')
                      }
                      onInput={(e) => e.target.setCustomValidity('')}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={data.pgnum_first || ''}
                      onChange={(a) =>
                        setData({ pgnum_first: a.currentTarget.value })
                      }
                      required={
                        data &&
                        data.financial_file &&
                        checkFileIsPdf(data.financial_file)
                      }
                      min={0}
                      max={300}
                    />
                  </Col>
                  <Col md="2">
                    <Label>End</Label>
                    <Input
                      onInvalid={(e) =>
                        e.target.setCustomValidity('This field is required')
                      }
                      onInput={(e) => e.target.setCustomValidity('')}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={data.pgnum_last || ''}
                      onChange={(a) =>
                        setData({ pgnum_last: a.currentTarget.value })
                      }
                      required={
                        data &&
                        data.financial_file &&
                        checkFileIsPdf(data.financial_file)
                      }
                      min={0}
                      max={300}
                    />
                  </Col>
                </FormGroup>
              )}
          </Row>
        </CardBody>
      </Card>
    </CustomForm>
  );
}
