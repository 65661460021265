import React, { useContext } from 'react';

const CompanyCodeContext = React.createContext({});

export const useCompanyCode = () => {
  return useContext(CompanyCodeContext);
};

export const CompanyCodeProvider = ({ children, value }) => {
  return (
    <CompanyCodeContext.Provider value={{ companyCode: value }}>
      {children}
    </CompanyCodeContext.Provider>
  );
};
