/* eslint-disable no-compare-neg-zero */
/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useState } from 'react';
import { MultiValue, useAuth } from 'react-admin-base';
import { Navigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  Table,
  Alert,
} from 'reactstrap';
import { useFormObject } from './AnalysisTaemeed';
import { CustomForm } from './AnalysisEntity';
import NumberFormat from 'react-number-format';
import { InputField } from '../../Components/Input';
import { AccordionItems } from '../Analysis/AnalysisTaemeed';

const multiBankStatmentHeaders = [
  'Bank Account Names',
  'Currency',
  'Statement Period',
  'Beginning Balance',
  'Total Amount of Deposits',
  'Deposits from Contracts',
  'Total Amount of Credits (Withdrawals)',
  'Ending Balance',
];

function CompanyInfo({ value, onChange, statementNames }) {
  return (
    <Table striped responsive bordered hover size="sm">
      <thead>
        <tr>
          <th className="text-center" colSpan="2">
            Company Info
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Company Name</td>
          <td>
            <Input
              disabled
              type="text"
              value={statementNames['analysisCompanyTitle']}
            />
          </td>
        </tr>
        <tr>
          <td>Entity Name</td>
          <td>
            <Input disabled type="text" value={statementNames['entityName']} />
          </td>
        </tr>
        <tr>
          <td>Project Name</td>
          <td>
            <Input disabled type="text" value={statementNames['projectName']} />
          </td>
        </tr>

        <tr>
          <td>Denomination</td>
          <td>
            <Input
              type="select"
              value={value['denomination']}
              onChange={(a) =>
                onChange({ ...value, denomination: a.currentTarget.value })
              }
            >
              <option value="0">None</option>
              <option value="Thousand">Thousand</option>
              <option value="Million">Million</option>
            </Input>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export const CompanyBalance = ({ value, onChange }) => {
  return value.map((a, i) => (
    <CompanyBalanceInner
      key={i}
      title={a.name}
      color={a.colorCode}
      value={a.data.rowList}
      onChange={(e) =>
        onChange(
          value.map((b) => (b === a ? { ...b, data: { rowList: e } } : b))
        )
      }
    />
  ));
};

function CompanyBalanceInner({ title, color, value, onChange }) {
  const years = value[0].values.map((a) => a.year);
  return (
    <Table responsive bordered hover size="sm">
      <thead>
        <tr>
          <th className="text-center" colSpan="3">
            <span style={{ padding: '0px 10px', color: '#' + color }}>
              {title}
            </span>
          </th>
        </tr>
        <tr>
          <td />
          {years.map((year) => (
            <td key={year}>{year}</td>
          ))}
        </tr>
      </thead>
      <tbody>
        {value.map((ab, index) => (
          <tr key={index} style={{ backgroundColor: ab.title === '' && '' }}>
            <td
              style={{ textAlign: ab.alignment === 'Center' ? 'center' : ' ' }}
              colSpan={ab.editable ? '1' : '3'}
            >
              <span
                style={{
                  padding: '0px 10px',
                  color: '#' + ab.bold,
                  fontWeight: ab.bold === true ? 'bold' : ' ',
                }}
              >
                {ab.title}
              </span>
            </td>
            {ab.editable &&
              years.map((year, index) => (
                <td key={year}>
                  <NumberFormat
                    className="form-control"
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    value={(ab && ab.values[index].value) || ''}
                    onValueChange={(e) => {
                      onChange(
                        value.map((v) =>
                          v === ab
                            ? {
                                ...ab,
                                values: ab.values.map((t) =>
                                  t === ab.values[index]
                                    ? {
                                        ...t,
                                        value: e.floatValue,
                                      }
                                    : t
                                ),
                              }
                            : v
                        )
                      );
                    }}
                  />
                </td>
              ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function MultiBankStatement({ value, onChange }) {
  return (
    <Table striped responsive bordered hover size="sm">
      <thead>
        <tr>
          <th colSpan={8} className="text-center">
            Bank Statements
          </th>
        </tr>
        <tr>
          <th colSpan={8}>
            Please dowload the excel version of the bank statement here and fill
            the sections below.
          </th>
        </tr>
        <tr>
          {multiBankStatmentHeaders.map((e, i) => (
            <td key={i}>{e}</td>
          ))}
        </tr>
      </thead>
      <tbody>
        <MultiValue value={value} onChange={onChange} add={value.length !== 5}>
          {(row, onChange, i) => (
            <tr key={i}>
              <td>
                <Input
                  type="text"
                  value={(row && row.bankAccountName) || ''}
                  onChange={(a) =>
                    onChange({
                      ...(row || {}),
                      bankAccountName: a.currentTarget.value,
                    })
                  }
                />
              </td>
              <td>
                <Input
                  type="text"
                  value={(row && row.currency) || ''}
                  onChange={(a) =>
                    onChange({
                      ...(row || {}),
                      currency: a.currentTarget.value,
                    })
                  }
                />
              </td>
              <td>
                <Input
                  type="text"
                  value={(row && row.statementPeriod) || ''}
                  onChange={(a) =>
                    onChange({
                      ...(row || {}),
                      statementPeriod: a.currentTarget.value,
                    })
                  }
                />
              </td>
              <td>
                <NumberFormat
                  className="form-control"
                  thousandSeparator={','}
                  decimalSeparator={'.'}
                  allowNegative={true}
                  value={row && row.beginningBalance}
                  onChange={(e) => {
                    onChange({
                      ...(row || {}),
                      beginningBalance: e.currentTarget.value,
                    });
                  }}
                  isNumericString={true}
                />
              </td>
              <td>
                <NumberFormat
                  className="form-control"
                  thousandSeparator={','}
                  decimalSeparator={'.'}
                  allowNegative={true}
                  value={row && row.totalAmountOfDeposits}
                  onChange={(e) => {
                    onChange({
                      ...(row || {}),
                      totalAmountOfDeposits: e.currentTarget.value,
                    });
                  }}
                  isNumericString={true}
                />
              </td>
              <td>
                <NumberFormat
                  className="form-control"
                  thousandSeparator={','}
                  decimalSeparator={'.'}
                  allowNegative={true}
                  value={row && row.depositsFromContracts}
                  onChange={(e) => {
                    onChange({
                      ...(row || {}),
                      depositsFromContracts: e.currentTarget.value,
                    });
                  }}
                />
              </td>
              <td>
                <NumberFormat
                  className="form-control"
                  thousandSeparator={','}
                  decimalSeparator={'.'}
                  allowNegative={true}
                  value={row && row.totalAmountOfCredits}
                  onChange={(e) => {
                    onChange({
                      ...(row || {}),
                      totalAmountOfCredits: e.currentTarget.value,
                    });
                  }}
                />
              </td>
              <td>
                <NumberFormat
                  className="form-control"
                  thousandSeparator={','}
                  decimalSeparator={'.'}
                  allowNegative={true}
                  value={row && row.endingBalance}
                  onChange={(e) => {
                    onChange({
                      ...(row || {}),
                      endingBalance: e.currentTarget.value,
                    });
                  }}
                />
              </td>
              {!!row && (
                <td className="text-end min">
                  <Button
                    type="button"
                    size="sm"
                    color="danger"
                    onClick={() => onChange(null)}
                  >
                    Del
                  </Button>
                </td>
              )}
            </tr>
          )}
        </MultiValue>
      </tbody>
    </Table>
  );
}

function saveFile(blob, name) {
  var a = document.createElement('a');
  var url = window.URL.createObjectURL(blob);
  a.setAttribute('href', url);
  a.setAttribute('download', name);
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

function DownloadBtn({ url, children }) {
  const [api] = useAuth();

  const onClick = useCallback(
    async function (e) {
      e.preventDefault();

      const response = await api.tokenized.get(url, { responseType: 'blob' });
      saveFile(response.data, 'jet.xls');
    },
    [api, url]
  );

  return (
    <Button
      type="button"
      color="primary"
      onClick={onClick}
      className="my-1 w-100"
    >
      {children}
    </Button>
  );
}
const BankRatios = ({ bankStatementRatio, handleChangeDepositRatio }) => {
  return (
    <Table striped responsive bordered hover size="sm">
      <thead>
        <tr>
          <th className="text-center" colSpan="2">
            Ratios
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Desposits from Contracts/ Total Deposits</td>
          <td>
            <InputField
              name="depositsFromContracts_TotalDepositRatio"
              onChange={handleChangeDepositRatio}
              value={bankStatementRatio.depositsFromContracts_TotalDepositRatio}
              type="number"
              onKeyPress={(event) => {
                if (event.key === 'e') {
                  event.preventDefault();
                }
              }}
              onPaste={(event) => {
                let pastedValue = event.clipboardData.getData('text');
                if (pastedValue === 'e') {
                  event.preventDefault();
                }
              }}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default function AnalysisEdit() {
  const [api] = useAuth();
  const { id } = useParams();
  const { bid } = useParams();
  const [done, setDone] = useState(false);

  const [companyInfo, setCompanyInfo] = useState();
  const [companyBalance, setCompanyBalance] = useState();
  const [companyStatement, setCompanyStatement] = useState([]);
  const [statementNames, setStatementNames] = useState({});
  const [bankStatementRatio, setBankStatementRatio] = useState({});
  const [accordionData, setAccordionData] = useFormObject();
  const [delayed, setDelayed] = useState([]);
  const [rejected, setRejected] = useState([]);
  const handleChangeDepositRatio = (e) => {
    const { name, value } = e.target;

    setBankStatementRatio((prev) => ({ ...prev, [name]: value }));
  };
  useEffect(
    function () {
      api.tokenized
        .get('/tables/companyInfo/' + window.atob(id))
        .then((a) => setCompanyInfo(a.data));

      api.tokenized
        .get('/tables/statement/edit/' + window.atob(id))
        .then((a) => setCompanyBalance(a.data));

      api.tokenized
        .get('/bankstatement/bankStatementRatio?documentId=' + window.atob(id))
        .then((a) => setBankStatementRatio({ ...a.data }));

      api.tokenized
        .get('/analyze/details/' + window.atob(id))
        .then((a) => setStatementNames({ ...a.data }));

      api.tokenized
        .get('/bankstatement/bankStatementInfo?documentId=' + window.atob(id))
        .then((a) =>
          setCompanyStatement([
            ...a.data.statementRows.filter(
              (e) =>
                !(
                  e.bankAccountName === '' &&
                  e.beginningBalance === 0 &&
                  e.currency === '' &&
                  e.depositsFromContracts === 0 &&
                  e.endingBalance === 0 &&
                  e.statementPeriod === '' &&
                  e.totalAmountOfCredits === 0 &&
                  e.totalAmountOfDeposits === 0
                )
            ),
          ])
        );

      api.tokenized.get('/tables/survey/edit/' + window.atob(id)).then((a) => {
        const delayed = a.data.penalty.numberOfDelayedPaymentsOnLoans.filter(
          (e) => e.days === 'No Delay' || e.number !== '0'
        );
        const rejected =
          a.data.penalty.numberOfDelayedPaymentsOnCreditCards.filter(
            (e) => e.days === 'No Delay' || e.number !== '0'
          );
        setDelayed([...delayed]);
        setRejected([...rejected]);
        setAccordionData({
          ...a.data.history,
          numberOfRejectedCheques: a.data.penalty.numberOfRejectedCheques,
          numberOfSettledLitigations: a.data.penalty.numberOfSettledLitigations,
          totalScoreOfPenaltyQuestions:
            a.data.penalty.totalScoreOfPenaltyQuestions,
          ...a.data.qualitative,
        });
      });
    },
    [
      api,
      id,
      setCompanyInfo,
      setCompanyBalance,
      setStatementNames,
      setDelayed,
      setRejected,
      setAccordionData,
    ]
  );
  const onSubmit = useCallback(
    async function (e) {
      e.preventDefault();

      //hata veriyor düzenle
      const newCompanyStatement = [...companyStatement];
      newCompanyStatement.map((e) => {
        Object.keys(e).forEach((key) => {
          if (
            !['bankAccountName', 'currency', 'statementPeriod'].includes(key) &&
            typeof e[key] !== 'number'
          ) {
            if (e[key]) {
              if (e[key].endsWith('.') || e[key] === '.') {
                e[key] = e[key].replace('.', '');
              } else if (e[key].startsWith('.')) {
                e[key] = `0${e[key]}`;
              }
              if (e[key] === '-') e[key] = e[key].replace('-', '');
            }
            if (e[key] === '') {
              delete e[key];
            } else e[key] = Number(e[key].replaceAll(',', ''));
          } else if (e[key] === '') {
            delete e[key];
          }
        });
      });

      await api.tokenized.post(
        '/tables/companyInfo/' + window.atob(id),
        companyInfo
      );

      await api.tokenized.post(
        '/tables/statement/edit/' + window.atob(id),
        companyBalance
      );
      await api.tokenized.post('/bankstatement/update/', {
        financialStatementDocumentId: window.atob(id),
        statementRows: newCompanyStatement || [],
      });
      await api.tokenized.post(
        '/bankstatement/bankStatementRatio?documentId=' + window.atob(id),
        bankStatementRatio
      );
      await api.tokenized.post('/tables/survey/edit', {
        documentId: window.atob(id),
        qualitative: {
          durationSinceLastDefault: accordionData.durationSinceLastDefault,
          outstandingDebt_TotalDeposits_Ratio:
            accordionData.outstandingDebt_TotalDeposits_Ratio,
          requestedLoan_WholeProject_Ratio:
            accordionData.requestedLoan_WholeProject_Ratio,
          numberOfEmployees: accordionData.numberOfEmployees,
          companyAge: accordionData.companyAge,
          numberOfFinishedProjects: accordionData.numberOfFinishedProjects,
          requestedLoan_TotalDeposits_Ratio:
            accordionData.requestedLoan_TotalDeposits_Ratio,
          totalSettledDefaultsDebt_TotalOutstandingDebt_Ratio:
            accordionData.totalSettledDefaultsDebt_TotalOutstandingDebt_Ratio,
          typeOfProject: accordionData.typeOfProject,
        },
        penalty: {
          numberOfRejectedCheques: accordionData.numberOfRejectedCheques,
          numberOfSettledLitigations: accordionData.numberOfSettledLitigations,
          numberOfDelayedPaymentsOnLoans: delayed,
          numberOfDelayedPaymentsOnCreditCards: rejected,
        },
        history: {
          requestedLoanAmount: accordionData.requestedLoanAmount,
          projectValueAmount: accordionData.projectValueAmount,
          outstandingLoansAmount: accordionData.outstandingLoansAmount,
          loanDuration: accordionData.loanDuration,
          totalAmountDebtUntilPaymentDueDate:
            accordionData.totalAmountDebtUntilPaymentDueDate,
          totalPotentialDebt_TotalDeposits_Ratio:
            accordionData.totalPotentialDebt_TotalDeposits_Ratio,
          anyAssignmentProceeds: accordionData.anyAssignmentProceeds,
          anyDefaultOnOutstanding: accordionData.anyDefaultOnOutstanding,
          anyUnsettledLitigation: accordionData.anyUnsettledLitigation,
          anyOutstandingDelayedPayment:
            accordionData.anyOutstandingDelayedPayment,
          isCRValid: accordionData.isCRValid,
          isGOSICertificateValid: accordionData.isGOSICertificateValid,
          isSaudizationCertificateValid:
            accordionData.isSaudizationCertificateValid,
          isClientComplianceZATCA: accordionData.isClientComplianceZATCA,
          hasClientCertificateChamberCommerce:
            accordionData.hasClientCertificateChamberCommerce,
          hasCompanyEstablishedLessYear:
            accordionData.hasCompanyEstablishedLessYear,
          isOwnerLessThan25Older80: accordionData.isOwnerLessThan25Older80,
        },
      });

      setDone(true);
    },
    [
      setDone,
      companyInfo,
      companyBalance,
      companyStatement,
      bankStatementRatio,
      accordionData,
      rejected,
      delayed,
    ]
  );
  if (!companyInfo || !companyBalance) return null;

  if (done) {
    return <Navigate to={'/report/' + id + '/summary'} />;
  }

  return (
    <CustomForm onSubmit={onSubmit}>
      <Card>
        <CardBody>
          <Row>
            <Col md="12">
              <Table striped responsive bordered hover size="sm">
                <thead>
                  <tr>
                    <th className="text-center">Questions</th>
                  </tr>
                </thead>
                <tbody>
                  <AccordionItems
                    defaultOpen=""
                    data={accordionData}
                    setData={setAccordionData}
                    rejected={rejected}
                    setRejected={setRejected}
                    delayed={delayed}
                    setDelayed={setDelayed}
                  />
                </tbody>
              </Table>
            </Col>

            <Col md="12">
              <CompanyInfo
                value={companyInfo}
                onChange={setCompanyInfo}
                statementNames={statementNames}
              />
            </Col>
            <Col md="12">
              <hr />
            </Col>
            <Col md="12">
              <CompanyBalance
                value={companyBalance}
                onChange={setCompanyBalance}
              />
            </Col>
            <Col md="12">
              <hr />
            </Col>
            <Col md="10" />
            <Col md="2">
              {bid !== 'empty' && (
                <DownloadBtn
                  url={'/bankstatement/download?documentId=' + window.atob(bid)}
                >
                  Download
                </DownloadBtn>
              )}
            </Col>
            <Col md="12">
              <MultiBankStatement
                value={companyStatement}
                onChange={setCompanyStatement}
              />
              {companyStatement.length === 5 && (
                <Alert
                  style={{
                    padding: '0 0 0 2px',
                    width: '22%',
                  }}
                  color="danger"
                >
                  You can fill only 5 sections
                </Alert>
              )}
            </Col>
            <Col md="12">
              <BankRatios
                bankStatementRatio={bankStatementRatio}
                handleChangeDepositRatio={handleChangeDepositRatio}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </CustomForm>
  );
}
