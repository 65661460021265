import React from 'react';
import { Table } from 'react-bootstrap';
import { formatNumber } from '../../../Util/formatNumber';

const titlesEnum = {
  itemsFromDocument: 'Belgeden Aktarılan',
  itemsFromCalculation: 'Kalemler Toplanarak Hesaplanan',
  difference: 'Fark',
};

const boldTitles = ['VARLIKLAR', 'KAYNAKLAR', 'AKTİF TOPLAMI', 'PASİF TOPLAMI'];

export const title = 'Aktif-Pasif (±5)';

export const boundaryNumber = 5;

export const differencOfMainTitle = (data, key) => {
  const values1 = data
    .find((e) => e.collapsable === 'B1')
    .values[key].map((e) => e);
  const values2 = data
    .find((e) => e.collapsable === 'B2')
    .values[key].map((e) => e);

  return values1.map((e, index) => {
    return {
      year: e.year,
      value: e.value - values2[index].value,
    };
  });
};

//find sum according to collapsableInfo
export const findSumOfCalculatedDatas = (
  calculatorDatas,
  aktifPasifDetection
) => {
  const newCalcualtorDatas = {};
  const collapsableTitles = aktifPasifDetection.map((e) => e.collapsable);
  aktifPasifDetection.forEach((e) => {
    Object.entries(calculatorDatas).forEach(([k, d]) => {
      if (
        d.collapsableInfo &&
        d.collapsableInfo !== e.collapsable &&
        d.collapsableInfo.startsWith(e.collapsable) &&
        (!d.isDisabled || collapsableTitles.includes(d.collapsableInfo))
      ) {
        Object.entries(d).forEach(([key, value]) => {
          let value_ = value;
          if (k.includes('(-)')) {
            value_ = -value;
          }

          if (typeof value === 'number') {
            if (newCalcualtorDatas[e.collapsable]) {
              newCalcualtorDatas[e.collapsable] = {
                ...newCalcualtorDatas[e.collapsable],
                [key]: newCalcualtorDatas[e.collapsable][key]
                  ? newCalcualtorDatas[e.collapsable][key] + value_
                  : value_,
              };
            } else {
              newCalcualtorDatas[e.collapsable] = {
                [key]: value_,
              };
            }
          }
        });
      }
    });
  });

  return newCalcualtorDatas;
};

export const calculateNewAktifPasifDetection = (
  aktifPasifDetection,
  sumOfCAlculatedDatas
) => {
  const data = JSON.parse(JSON.stringify(aktifPasifDetection));

  data.forEach((item) => {
    let collapsable = item.collapsable;
    item.values.itemsFromCalculation.forEach((yearInfo) => {
      let year = String(yearInfo.year);
      if (
        sumOfCAlculatedDatas[collapsable] &&
        sumOfCAlculatedDatas[collapsable][year] !== undefined
      ) {
        yearInfo.value += sumOfCAlculatedDatas[collapsable][year];
      }
    });
    item.values.difference.forEach((yearInfo) => {
      let year = String(yearInfo.year);
      if (
        sumOfCAlculatedDatas[collapsable] &&
        sumOfCAlculatedDatas[collapsable][year] !== undefined
      ) {
        yearInfo.value += sumOfCAlculatedDatas[collapsable][year];
      }
    });
  });
  data.push({
    title: title,
    collapsable: '',
    values: {
      itemsFromDocument: differencOfMainTitle(data, 'itemsFromDocument'),
      itemsFromCalculation: differencOfMainTitle(data, 'itemsFromCalculation'),
    },
  });
  return data;
};

const AktifPasifDetectionTable_ = ({
  aktifPasifDetection,
  openCalculatorStatus,
  setOpenCalculatorStatus,
  calculatorDatas,
}) => {
  const aktifPasifDetection_ = JSON.parse(JSON.stringify(aktifPasifDetection));
  const years = aktifPasifDetection_[0].values.difference.map((e) => e.year);
  const values = Object.keys(aktifPasifDetection_[0].values);

  const sumOfCAlculatedDatas = findSumOfCalculatedDatas(
    calculatorDatas,
    aktifPasifDetection_
  );
  const newData = calculateNewAktifPasifDetection(
    aktifPasifDetection_,
    sumOfCAlculatedDatas
  );

  return (
    <div
      className="table-container"
      style={{
        paddingRight: '35px',
      }}
    >
      <Table striped bordered hover size="sm" responsive>
        <thead className="thead">
          <tr style={{ border: '1px solid black' }}>
            <th
              className="th-first"
              style={{
                minWidth: '250px',
                width: '400px',
              }}
            >
              Aktif-Pasif Kontrolü
            </th>
            {years.map((e, index) => {
              return values.map((e2, index2) => {
                return (
                  (openCalculatorStatus.includes(e) || index2 % 3 === 0) && (
                    <th key={`${index}${index2}`} className="text-center">
                      {e} {titlesEnum[e2]}
                      <i
                        className="fa fa-calculator"
                        aria-hidden="true"
                        style={{
                          marginLeft: '1em',
                          display: index2 === 0 ? 'block' : 'none',
                        }}
                        onClick={() =>
                          setOpenCalculatorStatus((prev) =>
                            prev.includes(e)
                              ? prev.filter((x) => x !== e)
                              : [...prev, e]
                          )
                        }
                      />
                    </th>
                  )
                );
              });
            })}
          </tr>
        </thead>
        <tbody className="tbody">
          {newData.map((e, index) => (
            <tr key={index} style={{ border: '1px solid black' }}>
              <td
                className="td-first"
                style={{
                  fontWeight: boldTitles.includes(e.title) ? 'bold' : 'normal',
                }}
              >
                {e.title}
              </td>
              {years.map((year, index2) => {
                return values.map((header, index3) => {
                  return (
                    (openCalculatorStatus.includes(year) ||
                      index3 % 3 === 0) && (
                      <td key={`${index2}${index3}`} className="text-center">
                        {e.values[header] &&
                          (index === newData.length - 1 ? (
                            e.values[header][index2].value &&
                            Math.abs(e.values[header][index2].value) >
                              boundaryNumber ? (
                              <span
                                style={{
                                  color: 'red',
                                }}
                              >
                                Fark:{' '}
                                {formatNumber(e.values[header][index2].value)}
                              </span>
                            ) : (
                              <span
                                style={{
                                  color: 'green',
                                }}
                              >
                                Eşit
                              </span>
                            )
                          ) : (
                            formatNumber(e.values[header][index2].value)
                          ))}
                      </td>
                    )
                  );
                });
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export const AktifPasifDetectionTable = React.memo(AktifPasifDetectionTable_);
