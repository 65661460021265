import React from 'react';
import { Row, Col } from 'reactstrap';
import ReactSpeedometer from 'react-d3-speedometer';

import { FormattedMessage } from 'react-intl';

import { findColorAndRisk } from '../Util/module_altman';
import './components.scss';

export const AltmanBrPrediction = ({
  altmanzscoring,
  containerRef9,
  containerRef10,
  containerRef11,
  containerRef12,
}) => {
  const currentValueText = '';
  const findGauge = (val) => {
    let gauge = 80;
    if (val === 'Düşük İflas Riski' || val === 'Low Risk') gauge = 10;
    else if (val === 'Average Risk' || val === 'Ortalama İflas Riski')
      gauge = 50;
    return gauge;
  };

  const y1 = altmanzscoring && altmanzscoring.titles[0].values[0].year;
  const y2 = altmanzscoring && altmanzscoring.titles[0].values[1].year;

  let alt_puan_1 = '';
  let alt_puan_2 = '';
  let alt_puan_3 = '';
  let alt_puan_4 = '';

  let gauge_1;
  let gauge_2;
  let gauge_3;
  let gauge_4;
  if (altmanzscoring && altmanzscoring.titles) {
    const azTitlesLength = altmanzscoring && altmanzscoring.titles.length;
    for (var i = 0; i < azTitlesLength; i++) {
      const title = altmanzscoring.titles[i].title;
      if (
        title === "Altman Z' Puan-İmalatçı Firmalar" ||
        title === "Z' Score Manufacturing"
      ) {
        alt_puan_1 = altmanzscoring.titles[i].values[0].value.toString();
        alt_puan_2 = altmanzscoring.titles[i].values[1].value.toString();
      } else if (
        title === "Altman Z' Puan-İmalat Harici Firmalar" ||
        title === "Z' Score Non-Manufacturing"
      ) {
        alt_puan_3 = altmanzscoring.titles[i].values[0].value.toString();
        alt_puan_4 = altmanzscoring.titles[i].values[1].value.toString();
      } else if (
        title === "Altman Z' Puan Riski-İmalatçı Firmalar" ||
        title === 'Risk Category/Manufacturing'
      ) {
        gauge_1 = findGauge(
          altmanzscoring.titles[i].values[0].value.toString()
        );
        gauge_2 = findGauge(
          altmanzscoring.titles[i].values[1].value.toString()
        );
      } else if (
        title === "Altman Z' Puan Riski-İmalat Harici Firmalar" ||
        title === 'Risk Category/Non-Manufacturing'
      ) {
        gauge_3 = findGauge(
          altmanzscoring.titles[i].values[0].value.toString()
        );
        gauge_4 = findGauge(
          altmanzscoring.titles[i].values[1].value.toString()
        );
      }
    }
  }

  return (
    <>
      {Number(alt_puan_1) !== 0 && Number(alt_puan_2) !== 0 && (
        <Row>
          <Col>
            <div ref={containerRef9} id="tableElement9">
              <AltmanPredictionChart
                y={y1}
                gauge={gauge_1}
                altPuan={alt_puan_1}
                currentValueText={currentValueText}
                color="#F3F3F5"
                containerRef9={containerRef9}
              />
            </div>
          </Col>
          <Col>
            <div ref={containerRef10} id="tableElement10">
              <AltmanPredictionChart
                y={y2}
                gauge={gauge_2}
                altPuan={alt_puan_2}
                currentValueText={currentValueText}
                color="#E9E7E8"
                containerRef9={containerRef9}
              />
            </div>
          </Col>
        </Row>
      )}
      {Number(alt_puan_3) !== 0 && Number(alt_puan_4) !== 0 && (
        <Row>
          <Col>
            <div ref={containerRef11} id="tableElement11">
              <AltmanPredictionChart
                y={y1}
                gauge={gauge_3}
                altPuan={alt_puan_3}
                currentValueText={currentValueText}
                color="#E9E7E8"
                containerRef9={containerRef9}
              />
            </div>
          </Col>
          <Col>
            <div ref={containerRef12} id="tableElement12">
              <AltmanPredictionChart
                y={y2}
                gauge={gauge_4}
                altPuan={alt_puan_4}
                currentValueText={currentValueText}
                color="#E9E7E8"
                containerRef9={containerRef9}
              />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default AltmanBrPrediction;

export const AltmanPredictionChart = ({
  y,
  gauge,
  altPuan,
  currentValueText,
  color,
  containerRef9,
}) => {
  return (
    <div
      style={{
        marginBottom: '10px',
      }}
    >
      {' '}
      <Row>
        <h4
          style={{
            textAlign: containerRef9 ? 'left' : 'center',
          }}
        >
          {y}
        </h4>
      </Row>
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: color,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className="reactspeedometer-wrapper">
              <ReactSpeedometer
                needleHeightRatio={0.7}
                ringWidth={10}
                minValue={0}
                maxValue={100}
                segments={3}
                value={gauge}
                needleTransition="easeElastic"
                needleTransitionDuration={3000}
                segmentColors={['#63E02D', '#FFCA28', '#D41B1B']}
                textColor="#ffff"
                currentValueText={' '}
                maxSegmentLabels={0}
                width={400}
              />
              <div
                style={{
                  marginTop: '50px',
                }}
              >
                {findColorAndRisk(gauge, altPuan)}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
