import { Table } from 'reactstrap';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPercentage, formatNumber } from '../Util/formatNumber';

const percentageTitles = ['Profitability Ratios'];

const percentageNames = ['Financial Liabilities Percentage'];

export const FinancialRatios = ({ scoring, disabled, companyCode }) => {
  const ratios = scoring.ratios;
  const isTa3meed = companyCode === 'ta3meed';

  return (
    <>
      <h4>
        <b>
          <u>
            {' '}
            <FormattedMessage id="FINANCIAL_RATIOS" />
          </u>
        </b>
      </h4>

      {ratios.map((row, i) => (
        <Table
          striped
          responsive
          bordered
          hover
          key={i}
          style={{ marginTop: '0.4em' }}
          size="sm"
        >
          <thead>
            <tr>
              <th>{row.title}</th>
              {row.title !== 'Growth' ? (
                row.subRatios &&
                row.subRatios[0] &&
                row.subRatios[0].values.map((a, z) => (
                  <React.Fragment key={z}>
                    <th>{a.year}</th>
                    {!disabled && (
                      <th>
                        {a.year} <FormattedMessage id="SUBTAB_2_1_TITLE3" />
                      </th>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <th>
                  {row.subRatios[0].values[1].year +
                    ' vs ' +
                    row.subRatios[0].values[0].year}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {row.subRatios.map((a, i) => (
              <tr key={i}>
                <td key={i} style={{ width: '400px' }}>
                  {a.name}
                </td>
                {row.title !== 'Growth' ? (
                  a.values.map((b, c) => {
                    const isTaemedAndPercentage =
                      isTa3meed &&
                      (percentageTitles.includes(row.title) ||
                        percentageNames.includes(a.name));

                    return (
                      <React.Fragment key={c}>
                        <td style={{ width: '20%' }}>
                          {isTaemedAndPercentage
                            ? formatPercentage(b.yearValue)
                            : formatNumber(b.yearValue)}
                        </td>
                        {!disabled && (
                          <td>
                            {isTaemedAndPercentage
                              ? formatPercentage(b.q2Value)
                              : formatNumber(b.q2Value)}
                          </td>
                        )}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <td style={{ width: '40%' }}>
                    {isTa3meed
                      ? formatPercentage(a.values[0].yearValue)
                      : formatNumber(a.values[0].yearValue)}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      ))}
    </>
  );
};

export default FinancialRatios;
