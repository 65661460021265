export const categories = [
  {
    enName: 'Agency, Advertising',
    trName: 'Ajans, Reklam',
  },
  {
    enName: 'Flower delivery',
    trName: 'Çiçek teslimatı',
  },
  {
    enName: 'SaaS, Marketingtech',
    trName: 'SaaS, Pazarlama teknolojisi',
  },
  {
    enName: 'Advertising',
    trName: 'Reklamcılık',
  },
  {
    enName: 'Telecom',
    trName: 'Telekom',
  },
  {
    enName: 'Gaming',
    trName: 'Oyun',
  },
  {
    enName: 'Video',
    trName: 'Video',
  },
  {
    enName: 'Marketplace, Event',
    trName: 'Pazar yeri, Etkinlik',
  },
  {
    enName: 'Marketplace, Baby toddler',
    trName: 'Pazar yeri, Bebek ve çocuk',
  },
  {
    enName: 'Autotech, Internet of things',
    trName: 'Otomotiv teknolojisi, Nesnelerin interneti',
  },
  {
    enName: 'Cybersecurity',
    trName: 'Siber güvenlik',
  },
  {
    enName: 'Blockchain, Book',
    trName: 'Blok zinciri, Kitap',
  },
  {
    enName: 'Ecommerce',
    trName: 'E-ticaret',
  },
  {
    enName: 'Agency',
    trName: 'Ajans',
  },
  {
    enName: 'Event',
    trName: 'Etkinlik',
  },
  {
    enName: 'Fintech',
    trName: 'Fintech',
  },
  {
    enName: 'Taxi hailing, On demand mobile, Mobility',
    trName: 'Taksi çağırma, İsteğe bağlı mobil, Mobilite',
  },
  {
    enName: 'Edutech, Military, AR / VR',
    trName: 'Eğitim teknolojisi, Askeri, AR / VR',
  },
  {
    enName: 'Gift',
    trName: 'Hediye',
  },
  {
    enName: 'Conservative fashion, Marketplace',
    trName: 'Muhafazakar moda, Pazar yeri',
  },
  {
    enName: 'Restaurant',
    trName: 'Restoran',
  },
  {
    enName: 'Social network',
    trName: 'Sosyal ağ',
  },
  {
    enName: 'On demand mobile',
    trName: 'İsteğe bağlı mobil',
  },
  {
    enName: 'Smart city, Autotech, Mobility',
    trName: 'Akıllı şehir, Otomotiv teknolojisi, Mobilite',
  },
  {
    enName: 'Artificial intelligence, Deeptech',
    trName: 'Yapay zeka, Derin teknoloji',
  },
  {
    enName: 'Event, Social network, Content',
    trName: 'Etkinlik, Sosyal ağ, İçerik',
  },
  {
    enName: 'Proptech, SaaS',
    trName: 'Emlak teknolojisi, SaaS',
  },
  {
    enName: 'Healthtech',
    trName: 'Sağlık teknolojisi',
  },
  {
    enName: 'Marketplace',
    trName: 'Pazar yeri',
  },
  {
    enName: 'Autotech',
    trName: 'Otomotiv teknolojisi',
  },
  {
    enName: 'Wedding',
    trName: 'Düğün',
  },
  {
    enName: 'Baby toddler',
    trName: 'Bebek ve çocuk',
  },
  {
    enName: 'Fintech, Internet of things, SaaS',
    trName: 'Fintech, Nesnelerin interneti, SaaS',
  },
  {
    enName: 'Local services',
    trName: 'Yerel hizmetler',
  },
  {
    enName: 'Proptech',
    trName: 'Emlak teknolojisi',
  },
  {
    enName: 'Autotech, Mobility, Climatetech',
    trName: 'Otomotiv teknolojisi, Mobilite, İklim teknolojisi',
  },
  {
    enName: 'Home decor',
    trName: 'Ev dekorasyonu',
  },
  {
    enName: 'Music',
    trName: 'Müzik',
  },
  {
    enName: 'Esports',
    trName: 'Espor',
  },
  {
    enName: 'Regtech, Fintech',
    trName: 'Regtech, Fintech',
  },
  {
    enName: 'Sports, News',
    trName: 'Spor, Haberler',
  },
  {
    enName: 'Marketplace, Autotech, SaaS',
    trName: 'Pazar yeri, Otomotiv teknolojisi, SaaS',
  },
  {
    enName: 'Media',
    trName: 'Medya',
  },
  {
    enName: 'Car pooling sharing, Mobility',
    trName: 'Araç paylaşımı, Mobilite',
  },
  {
    enName: 'Marketplace, Ecommerce',
    trName: 'Pazar yeri, E-ticaret',
  },
  {
    enName: 'Group buying',
    trName: 'Grup satın alma',
  },
  {
    enName: 'Wearable, Healthtech',
    trName: 'Giyilebilir teknoloji, Sağlık teknolojisi',
  },
  {
    enName: 'Comparison, Fintech, Insurtech',
    trName: 'Karşılaştırma, Fintech, Sigortacılık teknolojisi',
  },
  {
    enName: 'Industry40, Internet of things, SaaS',
    trName: 'Endüstri 4.0, Nesnelerin interneti, SaaS',
  },
  {
    enName: 'Wedding, Marketplace, Event',
    trName: 'Düğün, Pazar yeri, Etkinlik',
  },
  {
    enName: 'Sports',
    trName: 'Spor',
  },
  {
    enName: 'Hrtech',
    trName: 'İK teknolojisi',
  },
  {
    enName: 'Edutech',
    trName: 'Eğitim teknolojisi',
  },
  {
    enName: 'Hospital information system, Image process, Healthtech',
    trName: 'Hastane bilgi sistemi, Görüntü işleme, Sağlık teknolojisi',
  },
  {
    enName: 'Baby toddler, Healthtech',
    trName: 'Bebek ve çocuk, Sağlık teknolojisi',
  },
  {
    enName: 'Marketplace, Travel',
    trName: 'Pazar yeri, Seyahat',
  },
  {
    enName:
      'Retailtech, Regtech, Hrtech, Business intelligence, Desktop app, Fintech, SaaS',
    trName:
      'Perakende teknolojisi, Regtech, İK teknolojisi, İş zekası, Masaüstü uygulaması, Fintech, SaaS',
  },
  {
    enName: 'Sports, Content',
    trName: 'Spor, İçerik',
  },
  {
    enName: 'Flash sales, Ecommerce',
    trName: 'Flaş satışlar, E-ticaret',
  },
  {
    enName: 'Grocery delivery',
    trName: 'Gıda teslimatı',
  },
  {
    enName: 'Business intelligence, SaaS',
    trName: 'İş zekası, SaaS',
  },
  {
    enName: 'Beauty & cosmetics, Ecommerce',
    trName: 'Güzellik & kozmetik, E-ticaret',
  },
  {
    enName: 'Agency, Fintech',
    trName: 'Ajans, Fintech',
  },
  {
    enName: 'Marketplace, Fashion',
    trName: 'Pazar yeri, Moda',
  },
  {
    enName: 'Restaurant, Fintech',
    trName: 'Restoran, Fintech',
  },
  {
    enName: 'Baby toddler, Hrtech',
    trName: 'Bebek ve çocuk, İK teknolojisi',
  },
  {
    enName: 'Portal',
    trName: 'Portal',
  },
  {
    enName: 'Fintech, SaaS',
    trName: 'Fintech, SaaS',
  },
  {
    enName: 'Edutech, Metaverse, AR / VR',
    trName: 'Eğitim teknolojisi, Metaverse, AR / VR',
  },
  {
    enName: 'Content',
    trName: 'İçerik',
  },
  {
    enName: 'Travel',
    trName: 'Seyahat',
  },
  {
    enName: 'News, Content',
    trName: 'Haberler, İçerik',
  },
  {
    enName: 'Aggregator, Travel',
    trName: 'Toplayıcı, Seyahat',
  },
  {
    enName: 'Productivity, SaaS',
    trName: 'Verimlilik, SaaS',
  },
  {
    enName: 'Cryptocurrency, Fintech',
    trName: 'Kripto para birimi, Fintech',
  },
  {
    enName: 'Artificial intelligence, Ecommerce enabler, Fintech',
    trName: 'Yapay zeka, E-ticaret kolaylaştırıcı, Fintech',
  },
  {
    enName: 'Retailtech, Restaurant, Fintech',
    trName: 'Perakende teknolojisi, Restoran, Fintech',
  },
  {
    enName: 'Video, Hrtech, SaaS',
    trName: 'Video, İK teknolojisi, SaaS',
  },
  {
    enName: 'Ecommerce enabler, Marketingtech',
    trName: 'E-ticaret kolaylaştırıcı, Pazarlama teknolojisi',
  },
  {
    enName: 'Pet, Social network',
    trName: 'Evcil hayvan, Sosyal ağ',
  },
  {
    enName: 'Pet, Ecommerce',
    trName: 'Evcil hayvan, E-ticaret',
  },
  {
    enName: 'Video, Social media',
    trName: 'Video, Sosyal medya',
  },
  {
    enName: 'Marketplace, Proptech, Classifieds',
    trName: 'Pazar yeri, Emlak teknolojisi, İlanlar',
  },
  {
    enName: 'Social media, SaaS',
    trName: 'Sosyal medya, SaaS',
  },
  {
    enName: 'Cloud, SaaS',
    trName: 'Bulut, SaaS',
  },
  {
    enName: 'Big data',
    trName: 'Büyük veri',
  },
  {
    enName: 'Deeptech, Healthtech',
    trName: 'Derin teknoloji, Sağlık teknolojisi',
  },
  {
    enName: 'Startup Ecosystem, Content',
    trName: 'Startup Ekosistemi, İçerik',
  },
  {
    enName: 'SaaS, Startup Ecosystem, Content',
    trName: 'SaaS, Startup Ekosistemi, İçerik',
  },
  {
    enName: 'Classifieds',
    trName: 'İlanlar',
  },
  {
    enName: 'Fresh delivery',
    trName: 'Taze gıda teslimatı',
  },
  {
    enName: 'Developer Tools, SaaS',
    trName: 'Geliştirici Araçları, SaaS',
  },
  {
    enName: 'Ecommerce enabler',
    trName: 'E-ticaret kolaylaştırıcı',
  },
  {
    enName: 'Industry40, Image process',
    trName: 'Endüstri 4.0, Görüntü işleme',
  },
  {
    enName: 'Event, News',
    trName: 'Etkinlik, Haberler',
  },
  {
    enName: 'Artificial intelligence, Advertising, Marketingtech',
    trName: 'Yapay zeka, Reklam, Pazarlama teknolojisi',
  },
  {
    enName: 'Restaurant, Meal delivery, On demand mobile',
    trName: 'Restoran, Yemek teslimatı, İsteğe bağlı mobil',
  },
  {
    enName: 'Dating',
    trName: 'Flört',
  },
  {
    enName: 'Social good, Fintech, Sustainability',
    trName: 'Sosyal sorumluluk, Fintech, Sürdürülebilirlik',
  },
  {
    enName: 'Video, SaaS',
    trName: 'Video, SaaS',
  },
  {
    enName: 'Edutech, Blockchain',
    trName: 'Eğitim teknolojisi, Blok zinciri',
  },
  {
    enName: 'Video, Advertising',
    trName: 'Video, Reklam',
  },
  {
    enName: 'SaaS',
    trName: 'SaaS',
  },
  {
    enName: 'Pet',
    trName: 'Evcil hayvan',
  },
  {
    enName: 'Sharing economy, Logistics',
    trName: 'Paylaşım ekonomisi, Lojistik',
  },
  {
    enName: 'Ecommerce, Fashion',
    trName: 'E-ticaret, Moda',
  },
  {
    enName: 'Restaurant, Logistics',
    trName: 'Restoran, Lojistik',
  },
  {
    enName: 'Hrtech, AR / VR',
    trName: 'İK teknolojisi, AR / VR',
  },
  {
    enName: 'Social media, Agency, SaaS',
    trName: 'Sosyal medya, Ajans, SaaS',
  },
  {
    enName: 'Marketplace, Retailtech',
    trName: 'Pazar yeri, Perakende teknolojisi',
  },
  {
    enName: 'Artificial intelligence, Agency, Deeptech',
    trName: 'Yapay zeka, Ajans, Derin teknoloji',
  },
  {
    enName: 'Artificial intelligence, Agency',
    trName: 'Yapay zeka, Ajans',
  },
  {
    enName: 'Smart home, Internet of things',
    trName: 'Akıllı ev, Nesnelerin interneti',
  },
  {
    enName: 'Entertainment',
    trName: 'Eğlence',
  },
  {
    enName: 'Artificial intelligence, Advertising',
    trName: 'Yapay zeka, Reklam',
  },
  {
    enName: 'Legaltech',
    trName: 'Hukuk teknolojisi',
  },
  {
    enName: 'Well-being',
    trName: 'İyi yaşam',
  },
  {
    enName: 'Cleantech',
    trName: 'Temiz teknoloji',
  },
  {
    enName: 'Event, Baby toddler',
    trName: 'Etkinlik, Bebek ve çocuk',
  },
  {
    enName: 'Logistics, Mobility',
    trName: 'Lojistik, Mobilite',
  },
  {
    enName: 'Social good',
    trName: 'Sosyal sorumluluk',
  },
  {
    enName: 'Productivity',
    trName: 'Verimlilik',
  },
  {
    enName: '3D Printing',
    trName: '3D Baskı',
  },
  {
    enName: 'Marketplace, Autotech',
    trName: 'Pazar yeri, Otomotiv teknolojisi',
  },
  {
    enName: 'Healthtech, AR / VR',
    trName: 'Sağlık teknolojisi, AR / VR',
  },
  {
    enName: 'Industry40, Artificial intelligence, Smart manufacturing',
    trName: 'Endüstri 4.0, Yapay zeka, Akıllı üretim',
  },
  {
    enName: 'Internet of things, Healthtech',
    trName: 'Nesnelerin interneti, Sağlık teknolojisi',
  },
  {
    enName: 'Meal delivery',
    trName: 'Yemek teslimatı',
  },
  {
    enName: 'Artificial intelligence',
    trName: 'Yapay zeka',
  },
  {
    enName: 'Marketingtech',
    trName: 'Pazarlama teknolojisi',
  },
  {
    enName: 'Social network, Media',
    trName: 'Sosyal ağ, Medya',
  },
  {
    enName: 'Energy',
    trName: 'Enerji',
  },
  {
    enName: 'Retailtech',
    trName: 'Perakende teknolojisi',
  },
  {
    enName: 'Media, Content',
    trName: 'Medya, İçerik',
  },
  {
    enName: 'Biotech, Deeptech',
    trName: 'Biyoteknoloji, Derin teknoloji',
  },
  {
    enName: 'Book, Social network',
    trName: 'Kitap, Sosyal ağ',
  },
  {
    enName: 'Artificial intelligence, Big data, Deeptech',
    trName: 'Yapay zeka, Büyük veri, Derin teknoloji',
  },
  {
    enName: 'Artificial intelligence, Deeptech, Healthtech',
    trName: 'Yapay zeka, Derin teknoloji, Sağlık teknolojisi',
  },
  {
    enName: 'Social network, Healthtech',
    trName: 'Sosyal ağ, Sağlık teknolojisi',
  },
  {
    enName: 'Deeptech, Unmanned vehicle',
    trName: 'Derin teknoloji, İnsansız hava aracı',
  },
  {
    enName: 'Sports, Artificial intelligence, Energy, Deeptech',
    trName: 'Spor, Yapay zeka, Enerji, Derin teknoloji',
  },
  {
    enName: 'Biotech, Deeptech, Healthtech',
    trName: 'Biyoteknoloji, Derin teknoloji, Sağlık teknolojisi',
  },
  {
    enName: 'Industry40, Artificial intelligence, Robotics, Deeptech',
    trName: 'Endüstri 4.0, Yapay zeka, Robotik, Derin teknoloji',
  },
  {
    enName: 'Fintech, Insurtech',
    trName: 'Fintech, Sigortacılık teknolojisi',
  },
  {
    enName: 'Artificial intelligence, Business intelligence, Agency',
    trName: 'Yapay zeka, İş zekası, Ajans',
  },
  {
    enName: 'Electronics, Music, Deeptech',
    trName: 'Elektronik, Müzik, Derin teknoloji',
  },
  {
    enName: 'Military, Nanotechnology, Energy, Deeptech',
    trName: 'Askeri, Nanoteknoloji, Enerji, Derin teknoloji',
  },
  {
    enName: 'Event, Gift',
    trName: 'Etkinlik, Hediye',
  },
  {
    enName: 'Retailtech, Location, Internet of things',
    trName: 'Perakende teknolojisi, Konum, Nesnelerin interneti',
  },
  {
    enName: 'Artificial intelligence, Agritech, Deeptech',
    trName: 'Yapay zeka, Tarım teknolojisi, Derin teknoloji',
  },
  {
    enName: 'Mobility, Climatetech',
    trName: 'Mobilite, İklim teknolojisi',
  },
  {
    enName: 'Agritech',
    trName: 'Tarım teknolojisi',
  },
  {
    enName: 'Military',
    trName: 'Askeri',
  },
  {
    enName: 'Nanotechnology, Biotech',
    trName: 'Nanoteknoloji, Biyoteknoloji',
  },
  {
    enName: 'Edutech, Book',
    trName: 'Eğitim teknolojisi, Kitap',
  },
  {
    enName: 'Wearable',
    trName: 'Giyilebilir teknoloji',
  },
  {
    enName: 'Artificial intelligence, Hrtech',
    trName: 'Yapay zeka, İK teknolojisi',
  },
  {
    enName: 'Edutech, AR / VR',
    trName: 'Eğitim teknolojisi, AR / VR',
  },
  {
    enName: 'Edutech, Industry40, Baby toddler',
    trName: 'Eğitim teknolojisi, Endüstri 4.0, Bebek ve çocuk',
  },
  {
    enName: 'Electronics, Deeptech',
    trName: 'Elektronik, Derin teknoloji',
  },
  {
    enName: 'Agritech, Agency, Energy',
    trName: 'Tarım teknolojisi, Ajans, Enerji',
  },
  {
    enName: 'Artificial intelligence, Content',
    trName: 'Yapay zeka, İçerik',
  },
  {
    enName: 'Foodtech',
    trName: 'Gıda teknolojisi',
  },
  {
    enName: 'Fitness, Healthtech',
    trName: 'Fitness, Sağlık teknolojisi',
  },
  {
    enName: 'Logistics',
    trName: 'Lojistik',
  },
  {
    enName: 'Hrtech, Gaming',
    trName: 'İK teknolojisi, Oyun',
  },
  {
    enName: 'Smart city, Energy, Mobility, Climatetech',
    trName: 'Akıllı şehir, Enerji, Mobilite, İklim teknolojisi',
  },
  {
    enName: 'Big data, Agency, Cloud',
    trName: 'Büyük veri, Ajans, Bulut',
  },
  {
    enName: 'Marketplace, Artificial intelligence, Hrtech',
    trName: 'Pazar yeri, Yapay zeka, İK teknolojisi',
  },
  {
    enName: 'Advanced materials, Deeptech',
    trName: 'İleri malzemeler, Derin teknoloji',
  },
  {
    enName: 'Artificial intelligence, SaaS',
    trName: 'Yapay zeka, SaaS',
  },
  {
    enName: 'Agency, Image process',
    trName: 'Ajans, Görüntü işleme',
  },
  {
    enName: 'Restaurant, Meal delivery',
    trName: 'Restoran, Yemek teslim',
  },
  {
    enName: 'Mechatronics, Deeptech',
    trName: 'Mekatronik, Derin teknoloji',
  },
  {
    enName: 'Book, Content',
    trName: 'Kitap, İçerik',
  },
  {
    enName: 'Business intelligence',
    trName: 'İş zekası',
  },
  {
    enName: 'Marketplace, Entertainment',
    trName: 'Pazar yeri, Eğlence',
  },
  {
    enName: 'Artificial intelligence, Agritech, Internet of things',
    trName: 'Yapay zeka, Tarım teknolojisi, Nesnelerin interneti',
  },
  {
    enName: 'Marketplace, Book',
    trName: 'Pazar yeri, Kitap',
  },
  {
    enName: 'Sports, Artificial intelligence, Big data',
    trName: 'Spor, Yapay zeka, Büyük veri',
  },
  {
    enName: 'Internet of things',
    trName: 'Nesnelerin interneti',
  },
  {
    enName: 'Artificial intelligence, Smart city, Image process',
    trName: 'Yapay zeka, Akıllı şehir, Görüntü işleme',
  },
  {
    enName: 'Autotech, Mobility',
    trName: 'Otomotiv teknolojisi, Mobilite',
  },
  {
    enName: 'Internet of things, Cleantech',
    trName: 'Nesnelerin interneti, Temiz teknoloji',
  },
  {
    enName: 'Social good, Healthtech, Content',
    trName: 'Sosyal sorumluluk, Sağlık teknolojisi, İçerik',
  },
  {
    enName: 'Foodtech, Fresh delivery',
    trName: 'Gıda teknolojisi, Taze gıda teslimatı',
  },
  {
    enName: 'Foodtech, Social good',
    trName: 'Gıda teknolojisi, Sosyal sorumluluk',
  },
  {
    enName: 'Edutech, Social good, Healthtech',
    trName: 'Eğitim teknolojisi, Sosyal sorumluluk, Sağlık teknolojisi',
  },
  {
    enName: 'Biotech',
    trName: 'Biyoteknoloji',
  },
  {
    enName: 'Sports, Fitness',
    trName: 'Spor, Fitness',
  },
  {
    enName: 'Fashion',
    trName: 'Moda',
  },
  {
    enName: 'News, Startup Ecosystem, Content',
    trName: 'Haberler, Startup Ekosistemi, İçerik',
  },
  {
    enName: 'Gift, Healthtech',
    trName: 'Hediye, Sağlık teknolojisi',
  },
  {
    enName: 'Artificial intelligence, Healthtech',
    trName: 'Yapay zeka, Sağlık teknolojisi',
  },
  {
    enName: 'Ecommerce enabler, Fintech',
    trName: 'E-ticaret kolaylaştırıcı, Fintech',
  },
  {
    enName: 'Electronics, Mobility',
    trName: 'Elektronik, Mobilite',
  },
  {
    enName: 'Ecommerce enabler, Logistics',
    trName: 'E-ticaret kolaylaştırıcı, Lojistik',
  },
  {
    enName: 'Marketplace, Hrtech',
    trName: 'Pazar yeri, İK teknolojisi',
  },
  {
    enName: 'Marketplace, Proptech',
    trName: 'Pazar yeri, Emlak teknolojisi',
  },
  {
    enName: 'Artificial intelligence, Retailtech, Regtech',
    trName: 'Yapay zeka, Perakende teknolojisi, Regtech',
  },
  {
    enName: 'Smart manufacturing',
    trName: 'Akıllı üretim',
  },
  {
    enName: 'Proptech, Content',
    trName: 'Emlak teknolojisi, İçerik',
  },
  {
    enName: 'Marinetech',
    trName: 'Denizcilik teknolojisi',
  },
  {
    enName: 'Logistics, Internet of things',
    trName: 'Lojistik, Nesnelerin interneti',
  },
  {
    enName: 'Energy, Cleantech',
    trName: 'Enerji, Temiz teknoloji',
  },
  {
    enName: 'Social media, Startup Ecosystem',
    trName: 'Sosyal medya, Startup Ekosistemi',
  },
  {
    enName: 'Military, Agency',
    trName: 'Askeri, Ajans',
  },
  {
    enName: 'Agency, Cloud',
    trName: 'Ajans, Bulut',
  },
  {
    enName: 'Big data, Energy, Insurtech, Climatetech',
    trName: 'Büyük veri, Enerji, Sigortacılık teknolojisi, İklim teknolojisi',
  },
  {
    enName: 'Video, Ecommerce enabler',
    trName: 'Video, E-ticaret kolaylaştırıcı',
  },
  {
    enName: 'Edutech, Marketplace, Baby toddler, Healthtech',
    trName:
      'Eğitim teknolojisi, Pazar yeri, Bebek ve çocuk, Sağlık teknolojisi',
  },
  {
    enName: 'Event, SaaS',
    trName: 'Etkinlik, SaaS',
  },
  {
    enName: 'Artificial intelligence, Agency, Fintech',
    trName: 'Yapay zeka, Ajans, Fintech',
  },
  {
    enName: 'Agritech, Sustainability',
    trName: 'Tarım teknolojisi, Sürdürülebilirlik',
  },
  {
    enName: 'Video, Artificial intelligence',
    trName: 'Video, Yapay zeka',
  },
  {
    enName: 'Gaming, Agency',
    trName: 'Oyun, Ajans',
  },
  {
    enName: 'Artificial intelligence, Constructiontech, SaaS',
    trName: 'Yapay zeka, İnşaat teknolojisi, SaaS',
  },
  {
    enName: 'Edutech, Video, Artificial intelligence, Advertising',
    trName: 'Eğitim teknolojisi, Video, Yapay zeka, Reklam',
  },
  {
    enName: 'Social media, Social network',
    trName: 'Sosyal medya, Sosyal ağ',
  },
  {
    enName: 'Artificial intelligence, Customer experience, SaaS',
    trName: 'Yapay zeka, Müşteri deneyimi, SaaS',
  },
  {
    enName: 'Blockchain, Ecommerce, Logistics, SaaS',
    trName: 'Blok zinciri, E-ticaret, Lojistik, SaaS',
  },
  {
    enName: 'Robotics, Aviationtech , Energy, Unmanned vehicle',
    trName: 'Robotik, Havacılık teknolojisi, Enerji, İnsansız hava aracı',
  },
  {
    enName: 'Mechatronics, 3D Printing',
    trName: 'Mekatronik, 3D Baskı',
  },
  {
    enName: 'Location',
    trName: 'Konum',
  },
  {
    enName: 'Restaurant, Sustainability, Climatetech',
    trName: 'Restoran, Sürdürülebilirlik, İklim teknolojisi',
  },
  {
    enName: 'Robotics',
    trName: 'Robotik',
  },
  {
    enName: 'Electronics',
    trName: 'Elektronik',
  },
  {
    enName: 'Hrtech, Fintech',
    trName: 'İK teknolojisi, Fintech',
  },
  {
    enName: 'Edutech, Content',
    trName: 'Eğitim teknolojisi, İçerik',
  },
  {
    enName: 'Blockchain, Developer Tools',
    trName: 'Blok zinciri, Geliştirici Araçları',
  },
  {
    enName: 'Edutech, Agritech',
    trName: 'Eğitim teknolojisi, Tarım teknolojisi',
  },
  {
    enName: 'Marketplace, Healthtech',
    trName: 'Pazar yeri, Sağlık teknolojisi',
  },
  {
    enName: 'Edutech, Baby toddler, Book',
    trName: 'Eğitim teknolojisi, Bebek ve çocuk, Kitap',
  },
  {
    enName: 'Cloud',
    trName: 'Bulut',
  },
  {
    enName: 'Dating, Metaverse',
    trName: 'Flört, Metaverse',
  },
  {
    enName: 'Blockchain, Aggregator, Fintech',
    trName: 'Blok zinciri, Toplayıcı, Fintech',
  },
  {
    enName: 'Ecommerce enabler, Agency',
    trName: 'E-ticaret kolaylaştırıcı, Ajans',
  },
  {
    enName: 'Video, Entertainment, Media',
    trName: 'Video, Eğlence, Medya',
  },
  {
    enName: 'Business intelligence, Agency',
    trName: 'İş zekası, Ajans',
  },
  {
    enName: 'Edutech, Book, Ecommerce',
    trName: 'Eğitim teknolojisi, Kitap, E-ticaret',
  },
  {
    enName: 'Agency, Internet of things',
    trName: 'Ajans, Nesnelerin interneti',
  },
  {
    enName: 'Mechanics',
    trName: 'Mekanik',
  },
  {
    enName: 'Desktop app, Agency',
    trName: 'Masaüstü uygulaması, Ajans',
  },
  {
    enName: 'Video, Artificial intelligence, SaaS',
    trName: 'Video, Yapay zeka, SaaS',
  },
  {
    enName: 'Hrtech, Business intelligence',
    trName: 'İK teknolojisi, İş zekası',
  },
  {
    enName: 'Agency, AR / VR',
    trName: 'Ajans, AR / VR',
  },
  {
    enName: 'Hrtech, SaaS',
    trName: 'İK teknolojisi, SaaS',
  },
  {
    enName: 'Agency, SaaS',
    trName: 'Ajans, SaaS',
  },
  {
    enName: 'Ecommerce, Autotech',
    trName: 'E-ticaret, Otomotiv teknolojisi',
  },
  {
    enName: 'Aviationtech ',
    trName: 'Havacılık teknolojisi',
  },
  {
    enName: 'Industry40, Electronics, Energy',
    trName: 'Endüstri 4.0, Elektronik, Enerji',
  },
];

export const stateOptions = [
  'Çanakkale',
  'Afyonkarahisar',
  'Ankara',
  'Antalya',
  'Aydın',
  'Bolu',
  'Bursa',
  'Düzce',
  'Denizli',
  'Eskişehir',
  'Gaziantep',
  'Istanbul',
  'Izmir',
  'Çanakkale',
  'Kayseri',
  'Kocaeli',
  'Konya',
  'Mersin',
  'Muğla',
  'Samsun',
  'Sivas',
  'Tokat',
  'Van',
  'Diğer',
];
