import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import { colors, cellHeight } from '../Common';
import icon1 from '../../../FileTemplatesAndImages/layer_1.png';
import icon2 from '../../../FileTemplatesAndImages/layer_2.png';
import icon3 from '../../../FileTemplatesAndImages/layer_3.png';
import icon4 from '../../../FileTemplatesAndImages/layer_4.png';
import icon5 from '../../../FileTemplatesAndImages/layer_5.png';
import icon8 from '../../../FileTemplatesAndImages/dupont.png';
import icon9 from '../../../FileTemplatesAndImages/financial_ratios.png';

const iconMapping = {
  Verimlilik: icon1,
  Likidite: icon2,
  'Borçluluk Düzeyi': icon3,
  Karlılık: icon4,
  Büyüme: icon5,
  Efficiency: icon1,
  Liquidity: icon2,
  Solvency: icon3,
  Profitability: icon4,
  Growth: icon5,
  FINANCIAL_RATIOS: icon9,
  SUBTAB_DUPONT_TITLE: icon8,
};

export const TableHeader = ({ title, styles, title2 }) => {
  const icon = iconMapping[title]
    ? iconMapping[title]
    : title && title.props && title.props.id
      ? iconMapping[title.props.id]
      : null;
  return (
    <View style={styles.tableRow}>
      <View
        style={[
          styles.tableCol,
          {
            backgroundColor: colors.secondary,
            width: cellHeight,
            alignItems: 'center',
          },
        ]}
      >
        {icon && <Image src={icon} style={styles.icon} />}
      </View>
      <View
        style={[
          styles.tableCol,
          {
            backgroundColor: colors.primary,
            flex: 1,
          },
        ]}
      >
        <Text
          style={[styles.tableCell, { color: colors.white, fontWeight: 600 }]}
        >
          {title}
        </Text>
      </View>
      <View
        style={[
          styles.tableCol,
          {
            backgroundColor: colors.secondary,
            flex: 1,
            marginRight: 0,
          },
        ]}
      >
        <Text
          style={[styles.tableCell, { color: colors.white, fontWeight: 600 }]}
        >
          {title2 || ''}
        </Text>
      </View>
    </View>
  );
};
