import React from 'react';
import {
  Document,
  Text,
  View,
  StyleSheet,
  Image,
  PDFDownloadLink,
  Font,
  pdf,
} from '@react-pdf/renderer';
import icon10 from '../../FileTemplatesAndImages/altman_1.png';
import icon11 from '../../FileTemplatesAndImages/altman_2.png';
import html2canvas from 'html2canvas';
import { FormattedMessage, useIntl } from 'react-intl';
import { languages } from '../../App';
import { LanguageProvider } from 'react-admin-base-bootstrap';
import { AltmanBrPrediction } from '../../Components/AltmanzScoringReport';
import { Button } from 'react-bootstrap';
import { Loading } from '../../Components/Loading';
import { formatNumber } from '../../Util/formatNumber';

import { colors, margin, cellHeight, fontSizes, padding } from '../Pdf/Common';
import { PdfPageForAktif } from '../Pdf/components/PdfPageForAktif';
import OpenSansRegular from '../Pdf/assets/fonts/open-sans.regular.ttf';
import OpenSansBold from '../Pdf/assets/fonts/open-sans.bold.ttf';
import OpenSansSemiBold from '../Pdf/assets/fonts/open-sans.semibold.ttf';
import { Button_ } from '../../Components/ActionButtons/ActionButtons';
import { icons } from '../../Icons/icons';

export const documentOptions = {
  1: 'Kurumlar Vergisi Beyannamesi',
  7: 'Mizan Analizi',
  9: 'Bağımsız Denetim Raporu',
  10: 'Bağımsız Denetim Raporu',
  14: 'Konsolidasyon Analizi',
  15: 'Mizan Analizi',
  11: 'Financial Statement',
  13: 'Simah Statement',
  16: 'BNPL Statement',
};

const sortedTitles = [
  'Karlılık Oranları',
  'Büyüme Oranları',
  'Finansal Yapı Oranları',
  'Likidite Oranları',
  'Faaliyet Oranları',
];

export const PdfFinancialEvaluationTable = ({ data }) => {
  const title = data.name;
  const tableData = data.subTitles;

  const configureData = (data) => {
    if (!data.includes('\n')) {
      const splittedData = data.split(': ');
      return [splittedData[0], splittedData[1]];
    } else {
      const splittedData = data.split('\n');

      return [
        `${splittedData[0].split(': ')[0]}\n${splittedData[1].split(': ')[0]}`,
        `${splittedData[0].split(': ')[1]}\n ${splittedData[1].split(': ')[1]}`,
      ];
    }
  };
  return (
    <View
      style={{
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 0.75,
        borderColor: '#1C4562',
        marginTop: '3px',
        borderRadius: '2px',
      }}
    >
      <View
        style={{
          borderStyle: 'solid',
          borderColor: '#bfbfbf',
          backgroundColor: '#FFFFFF',
          color: '#1C4562',
        }}
      >
        <Text
          style={{
            padding: '2px 5px',
            fontSize: '6px',
            color: '#1C4562',
            fontWeight: 600,
          }}
        >
          {title}
        </Text>
      </View>
      {tableData.map(
        (e, i) =>
          !['Finansman Giderleri Trendi: Artış'].includes(e.name.trim()) && (
            <React.Fragment key={i}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View
                  style={{
                    flex: 1,
                    width: '25%',
                    borderStyle: 'solid',
                    borderColor: '#1C4562',
                    color: '#1C4562',
                    backgroundColor: '#ECF9F5',
                  }}
                >
                  <Text
                    style={{
                      padding: '2px 5px',
                      fontSize: '5.75px',
                      color: '#1C4562',
                      fontWeight: 700,
                    }}
                  >
                    {e.name ? configureData(e.name)[0] : '-'}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    marginRight: 0,
                    width: '25%',
                    borderStyle: 'solid',
                    borderColor: '#1C4562',
                    color: '#1C4562',
                    backgroundColor: '#FAFFFD',
                  }}
                >
                  <Text
                    style={{
                      padding: '2px 5px',
                      fontSize: '5.5px',
                      color: '#1C4562',
                    }}
                  >
                    {e.name ? configureData(e.name)[1] : '-'}
                  </Text>
                </View>
              </View>
            </React.Fragment>
          )
      )}
    </View>
  );
};

export const PdfFinancialRatiosTable = ({ data }) => {
  const styles = StyleSheet.create({
    table: {
      width: '100%',
      borderStyle: 'solid',
      borderWidth: 0.75,
      borderColor: '#1C4562',
      marginTop: '5px',
      borderRadius: '2px',
    },
    tableRow: { flexDirection: 'row' },
    tableColHeader: {
      width: '25%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      backgroundColor: '#f2f2f2',
      color: '#1C4562',
    },
    tableCol: {
      width: '25%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
    },
    tableCell: { margin: 1.3, fontSize: 6.25 },
    bold: { fontWeight: 'bold' },
    content: {
      color: colors.primary,
      fontSize: fontSizes.content,
      position: 'relative',
      padding: padding,
    },
    icon: {
      width: '20px',
      height: '20px',
    },
  });

  const title = data.title;
  const tableData = data.subRatios;

  const numberOfYears = Math.min(tableData[0].values.length, 3);

  const yearsToShow = tableData[0].values.slice(-numberOfYears);

  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View
          style={[
            styles.tableCol,
            {
              backgroundColor: '#ECF9F5',
              fontSize: fontSizes.title,
              flex: 2,
              fontWeight: 'bold',
            },
          ]}
        >
          <Text
            style={[styles.tableCell, { fontWeight: 600, color: '#1C4562' }]}
          >
            {title}
          </Text>
        </View>
        {yearsToShow.map((a, i) => (
          <React.Fragment key={i}>
            <View
              style={[
                styles.tableCol,
                {
                  backgroundColor: '#ECF9F5',
                  flex: 1,
                  fontWeight: 'bold',
                  color: '#1C4562',
                },
              ]}
            >
              <Text
                style={[
                  styles.tableCell,
                  { fontWeight: 600, color: '#1C4562' },
                ]}
              >
                {a.year}
              </Text>
            </View>
            -{' '}
            <View
              style={[
                styles.tableCol,
                {
                  backgroundColor: '#ECF9F5',
                  flex: 1,
                  fontWeight: 'bold',
                  color: '#1C4562',
                },
              ]}
            >
              <Text
                style={[
                  styles.tableCell,
                  { fontWeight: 600, color: '#1C4562' },
                ]}
              >
                {a.year} <FormattedMessage id="SUBTAB_2_1_TITLE3" />
              </Text>
            </View>
          </React.Fragment>
        ))}
      </View>

      {tableData.map(
        (a, i) =>
          ![
            'Madd.D.V.Devir Hızı',
            'Özkaynak Devir Hızı',
            'K.V. Ticari Borçlar/Net Satışlar',
            'Kısa Vad. Alac. / Dönen Varlıklar',
            'Duran Varlık. / Öz Kaynaklar',
            'Maddi + Maddi Olmayan Duran Varlıklar / Devamlı Sermaye',
            'KVYK / Özkaynaklar',
            'Net Kar / Aktif Toplam',
            'Geçmiş Yıl Karları + Kar Yedekleri / Toplam Varlıklar',
          ].includes(a.name.trim()) && (
            <View style={styles.tableRow} key={i}>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor: '#ECF9F5',
                    fontSize: fontSizes.title,
                    flex: 2,
                    color: '#1C4562',
                  },
                ]}
              >
                <Text style={styles.tableCell}>{a.name}</Text>
              </View>
              {title !== 'Growth' ? (
                a.values.slice(-numberOfYears).map((b, v) => {
                  return (
                    <React.Fragment key={v}>
                      <View
                        style={[
                          styles.tableCol,
                          {
                            backgroundColor: '#FAFFFD',
                            fontSize: fontSizes.title,
                            flex: 1,
                            color: '#1C4562',
                          },
                        ]}
                      >
                        <Text style={styles.tableCell}>
                          {formatNumber(b.yearValue)}
                        </Text>
                      </View>

                      <View
                        style={[
                          styles.tableCol,
                          {
                            backgroundColor: '#ECF9F5',
                            fontSize: fontSizes.title,
                            flex: 1,
                            color: '#1C4562',
                          },
                        ]}
                      >
                        <Text style={styles.tableCell}>
                          {formatNumber(b.q2Value)}
                        </Text>
                      </View>
                    </React.Fragment>
                  );
                })
              ) : (
                <View
                  style={[
                    styles.tableCol,
                    {
                      backgroundColor: '#ECF9F5',
                      fontSize: fontSizes.title,
                      flex: 1,
                      color: '#1C4562',
                    },
                  ]}
                >
                  <Text style={styles.tableCell}>
                    {formatNumber(a.values.slice(-numberOfYears)[0].yearValue)}
                  </Text>
                </View>
              )}
            </View>
          )
      )}
    </View>
  );
};

export const DupontTable = ({ dupont }) => {
  const intl = useIntl();
  const styles = StyleSheet.create({
    table: {
      width: '100%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: '2px',
      borderColor: '#1C4562',
      marginTop: '-80px',
    },
    tableRow: { flexDirection: 'row' },
    tableColHeader: {
      width: '25%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      backgroundColor: '#f2f2f2',
      color: '#1C4562',
    },
    tableCol: {
      width: '25%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
    },
    tableCell: { margin: 2, fontSize: 6.25 },
    bold: { fontWeight: 'bold' },
  });

  const dupontTitles = [
    intl.formatMessage({ id: 'SUBTAB_1_3_TITLE1' }),

    intl.formatMessage({ id: 'SUBTAB_1_3_TITLE2' }),
    ...dupont[0].values.map((e) => e.year),
    intl.formatMessage({ id: 'SUBTAB_1_3_TITLE5' }),
  ];

  return (
    <View style={styles.table}>
      <Text
        style={{
          border: '1px solid #1C4562',
          backgroundColor: '#1C4562',
          fontSize: 9,
          color: 'white',
          fontWeight: 500,
          textAlign: 'center',
        }}
      >
        DUPONT ANALIZI
      </Text>

      <View style={styles.tableRow}>
        {dupontTitles.map((e, i) => {
          return (
            <View
              style={[
                styles.tableColHeader,
                {
                  backgroundColor: '#ECF9F5',
                  flex: i === dupontTitles.length - 1 || i === 1 ? 2 : 1,

                  fontWeight: 600,
                },
              ]}
              key={i}
            >
              <Text style={[styles.tableCell]}>{e}</Text>
            </View>
          );
        })}
      </View>
      {dupont.map((e, i) => {
        return (
          <>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor: '#ECF9F5',
                    flex: 1,
                    color: '#1C4562',
                  },
                ]}
              >
                <Text style={styles.tableCell}>{e.name}</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor: '#FAFFFD',
                    flex: 2,
                    color: '#1C4562',
                  },
                ]}
              >
                <Text style={styles.tableCell}>{e.formula}</Text>
              </View>
              {e.values.map((a, i) => {
                return (
                  <View
                    style={[
                      styles.tableCol,
                      {
                        backgroundColor: i % 2 !== 0 ? '#FAFFFD' : '#ECF9F5',
                        flex: 1,
                        color: '#1C4562',
                      },
                    ]}
                    key={i}
                  >
                    <Text style={styles.tableCell}>
                      {formatNumber(a.value)}
                    </Text>
                  </View>
                );
              })}

              <View
                style={[
                  styles.tableCol,
                  {
                    marginRight: 0,
                    flex: 2,
                    backgroundColor: '#ECF9F5',
                    color: '#1C4562',
                  },
                ]}
              >
                <Text style={styles.tableCell}>{e.description}</Text>
              </View>
            </View>
          </>
        );
      })}
    </View>
  );
};

const PdfDocument = ({
  assessment,
  scoringVal,
  scoring,
  dupont,
  screenshotUrl,
}) => {
  const assesmentData =
    assessment && assessment.titles ? [...assessment.titles] : [];
  const ratios = (scoring && scoring.ratios) || '';

  const dopontData = (dupont && dupont.categories) || '';

  Font.register({
    family: 'Open Sans',
    fonts: [
      {
        src: OpenSansRegular,
      },
      {
        src: OpenSansBold,
        fontWeight: 600,
      },
      {
        src: OpenSansSemiBold,
        fontWeight: 700,
      },
    ],
  });

  const sortedRatios = [];

  sortedTitles.map((title) => {
    const data = ratios.find((ratio) => ratio.title === title);
    sortedRatios.push(data);
  });

  return (
    <Document
      onRender={() => {
        console.log('Rendered');
      }}
    >
      <PdfPageForAktif key="financialPage" orientation="portrait">
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <View style={{ height: '20%', marginTop: '-10px' }}>
            {assesmentData && assesmentData.length > 0 && (
              <React.Fragment>
                <FinancialScoreChart
                  scoringVal={scoringVal}
                  scoring={scoring}
                />
              </React.Fragment>
            )}
          </View>
          <View
            style={{
              height: '80%',
              display: 'flex',
              flexDirection: 'row',
              marginTop: '0px',
            }}
          >
            <View style={{ width: '65%' }}>
              <DupontTable dupont={dopontData} />
              {sortedRatios &&
                sortedRatios.length > 0 &&
                sortedRatios.map((e, key) => (
                  <PdfFinancialRatiosTable key={key} data={e} />
                ))}
            </View>
            <View
              style={{
                width: '35%',
                marginLeft: '15px',
                marginTop: '-84px',
              }}
            >
              <View>
                {screenshotUrl['tableElement9'] &&
                  screenshotUrl['tableElement10'] && (
                    <PdfTableForScreenShot
                      screenshotUrl={screenshotUrl}
                      tableElement={'tableElement9'}
                      tableElement2={'tableElement10'}
                      width="90px"
                      height="40px"
                      title=<FormattedMessage id="SUBTAB_1_4_ALTMAN_BANKRUPTCY_FORECAST" />
                      title2=<FormattedMessage id="SUBTAB_MANUFACTORING_TITLE" />
                      icon={icon10}
                    />
                  )}
                {screenshotUrl['tableElement11'] &&
                  screenshotUrl['tableElement12'] && (
                    <PdfTableForScreenShot
                      screenshotUrl={screenshotUrl}
                      tableElement={'tableElement11'}
                      tableElement2={'tableElement12'}
                      width="90px"
                      height="40px"
                      title=<FormattedMessage id="SUBTAB_1_4_ALTMAN_BANKRUPTCY_FORECAST" />
                      title2=<FormattedMessage id="SUBTAB_NON_MANUFACTORING_TITLE" />
                      icon={icon11}
                    />
                  )}
              </View>

              <View
                style={{
                  marginTop: '0px',
                }}
              >
                {assesmentData &&
                  assesmentData.length > 0 &&
                  assesmentData.map((e, key) => (
                    <PdfFinancialEvaluationTable key={key} data={e} />
                  ))}
              </View>
            </View>
          </View>
        </View>
      </PdfPageForAktif>
    </Document>
  );
};

export const PdfAktif = ({
  assessment,
  scoringVal,
  scoring,
  dupont,
  altmanzscoring,
}) => {
  const intl = useIntl();

  const [loadingSs, setLoadingSs] = React.useState(false);

  const [state, setState] = React.useState(false);

  const containerRef9 = React.useRef(null);
  const containerRef10 = React.useRef(null);
  const containerRef11 = React.useRef(null);
  const containerRef12 = React.useRef(null);

  const [screenshotUrl, setScreenshotUrl] = React.useState({});

  const takeScreenshot = () => {
    const tableElement9 = containerRef9.current;
    const tableElement10 = containerRef10.current;
    const tableElement11 = containerRef11.current;
    const tableElement12 = containerRef12.current;

    const documents = [
      tableElement9,
      tableElement10,
      tableElement11,
      tableElement12,
    ];
    const getBlob = async (urls) => {
      const blob = await pdf(
        <LanguageProvider
          defaultLanguage={
            localStorage.getItem('frayjet_frayjet_language') || 'tr'
          }
          languages={languages}
        >
          <PdfDocument
            assessment={assessment}
            scoringVal={scoringVal}
            scoring={scoring}
            dupont={dupont}
            screenshotUrl={urls}
          />
        </LanguageProvider>
      ).toBlob();

      return blob;
    };

    const downloadPdf = async (urls) => {
      const pdfBlob = await getBlob(urls);
      const url = URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = intl.formatMessage({ id: 'FINANCIAL_ANALYSIS_REPORT' });
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    };

    const screenshotPromises = [...documents.map((e) => e && html2canvas(e))];
    const urls = {};
    Promise.all(screenshotPromises)
      .then((canvases) => {
        canvases.forEach((canvas, index) => {
          const num = index + 9;
          const imgData = canvas ? canvas.toDataURL('image/png') : '';

          urls[`tableElement${num}`] = imgData;
        });
      })
      .catch((error) => {
        console.error('Ekran görüntüsü alma hatası:', error);
      })
      .finally(() => {
        setScreenshotUrl(urls);
        downloadPdf(urls);
        setState(true);
        setLoadingSs(false);
      });
  };
  return (
    <div>
      <div className="image-container"></div>
      <div
        style={{
          height: 0,
          overflow: 'hidden',
        }}
      >
        <AltmanBrPrediction
          altmanzscoring={altmanzscoring}
          containerRef9={containerRef9}
          containerRef10={containerRef10}
          containerRef11={containerRef11}
          containerRef12={containerRef12}
        />
      </div>
      {loadingSs && <Loading />}
      {state ? (
        <PDFDownloadLink
          document={
            <LanguageProvider
              defaultLanguage={
                localStorage.getItem('frayjet_frayjet_language') || 'tr'
              }
              languages={languages}
            >
              <PdfDocument
                assessment={assessment}
                scoringVal={scoringVal}
                scoring={scoring}
                dupont={dupont}
                screenshotUrl={screenshotUrl}
              />
            </LanguageProvider>
          }
          fileName={intl.formatMessage({ id: 'FINANCIAL_ANALYSIS_REPORT' })}
        >
          {({ blob, url, loading, error }) =>
            loading || !blob || !url || loadingSs ? (
              <>
                <Loading />
                <FormattedMessage id="LOADING_DOCUMENT" />
              </>
            ) : (
              <Button_
                text={intl.formatMessage({
                  id: 'SUBTAB_PRINT_FINANCIAL_ANALYSIS_REPORT',
                })}
                buttonStyle={{ background: 'var(--bs-indigo)' }}
                iconClassName={icons.download}
              />
            )
          }
        </PDFDownloadLink>
      ) : loadingSs ? (
        <FormattedMessage id="LOADING_DOCUMENT" />
      ) : (
        <Button_
          text={intl.formatMessage({
            id: 'PREPARE_PDF',
          })}
          onClick={() => {
            setLoadingSs(true);

            setTimeout(() => {
              takeScreenshot();
            }, 1000);
          }}
          buttonStyle={{ background: 'var(--bs-indigo)' }}
          iconClassName={icons.pdf}
        />
      )}
    </div>
  );
};

const FinancialScoreChart = ({ scoringVal, scoring }) => {
  const reversedData =
    scoringVal &&
    scoringVal.dataList &&
    scoringVal.dataList.sort((a, b) => a.upperLimit - b.upperLimit);

  return (
    <View style={{ marginBottom: '100px' }}>
      <Text
        style={{
          fontSize: '14px',
          color: colors.primary,
          marginTop: '-45px',
          marginBottom: '25px',

          fontWeight: 600,
        }}
      >
        <FormattedMessage id="FINANCIAL_SCORE" />
      </Text>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '-20px',
          }}
        >
          <View style={{ marginRight: '10px', marginBottom: '2px' }}>
            <Text
              style={{ color: '#FF0000', fontWeight: 600, fontSize: '14px' }}
            >
              0
            </Text>
          </View>
          {reversedData &&
            reversedData.map((ab, i) => {
              return (
                <View
                  style={{
                    color: `#${ab?.colorCode?.slice(2)}`,
                    width: '45px',
                    backgroundColor: `#${ab?.colorCode?.slice(2)}`,
                    borderRadius: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    height: '15px',
                    marginRight: '-16px',
                    paddingLeft:
                      i !== reversedData.length - 1 ? '10px' : '16px',
                  }}
                  key={i}
                >
                  <Text
                    style={{
                      color: 'white',
                      fontSize: '7px',
                      marginRight: '35px',
                    }}
                  >
                    {ab.rating}
                  </Text>
                  {Math.round(scoring.scorePoint) >= ab.lowerLimit &&
                    Math.round(scoring.scorePoint) <= ab.upperLimit && (
                      <View
                        style={{
                          position: 'absolute',
                          left: '-35px',
                        }}
                      >
                        <View
                          style={{
                            margin: '45px 0 0 50px',
                          }}
                        >
                          <View
                            style={{
                              width: '3px',
                              height: '10px',
                              backgroundColor: `#${ab?.colorCode?.slice(2)}`,
                              position: 'relative',
                              top: '-12px',
                            }}
                          ></View>
                          <View
                            style={{
                              width: '10px',
                              height: '10px',
                              backgroundColor: `#${ab?.colorCode?.slice(2)}`,
                              borderRadius: '50%',
                              position: 'absolute',
                              left: '-3.5px;',
                              top: '-4px',
                            }}
                          ></View>
                        </View>

                        <View
                          style={{
                            textAlign: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            marginTop: '90px',
                            position: 'absolute',
                            width: '100px',
                          }}
                        >
                          <Text
                            style={{
                              color: `#${ab?.colorCode?.slice(2)}`,
                              fontSize: '10px',
                              marginTop: '-60px',
                            }}
                          >
                            {scoring.scorePoint} / {scoring.scoreResult}
                          </Text>
                        </View>
                      </View>
                    )}

                  {i !== reversedData.length - 1 && (
                    <View
                      style={{
                        width: '22px',
                        height: '22px',
                        backgroundColor: 'white',
                        borderRadius: '50px',
                        position: 'absolute',
                        right: -5,
                      }}
                    ></View>
                  )}
                </View>
              );
            })}
          <View
            style={{
              marginLeft: '20px',
              marginBottom: '2px',
              color: '#00B050',
              fontWeight: 600,
              fontSize: '14px',
            }}
          >
            <Text>100</Text>
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: '6px',
            gap: '12px',
            marginTop: '-10px',
            marginRight: '15px',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <View
                style={{
                  border: '2px solid',
                  backgroundColor: '#D00002',
                  borderColor: '#D00002',
                  width: '10px',
                  height: '5px',
                  borderRadius: '2px',
                }}
              ></View>
              <Text>D / Çok Zayıf -</Text>
              <Text>0-32</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <View
                style={{
                  border: '2px solid',
                  backgroundColor: '#FD0002',
                  borderColor: '#FD0002',
                  width: '10px',
                  height: '5px',
                  borderRadius: '2px',
                }}
              ></View>
              <Text>C / Çok Zayıf</Text>
              <Text>33-40</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <View
                style={{
                  border: '2px solid',
                  backgroundColor: '#FD5B00',
                  borderColor: '#FD5B00',
                  width: '10px',
                  height: '5px',
                  borderRadius: '2px',
                }}
              ></View>
              <Text>CC / Zayıf -</Text>
              <Text>41-47</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <View
                style={{
                  border: '2px solid',
                  backgroundColor: '#FD9800',
                  borderColor: '#FD9800',
                  width: '10px',
                  height: '5px',
                  borderRadius: '2px',
                }}
              ></View>
              <Text>CCC / Zayıf </Text>
              <Text>48-55</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View
                style={{
                  border: '2px solid',
                  backgroundColor: '#FDCC03',
                  borderColor: '#FDCC03',
                  width: '10px',
                  height: '5px',
                  borderRadius: '2px',
                }}
              ></View>
              <Text>B / Orta </Text>
              <Text>56-62</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View
                style={{
                  border: '2px solid',
                  backgroundColor: '#FDE400',
                  borderColor: '#FDE400',
                  width: '10px',
                  height: '5px',
                  borderRadius: '2px',
                }}
              ></View>
              <Text>BB / Orta +</Text>
              <Text>63-70</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View
                style={{
                  border: '2px solid',
                  backgroundColor: '#FDF303',
                  borderColor: '#FDF303',
                  width: '10px',
                  height: '5px',
                  borderRadius: '2px',
                }}
              ></View>
              <Text>BBB / İyi -</Text>
              <Text>71-77</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View
                style={{
                  border: '2px solid',
                  backgroundColor: '#CCFF66',
                  borderColor: '#CCFF66',
                  width: '10px',
                  height: '5px',
                  borderRadius: '2px',
                }}
              ></View>
              <Text>A / İyi </Text>
              <Text>78-85</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View
                style={{
                  border: '2px solid',
                  backgroundColor: '#90D14F',
                  borderColor: '#90D14F',
                  width: '10px',
                  height: '5px',
                  borderRadius: '2px',
                }}
              ></View>
              <Text>AA / İyi +</Text>
              <Text>86-92</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View
                style={{
                  border: '2px solid',
                  backgroundColor: '#01AF4E',
                  borderColor: '#01AF4E',
                  width: '10px',
                  height: '5px',
                  borderRadius: '2px',
                }}
              ></View>
              <Text>AAA / Çok İyi </Text>
              <Text>93-100</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const PdfHeaderForScreenShot = ({ title, title2, icon }) => {
  const styles = StyleSheet.create({
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginTop: margin,
      minHeight: cellHeight,
      alignItems: 'stretch',
      pageBreakInside: 'avoid',
    },
    tableCol: {
      display: 'flex',
      textAlign: 'left',
      justifyContent: 'center',
    },
    tableCell: {
      padding: padding,
      fontSize: 6,
    },
  });
  return (
    <View style={styles.tableRow}>
      <View
        style={[
          styles.tableCol,
          {
            flex: 1,
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginRight: '2px',
          },
        ]}
      >
        <View
          style={[
            styles.tableCol,
            {
              backgroundColor: colors.secondary,
              height: '20px',
              marginRight: '2px',
              alignItems: 'center',
            },
          ]}
        >
          {icon && (
            <Image
              src={icon}
              style={{ width: '20px', height: '20px', padding: '4px' }}
            />
          )}
        </View>
        <View
          style={[
            styles.tableCol,
            {
              backgroundColor: colors.primary,
              height: '20px',
              flex: 1,
            },
          ]}
        >
          <Text
            style={[
              styles.tableCell,
              {
                color: colors.white,
                fontWeight: 600,
                padding: '1px',
                fontSize: '5px',
                marginLeft: '2px',
              },
            ]}
          >
            {title}
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.tableCol,
          {
            backgroundColor: colors.secondary,
            flex: 1,
            height: '20px',
            width: '30px',
          },
        ]}
      >
        <Text
          style={[
            styles.tableCell,
            {
              color: colors.white,
              fontWeight: 600,
              padding: '1px',
              fontSize: '5px',
              marginLeft: '2px',
            },
          ]}
        >
          {title2}
        </Text>
      </View>
    </View>
  );
};

const PdfTableForScreenShot = ({
  screenshotUrl,
  tableElement,
  tableElement2,
  width,
  height,
  title2,
  title,
  icon,
}) => {
  const styles = StyleSheet.create({
    table: {
      border: 'none',
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginTop: margin,
      minHeight: cellHeight,
      alignItems: 'stretch',
    },
    tableCol: {
      minHeight: cellHeight,
      display: 'flex',
      textAlign: 'left',
      justifyContent: 'center',
      marginRight: margin,
    },
    tableCell: {
      padding: padding,
    },
    content: {
      color: colors.primary,
      fontSize: fontSizes.header,
      position: 'relative',
      padding: padding,
    },
  });

  return (
    <>
      <PdfHeaderForScreenShot title={title} title2={title2} icon={icon} />
      <View style={styles.tableRow}>
        <View
          style={[
            styles.tableCol,
            {
              flex: 1,
              marginTop: '-35px',
            },
          ]}
        >
          {screenshotUrl[tableElement] && (
            <Image
              src={screenshotUrl[tableElement]}
              style={{ width: width, height: height }}
            />
          )}{' '}
        </View>
        {screenshotUrl[tableElement2] && (
          <View
            style={[
              styles.tableCol,
              {
                flex: 1,
                marginRight: 0,
                marginTop: '-35px',
              },
            ]}
          >
            {screenshotUrl[tableElement2] && (
              <Image
                src={screenshotUrl[tableElement2]}
                style={{ width: width, height: height }}
              />
            )}
          </View>
        )}
      </View>
    </>
  );
};
