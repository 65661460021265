import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormattedMessage } from 'react-intl';
import { useFetch } from 'react-admin-base';
import { useAuth } from 'react-admin-base';
import { useIntl } from 'react-intl';
import { LinkButton } from '../../ActionButtons/ActionButtons';
import { icons } from '../../../Icons/icons';
import { Button_ } from '../../ActionButtons/ActionButtons';
import { Input, Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { InputField } from '../../Input';
import { Link } from 'react-router-dom';
import Template from '../../../FileTemplatesAndImages/Template.xlsx';
import { Badge } from 'reactstrap';
import { SingleFilePicker } from 'react-admin-base-bootstrap';
import { Alerts } from '../../Alerts';
import { showSwal } from '../../CustomForm';
import { Loading } from '../../Loading';
import { useUser } from '../../UserProvider';

const url = 'customer/get/groups';

const createTableData = (groups, customers) => {
  const tableDatas = [];

  groups &&
    groups.map((group) => {
      const groupData = {
        ...group,
        id: group.id,
        customers: [],
      };
      const groupCustomers = customers.filter(
        (customer) => customer.groupId === group.id
      );
      groupData.customers = groupCustomers;
      tableDatas.push(groupData);
    });

  return tableDatas;
};

const getFileId = (state) => {
  return Object.values(state).map((e) => e.id)[0];
};

const getGroupSectorCodeByCustomers = (checkedCompanies, groups) => {
  const groupId = checkedCompanies[0].groupId;
  const group = groups.find((e) => e.id === groupId);
  return group?.sector.code;
};

export const ConsolidationTable = () => {
  const intl = useIntl();
  const [groups, setGroups] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [loading, setLoading] = useState({
    fetch: false,
    submit: false,
  });
  const [globalFilterValue, setGlobalFilterValue] = React.useState('');

  const [lazyParams, setLazyParams] = React.useState({
    first: 1,
    rows: 10,
    page: 1,
    sortField: 'id',
    sortOrder: 1,
    filters: {},
    multiSortMeta: [],
  });
  const [checkedCompanies, setCheckedCompanies] = useState([]);
  const [file, setFile] = useState({});
  const [done, setDone] = useState(false);
  const [ID, setID] = useState(null);
  const user = useUser();

  const navigate = useNavigate();
  const user_id = user.id;

  const [customers, customersLoading] = useFetch('/customer/get/all/groups');
  const [company, companyLoading] = useFetch('/company/get/user/' + user_id);
  const companyCode = company && company.code;

  const [api] = useAuth();
  const [, setError] = useState('');

  React.useEffect(() => {
    fetchData();
  }, [lazyParams, globalFilterValue]);

  const fetchData = async () => {
    setLoading((prev) => ({
      ...prev,
      fetch: true,
    }));
    try {
      const response = await api.tokenized.get(
        `/${url}?page=${lazyParams.page}&itemPerPage=${lazyParams.rows}&query=${globalFilterValue}&sort=${lazyParams.sortField}&desc=${lazyParams.sortOrder === 1 ? true : false}`
      );
      setGroups(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prev) => ({
        ...prev,
        fetch: false,
      }));
    }
  };

  const handleFilePicker = (id, e) => {
    if (e) setFile({ [id]: e });
    else setFile({});
  };

  const onPage = (event) => {
    setLazyParams({ ...event, page: event.page + 1 });
  };

  const onFilter = (event) => {
    setLazyParams(event);
  };

  const onSort = (event) => {
    setLazyParams((prev) => ({
      ...event,
      page: 1,
    }));
  };

  const totalRecords = groups ? groups.itemCount : 0;

  const renderHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '1rem',
          }}
        >
          <InputField
            name="globalFilterValue"
            type="text"
            onChange={(e) => setGlobalFilterValue(e.target.value)}
            value={globalFilterValue}
            placeHolder={intl.formatMessage({ id: 'SEARCH' })}
            style={{ width: '60%' }}
          />
        </div>

        <Link
          to={Template}
          target="_blank"
          download={intl.formatMessage({
            id: 'DOWNLOAD_CONSOLIDATION_TEMPLATE',
          })}
        >
          <Button_
            text={intl.formatMessage({
              id: 'DOWNLOAD_CONSOLIDATION_TEMPLATE',
            })}
            size="sm"
            color="success"
            iconClassName={icons.download}
          />
        </Link>
      </div>
    );
  };

  const renderHeader2 = (data) => {
    const id = data?.props?.value && data?.props?.value[0]?.groupId;
    const isButtonVisible = checkedCompanies.length > 1;

    const groupName = groups.data.find((e) => e.id === id)?.name;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        <Label>
          <FormattedMessage
            id="CONSOLIDATION_TABLE_CUSTOMERS_HEADER"
            values={{ name: groupName }}
          />
        </Label>
        <hr style={{ margin: 0 }} />

        {isButtonVisible && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '0.5rem',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <Label style={{ marginRight: '1em' }}>
                <FormattedMessage id="UPLOAD_CONSOLIDATION_TEMPLATE" />:
              </Label>
              <SingleFilePicker
                accepts=".pdf,.xlsx,.png,.jpg"
                value={file[id]}
                onChange={(e) => handleFilePicker(id, e)}
              />
            </div>
            <Button_
              text={intl.formatMessage({
                id: 'CONSOLIDATION_BUTTON',
              })}
              color="primary"
              iconClassName={icons.startAnalysis}
              type="submit"
              disabled={file[id] ? false : true}
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const onSubmit = async () => {
    setLoading((prev) => ({
      ...prev,
      submit: true,
    }));
    try {
      const result = await api.tokenized.post('/consolidation', {
        inGroupExcelFileId: getFileId(file),
        customerIds: checkedCompanies.map((e) => e.id),
        sectorCode: getGroupSectorCodeByCustomers(
          checkedCompanies,
          groups.data
        ),
      });

      if (result.status === 200) {
        setID(result.data.requestModel);
        setDone(true);
      }
    } catch (e) {
      if (e.message && e.response.data.message) {
        const errorMessage = e.response.data.message;
        showSwal(
          `<p>${
            typeof errorMessage === 'string'
              ? ''
              : localStorage.frayjet_frayjet_language === 'en'
                ? errorMessage.eng
                : errorMessage.tr
          }</p>`,
          setError,
          intl
        );
      }
      setError(e.message);
    } finally {
      setLoading((prev) => ({
        ...prev,
        submit: false,
      }));
    }
  };

  if (done) {
    setTimeout(() => {
      document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
      navigate('/analysis/' + window.btoa(ID) + '/edit');
      setDone(false);
    }, 2000);
  }
  const rowExpansionTemplate = (data) => {
    return (
      <div
        style={{
          padding: '0.5rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <DataTable
          value={data.customers}
          selectionMode="multiple"
          selection={checkedCompanies}
          onSelectionChange={(e) => setCheckedCompanies(e.value)}
          header={renderHeader2}
        >
          <Column
            body={(rowData) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '0.5rem',
                  }}
                >
                  {/* <LinkButton
                url={'/customer/' + rowData.id + '/edit'}
                text={'Kullanıcıyı Düzenle'}
                size="sm"
                color="info"
                iconClassName={icons.profile}
              />
              <LinkButton
                url={'/analysis/' + window.btoa(rowData.documentId) + '/edit'}
                text={'Analizi Düzenle'}
                size="sm"
                color="secondary"
                iconClassName={icons.edit}
              /> */}

                  <Input
                    type="checkbox"
                    // checked={modal[row.id] ? modal[row.id].includes(c.id) : false}
                    // disabled={Object.keys(modal).length > 1}
                    // onChange={(e) => {
                    //   const checked = e.target.checked;

                    //   if (checked)
                    //     setModal((prev) => ({
                    //       [row.id]: [...(prev[row.id] || []), c.id],
                    //     }));
                    //   else {
                    //     setModal((prev) => ({
                    //       [row.id]: prev[row.id].filter((e) => e !== c.id),
                    //     }));
                    //   }
                    // }}
                    checked={
                      checkedCompanies.find((e) => e.id === rowData.id)
                        ? true
                        : false
                    }
                    onClick={(e) => {
                      setCheckedCompanies((prev) => {
                        const index = prev.findIndex(
                          (e) => e.id === rowData.id
                        );
                        if (index === -1) return [...prev, rowData];
                        return prev.filter((e) => e.id !== rowData.id);
                      });
                    }}
                    style={{
                      width: '20px',
                      height: '20px',
                      pointerEvents: 'auto',
                    }}
                  />
                </div>
              );
            }}
            style={{
              width: '10%',
            }}
          />
          <Column
            field="name"
            header={<FormattedMessage id={'name'} />}
          ></Column>

          <Column
            field="statementYear"
            header={<FormattedMessage id={'STATEMENT_YEAR'} />}
            body={(rowData) => {
              return (
                <span>{`${rowData?.lastAnalyzeYear}/${rowData?.lastAnalyzeMonth}`}</span>
              );
            }}
          ></Column>

          <Column
            field="analyzeType"
            header={<FormattedMessage id={'companyAnalysisType'} />}
            body={(rowData) => {
              return (
                <span>
                  {' '}
                  {rowData.analyzeType
                    ? intl.formatMessage({
                        id: rowData.analyzeType,
                      })
                    : ''}
                </span>
              );
            }}
          ></Column>

          {companyCode !== 'ta3meed' && (
            <Column
              header={<i className="fas fa-water" />}
              body={(rowData) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '0.5rem',
                    }}
                  >
                    <LinkButton
                      url={'/customer/' + rowData.id + '/profile'}
                      text={intl.formatMessage({ id: 'PROFILE' })}
                      size="sm"
                      color="info"
                      iconClassName={icons.profile}
                      openNewTab={true}
                    />
                  </div>
                );
              }}
              style={{
                width: '10%',
              }}
            />
          )}
        </DataTable>
      </div>
    );
  };
  const allowExpansion = (rowData) => {
    return rowData.customers.length > 0;
  };

  const onRowCollapse = (e) => {
    clearFileAndCompStates();
    setExpandedRows(null);
  };

  const clearFileAndCompStates = () => {
    setFile({});
    setCheckedCompanies([]);
  };

  const onRowClick = (e) => {
    if (e.data.customers.length === 0) return;
    const isExpanded = expandedRows?.find((row) => row.id === e.data?.id);
    console.log('click: ', e, isExpanded, expandedRows);
    clearFileAndCompStates();
    setExpandedRows(isExpanded ? null : [e.data]);
  };

  const onRowExpand = (e) => {
    onRowClick(e);
  };

  console.log(
    'Seçilen Müşteriler: ',
    checkedCompanies,
    'Seçilen Dosya: ',
    file,
    'Seçilen Gruplar: ',
    expandedRows
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
      }}
    >
      {done && (
        <Alerts
          className="fa fa-check"
          color="success"
          messageId={'COMPLETED_CONSOLIDATION'}
        />
      )}
      {loading.submit && <Loading />}
      <Badge
        color="primary"
        // style={{
        //   alignSelf: 'center',
        // }}
      >
        <Label size="sm">
          <FormattedMessage id="CONSOLIDATION_INFO" />
        </Label>
      </Badge>
      <DataTable
        value={
          customers && groups
            ? [...createTableData(groups.data, customers)]
            : []
        }
        rowGroupMode="subheader"
        groupRowsBy="representative.name"
        expandedRows={expandedRows}
        onRowExpand={onRowExpand}
        rowsPerPageOptions={[5, 10, 25, 50]}
        tableStyle={{ minWidth: '50rem' }}
        onPage={onPage}
        first={lazyParams.first}
        rows={lazyParams.rows}
        totalRecords={totalRecords}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
        currentPageReportTemplate=" "
        lazy
        paginator
        onRowClick={onRowClick}
        header={renderHeader}
        onGlobalFilterChange={onFilter}
        rowExpansionTemplate={rowExpansionTemplate}
        onRowCollapse={onRowCollapse}
        selectionMode="single"
        loading={loading.fetch || customersLoading || companyLoading}
        emptyMessage={intl.formatMessage({ id: 'NO_DATA_IS_AVAILABLE' })}
      >
        <Column expander={allowExpansion} />

        <Column
          field="name"
          header={intl.formatMessage({ id: 'GROUP_NAME' })}
          sortable
          sortField="name"
        ></Column>
        <Column
          field="email"
          header={<FormattedMessage id={'ADD_USER_EMAIL'} />}
          sortable
          sortField="email"
        ></Column>
        {/* <Column
          header={<i className="fas fa-water" />}
          body={(rowData) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '0.5rem',
                }}
              >
                <LinkButton
                  url={'/group/' + rowData.id + '/profile'}
                  text={'Profil'}
                  size="sm"
                  color="info"
                  iconClassName={icons.profile}
                />
              </div>
            );
          }}
          style={{
            width: '10%',
          }}
        /> */}
      </DataTable>
    </div>
  );
};
