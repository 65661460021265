import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { VukIfrsTable } from '../tables/customer-profile/VukIfrsTable';
import { useFetch } from 'react-admin-base';
import { Alerts } from '../Alerts';

export const CustomerFinancialsContent = ({ customerId }) => {
  const [key4, setKey] = React.useState('0');

  const [ifrsData, ifrsDataLoading] = useFetch(
    `customer/statement/detailed/ifrs/${customerId}`
  );
  const [vukData, vukDataLoading] = useFetch(
    `customer/statement/detailed/vuk/${customerId}`
  );

  if (vukDataLoading || ifrsDataLoading)
    return (
      <Alerts
        color="primary"
        className="fas fa-spin fa-spinner"
        messageId="LOADING"
        label=""
      />
    );

  return (
    <Tabs
      id="controlled-tab-example2"
      className="mb-3"
      unmountOnExit={true}
      mountOnEnter={true}
      transition={false}
    >
      <Tab eventKey="vuk" title="VUK">
        <VukIfrsTable data={vukData} />
      </Tab>

      <Tab eventKey="ifrs" title="IFRS">
        <VukIfrsTable data={ifrsData} />
      </Tab>
    </Tabs>
  );
};
