// @ts-nocheck
import React, { useCallback, useRef } from 'react';
import { Button } from 'reactstrap';
import { FaPrint } from 'react-icons/fa';
import { CustomFinancialRatiosPDF } from '../../Common/CustomFinancialRatiosPDF';
import FirmLoadingAlert from '../../Components/Alerts';
import { FormattedMessage } from 'react-intl';

export default function SessionTableCustomButton({
  slug,
  customAssessment,
  logo,
  dname,
  value,
}) {
  if (!customAssessment) return <FirmLoadingAlert />;
  if (!value || !value.ratios) return <FirmLoadingAlert />;
  return (
    <td>
      <SessionTableCustom
        slug={slug}
        customAssessment={customAssessment}
        logo={logo}
        dname={dname}
        value={value}
      />
    </td>
  );
}

export function SessionTableCustom({
  slug,
  customAssessment,
  logo,
  dname,
  value,
}) {
  const ref = useRef();
  const print = useCallback(
    function () {
      const win = window.open(
        'about:blank',
        '',
        'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000'
      );
      //   background: url("https://f-rayscoring.com/wp-content/uploads/2022/01/bgPDF.png");

      const styleSheet = document.createElement('style');
      styleSheet.type = 'text/css';
      styleSheet.innerHTML = `
     
            @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&display=swap');
            html{
              height: 95.6%;
              background-position: center;
              background-size: cover; 
              padding-top:  50px;
              background-repeat: no-repeat;
              position: absolute;
              font-family: 'IBM Plex Sans', sans-serif;
            } 
            header {font-size: 12px; font-weight: bold;color: #1A1A27;text-align: center;position: fixed;top: 0;}
            body {margin:5mm}
            @media print{ @page {size: A4; margin: 2mm;} }
            .comma > span:not(:last-child)::after { content: ', '; }
            h3, h4 { text-align: center }
            h5 {text-align: left;}
            button { display: none }
            table { border-collapse: collapse; width: 100%; }
            td, th { border: solid 1px #000; padding: 5px 5px; }
            .min { width: 20%; }
            .min2 { width: 1% !important; } 
            .column { width: 50%; padding: 10px; height: 300px; }
            .row:after {content: ""; display: table; clear: both; }
            .centered_div{ margin: auto;width: 50%;padding: 5px; }
            .custom_div_b {margin-bottom: 100px;}
            .custom_div {margin: 60px;}
            
            .finansal_analiz{
            }
            .custom_title{
              width:  100%;
              text-align: center;
              margin-left: 15%
            }
            
        `;

      win.document.head.appendChild(styleSheet);
      win.document.body.innerHTML = ref.current.innerHTML;
      win.print();
    },
    [ref]
  );

  if (!customAssessment) return null;
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return (
    <>
      <Button
        type="button"
        className="text-white"
        style={{ background: '#795D9C' }}
        onClick={print}
      >
        <FaPrint style={{ marginRight: '10px' }} />{' '}
        <FormattedMessage id="SUBTAB_PRINT_FINANCIAL_ANALYSIS_REPORT" />
      </Button>
      <div style={{ display: 'none' }} ref={ref}>
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <b>
            <FormattedMessage id="DATE" />: {date}/{month}/{year}
          </b>
        </div>
        {logo == 'argeteknokent' ? (
          <div className="imgs">
            <img
              src="https://f-rayscoring.com/wp-content/uploads/2022/01/disi-logo-e1641909656297.png"
              width="200"
              height="50"
              style={{
                float: 'right',
                marginLeft: '0px',
                marginRight: '150px',
                position: 'center',
              }}
            ></img>
            <img
              src="https://f-rayscoring.com/wp-content/uploads/2021/06/fray-logo.png"
              width="300"
              height="50"
              style={{ float: 'right' }}
            ></img>
          </div>
        ) : (
          <div className="imgs">
            <img
              src="https://f-rayscoring.com/wp-content/uploads/2021/06/fray-logo.png"
              width="300"
              height="50"
              style={{ textAlign: 'center', marginLeft: '30%' }}
            ></img>
          </div>
        )}

        <br />
        <br />
        <h4 style={{ textAlign: 'center' }}>
          {dname} Analizi
          <footer>
            <hr />
          </footer>
        </h4>
        <body>
          <CustomFinancialRatiosPDF
            slug={slug}
            customAssessment={customAssessment}
            value={value}
          />
        </body>
      </div>
    </>
  );
}
