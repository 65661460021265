import React from 'react';
import useFetch from '../../../Hooks/useFetch';
import { Row } from 'reactstrap';
import { Alerts } from '../../../Components/Alerts';
import { CombinedScore } from '../../../Common/ScoringTab';
import { ModuleCredi } from '../../../Common/ModuleCredi';

export const FinancialScore = ({ firmInfo, id }) => {
  const [data, loading] = useFetch('/bnpl/combinedScore/' + firmInfo);
  const [scoring, loading2] = useFetch('/analyze/scoring/' + firmInfo);
  const [scoringVal, loading3] = useFetch('/analyze/scoringpoints/');
  if (loading || loading2 || loading3) {
    return (
      <Alerts
        color="primary"
        className="fas fa-spin fa-spinner"
        messageId={'LOADING'}
        label=""
      />
    );
  }

  return (
    <>
      <Row>
        {scoring && scoringVal && (
          <ModuleCredi
            scoring={scoring}
            scoringVal={scoringVal}
            formattedMessageId={id}
          />
        )}
      </Row>

      <Row>{data && <CombinedScore combinedScore={data} />}</Row>
    </>
  );
};
