import React from 'react';
import { Col, Row } from 'reactstrap';
import { FaSquare } from 'react-icons/fa';
import { Bar, BarChart, Tooltip, XAxis, YAxis } from 'recharts';
import { useIntl } from 'react-intl';

export const ModuleGrafikDataTaemed = ({ incomestatement }) => {
  const Intl = useIntl();
  const chartDatas =
    incomestatement &&
    incomestatement.titles &&
    incomestatement.titles.filter(
      (t) =>
        t.title === 'Net Sales (Revenue)' ||
        t.title === 'Gross Profit' ||
        t.title === 'Operating Profit' ||
        t.title === 'Net Profit (Net Earnings)'
    );

  const y1 =
    (chartDatas[0] &&
      chartDatas[0].values[0] &&
      chartDatas[0].values[0].year) ||
    '';
  const y2 =
    (chartDatas[0] &&
      chartDatas[0].values[1] &&
      chartDatas[0].values[1].year) ||
    '';

  const d1 =
    chartDatas[0] && chartDatas[0].values[0] && chartDatas[0].values[0].value;

  const d2 =
    chartDatas[0] && chartDatas[0].values[1] && chartDatas[0].values[1].value;

  const d3 =
    chartDatas[1] && chartDatas[1].values[0] && chartDatas[1].values[0].value;
  const d4 =
    chartDatas[1] && chartDatas[1].values[1] && chartDatas[1].values[1].value;

  const d5 =
    chartDatas[2] && chartDatas[2].values[0] && chartDatas[2].values[0].value;
  const d6 =
    chartDatas[2] && chartDatas[2].values[1] && chartDatas[2].values[1].value;

  const d7 =
    chartDatas[3] && chartDatas[3].values[0] && chartDatas[3].values[0].value;
  const d8 =
    chartDatas[3] && chartDatas[3].values[1] && chartDatas[3].values[1].value;

  const data_1 = [
    {
      date: chartDatas[0] && Intl.formatMessage({ id: chartDatas[0].title }),
      y1: d1,
      y2: d2,
    },
  ];

  const data_2 = [
    {
      date: chartDatas[1] && Intl.formatMessage({ id: chartDatas[1].title }),
      y1: d3,
      y2: d4,
    },
  ];
  const data_3 = [
    {
      date: chartDatas[2] && Intl.formatMessage({ id: chartDatas[2].title }),
      y1: d5,
      y2: d6,
    },
  ];
  const data_4 = [
    {
      date: chartDatas[3] && Intl.formatMessage({ id: chartDatas[3].title }),
      y1: d7,
      y2: d8,
    },
  ];
  return [data_1, data_2, data_3, data_4, y1, y2];
};

export const ModuleGrafiklerTaemed = ({ incomestatement }) => {
  const CustomTooltip = (data) => {
    const {
      active,
      payload: [p1, p2],
    } = data;

    return (
      active && (
        <div
          style={{
            textAlign: 'center',
            padding: '0.5em',
            borderRadius: '0.5em',
            backgroundColor: 'whitesmoke',
          }}
        >
          {p1 && (
            <p style={{ color: p1.color, marginBottom: 0 }}>
              {p1.name}: {new Intl.NumberFormat('tr').format(p1.value)}
            </p>
          )}
          {p2 && (
            <p style={{ color: p2.color, marginBottom: 0 }}>
              {p2.name}: {new Intl.NumberFormat('tr').format(p2.value)}
            </p>
          )}
        </div>
      )
    );
  };

  const IncomeStatementCharts = ({ incomestatement }) => {
    const [data_1, data_2, data_3, data_4, y1, y2] = ModuleGrafikDataTaemed({
      incomestatement,
    });

    return (
      <>
        <Row>
          <Col sm={{ size: 'auto', offset: 4 }}>
            <FaSquare style={{ color: '#007FB2' }} /> {y1}
          </Col>
          <Col sm={{ size: 'auto', offset: 0 }}>
            <FaSquare style={{ color: '#795D9C' }} /> {y2}
          </Col>
        </Row>

        <Row>
          <Col>
            <BarChart
              width={350}
              height={350}
              data={data_1}
              margin={{ left: 50, bottom: 10 }}
            >
              <Tooltip
                cursor={false}
                position={{ y: 0, x: 280 }}
                content={<CustomTooltip />}
              />

              <XAxis dataKey="date" interval={0} />

              <YAxis
                tickFormatter={(value) =>
                  new Intl.NumberFormat('tr').format(value)
                }
              />

              <Bar dataKey="y1" fill="#007FB2" barSize={50} name={y1} />
              <Bar dataKey="y2" fill="#795D9C" barSize={50} name={y2} />
            </BarChart>
          </Col>

          <Col>
            <BarChart
              width={350}
              height={350}
              data={data_2}
              margin={{ left: 50, bottom: 10 }}
            >
              <XAxis dataKey="date" interval={0} />

              <YAxis
                tickFormatter={(value) =>
                  new Intl.NumberFormat('tr').format(value)
                }
              />
              <Tooltip
                cursor={false}
                position={{ y: 0, x: 280 }}
                content={<CustomTooltip />}
              />

              <Bar dataKey="y1" fill="#007FB2" barSize={50} name={y1} />
              <Bar dataKey="y2" fill="#795D9C" barSize={50} name={y2} />
            </BarChart>
          </Col>
        </Row>
        <hr style={{ marginTop: 0 }} />
        <Row>
          <Col>
            <BarChart
              width={350}
              height={350}
              data={data_3}
              margin={{ left: 50, bottom: 10 }}
            >
              <XAxis dataKey="date" interval={0} />

              <YAxis
                tickFormatter={(value) =>
                  new Intl.NumberFormat('tr').format(value)
                }
              />

              <Tooltip
                cursor={false}
                position={{ y: 0, x: 280 }}
                content={<CustomTooltip />}
              />

              <Bar dataKey="y1" fill="#007FB2" barSize={50} name={y1} />
              <Bar dataKey="y2" fill="#795D9C" barSize={50} name={y2} />
            </BarChart>
          </Col>
          <Col>
            <BarChart
              width={350}
              height={350}
              data={data_4}
              margin={{ left: 50, bottom: 10 }}
            >
              <XAxis dataKey="date" interval={0} />

              <YAxis
                tickFormatter={(value) =>
                  new Intl.NumberFormat('tr').format(value)
                }
              />

              <Tooltip
                cursor={false}
                position={{ y: 0, x: 280 }}
                content={<CustomTooltip />}
              />

              <Bar dataKey="y1" fill="#007FB2" barSize={50} name={y1} />
              <Bar dataKey="y2" fill="#795D9C" barSize={50} name={y2} />
            </BarChart>
          </Col>
        </Row>
        <hr />
      </>
    );
  };

  return (
    <div className="mt-2">
      {incomestatement && (
        <IncomeStatementCharts incomestatement={incomestatement} />
      )}
    </div>
  );
};

export default ModuleGrafiklerTaemed;
