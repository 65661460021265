import { Table } from 'reactstrap';
import React from 'react';
import {
  FaExclamationCircle,
  FaCheckCircle,
  FaAngleRight,
} from 'react-icons/fa';
import { CustomBarChart } from '../Common/BarChartComponent';
import '../pages/Summarys/energy.scss';
import { PieCharts } from '../Common/PieChartComponent';
import { FormattedMessage } from 'react-intl';

const filteredTitles = ['Liquidity', 'Solvency', 'Efficiency'];

export const FinancialAnalyseDetectionReport = ({
  assessment,
  trialBalance,
  type,
  scoring,
  companyCode,
}) => {
  const style_plus = { color: 'green' };
  const style_minus = { color: 'red' };
  const isTaemed = companyCode === 'ta3meed';

  const newAssessment = isTaemed
    ? {
        titles: assessment.titles.filter(
          (val) => !filteredTitles.includes(val.name)
        ),
      }
    : assessment;

  return (
    <div>
      <row>
        {type == 'beyanname' && (
          <h4>
            <b>
              <u>
                <FormattedMessage id="SUBTAB_1_1_FINANCIAL_ANALYSIS_AND_DETECTION_REPORT" />
              </u>
            </b>
          </h4>
        )}
        {type == 'beyanname' ? (
          <>
            {newAssessment.titles.map((val, key) => (
              <Table
                striped
                responsive
                bordered
                hover
                key={key}
                style={{ marginTop: '2.5em' }}
                size="sm"
              >
                <tbody>
                  <tr key={key}>
                    <td>
                      {' '}
                      <p
                        style={{
                          fontWeight: 'bolder',
                          textAlign: 'center',
                          fontSize: '18px',
                        }}
                      >
                        {val.name}
                      </p>
                      {val.subTitles.map((data, i) => (
                        <p
                          key={i}
                          style={{
                            justifyContent: 'center',
                            textAlign: 'justify',
                          }}
                        >
                          {data.isPositive.toLocaleString() == 'true' ? (
                            <p>
                              <FaAngleRight size={12} />
                              {data.name} {'  '}
                              <FaCheckCircle style={style_plus} size={18} />
                            </p>
                          ) : (
                            <p>
                              <FaAngleRight size={12} />
                              {data.name} {'  '}
                              <FaExclamationCircle
                                style={style_minus}
                                size={18}
                              />
                            </p>
                          )}
                          {isTaemed
                            ? val.name !== 'Profitability'
                              ? data.description
                              : ''
                            : data.description}
                        </p>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </Table>
            ))}
          </>
        ) : (
          <>
            {trialBalance.titles.map((val, j) => (
              <Table
                striped
                responsive
                bordered
                hover
                style={{ marginTop: '2.5em' }}
                size="sm"
                key={j}
              >
                <tbody>
                  <tr key={j}>
                    <td>
                      {' '}
                      <p
                        style={{
                          fontWeight: 'bolder',
                          textAlign: 'center',
                          fontSize: '18px',
                        }}
                      >
                        {val.name}
                      </p>
                      {val.subTitles.map((data, k) => (
                        <React.Fragment key={k}>
                          <p>
                            {data.name &&
                            data.name.split(':')[0] == 'Faaliyet Giderleri' ? (
                              <>
                                <PieCharts
                                  array={data.comparisonList}
                                  type="print"
                                />
                                {/* <h1>{data.comparisonList[0]}</h1> */}
                              </>
                            ) : (
                              <CustomBarChart
                                title={
                                  (data.name && data.name.split(':')[0]) || ''
                                }
                                scoring={scoring}
                                value={10}
                                type="print"
                                array={data.comparisonList}
                              />
                            )}
                            {data.isPositive.toLocaleString() == 'true' ? (
                              <p>
                                <FaAngleRight size={12} />
                                {data.name} {'  '}
                                <FaCheckCircle style={style_plus} size={20} />
                              </p>
                            ) : (
                              <p>
                                <FaAngleRight size={12} />
                                {data.name} {'  '}
                                <FaExclamationCircle
                                  style={style_minus}
                                  size={20}
                                />
                              </p>
                            )}
                            <p style={{ textAlign: 'justify' }}>
                              {data.description}{' '}
                            </p>
                          </p>
                        </React.Fragment>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </Table>
            ))}
          </>
        )}
      </row>
    </div>
  );
};

export default FinancialAnalyseDetectionReport;
