import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Table } from 'reactstrap';
import { Tab, Tabs } from 'react-bootstrap';
import { filterTitles } from '../../../Common/ModuleFinansalOranlar';
import { useIntl } from 'react-intl';
import { ExcelImportFromTable } from '../../DownloadExcelFromTable';
import { Button_ } from '../../ActionButtons/ActionButtons';

import { icons } from '../../../Icons/icons';

import { Label } from 'reactstrap';

export const VukIfrsTable = ({ data }) => {
  const intl = useIntl();

  const excelProps = data
    ? data.mainList.map((e, i) => {
        return { id: `module${i}-to-xls`, sheetName: e.name };
      })
    : null;
  return data ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1em',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Button_
          onClick={() => ExcelImportFromTable(excelProps, intl)}
          className="font-xl btn btn-primary btn-block"
          type="button"
          text={
            <>
              {' '}
              <FormattedMessage id="TAB_4_FINANCIAL_TABLES" /> Excel{' '}
              <FormattedMessage id="DOWNLOAD" />
            </>
          }
          iconClassName={icons.download}
          icon
        ></Button_>
      </div>
      <Tabs>
        {data.mainList.map((row, i) => (
          <Tab eventKey={row + i} title={row.name} key={i}>
            <Table
              id={'module' + i + '-to-xls'}
              responsive
              bordered
              key={i}
              size="sm"
            >
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="SUBTAB_4_1_TITLE1" />
                  </th>
                  {row.dataList &&
                    row.dataList[0] &&
                    row.dataList[0].titles &&
                    row.dataList[0].titles[0] &&
                    row.dataList[0].titles[0].values.map((a, z) => (
                      <React.Fragment key={z}>
                        <th className="text-center">
                          {a.year.endsWith('/0')
                            ? a.year.replaceAll('/0', '')
                            : a.year}
                        </th>
                      </React.Fragment>
                    ))}
                </tr>
              </thead>
              <tbody>
                {row.dataList &&
                  row.dataList[0] &&
                  row.dataList[0].titles &&
                  row.dataList.map((b, i) => (
                    <React.Fragment key={i}>
                      {b.mainEntry && (
                        <tr>
                          <td
                            colSpan={100}
                            style={{
                              background: '#a4b5c0',
                            }}
                          >
                            <p
                              style={{
                                textAlign: 'center',
                                padding: 0,
                                margin: 10,
                                fontWeight: 'bold',
                              }}
                            >
                              {b.mainEntry}
                            </p>
                          </td>
                        </tr>
                      )}

                      {filterTitles(b.titles).map((a, j) => (
                        <React.Fragment key={j}>
                          <tr key={j}>
                            <td key={j}>
                              <p>{a.title}</p>
                            </td>

                            {(a.values[0]?.value !== 0 ||
                              a.values[1]?.value !== 0 ||
                              a.values[2]?.value !== 0 ||
                              a.values[3]?.value !== 0) &&
                              a.values.map((c, index) => (
                                <React.Fragment key={index}>
                                  <td className="text-center">
                                    {c.year.toString().indexOf('vs') === -1 &&
                                    c.year.toString().indexOf('Dikey') === -1 &&
                                    c.year.toString().indexOf('Common') ===
                                      -1 ? (
                                      c.value === 0 ? (
                                        '0'
                                      ) : (
                                        <FormattedNumber value={c.value} />
                                      )
                                    ) : c.value === 0 ? (
                                      '0'
                                    ) : (
                                      <FormattedNumber
                                        value={c.value}
                                        style="percent"
                                      />
                                    )}
                                  </td>
                                </React.Fragment>
                              ))}
                          </tr>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
              </tbody>
            </Table>
          </Tab>
        ))}
      </Tabs>
    </div>
  ) : (
    <Label>Mevcut Veri Bulunmamaktadır.</Label>
  );
};
