import React from 'react';
import { Breadcrumb } from 'react-admin-base-nazox';
import { useParams } from 'react-router-dom';
import { useFetch } from 'react-admin-base';
import FirmLoadingAlert from '../../Components/Alerts';
import { Card, CardBody, Table } from 'reactstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useIntl } from 'react-intl';

//faturalar null mı yoksa boş array mi geliyor sor.
export const BillSummary = () => {
  const params = useParams();
  const intl = useIntl();
  const code = params && params.Id && window.atob(params.Id);
  const [analyze, loading] = useFetch(`/process/inovice/${code}`);
  if (loading) return <FirmLoadingAlert />;

  const headers = [
    intl.formatMessage({ id: 'SELLER_BILL' }),
    intl.formatMessage({ id: 'BUYER_TAX' }),
    intl.formatMessage({ id: 'INVOICE' }),
    intl.formatMessage({ id: 'TOTAL_AMOUNT' }),
    intl.formatMessage({ id: 'VAT_1' }),
    intl.formatMessage({ id: 'VAT_2' }),
    intl.formatMessage({ id: 'VAT_3' }),
    intl.formatMessage({ id: 'TOTAL_AMOUNT_VAT' }),
  ];
  const datas = analyze ? Object.values(analyze) : [];
  return (
    <Breadcrumb
      title={'Faturalar'}
      data={[
        {
          href: `/bill/${code}summary`,
          name: 'Faturalar',
        },
      ]}
    >
      <Card>
        <CardBody>
          {analyze ? (
            <div>
              <span
                style={{
                  justifyContent: 'right',
                  display: 'flex',
                  marginBottom: '5px',
                }}
              >
                <ReactHTMLTableToExcel
                  table="fatura-1"
                  className="font-xl btn btn-primary btn-block"
                  filename={'Faturalar'}
                  sheet={'Faturalar'}
                  buttonText={'Fatura İndir'}
                />
              </span>
              <Table
                striped
                responsive
                bordered
                hover
                id={'fatura-1'}
                size="sm"
              >
                <thead>
                  <tr>
                    {headers.map((e, i) => (
                      <th key={i}>{e}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {datas.map((e, i) => (
                      <td key={i}>{e}</td>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : (
            <h6>Data bulunamadı.</h6>
          )}
        </CardBody>
      </Card>
    </Breadcrumb>
  );
};
