import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { colors, fontSizes, padding, margin, cellHeight } from '../Common';

export const SurveyResult = ({ surveyResult }) => {
  const styles = StyleSheet.create({
    table: {
      border: 'none',
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginTop: margin,
      minHeight: cellHeight,
      alignItems: 'stretch',
      pageBreakInside: 'avoid',
    },
    tableCol: {
      minHeight: cellHeight,
      marginRight: margin,
      display: 'flex',
      textAlign: 'left',
      justifyContent: 'center',
    },
    tableCell: {
      padding: padding,
      fontSize: fontSizes.title,
      color: colors.primary,
    },
    content: {
      color: colors.primary,
      fontSize: fontSizes.content,
      position: 'relative',
      padding: padding,
    },
    icon: {
      width: '20px',
      height: '20px',
    },
  });

  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View
          style={[
            styles.tableCol,
            {
              backgroundColor: colors.cell1,
              flex: 1,

              fontWeight: 600,
            },
          ]}
        >
          <Text style={[styles.tableCell]}>{surveyResult[0].title}</Text>
        </View>

        <View
          style={[
            styles.tableCol,
            {
              backgroundColor: colors.cell2,
              flex: 1,

              marginRight: 0,
            },
          ]}
        >
          <Text style={[styles.tableCell]}>{surveyResult[0].result}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View
          style={[
            styles.tableCol,
            {
              backgroundColor: colors.cell1,
              flex: 1,

              fontWeight: 600,
            },
          ]}
        >
          <Text style={[styles.tableCell]}>{surveyResult[1].title}</Text>
        </View>

        <View
          style={[
            styles.tableCol,
            {
              backgroundColor: colors.cell2,
              flex: 1,

              marginRight: 0,
            },
          ]}
        >
          <Text style={[styles.tableCell]}>{surveyResult[1].result}</Text>
        </View>
      </View>
    </View>
  );
};
