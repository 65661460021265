import React from 'react';
import { Chart } from 'react-google-charts';
import { FormattedMessage } from 'react-intl';
import { Label, Alert } from 'reactstrap';
export const GoogleChart = ({ data, chartType, title, is3D, pieHole }) => {
  const chartOptions = {
    sliceVisibilityThreshold: 0,
    hAxis: {
      format: '#',
      minValue: 0,
    },
    bar: { groupWidth: '40%' },
    is3D: is3D || false,
    pieHole: pieHole || 1,
    chartArea: { bottom: 30, width: '75%', height: '100%' },
    tooltip: { trigger: 'selection' },
    colors: ['#e2431e', '#e7711b', '#f1ca3a', '#6f9654', '#1c91c0', '#43459d'],
  };
  return (
    <>
      <Label>{title}</Label>
      {data && data.length > 1 ? (
        <Chart data={[...data]} options={chartOptions} chartType={chartType} />
      ) : (
        <Alert
          color="warning"
          style={{
            width: '20em',
            textAlign: 'center',
          }}
        >
          <FormattedMessage id={'NO_DATA'} />
        </Alert>
      )}
    </>
  );
};
export default GoogleChart;
