import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { CheckBox } from 'react-admin-base-bootstrap';
import { orcSecData } from '../Util/module_altman';
import { ModuleAltmanTable } from '../Components/tables/ModuleAltmanTable';
import { ModuleAltmanChartContent } from '../Components/ModuleAltmanChartContent';

export function ModuleAltman({ altmanzscoring, incomestatement }) {
  const intl = useIntl();
  const [OcrSec, setOcrSec] = useState(true);

  let alt_puan_1 = '';
  let alt_puan_2 = '';
  let alt_puan_3 = '';
  let alt_puan_4 = '';

  let gauge_1;
  let gauge_2;
  let gauge_3;
  let gauge_4;

  const findGauge = (val) => {
    let gauge = 80;
    if (val === 'Düşük İflas Riski' || val === 'Low Risk') gauge = 10;
    else if (val === 'Average Risk' || val === 'Ortalama İflas Riski')
      gauge = 50;
    return gauge;
  };

  if (altmanzscoring && altmanzscoring.titles) {
    const azTitlesLength = altmanzscoring && altmanzscoring.titles.length;
    for (var i = azTitlesLength - 1; i >= azTitlesLength - 4; i--) {
      const title = altmanzscoring.titles[i].title;
      if (
        title === "Altman Z' Puan-İmalatçı Firmalar" ||
        title === "Z' Score Manufacturing"
      ) {
        alt_puan_1 = altmanzscoring.titles[i].values[0].value.toString();
        alt_puan_2 = altmanzscoring.titles[i].values[1].value.toString();
      } else if (
        title === "Altman Z' Puan-İmalat Harici Firmalar" ||
        title === "Z' Score Non-Manufacturing"
      ) {
        alt_puan_3 = altmanzscoring.titles[i].values[0].value.toString();
        alt_puan_4 = altmanzscoring.titles[i].values[1].value.toString();
      } else if (
        title === "Altman Z' Puan Riski-İmalatçı Firmalar" ||
        title === 'Risk Category/Manufacturing'
      ) {
        gauge_1 = findGauge(
          altmanzscoring.titles[i].values[0].value.toString()
        );
        gauge_2 = findGauge(
          altmanzscoring.titles[i].values[1].value.toString()
        );
      } else if (
        title === "Altman Z' Puan Riski-İmalat Harici Firmalar" ||
        title === 'Risk Category/Non-Manufacturing'
      ) {
        gauge_3 = findGauge(
          altmanzscoring.titles[i].values[0].value.toString()
        );
        gauge_4 = findGauge(
          altmanzscoring.titles[i].values[1].value.toString()
        );
      }
    }
  }

  const metricValue_1 = gauge_1;
  const metricValue_2 = gauge_2;
  const metricValue_3 = gauge_3;
  const metricValue_4 = gauge_4;

  const pointerAngle_1 = (metricValue_1 * 180) / 100;
  const pointerAngle_2 = (metricValue_2 * 180) / 100;
  const pointerAngle_3 = (metricValue_3 * 180) / 100;
  const pointerAngle_4 = (metricValue_4 * 180) / 100;

  const y1 = incomestatement
    ? incomestatement.titles[0].values[0].year.toString()
    : '';
  const y2 = incomestatement
    ? incomestatement.titles[0].values[1].year.toString()
    : '';

  const firmData = {
    false: {
      title: 'SUBTAB_MANUFACTORING_TITLE',
      data: [
        {
          year: y1,
          pointerAngle: pointerAngle_1,
          gauge: gauge_1,
          alt_puan: alt_puan_1,
        },
        {
          year: y2,
          pointerAngle: pointerAngle_2,
          gauge: gauge_2,
          alt_puan: alt_puan_2,
        },
      ],
    },
    true: {
      title: 'SUBTAB_NON_MANUFACTORING_TITLE',
      data: [
        {
          year: y1,
          pointerAngle: pointerAngle_3,
          gauge: gauge_3,
          alt_puan: alt_puan_3,
        },
        {
          year: y2,
          pointerAngle: pointerAngle_4,
          gauge: gauge_4,
          alt_puan: alt_puan_4,
        },
      ],
    },
  };

  return (
    <Card className={'mt-2'}>
      <CardHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <h4>
            <FormattedMessage id={firmData[OcrSec].title} />
          </h4>
          <CheckBox
            id="OcrSec"
            type="checkbox"
            label={intl.formatMessage({
              id: 'SHOW_SUBTAB_NON_MANUFACTORING_TITLE',
            })}
            checked={OcrSec}
            onChange={() => {
              setOcrSec(!OcrSec);
            }}
          />
        </div>
      </CardHeader>
      <CardBody>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <Row>
            <Col md="1"></Col>
            {firmData[OcrSec].data.map((e, i) => {
              return (
                <ModuleAltmanChartContent
                  key={i}
                  y={e.year}
                  pointerAngle={e.pointerAngle}
                  gauge={e.gauge}
                  alt_puan={e.alt_puan}
                />
              );
            })}
            <Col md="1"></Col>
          </Row>
        </div>
        {altmanzscoring && altmanzscoring.titles && (
          <ModuleAltmanTable
            data={altmanzscoring.titles
              .filter((e) => !orcSecData[!OcrSec].includes(e.title))
              .reverse()}
            y1={y1}
            y2={y2}
          />
        )}
      </CardBody>
    </Card>
  );
}
