import React from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { arc } from '../pages/Summarys/utils';
import { findColorAndRisk } from '../Util/module_altman';

export const ModuleAltmanChartContent = ({
  y,
  gauge,
  alt_puan,
  pointerAngle,
}) => {
  const width = 200;
  const height = 200;
  const cx = width / 2;
  const cy = height / 2;
  const ringThick = 19;
  const ringRadius = width / 2;

  const ringSegments = [
    { color: '#63e02d', start: 0, end: 29 },
    { color: '#FFCA28', start: 30, end: 69 },
    { color: '#D41B1B', start: 70, end: 100 },
  ];

  return (
    <Col md="5">
      <Card>
        <CardHeader>{y}</CardHeader>
        <CardBody>
          <svg viewBox={`0 0 ${width} ${height}`}>
            <g>
              {ringSegments.map(({ color, start, end }, i) => (
                <path
                  key={i}
                  fill="none"
                  stroke={color}
                  strokeWidth={ringThick}
                  d={arc(
                    cx,
                    cy,
                    ringRadius - ringThick / 2,
                    (start * 180) / 100 - 90,
                    (end * 180) / 100 - 90
                  )}
                />
              ))}
            </g>

            <g
              id="pointer-circle"
              transform={`rotate(${pointerAngle}, ${cx}, ${cy}), translate(${
                -ringRadius + ringThick / 2
              }, 0)`}
            >
              <defs>
                <filter
                  id="pointer-shadow"
                  x="-25%"
                  y="-25%"
                  width="200%"
                  height="200%"
                >
                  <feDropShadow
                    dx="0"
                    dy="4"
                    stdDeviation="5"
                    floodColor="#000"
                    floodOpacity="0.45"
                  />
                </filter>
                <filter
                  id="pointer-inner-shadow"
                  x="-25%"
                  y="-25%"
                  width="200%"
                  height="200%"
                >
                  <feDropShadow
                    dx="0"
                    dy="0"
                    stdDeviation="2"
                    floodColor="#000"
                    floodOpacity="0.24"
                  />
                </filter>
              </defs>
            </g>

            <g
              id="pointer-triangle"
              transform={`translate(${cx}, ${cy}), rotate(${pointerAngle})`}
            >
              <path
                d="M 0 0 L 8 0 L 0 80 z"
                fill="#6D7782"
                stroke="none"
                transform={`translate(${
                  -ringRadius + ringThick + 90 + -8
                }, -4), rotate(90)`}
              />
            </g>
          </svg>

          {findColorAndRisk(gauge, alt_puan)}
        </CardBody>
      </Card>
    </Col>
  );
};
