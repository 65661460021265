import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Breadcrumb } from 'react-admin-base-nazox';
import { CustomersTable_ } from '../../Components/tables/customer-profile/CustomersTable_';

export const CustomersPage = () => {
  return (
    <Breadcrumb
      data={[
        {
          href: '/customers',
          name: <FormattedMessage id="CUSTOMERS" />,
        },
      ]}
    >
      <Card>
        <CardBody>
          <CustomersTable_ />
        </CardBody>
      </Card>
    </Breadcrumb>
  );
};
