import {
  App,
  Authorized,
  AuthProvider,
  ForgotPasswordRoute,
  LoginRoute,
  NotAuthorized,
} from 'react-admin-base';
import { LanguageProvider } from 'react-admin-base-bootstrap';
import { MainLayout, ThemeOptionProvider } from 'react-admin-base-nazox';

import MenuSidebar from './MenuSidebar';
import Router from './Router';
import MenuHeader from './MenuHeader';
import Footer from './Footer';
import { UserProvider } from './Components/UserProvider';
import UploadConfig from './UploadConfig';

import languageTr from './i18n/tr.json';
import languageEn from './i18n/en.json';

import { useNavigate } from 'react-router-dom';

import React, { useState } from 'react';
import ValidatorSettings from './ValidatorSettings';
import frayLogo from './FileTemplatesAndImages/1b.png';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPageProducer } from './Components/Error';
import { Reset } from './pages/Reset/Reset';
import Login from './pages/Login/Login';
import { ChangeLanguageOption } from './Components/ChangeLanguageOption';
export const languages = {
  tr: {
    icon: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/tr.svg',
    name: 'Turkish',
    messages: languageTr,
  },
  en: {
    icon: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/us.svg',
    name: 'English',
    messages: languageEn,
  },
};

function BaseApp() {
  const [appLogo, setAppLogo] = useState(frayLogo);
  const navigate = useNavigate();
  return (
    <ErrorBoundary
      FallbackComponent={ErrorPageProducer}
      onError={(err, info) => {
        console.log(err, info);
      }}
      onReset={() => {
        navigate('/');
      }}
    >
      <App
        id="frayjet"
        name="OTC F-Ray Finansal Teknolojiler"
        logo={appLogo}
        onlylogo="/cropped.png"
        introUrl="https://jetscoring.com"
        endpoint={process.env.REACT_APP_ENDPOINT}
      >
        <ThemeOptionProvider noLoginBanner>
          <LanguageProvider defaultLanguage="en" languages={languages}>
            <ValidatorSettings>
              <AuthProvider
                tokenEndpoint="/token"
                client_id="e86e91e7-d533-4e63-858a-e9d3c2caa253"
                client_secret="123456"
              >
                <NotAuthorized>
                  <LoginRoute>
                    <Login />
                  </LoginRoute>
                  <ForgotPasswordRoute>
                    <Reset />
                  </ForgotPasswordRoute>
                </NotAuthorized>
                <Authorized>
                  <UserProvider>
                    <ChangeLanguageOption />
                    <UploadConfig>
                      <MainLayout>
                        <MenuHeader />
                        <MenuSidebar setAppLogo={setAppLogo} />
                        <Router />
                        <Footer />
                      </MainLayout>
                    </UploadConfig>
                  </UserProvider>
                </Authorized>
              </AuthProvider>
            </ValidatorSettings>
          </LanguageProvider>
        </ThemeOptionProvider>
      </App>
    </ErrorBoundary>
  );
}

export default BaseApp;
