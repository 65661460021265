export const formAttendanceListParams = [
  {
    name: 'name',
    type: 'text',
    validation: false,
    languageId: 'ATTENDANCE_LIST_NAME',
  },

  {
    name: 'nationality',
    type: 'text',
    validation: false,
    languageId: 'NATIONALITY',
  },
  {
    name: 'shareType',
    type: 'text',
    validation: false,
    languageId: 'SHARE_TYPE',
  },
  {
    name: 'shareValueRatio',
    type: 'number',
    validation: false,
    languageId: 'SHARE_VALUE_RATIO',
  },
  {
    name: 'taxAdministrationNo',
    type: 'number',
    validation: false,
    languageId: 'taxNo2',
  },
  {
    name: 'totalShareValue',
    type: 'number',
    validation: false,
    languageId: 'TOTAL_SHARE_VALUE',
    format: true,
  },
];

export const formContentParams = {
  18: [
    {
      name: 'tckn',
      type: 'number',
      validation: true,
      languageId: 'TCKN',
      props: {
        allowNegative: false,
        thousandSeparator: false,
        decimalScale: 0,
      },
    },
    {
      name: 'name',
      type: 'text',
      validation: false,
      languageId: 'PROFILE_NAME',
    },
    {
      name: 'surname',
      type: 'text',
      validation: false,
      languageId: 'PROFILE_SURNAME',
    },
    {
      name: 'birthDate',
      type: 'date',
      validation: false,
      languageId: 'BIRTH_DATE',
    },
    {
      name: 'serialNumber',
      type: 'text',
      validation: false,
      languageId: 'SERIAL_NUMBER',
    },
    {
      name: 'validationEndDate',
      type: 'date',
      validation: false,
      languageId: 'VALIDATION_END_DATE',
    },
    {
      name: 'gender',
      type: 'select',
      validation: false,
      languageId: 'GENDER',
      options: [
        { label: 'Erkek', value: 'E' },
        { label: 'Kadın', value: 'K' },
      ],
    },
    {
      name: 'nationality',
      type: 'text',
      validation: false,
      languageId: 'NATIONALITY',
    },
  ],
  19: [
    {
      name: 'name',
      type: 'text',
      validation: false,
      languageId: 'PROFILE_NAME',
    },
    {
      name: 'surname',
      type: 'text',
      validation: false,
      languageId: 'PROFILE_SURNAME',
    },
    {
      name: 'businessName',
      type: 'text',
      validation: false,
      languageId: 'BUSINESS_NAME',
    },
    {
      name: 'businessAddress',
      type: 'text',
      validation: false,
      languageId: 'BUSINESS_ADDRESS',
    },
    {
      name: 'taxType',
      type: 'text',
      validation: false,
      languageId: 'TAX_TYPE',
    },
    {
      name: 'mainActivityCode',
      type: 'number',
      validation: false,
      languageId: 'MAIN_ACTIVITY_CODE',
      props: {
        allowNegative: false,
        thousandSeparator: false,
        decimalScale: 0,
      },
    },
    {
      name: 'taxAdministration',
      type: 'text',
      validation: false,
      languageId: 'TAX_ADMINISTRATION',
    },
    {
      name: 'taxIdentityNumber',
      type: 'text',
      validation: true,
      languageId: 'TAX_IDENTITY_NUMBER',
    },
    {
      name: 'tcIdentityNo',
      type: 'number',
      validation: true,
      languageId: 'TCKN',
      props: {
        allowNegative: false,
        thousandSeparator: false,
        decimalScale: 0,
      },
    },
    {
      name: 'businessStartDate',
      type: 'date',
      validation: false,
      languageId: 'BUSINESS_START_DATE',
    },
    {
      name: 'year',
      type: 'number',
      validation: false,
      languageId: 'YEAR',
      props: {
        allowNegative: false,
        thousandSeparator: false,
        decimalScale: 0,
      },
    },
  ],
  20: [
    {
      name: 'documentDate',
      type: 'date',
      validation: false,
      languageId: 'DOCUMENT_DATE',
    },
    {
      name: 'companyName',
      type: 'text',
      validation: false,
      languageId: 'COMPANY_NAME',
    },
    {
      name: 'companyAddress',
      type: 'text',
      validation: false,
      languageId: 'COMPANY_ADDRESS',
    },
    {
      name: 'authorized',
      type: 'text',
      validation: false,
      languageId: 'AUTHORIZED',
    },
    {
      name: 'tcK_No',
      type: 'number',
      validation: true,
      languageId: 'TCKN',
      props: {
        allowNegative: false,
        thousandSeparator: false,
        decimalScale: 0,
      },
    },
    {
      name: 'authorityUsageType',
      type: 'text',
      validation: false,
      languageId: 'AUTHORITY_USAGE_TYPE',
    },
    {
      name: 'representationType',
      type: 'text',
      validation: false,
      languageId: 'REPRESENTATION_TYPE',
    },
    {
      name: 'tradeRegistryName',
      type: 'text',
      validation: false,
      languageId: 'TRADE_REGISTRY_NAME',
    },
    {
      name: 'taxAdministration',
      type: 'text',
      validation: false,
      languageId: 'TAX_ADMINISTRATION',
    },
  ],
  21: [
    {
      name: 'tradeRegistryNumber',
      type: 'text',
      validation: false,
      languageId: 'tradeRegistrationNumber',
    },
    {
      name: 'tradeBoard',
      type: 'text',
      validation: false,
      languageId: 'TRADE_BOARD',
    },
    {
      name: 'companyName',
      type: 'text',
      validation: false,
      languageId: 'COMPANY_NAME',
    },
    {
      name: 'address',
      type: 'text',
      validation: false,
      languageId: 'ADDRESS',
    },
    {
      name: 'naceCode',
      type: 'number',
      validation: false,
      languageId: 'NACE_CODE',
      props: {
        allowNegative: false,
        thousandSeparator: false,
        decimalScale: 0,
      },
    },
    {
      name: 'capital',
      type: 'number',
      validation: false,
      languageId: 'CAPITAL',
      props: {
        allowNegative: false,
        thousandSeparator: false,
        decimalScale: 0,
      },
    },
    {
      name: 'documentDate',
      type: 'date',
      validation: false,
      languageId: 'DOCUMENT_DATE',
    },
    {
      name: 'registrationDate',
      type: 'date',
      validation: false,
      languageId: 'REGISTRATION_DATE',
    },
    {
      name: 'degree',
      type: 'text',
      validation: false,
      languageId: 'DEGREE',
    },
    {
      name: 'mersisNumber',
      type: 'text',
      validation: false,
      languageId: 'MERSIS_NUMBER',
    },
    {
      name: 'chamberRegistrationNumber',
      type: 'text',
      validation: false,
      languageId: 'CHAMBER_REGISTRATION_NUMBER',
    },
    {
      name: 'chamberRegistrationDate',
      type: 'date',
      validation: false,
      languageId: 'CHAMBER_REGISTRATION_DATE',
    },
    {
      name: 'professionGroup',
      type: 'text',
      validation: false,
      languageId: 'PROFESSION_GROUP',
    },
    {
      name: 'service',
      type: 'text',
      validation: false,
      languageId: 'SERVICE',
    },
  ],
  22: formAttendanceListParams,
};

export const typeEndPoints = {
  18: '/customer/edit/identityCard',
  19: '/customer/edit/taxBoard',
  20: '/customer/edit/authorizedSignatures',
  21: '/customer/edit/operatingCertificate',
  22: '/customer/edit/hazirunCertificate',
};
