import React from 'react';
import { FaSquare } from 'react-icons/fa';
import { Row, Col } from 'reactstrap';
import { Bar, BarChart, Tooltip, XAxis, YAxis } from 'recharts';
import { FormattedMessage } from 'react-intl';
import '../pages/Summarys/energy.scss';

export const ModuleGraficReport = ({
  incomestatement,
  dataAnalyseForCompany,
}) => {
  const [data_1, data_2, data_3, data_4, year1, year2] = dataAnalyseForCompany({
    incomestatement,
  });

  return (
    <div>
      <h4>
        <b>
          <u>
            <FormattedMessage id="SUBTAB_1_2_FINANCIAL_GRAPHS" />
          </u>
        </b>
      </h4>
      <div
        style={{
          justifyContent: 'center',
          textAlign: 'center',
          marginBottom: '1em',
        }}
      >
        <Col>
          <FaSquare style={{ color: '#007FB2' }} />
          <b> {year1}</b>
        </Col>
        <Col>
          <FaSquare style={{ color: '#795D9C' }} /> <b>{year2}</b>
        </Col>
      </div>

      <Row>
        <Col style={{ float: 'left', marginLeft: '1em' }}>
          <Row style={{ marginBottom: '1em', marginLeft: '1em' }}>
            <div
              style={{
                border: '1px solid black',
                borderRadius: '10px',
                textAlign: 'center',
                wordWrap: 'break-word',
                padding: 0,
              }}
            >
              <b>
                <p style={{ color: '#007FB2' }}>
                  {year1}: {new Intl.NumberFormat('tr').format(data_1[0].y1)}
                </p>
                <p style={{ color: 'purple' }}>
                  {year2}: {new Intl.NumberFormat('tr').format(data_1[0].y2)}
                </p>
              </b>
            </div>
          </Row>

          <BarChart
            width={300}
            height={300}
            data={data_1}
            margin={{ left: 60, bottom: 30 }}
          >
            <Tooltip cursor={false} position={{ y: 0, x: 280 }} />

            <XAxis dataKey="date" interval={0} />

            <YAxis
              tickFormatter={(value) =>
                new Intl.NumberFormat('tr').format(value)
              }
            />

            <Bar dataKey="y1" fill="#007FB2" barSize={50} name={year1} />
            <Bar dataKey="y2" fill="#795D9C" barSize={50} name={year2} />
          </BarChart>
        </Col>

        <Col style={{ float: 'right' }}>
          <Row style={{ marginBottom: '1em', marginLeft: '1em' }}>
            <div
              style={{
                border: '1px solid black',
                borderRadius: '10px',
                textAlign: 'center',
                wordWrap: 'break-word',
                padding: 0,
              }}
            >
              <b>
                <p style={{ color: '#007FB2' }}>
                  {year1}: {new Intl.NumberFormat('tr').format(data_2[0].y1)}
                </p>
                <p style={{ color: 'purple' }}>
                  {year2}: {new Intl.NumberFormat('tr').format(data_2[0].y2)}
                </p>
              </b>
            </div>
          </Row>
          <BarChart
            width={300}
            height={300}
            data={data_2}
            margin={{ left: 40, bottom: 50 }}
          >
            <XAxis dataKey="date" interval={0} />

            <YAxis
              tickFormatter={(value) =>
                new Intl.NumberFormat('tr').format(value)
              }
            />
            <Tooltip cursor={false} position={{ y: 0, x: 280 }} />

            <Bar dataKey="y1" fill="#007FB2" barSize={50} name={year1} />
            <Bar dataKey="y2" fill="#795D9C" barSize={50} name={year2} />
          </BarChart>
        </Col>
      </Row>
      <Row>
        <Col style={{ float: 'left' }}>
          <Row style={{ marginBottom: '1em', marginLeft: '1em' }}>
            <div
              style={{
                border: '1px solid black',
                borderRadius: '10px',
                textAlign: 'center',
                wordWrap: 'break-word',
                padding: 0,
              }}
            >
              <b>
                <p style={{ color: '#007FB2' }}>
                  {year1}: {new Intl.NumberFormat('tr').format(data_3[0].y1)}
                </p>
                <p style={{ color: 'purple' }}>
                  {year2}: {new Intl.NumberFormat('tr').format(data_3[0].y2)}
                </p>
              </b>
            </div>
          </Row>
          <BarChart
            width={300}
            height={300}
            data={data_3}
            margin={{ left: 60, bottom: 50 }}
          >
            <XAxis dataKey="date" interval={0} />

            <YAxis
              tickFormatter={(value) =>
                new Intl.NumberFormat('tr').format(value)
              }
            />

            <Tooltip cursor={false} position={{ y: 0, x: 280 }} />

            <Bar dataKey="y1" fill="#007FB2" barSize={50} name={year1} />
            <Bar dataKey="y2" fill="#795D9C" barSize={50} name={year2} />
          </BarChart>
        </Col>
        <Col style={{ float: 'right' }}>
          <Row style={{ marginBottom: '1em', marginLeft: '1em' }}>
            <div
              style={{
                border: '1px solid black',
                borderRadius: '10px',
                textAlign: 'center',
                wordWrap: 'break-word',
                padding: 0,
              }}
            >
              <b>
                <p style={{ color: '#007FB2' }}>
                  {year1}: {new Intl.NumberFormat('tr').format(data_4[0].y1)}
                </p>
                <p style={{ color: 'purple' }}>
                  {year2}: {new Intl.NumberFormat('tr').format(data_4[0].y2)}
                </p>
              </b>
            </div>
          </Row>
          <BarChart
            width={300}
            height={300}
            data={data_4}
            margin={{ left: 40, bottom: 30 }}
          >
            <XAxis dataKey="date" interval={0} />

            <YAxis
              tickFormatter={(value) =>
                new Intl.NumberFormat('tr').format(value)
              }
            />

            <Tooltip cursor={false} position={{ y: 0, x: 280 }} />

            <Bar dataKey="y1" fill="#007FB2" barSize={50} name={year1} />
            <Bar dataKey="y2" fill="#795D9C" barSize={50} name={year2} />
          </BarChart>
        </Col>
      </Row>
    </div>
  );
};

export default ModuleGraficReport;
