import React from 'react';
import { Table, Row } from 'reactstrap';
import { FormattedNumber } from 'react-intl';

export const BoundriesTable = ({ cycleBoundries }) => {
  const headers = ['', 'Lower', 'Upper', 'Score'];
  return (
    <Table striped responsive bordered hover size="sm">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {cycleBoundries.map((boundry) => (
          <tr key={boundry.id}>
            <td>{boundry.title}</td>
            <td>{<FormattedNumber value={boundry.lowerBound} />} </td>
            <td>{<FormattedNumber value={boundry.upperBound} />} </td>
            <td>{<FormattedNumber value={boundry.score} />}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
