import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Input,
  Button,
  Col,
  Row,
  Label,
  Form,
} from 'reactstrap';
import { useFormik } from 'formik';
import { useUser } from '../../Components/UserProvider';
import { FormattedMessage, useIntl } from 'react-intl';
import useFetch from '../../Hooks/useFetch';
import { ShowExpiration } from '../../MenuHeader';
import AddCreditModal from '../User/AddCreditModal';
import { Breadcrumb } from 'react-admin-base-nazox';
import { useAuth } from 'react-admin-base';
import { fireSwal } from '../../Components/SwalFire';
import { Alerts } from '../../Components/Alerts';
import '../../pages/Analysis/pricetable.scss';

export const UserProfile = () => {
  const user = useUser();
  const userId = user && user.id;
  const [api] = useAuth();
  const [company, loading1] = useFetch('/company/get/user/' + userId);
  const [credit, loading2] = useFetch('/credit/get');
  const [checked, setChecked] = useState(false);
  const [modal, setModal] = useState(false);
  const intl = useIntl();

  const toggle = () => setModal(!modal);

  const count = credit && credit.creditAmount;
  const firstName = user?.first_name || '';
  const lastName = user?.last_name || '';
  const email = user?.email || '';
  const id = user?.id;

  const defaultState = {
    first_name: firstName,
    last_name: lastName,
    email: email,
    old_password: '',
    password: '',
    password2: '',
    id: Number(id),
    submit: '',
  };
  const formik = useFormik({
    initialValues: {
      ...defaultState,
    },
    validateOnChange: true,
    validate: (data) => {
      const errors = {};

      if (data.first_name.length < 2) {
        errors.first_name = 'ENTER_NAME';
      }

      if (data.last_name.length < 2) {
        errors.last_name = 'ENTER_SURNAME';
      }

      if (data.email.length === 0) errors.email = 'FILL_EMAIL';
      else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email))
        errors.email = 'VALID_EMAIL';
      if (checked) {
        if (data.old_password.length === 0) errors.old_password = 'NO_PASSWORD';
        if (data.password.length === 0) errors.password = 'NO_PASSWORD';
        if (data.password2.length === 0) errors.password2 = 'NO_PASSWORD';
        if (data.password) {
          if (data.password.length < 8) errors.password = 'PASSWORD_EIGHT';
          else if (!/[A-Z]/.test(data.password) || !/\d/.test(data.password)) {
            errors.password = 'PASSWORD_LEAST';
          }
        }
        if (data.password2) {
          if (data.password2.length < 8) errors.password2 = 'PASSWORD_EIGHT';
          else if (
            !/[A-Z]/.test(data.password2) ||
            !/\d/.test(data.password2)
          ) {
            errors.password2 = 'PASSWORD_LEAST';
          }
        }
        if (data.password2 !== data.password)
          errors.password2 = 'PASSWORD_NOMATCH';
      }
      return errors;
    },
    onSubmit: async (data) => {
      try {
        await api.tokenized.patch('/user/me', {
          ...data,
        });
        fireSwal({
          background: '#90EE90',
          icon: 'success',
          text: intl.formatMessage({ id: 'SAVED' }),
        });
      } catch (e) {
        fireSwal({
          background: '#ffd8df',
          icon: 'error',
          text: e?.response?.data.message || 'Bir Hata Meydana Geldi',
        });
      }
    },
  });
  const getFormErrorMessage = (name) => {
    return (
      formik.touched[name] &&
      formik.errors[name] && (
        <p
          style={{
            color: 'red',
            opacity: 0.6,
            position: 'absolute',
          }}
        >
          {<FormattedMessage id={formik.errors[name]} />}
        </p>
      )
    );
  };
  useEffect(() => {
    if (!checked) {
      formik.setFieldValue('old_password', '');
      formik.setFieldValue('password', '');
      formik.setFieldValue('password2', '');
    }
  }, [checked]);

  return (
    <Breadcrumb
      title={<FormattedMessage id="PROFILE" />}
      data={[
        {
          href: '/profile',
          name: <FormattedMessage id="PROFILE" />,
        },
      ]}
    >
      <Card>
        <CardBody>
          {loading1 || loading2 ? (
            <Alerts
              color="primary"
              className="fas fa-spin fa-spinner"
              messageId={'LOADING'}
              label=""
            />
          ) : (
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col>
                  <Row className="mb-2">
                    <Label for="first_name" style={{ width: '100%' }}>
                      <FormattedMessage id="PROFILE_NAME" />
                      <Input
                        id="first_name"
                        name="first_name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                      />
                      {getFormErrorMessage('first_name')}
                    </Label>
                  </Row>
                  <Row className="mb-2">
                    <Label for="last_name" style={{ width: '100%' }}>
                      <FormattedMessage id="PROFILE_SURNAME" />

                      <Input
                        id="last_name"
                        name="last_name"
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                      />

                      {getFormErrorMessage('last_name')}
                    </Label>
                  </Row>

                  <Row className="mb-2">
                    <Label for="email" style={{ width: '100%' }}>
                      <FormattedMessage id="PROFILE_EMAIL" />
                      <Input
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />

                      {getFormErrorMessage('email')}
                    </Label>
                  </Row>

                  <Row className="mb-2">
                    <Label for="check" style={{ width: '100%' }}>
                      <FormattedMessage id="CHANGE_PASSWORD" />
                      <Input
                        id="check"
                        type="checkbox"
                        name="checked"
                        checked={checked}
                        onChange={(e) => {
                          setChecked((prev) => !prev);
                        }}
                        style={{ marginLeft: '4px' }}
                      />
                    </Label>
                  </Row>
                  {checked && (
                    <>
                      <Row className="mb-2">
                        <Label for="old_password" style={{ width: '100%' }}>
                          <FormattedMessage id="PREVIOUS_PASSWORD" />
                          <Input
                            autoComplete="new-password"
                            type="password"
                            id="old_password"
                            name="old_password"
                            value={formik.values.old_password}
                            onChange={formik.handleChange}
                          />
                          {getFormErrorMessage('old_password')}
                        </Label>
                      </Row>

                      <Row className="mb-2">
                        <Label for="password" style={{ width: '100%' }}>
                          <FormattedMessage id="NEW_PASSWORD" />

                          <Input
                            autoComplete="off"
                            type="password"
                            id="password"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                          />

                          {getFormErrorMessage('password')}
                        </Label>
                      </Row>
                      <Row className="mb-2">
                        <Label for="password2" style={{ width: '100%' }}>
                          <FormattedMessage id="NEW_PASSWORD_AGAIN" />
                          <Input
                            type="password"
                            id="password2"
                            name="password2"
                            value={formik.values.password2}
                            onChange={formik.handleChange}
                          />

                          {getFormErrorMessage('password2')}
                        </Label>
                      </Row>
                    </>
                  )}
                </Col>

                {company && company.paymentType !== 0 && credit && (
                  <Col>
                    <h4 style={{ textAlign: 'center' }}>
                      <FormattedMessage id="REMAINING_RIGHTS" />
                    </h4>
                    {count < 15 && (
                      <div id="loader-container_low">
                        <p id="loadingText">
                          {count !== 0 ? (
                            <ShowExpiration
                              withText={intl.formatMessage({
                                id: 'AVAILABLE_CREDIT',
                              })}
                              credit={credit}
                            />
                          ) : (
                            <p
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              Kontörünüz Bitmiştir.
                            </p>
                          )}
                        </p>
                      </div>
                    )}

                    {count >= 15 && (
                      <div id="loader-container_high">
                        <p id="loadingText">
                          <ShowExpiration
                            withText={intl.formatMessage({
                              id: 'AVAILABLE_CREDIT',
                            })}
                            credit={credit}
                          />
                        </p>
                      </div>
                    )}
                  </Col>
                )}
              </Row>
              {/* {!credit && company && company.paymentType !== 0 && (
                <Row>
                  <Col className="d-flex flex-row-reverse">
                    <Button color="primary" onClick={toggle}>
                      <FormattedMessage id="ADD_CREDIT" />
                    </Button>
                    {modal && <AddCreditModal onClose={toggle} />}
                  </Col>
                </Row>
              )} */}

              <Row className="mb-2 ">
                <Col md="4">
                  <Button
                    type="submit"
                    className="d-block righted"
                    color="primary"
                    loading={formik.isSubmitting ? 'true' : 'false'}
                    disabled={formik.isSubmitting}
                  >
                    <FormattedMessage id="SEND" />{' '}
                    <i className="fas fa-save fa-lg" />
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </CardBody>
      </Card>
    </Breadcrumb>
  );
};
