import React from 'react';
import { Col, Row } from 'reactstrap';
import PieChart, {
  Series,
  Label,
  Connector,
  Size,
  Legend,
  Font,
  Animation,
  Title,
} from 'devextreme-react/pie-chart';

export function PieCharts({ array, type }) {
  var year_1 = -1;
  var year_2 = -1;
  var value_1 = -1;
  var value_2 = -1;

  if (array) {
    year_1 = array[0].year;
    year_2 = array[1].year;
    value_1 = parseInt((array[0].value * 100).toLocaleString().split('.')[0]);
    value_2 = parseInt((array[1].value * 100).toLocaleString().split('.')[0]);
  }

  return (
    <div>
      <Row>
        <Col md="6" style={{ float: 'left' }}>
          <PieChartComponent value={value_1} type={type} year={year_1} />
        </Col>
        <Col md="6" style={{ float: 'right' }}>
          <PieChartComponent value={value_2} type={type} year={year_2} />
        </Col>
      </Row>
    </div>
  );
}

export function PieChartComponent({ value, type, year }) {
  const dataSource = [
    {
      label: 'Faaliyet Giderleri',
      value: value,
    },
    {
      label: 'Diğer Giderler',
      value: 100 - value,
    },
  ];

  return (
    <div>
      {type !== 'print' ? (
        <>
          <h5 style={{ textAlign: 'center' }}>{year}</h5>
          <PieChart
            id="pie"
            dataSource={dataSource}
            // title="Area of Countries"
            sizeGroup="piesGroup"
            customizePoint={function (e) {
              if (e.argument == 'Faaliyet Giderleri') {
                return {
                  color: '#F18733',
                };
              } else {
                return {
                  color: '#822019',
                };
              }
            }}
          >
            <Animation enabled={false} />
            <Size height={200} width={400} />
            <Legend
              visible={false}
              orientation="horizontal"
              itemTextPosition="right"
              horizontalAlignment="center"
              verticalAlignment="bottom"
              columnCount={4}
            />
            <Series argumentField="label" valueField="value">
              <Label visible={true} position="columns">
                <Font size={16} />
                <Connector visible={true} width={0.5} />
              </Label>
            </Series>
          </PieChart>
        </>
      ) : (
        <>
          {/* <h5 style={{ textAlign: "center" }}>{year}</h5> */}
          <PieChart
            id="pie"
            dataSource={dataSource}
            // title="Area of Countries"
            customizePoint={function (e) {
              if (e.argument == 'Faaliyet Giderleri') {
                return {
                  color: '#F18733',
                };
              } else {
                return {
                  color: '#822019',
                };
              }
            }}
          >
            <Animation enabled={false} />
            <Size height={150} width={300} />
            <Legend
              visible={true}
              orientation="horizontal"
              itemTextPosition="right"
              horizontalAlignment="center"
              verticalAlignment="bottom"
              columnCount={2}
            />
            <Title text={year}>
              <Font color="black" size={15} />
            </Title>
            <Series argumentField="label" valueField="value">
              <Label visible={true} position="columns">
                <Font size={10} />
                <Connector visible={true} width={0.5} />
              </Label>
            </Series>
          </PieChart>
        </>
      )}
    </div>
  );
}
