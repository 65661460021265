import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { BootstrapDataTable, Column } from 'react-admin-base-bootstrap';
import { FormattedMessage } from 'react-intl';

export const DeficitBalance = ({ id }) => {
  const params = useParams();

  const headerMap = ['ACCOUNT_CODE', 'ACCOUNT_NAME', 'DEBT', 'REVENUE', 'YEAR'];
  return (
    <Card>
      <CardBody>
        <BootstrapDataTable
          noStrip
          url={`/analyze/deficitBalance/${id}`}
          defaultParams={params}
        >
          <thead>
            <tr>
              {headerMap.map((e, i) => (
                <Column key={i}>
                  <FormattedMessage id={e} />
                </Column>
              ))}
            </tr>
          </thead>
          <tbody>
            {
              // @ts-ignore
              (row) => (
                <tr key={row.id}>
                  <td>{row.accountCode}</td>
                  <td>{row.accountName}</td>
                  <td>
                    {new Intl.NumberFormat('tr-TR', {}).format(
                      Number(row.debt)
                    )}
                  </td>
                  <td>
                    {' '}
                    {new Intl.NumberFormat('tr-TR', {}).format(
                      Number(row.revenue)
                    )}
                  </td>
                  <td>{row.year}</td>
                </tr>
              )
            }
          </tbody>
        </BootstrapDataTable>
      </CardBody>
    </Card>
  );
};
