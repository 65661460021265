import React from 'react';
import { Table } from 'reactstrap';
import { FormattedNumber } from 'react-intl';

export const ScoringTable = ({ cycleScorings }) => {
  const headers = [
    'Days of Cash Conversion Cycle / Loan Duration',
    'Date Value',
    'Score',
  ];

  const zeroValueTitles = [
    'Loan duration in days',
    'Days of cash conversion cycle',
  ];
  return (
    <Table striped responsive bordered hover size="sm">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {cycleScorings.map((boundry, index) => (
          <tr key={index}>
            <td>{boundry.title}</td>
            <td>{<FormattedNumber value={boundry.value} />}</td>
            <td>
              {zeroValueTitles.includes(boundry.title) ? (
                ''
              ) : (
                <FormattedNumber value={boundry.score} />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
