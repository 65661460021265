import React from 'react';
import { useIntl } from 'react-intl';
import { Label } from 'reactstrap';
import Swal from 'sweetalert2';

export const PrevAnalysesInfo = (props) => {
  const { filteredData, handleChangeDocuments, existingFile } = props;
  const intl = useIntl();

  const handleClick = async (shouldUpdate) => {
    if (shouldUpdate) {
      const result = await Swal.fire({
        text: intl.formatMessage({ id: 'ACTIONS.CHANGE.FINANCIAL' }),
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: intl.formatMessage({ id: 'YES_AGREE' }),
        cancelButtonText: intl.formatMessage({ id: 'CANCEL' }),
      });

      if (result.isConfirmed) {
        handleChangeDocuments(false);
      }
    } else {
      handleChangeDocuments(filteredData);
    }
  };

  return (
    filteredData && (
      <>
        <hr />
        <Label className="m-0">
          Seçtiğiniz yıla ait{' '}
          <a
            onClick={() =>
              window.open(
                `/report/${window.btoa(filteredData.documentId)}/summary`,
                '_blank',
                'noreferrer'
              )
            }
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            <u>finansal veri</u>
          </a>{' '}
          bulunmaktadır.
        </Label>
        {existingFile ? (
          <Label className="m-0">
            Farklı bir finansal yüklemek için{' '}
            <span
              onClick={() => handleClick(true)}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              tıklayınız.
            </span>
          </Label>
        ) : (
          <Label className="m-0">
            Mevcut finansal veriyi yüklemek için{' '}
            <span
              onClick={() => handleClick(false)}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              tıklayınız.
            </span>
          </Label>
        )}
      </>
    )
  );
};

export const findExistingAnalyses = (data, date, providerType, index) => {
  if (!data || !date || !providerType) return null;
  const splittedDate = date.split('-');

  const filteredData = data.filter(
    (d) =>
      d.statementYear === Number(splittedDate[0]) &&
      d.statementMonth === Number(splittedDate[1]) &&
      d.providerType === Number(providerType)
  )[0];

  return filteredData ? filteredData : null;
};
