import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './theme/index.scss';
import { PrimeReactProvider } from 'primereact/api';
import { twMerge } from 'tailwind-merge';
import classNames from 'classnames';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter basename={process.env.REACT_APP_PATHBASE || undefined}>
    <PrimeReactProvider
      value={{
        // ripple: true,
        pt: {
          datatable: {
            root: ({ props }) => ({
              style: {
                position: 'relative',
                ...(props.scrollable &&
                  props.scrollHeight && {
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }),
              },
            }),
            loadingOverlay: {
              style: {
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                opacity: 0.5,
                transition: 'all 0.2s',
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 2,
              },
            },
            header: {
              style: {
                backgroundColor: 'var(--bs-gray-200)',
                padding: '0.5rem',
              },
            },

            loadingIcon: {
              style: {
                width: '2.5rem',
                height: '2.5rem',
                color: '#5664d2',
              },
            },
            wrapper: () => ({
              style: {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                height: '100%',
              },
            }),
            table: {
              style: {
                width: '100%',
                borderSpacing: 0,
              },
            },
            tbody: ({ props, context }) => ({
              style: {
                ...(props.frozenRow &&
                  context.scrollable && {
                    position: 'sticky',
                    zIndex: 1,
                  }),
              },
            }),
            column: {
              headerCell: ({ context, props }) => ({
                style: {
                  textAlign: 'center',
                  backgroundColor: 'var(--bs-gray-200)',
                  height: '3rem',
                  padding: '0.5rem',
                  transition: 'all 0.2s',
                  ...(props.frozen ||
                    (props.frozen === '' && {
                      position: 'sticky',
                      zIndex: 1,
                    })),
                  ...(context.resizable && {
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    borderTop: '1px solid',
                    borderBottom: '1px solid',
                    position: 'relative',
                    backgroundClip: 'padding-box',
                  }),
                },
                className: props.sortable ? 'custom-header-cell' : '',
              }),
              headerContent: {
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                },
              },
              bodyCell: ({ props }) => ({
                style: {
                  textAlign: 'left',
                  padding: '0.5rem',
                  gap: '10px',
                  ...(props &&
                    (props.frozen || props.frozen === '') && {
                      position: 'sticky',
                      backgroundColor: 'inherit',
                    }),
                },
              }),

              rowgrouptoggler: {
                style: {
                  position: 'absolute',
                },
              },
              rowgroupheadername: {
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                  marginLeft: '1rem',
                },
              },

              sortIcon: () => ({
                style: {
                  marginLeft: '0.5rem',
                },
              }),
              columnResizer: {
                style: {
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  margin: 0,
                  width: '0.5rem',
                  height: '100%',
                  padding: 0,
                  cursor: 'col-resize',
                  border: '1px solid transparent',
                },
              },
              // headerTitle: {
              //   style: {
              //     fontSize: '12px',
              //   },
              // },
            },
            paginator: {
              root: {
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                  padding: '0.5rem',
                  justifyContent: 'center',
                  backgroundColor: 'var(--bs-gray-200)',
                },
              },
              // current: {
              //   style: {
              //     marginLeft: '0.75rem',
              //     justifySelf: 'end',
              //   },
              // },
              pages: {
                style: {
                  display: 'flex',
                  gap: '0.5rem',
                },
              },
              pageButton: ({ context }) => ({
                style: {
                  backgroundColor: 'white',
                  padding: '0.25rem 0.75rem',
                  borderRadius: '0.5rem',
                  ...(context.active && {
                    backgroundColor: '#5664d2',
                    color: 'white',
                  }),
                },
              }),
              current: {
                className: 'custom-current-page-button',
              },

              firstpagebutton: {
                className: 'custom-prev-next-next-page-button',
              },
              lastpagebutton: {
                className: 'custom-prev-next-next-page-button',
              },
              prevpagebutton: {
                className: 'custom-prev-next-next-page-button',
              },
              nextpagebutton: {
                className: 'custom-prev-next-next-page-button',
              },
            },
            bodyRow: (props) => {
              return {
                style: {
                  height: '2rem',
                  borderBottomWidth: '1px',
                  padding: '5rem 0',
                  cursor: props?.context.selectable ? 'pointer' : 'default',
                },
                className: props?.context.selected
                  ? 'custom-body-row-selected'
                  : 'custom-body-row',
              };
            },
            footer: {
              style: {
                display: 'flex',
                justifyContent: 'end',
              },
            },
          },
          progressspinner: {
            root: {
              className: classNames(
                'relative mx-auto w-28 h-28 inline-block',
                'before:block before:pt-full'
              ),
            },
            spinner:
              'absolute top-0 bottom-0 left-0 right-0 m-auto w-full h-full transform origin-center animate-spin',
            circle: 'text-red-500 progress-spinner-circle',
          },
          dropdown: {
            root: {
              className: 'custom-dropdown-root',
            },
            wrapper: {
              style: {
                margin: 0,
                borderRadius: '0.5rem',
                backgroundColor: 'white',
                justifyContent: 'center',
              },
            },
            list: {
              style: {
                borderRadius: '0.5rem',
                display: 'flex',
                flexDirection: 'column',
                gap: '0.2rem',
                margin: 0,
                padding: 0,
              },
            },

            item: {
              style: {
                backgroundColor: 'white',
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0.2rem 0.4rem',
              },
              className: 'custom-dropdown-item',
            },
            // trigger: {
            //   style: {
            //     marginLeft: '0.5rem', // 'ml-2'
            //   },
            // },
            // wrapper: {
            //   style: {
            //     marginTop: '0.5rem', // 'mt-2'
            //   },
            // },
          },
        },
        ptOptions: {
          mergeSections: true,
          mergeProps: true,
          classNameMergeFunction: twMerge,
        },
        unstyled: true,
      }}
    >
      <App />
    </PrimeReactProvider>
  </BrowserRouter>
);

// if (process.env.REACT_APP_ENVIRONMENT === "development") {
//   // @ts-ignore
//   const targetNode = document.getElementById("webpack-dev-server-client-overlay-div");

//   // Options for the observer (which mutations to observe)
//   const config = { attributes: true, childList: true, subtree: true };

//   // Callback function to execute when mutations are observed
//   const callback = (mutationList, observer) => {
//     for (const mutation of mutationList) {
//       if (mutation.type === "childList") {
//         console.log("A child node has been added or removed.");
//       } else if (mutation.type === "attributes") {
//         console.log(`The ${mutation.attributeName} attribute was modified.`);
//       }
//     }
//   };

//   // Create an observer instance linked to the callback function
//   const observer = new MutationObserver(callback);

//   // Start observing the target node for configured mutations
//   observer.observe(targetNode, config);

// }
