import React from 'react';
import { Table } from 'reactstrap';
import { findColor, isText, orcSecData } from '../../Util/module_altman';
import { formatNumber } from '../../Util/formatNumber';

export const ModuleAltmanTable = ({ data, y1, y2 }) => {
  return (
    <Table striped responsive bordered hover size="sm">
      <thead>
        <tr>
          <th>Altman`s Z&apos; Scoring Model</th>
          <th className="text-center">{y1}</th>
          <th className="text-center">{y2}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((val, i) => {
          return (
            <tr key={i}>
              <td>{val.title}</td>

              <td
                className="text-center"
                style={{
                  color: findColor(val.values[0].value),
                }}
              >
                {isText(val.values[0].value)
                  ? val.values[0].value
                  : formatNumber(val.values[0].value)}
              </td>

              <td
                className="text-center"
                style={{
                  color: findColor(val.values[1].value),
                }}
              >
                {isText(val.values[1].value)
                  ? val.values[1].value
                  : formatNumber(val.values[1].value)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
