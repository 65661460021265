// @ts-ignore
import React, { useCallback } from 'react';
import { UploadProvider, useAuth } from 'react-admin-base';

function abortToAxiosAbort() {
  return null;
  /*
    var cancelToken = Axios.CancelToken.source();
    abort.onabort = function() {
      
        cancelToken.cancel();
    };
    return cancelToken.token;
     */
}

export default function UploadConfig({ children }) {
  const [api] = useAuth();

  const uploader = useCallback(
    async function (name, blob, contentType, abort, progress) {
      abort = abortToAxiosAbort();

      try {
        var data = await api.tokenized.put(
          '/file/' + encodeURIComponent(name),
          blob,
          {
            headers: { 'Content-Type': contentType },
            onUploadProgress: function (progressEvent) {
              progress(progressEvent.loaded / progressEvent.total);
            },
            cancelToken: abort,
          }
        );

        let file = data.data;

        file.access_url =
          process.env.REACT_APP_ENDPOINT?.replace(/\/$/, '') +
          data.data.access_url;

        return file;
      } catch (e) {
        //  if (!Axios.isCancel(e)) {
        //     console.error('Hata olustu, tekrar deneniyor..', e);
        //} else {

        // }
        console.log(e);

        throw e;
      }
    },
    [api]
  );

  return <UploadProvider uploader={uploader}>{children}</UploadProvider>;
}
