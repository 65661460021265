import React, { useEffect, useState, useCallback } from 'react';
import { Breadcrumb } from 'react-admin-base-nazox';
import { FormattedMessage } from 'react-intl';
import { useParams, Navigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Accordion,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Table,
  Form,
} from 'reactstrap';
import useFetch from '../../Hooks/useFetch';
import { Alerts } from '../../Components/Alerts';
import { CompanyBalance } from './AnalysisEdit';
import NumberFormat from 'react-number-format';
import { MultiDelayedB } from './AnalysisTaemeed';
import { CustomForm } from './AnalysisEntity';
import { useAuth } from 'react-admin-base';
import numericFormatter from 'react-number-format';
const a1Options = [
  'More than 10 years',
  'Between 8 to 10 years',
  '7 years',
  '6 years',
  '5 years',
  'Between 3 years to 4 years',
  'Between 2.5 years to 3 years',
  'Between 2 years to 2.5 years',
  'Between 1.5 year to 2 years',
  'Between 1 year to 1.5 year',
  'Less than a year',
];

const a2Options = [
  'More than 60',
  '46 to 60',
  '36 to 45',
  '26 to 30',
  '21 to 25',
  '16 to 20',
  '11 to 15',
  '8 to 10',
  '6 to 7',
  '4 to 5',
  '1 to 3',
  '0',
];
const a3Options = [
  'More than 60',
  '46 to 60',
  '36 to 45',
  '26 to 30',
  '21 to 25',
  '16 to 20',
  '11 to 15',
  '8 to 10',
  '6 to 7',
  '4 to 5',
  '1 to 3',
  '0',
];

const a4Options = { 1: 'Type 1', 2: 'Type 2', 3: 'Type 3' };
const a5Options = [
  'No default',
  '5 years',
  '4 years',
  '3 years',
  '1 to 3 years',
  'Less than 1 year',
];

const a6Options = [
  'Less than 3%',
  '3% to 5%',
  '5% to 9%',
  '9% to 15%',
  '15% to 20%',
  '20% to 25%',
  '25% to 33%',
  '33% to 45%',
  '45% to 55%',
  'over 55%',
];

const a7Options = [
  'More than 70',
  '56 to 70',
  '45 to 55',
  '35 to 45',
  '30 to 34',
  '25 to 29',
  '20 to 24',
  '15 to 19',
  '10 to 14',
  '5 to 9',
  'Less than 5',
];

const a8Options = [
  'More than 25 years',
  '21 to 25 years',
  '18 to 20 years',
  '16 to 17 years',
  '13 to 15 years',
  '11 to 12years',
  '9 to 10 years',
  '7 to 8 year',
  '5 to 6 years',
  '4 years',
  '3 years',
  'Less than 3',
];

const a9Options = [
  'Less than 5%',
  '5% to 10%',
  '10% to 15%',
  '15% to 20%',
  '20% to 25%',
  '25% to 30%',
  '30% to 33%',
  '33% to 37%',
  '37% to 40%',
  '40% to 45%',
  '45% to 55%',
  '55% to 60%',
  'More than 60%',
];

const a10Options = [
  'No default',
  'Less than 3%',
  '3% to 7%',
  '7% to 12%',
  '12% to 15%',
  '15% to 20%',
  '20% to 30%',
  'More than 30%',
];

const b1Options = [
  'More than 10',
  '8 to 10',
  '6 to 7',
  '3 to 5',
  '2',
  'Less than 2',
];

const b2Options = [0, 1, 2, 3, 4, 5, '6 or more'];

const bankStatemntHeaders = [
  'Total Debit Amount',
  'Number of Deposits',
  'Average Deposits Ratio',
];

export const BnplEdit = () => {
  const { id } = useParams();
  const _id = id && window.atob(id);
  const [API] = useAuth();

  const [questionnarie, questionnarieLoading, questionnarieError] = useFetch(
    `bnpl/questionnarie/${_id}`
  );
  const [companyBalance, companyBalanceLoading, companyBalanceError] = useFetch(
    `/tables/statement/edit/${_id}`
  );
  const [bankStatement, bankStatementLoading, bankStatementError] = useFetch(
    `/bnpl/bankStatement/edit/${_id}`
  );
  const [open, setOpen] = useState('1');
  const [qualitative, setQualitative] = useState([]);
  const [penalty, setPenalty] = useState({});
  const [history, setHistory] = useState({});
  const [companyBalanceData, setCompanyBalanceData] = useState({});
  const [bankStatementData, setBankStatementData] = useState({});
  const [delayed, setDelayed] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [done, setDone] = useState(false);

  const toggle = (id) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  useEffect(() => {
    if (companyBalance) {
      setCompanyBalanceData([...companyBalance]);
    }
  }, [companyBalance]);

  useEffect(() => {
    if (bankStatement) {
      setBankStatementData({
        totalDebitAmount: Number(bankStatement.totalDebitAmount),
        numberOfDeposits: Number(bankStatement.numberOfDeposits),
        totalDebitAmount_NumberOfDepositsRatio: Number(
          bankStatement.totalDebitAmount_NumberOfDepositsRatio
        ),
      });
    }
  }, [bankStatement]);

  useEffect(() => {
    if (questionnarie) {
      const { qualitative, penalty, history } = questionnarie;
      setQualitative({
        ...qualitative,
        registrationDate: qualitative.registrationDate
          ? qualitative.registrationDate.split('T')[0]
          : '',
        defaultSettledDate: qualitative.defaultSettledDate
          ? qualitative.defaultSettledDate.split('T')[0]
          : '',
        issueDate: qualitative.issueDate
          ? qualitative.issueDate.split('T')[0]
          : '',
      });
      setPenalty({
        numberOfRejectedCheques: penalty.numberOfRejectedCheques,
        numberOfSettledLitigations: penalty.numberOfSettledLitigations,
      });
      setHistory({
        ...history,
        ownerBirthDate: history.ownerBirthDate
          ? history.ownerBirthDate.split('T')[0]
          : '',
      });
      const delayed = penalty.numberOfDelayedPaymentsOnLoans.filter(
        (e) => e.days === 'No Delay' || e.number !== '0'
      );
      const rejected = penalty.numberOfDelayedPaymentsOnCreditCards.filter(
        (e) => e.days === 'No Delay' || e.number !== '0'
      );
      setDelayed([...delayed]);
      setRejected([...rejected]);
    }
  }, [questionnarie]);

  const handleHistoryChange = useCallback((e) => {
    const { name, value } = e.target;
    setHistory((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleQualitativeChange = useCallback((e) => {
    const { name, value } = e.target;
    setQualitative((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handlePenaltyChange = useCallback((e) => {
    const { name, value } = e.target;
    setPenalty((prev) => ({ ...prev, [name]: value }));
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    await API.tokenized.post(`/bnpl/questionnarie/edit/${_id}`, {
      qualitative: {
        ...qualitative,
        registrationDate: qualitative.registrationDate || null,
        defaultSettledDate: qualitative.defaultSettledDate || null,
        issueDate: qualitative.issueDate || null,
        merchantType: qualitative.merchantType,
      },
      penalty: {
        ...penalty,
        numberOfDelayedPaymentsOnLoans: delayed,
        numberOfDelayedPaymentsOnCreditCards: rejected,
      },
      history: {
        ...history,
        ownerBirthDate: history.ownerBirthDate || null,
      },
    });

    await API.tokenized.post(
      `/tables/statement/edit/${_id}`,
      companyBalanceData
    );

    await API.tokenized.post(`/bnpl/bankStatement/edit/${_id}`, {
      ...bankStatementData,
    });

    setDone(true);
  };

  if (done) {
    return <Navigate to={'/report/' + window.btoa(_id) + '/summary'} />;
  }
  return (
    <Breadcrumb
      title={<FormattedMessage id="EDIT" />}
      data={[
        {
          href: '/analyzes',
          name: <FormattedMessage id="PREVIOUS_ANALYZES_TITLE" />,
        },
        {
          href: `/analysis/${_id}/edit`,
          name: <FormattedMessage id="EDIT" />,
        },
      ]}
    >
      <CustomForm onSubmit={onSubmit}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                {!questionnarieLoading && !bankStatementLoading ? (
                  !questionnarieError && !bankStatementError ? (
                    <Accordion open={open} toggle={toggle} flush>
                      <AccordionItem>
                        <AccordionHeader targetId="1">
                          <h6>A. Qualitative Analysis Questions</h6>
                        </AccordionHeader>
                        <AccordionBody accordionId="1">
                          <FormGroup>
                            <Label>A.1. Relationship with Merchant</Label>
                            <Input
                              type="date"
                              value={qualitative.registrationDate}
                              name="registrationDate"
                              onChange={handleQualitativeChange}
                            ></Input>
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              A.2. Number of Total Orders with the Merchant
                            </Label>
                            <NumberFormat
                              allowNegative={false}
                              className="form-control"
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              value={qualitative.totalOrders}
                              onValueChange={(values) => {
                                const { formattedValue, value, floatValue } =
                                  values;
                                setQualitative((prev) => ({
                                  ...prev,
                                  totalOrders:
                                    Number(value) < 2147483647
                                      ? floatValue
                                      : qualitative.totalOrders,
                                }));
                              }}
                              decimalScale={0}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              A.3. Number of Orders On Credit with the Merchant
                            </Label>
                            <NumberFormat
                              allowNegative={false}
                              className="form-control"
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              value={qualitative.totalCreditOrders}
                              onValueChange={(values) => {
                                const { formattedValue, value, floatValue } =
                                  values;
                                setQualitative((prev) => ({
                                  ...prev,
                                  totalCreditOrders:
                                    Number(value) < 2147483647
                                      ? floatValue
                                      : qualitative.totalCreditOrders,
                                }));
                              }}
                              decimalScale={0}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              A.4. Merchant Business Model Evaluation
                            </Label>
                            <Input
                              type="select"
                              value={qualitative.merchantType}
                              name="merchantType"
                              onChange={handleQualitativeChange}
                            >
                              <option value="">
                                <FormattedMessage id="SELECT" />
                              </option>
                              {Object.entries(a4Options).map(([key, value]) => (
                                <option value={key} key={key}>
                                  {value}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              A.5. Duration since Last Settled Default Debt
                            </Label>
                            <Input
                              type="date"
                              value={qualitative.defaultSettledDate}
                              name="defaultSettledDate"
                              onChange={handleQualitativeChange}
                            ></Input>
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              A.6. Outstanding Loans to Total Deposits
                            </Label>
                            <Row>
                              <Col>
                                <Label>Loan Outstanding</Label>
                                <NumberFormat
                                  allowNegative={false}
                                  className="form-control"
                                  thousandSeparator={'.'}
                                  decimalSeparator={','}
                                  value={qualitative.loanOutstanding}
                                  decimalScale={2}
                                  onValueChange={(values) => {
                                    const {
                                      formattedValue,
                                      value,
                                      floatValue,
                                    } = values;
                                    setQualitative((prev) => ({
                                      ...prev,
                                      loanOutstanding:
                                        Number(value) < 2147483647
                                          ? floatValue
                                          : qualitative.loanOutstanding,
                                    }));
                                    setBankStatementData((prev) => ({
                                      ...prev,
                                      loanOutstanding:
                                        Number(value) < 2147483647
                                          ? floatValue
                                          : bankStatementData.loanOutstanding,
                                    }));
                                  }}
                                />
                              </Col>
                              <Col>
                                <Label>Total Debit Amount</Label>
                                <NumberFormat
                                  allowNegative={false}
                                  className="form-control"
                                  thousandSeparator={'.'}
                                  decimalSeparator={','}
                                  value={bankStatementData.totalDebitAmount}
                                  decimalScale={2}
                                  onValueChange={(values) => {
                                    const {
                                      formattedValue,
                                      value,
                                      floatValue,
                                    } = values;
                                    setBankStatementData((prev) => ({
                                      ...prev,
                                      totalDebitAmount:
                                        Number(value) < 2147483647
                                          ? floatValue
                                          : bankStatementData.totalDebitAmount,
                                    }));
                                  }}
                                />
                              </Col>
                              <Col>
                                <Label>Number of Deposits</Label>
                                <NumberFormat
                                  allowNegative={false}
                                  className="form-control"
                                  thousandSeparator={'.'}
                                  decimalSeparator={','}
                                  value={bankStatementData.numberOfDeposits}
                                  decimalScale={2}
                                  onValueChange={(values) => {
                                    const {
                                      formattedValue,
                                      value,
                                      floatValue,
                                    } = values;
                                    setBankStatementData((prev) => ({
                                      ...prev,
                                      numberOfDeposits:
                                        Number(value) < 2147483647
                                          ? floatValue
                                          : bankStatementData.numberOfDeposits,
                                    }));
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Label>A.7. Number of Employees</Label>
                            <NumberFormat
                              allowNegative={false}
                              className="form-control"
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              value={qualitative.employeesNumber}
                              name="employeesNumber"
                              decimalScale={0}
                              onValueChange={(values) => {
                                const { formattedValue, value, floatValue } =
                                  values;
                                setQualitative((prev) => ({
                                  ...prev,
                                  employeesNumber:
                                    Number(value) < 2147483647
                                      ? floatValue
                                      : qualitative.employeesNumber,
                                }));
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>A.8. Company Age</Label>
                            <Input
                              type="date"
                              value={qualitative.issueDate}
                              name="issueDate"
                              onChange={handleQualitativeChange}
                            ></Input>
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              A.9. Requested Loan / Average Deposits for the
                              Same Loan Duration
                            </Label>
                            <Row>
                              <Col>
                                <Label>Total Price</Label>
                                <NumberFormat
                                  allowNegative={false}
                                  className="form-control"
                                  thousandSeparator={'.'}
                                  decimalSeparator={','}
                                  value={qualitative.totalPrice}
                                  decimalScale={0}
                                  onValueChange={(values) => {
                                    const { value, floatValue } = values;
                                    setQualitative((prev) => ({
                                      ...prev,
                                      totalPrice:
                                        Number(value) < 2147483647
                                          ? floatValue
                                          : qualitative.totalPrice,
                                    }));
                                  }}
                                />
                              </Col>
                              <Col>
                                <Label>Loan Duration</Label>
                                <NumberFormat
                                  allowNegative={false}
                                  className="form-control"
                                  thousandSeparator={'.'}
                                  decimalSeparator={','}
                                  value={qualitative.loanDuration}
                                  onChange={(e) => {
                                    handleHistoryChange(e);
                                    handleQualitativeChange(e);
                                  }}
                                  onValueChange={(values) => {
                                    const { value, floatValue } = values;
                                    setQualitative((prev) => ({
                                      ...prev,
                                      loanDuration:
                                        Number(value) < 2147483647
                                          ? floatValue
                                          : qualitative.loanDuration,
                                    }));
                                    setHistory((prev) => ({
                                      ...prev,
                                      loanDuration:
                                        Number(value) < 2147483647
                                          ? floatValue
                                          : history.loanDuration,
                                    }));
                                  }}
                                  decimalScale={0}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <Label>
                            A.10. Total Settled Defaults on Loan / Outstanding
                            Loan
                          </Label>
                          <Row>
                            <Col>
                              <Label>Default Settled Amount</Label>
                              <NumberFormat
                                allowNegative={false}
                                className="form-control"
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                value={qualitative.defaultSettledAmount}
                                decimalScale={2}
                                onValueChange={(values) => {
                                  const { formattedValue, value, floatValue } =
                                    values;
                                  setQualitative((prev) => ({
                                    ...prev,
                                    defaultSettledAmount:
                                      Number(value) < 2147483647
                                        ? floatValue
                                        : qualitative.defaultSettledAmount,
                                  }));
                                }}
                              />
                            </Col>
                            <Col>
                              <Label>Loan Outstanding</Label>
                              <NumberFormat
                                allowNegative={false}
                                className="form-control"
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                value={bankStatementData.loanOutstanding}
                                decimalScale={2}
                                onValueChange={(values) => {
                                  const { formattedValue, value, floatValue } =
                                    values;
                                  setQualitative((prev) => ({
                                    ...prev,
                                    loanOutstanding:
                                      Number(value) < 2147483647
                                        ? floatValue
                                        : qualitative.loanOutstanding,
                                  }));
                                  setBankStatementData((prev) => ({
                                    ...prev,
                                    loanOutstanding:
                                      Number(value) < 2147483647
                                        ? floatValue
                                        : bankStatementData.loanOutstanding,
                                  }));
                                }}
                              />
                            </Col>
                          </Row>
                        </AccordionBody>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionHeader targetId="2">
                          <h6>B. Penalty Questions</h6>
                        </AccordionHeader>
                        <AccordionBody accordionId="2">
                          <FormGroup>
                            <Label>B.1. Number of Rejected Cheques</Label>
                            <NumberFormat
                              allowNegative={false}
                              className="form-control"
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              value={penalty.numberOfRejectedCheques}
                              decimalScale={0}
                              onValueChange={(values) => {
                                const { value, floatValue } = values;
                                setPenalty((prev) => ({
                                  ...prev,
                                  numberOfRejectedCheques:
                                    Number(value) < 2147483647
                                      ? value
                                      : penalty.numberOfRejectedCheques,
                                }));
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>B.2. Number of Settled Litigations</Label>

                            <NumberFormat
                              allowNegative={false}
                              className="form-control"
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              value={penalty.numberOfSettledLitigations}
                              name="numberOfSettledLitigations"
                              decimalScale={0}
                              onValueChange={(values) => {
                                const { value, floatValue } = values;
                                setPenalty((prev) => ({
                                  ...prev,
                                  numberOfSettledLitigations:
                                    Number(value) < 2147483647
                                      ? floatValue
                                      : qualitative.numberOfSettledLitigations,
                                }));
                              }}
                            />
                          </FormGroup>
                          <MultiDelayedB
                            title="B.3. Number of Delayed Payments on Loans"
                            value={delayed}
                            onChange={setDelayed}
                          />
                          <MultiDelayedB
                            title="B.4. Number of Delayed Payments on Credit Cards"
                            value={rejected}
                            onChange={setRejected}
                          />
                        </AccordionBody>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionHeader targetId="3">
                          <h6>C. Observation Questions (Not for Scoring)</h6>
                        </AccordionHeader>
                        <AccordionBody accordionId="3">
                          <FormGroup>
                            <Row>
                              <Label>C.1.1. Loan Duration (in days)</Label>
                            </Row>
                            <NumberFormat
                              className="form-control"
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              desimalScale={0}
                              value={history.loanDuration}
                              onValueChange={(values) => {
                                const { value, floatValue } = values;
                                setQualitative((prev) => ({
                                  ...prev,
                                  loanDuration:
                                    Number(value) < 2147483647
                                      ? floatValue
                                      : qualitative.loanDuration,
                                }));
                                setHistory((prev) => ({
                                  ...prev,
                                  loanDuration:
                                    Number(value) < 2147483647
                                      ? floatValue
                                      : history.loanDuration,
                                }));
                              }}
                              allowNegative={false}
                              decimalScale={0}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Label>
                                C.1.2. Is There Current Outstanding Fund
                                Payables Due to The Merchant?
                              </Label>
                            </Row>
                            <Row>
                              <Col md="2">
                                <Label for="isThereCurrentOutstanding_1">
                                  True
                                </Label>
                                <Input
                                  id="isThereCurrentOutstanding_1"
                                  name="payableMerchant"
                                  type="checkbox"
                                  value="True"
                                  checked={history.payableMerchant === 'True'}
                                  style={{ marginLeft: '0.5rem' }}
                                  onChange={handleHistoryChange}
                                />
                              </Col>
                              <Col md="2">
                                <Label for="isThereCurrentOutstanding_2">
                                  False
                                </Label>
                                <Input
                                  id="isThereCurrentOutstanding_2"
                                  type="checkbox"
                                  name="payableMerchant"
                                  value="False"
                                  checked={history.payableMerchant === 'False'}
                                  style={{ marginLeft: '0.5rem' }}
                                  onChange={handleHistoryChange}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Label>
                                C.1.3. Is There an Outstanding Default?
                              </Label>
                            </Row>
                            <Row>
                              <Col md="2">
                                <Label for="anyDefaultOnOutstanding_1">
                                  Yes
                                </Label>
                                <Input
                                  id="anyDefaultOnOutstanding_1"
                                  name="defaultOutstanding"
                                  type="checkbox"
                                  value="Yes"
                                  checked={history.defaultOutstanding === 'Yes'}
                                  style={{ marginLeft: '0.5rem' }}
                                  onChange={handleHistoryChange}
                                />
                              </Col>
                              <Col md="2">
                                <Label for="anyDefaultOnOutstanding_2">
                                  No
                                </Label>
                                <Input
                                  id="anyDefaultOnOutstanding_2"
                                  type="checkbox"
                                  name="defaultOutstanding"
                                  value="No"
                                  checked={history.defaultOutstanding === 'No'}
                                  style={{ marginLeft: '0.5rem' }}
                                  onChange={handleHistoryChange}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Label>
                                C.1.4. Is There an Unsettled Litigation?
                              </Label>
                            </Row>
                            <Row>
                              <Col md="2">
                                <Label for="anyUnsettledLitigation_1">
                                  Yes
                                </Label>
                                <Input
                                  id="anyUnsettledLitigation_1"
                                  name="litigationUnsettled"
                                  type="checkbox"
                                  value="Yes"
                                  checked={
                                    history.litigationUnsettled === 'Yes'
                                  }
                                  style={{ marginLeft: '0.5rem' }}
                                  onChange={handleHistoryChange}
                                />
                              </Col>
                              <Col md="2">
                                <Label for="anyUnsettledLitigation_2">No</Label>
                                <Input
                                  id="anyUnsettledLitigation_2"
                                  type="checkbox"
                                  name="litigationUnsettled"
                                  value="No"
                                  checked={history.litigationUnsettled === 'No'}
                                  style={{ marginLeft: '0.5rem' }}
                                  onChange={handleHistoryChange}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Label>
                                C.1.5. Is There an Outstanding Delayed Payment?
                              </Label>
                            </Row>
                            <Row>
                              <Col md="2">
                                <Label for="anyOutstandingDelayedPayment_1">
                                  Yes
                                </Label>
                                <Input
                                  id="anyOutstandingDelayedPayment_1"
                                  name="delayOutstanding"
                                  type="checkbox"
                                  value="Yes"
                                  checked={history.delayOutstanding === 'Yes'}
                                  style={{ marginLeft: '0.5rem' }}
                                  onChange={handleHistoryChange}
                                />
                              </Col>
                              <Col md="2">
                                <Label for="anyOutstandingDelayedPayment_2">
                                  No
                                </Label>
                                <Input
                                  id="anyOutstandingDelayedPayment_2"
                                  type="checkbox"
                                  name="delayOutstanding"
                                  value="No"
                                  checked={history.delayOutstanding === 'No'}
                                  style={{ marginLeft: '0.5rem' }}
                                  onChange={handleHistoryChange}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Label>
                                C.1.6. Is GOSI Compliance Certificate Valid?
                              </Label>
                            </Row>
                            <Row>
                              <Col md="2">
                                <Label for="isGOSICertificateValid_1">
                                  True
                                </Label>
                                <Input
                                  id="isGOSICertificateValid_1"
                                  name="gosiCompliance"
                                  type="checkbox"
                                  value="True"
                                  checked={history.gosiCompliance === 'True'}
                                  style={{ marginLeft: '0.5rem' }}
                                  onChange={handleHistoryChange}
                                />
                              </Col>
                              <Col md="2">
                                <Label for="isGOSICertificateValid_2">
                                  False
                                </Label>
                                <Input
                                  id="isGOSICertificateValid_2"
                                  type="checkbox"
                                  name="gosiCompliance"
                                  value="False"
                                  checked={history.gosiCompliance === 'False'}
                                  style={{ marginLeft: '0.5rem' }}
                                  onChange={handleHistoryChange}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Label>
                                C.1.7. Does the Client have Certificate from The
                                Chamber of Commerce?
                              </Label>
                            </Row>
                            <Row>
                              <Col md="2">
                                <Label for="hasClientCertificateChamberCommerce_1">
                                  True
                                </Label>
                                <Input
                                  id="hasClientCertificateChamberCommerce_1"
                                  name="chamberCommerce"
                                  type="checkbox"
                                  value="True"
                                  checked={history.chamberCommerce === 'True'}
                                  style={{ marginLeft: '0.5rem' }}
                                  onChange={handleHistoryChange}
                                />
                              </Col>
                              <Col md="2">
                                <Label for="hasClientCertificateChamberCommerce_2">
                                  False
                                </Label>
                                <Input
                                  id="hasClientCertificateChamberCommerce_2"
                                  type="checkbox"
                                  name="chamberCommerce"
                                  value="False"
                                  checked={history.chamberCommerce === 'False'}
                                  style={{ marginLeft: '0.5rem' }}
                                  onChange={handleHistoryChange}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Label>
                                C.1.8. Is the Owner&apos;s or the Delegated
                                Person&apos;s Age is Less than 25 or Older 80
                                Years Old?
                              </Label>
                              <Input
                                type="date"
                                name="ownerBirthDate"
                                value={history.ownerBirthDate}
                                onChange={handleHistoryChange}
                              />
                            </Row>
                          </FormGroup>
                        </AccordionBody>
                      </AccordionItem>
                    </Accordion>
                  ) : (
                    <h6>No Questionnarie and Bank Statement Data Available.</h6>
                  )
                ) : (
                  <Alerts
                    color="primary"
                    className="fas fa-spin fa-spinner"
                    messageId={'LOADING'}
                    label=""
                  />
                )}
              </Col>
            </Row>
            <hr style={{ height: '2px' }} />

            <Row>
              <Col>
                {!companyBalanceLoading ? (
                  companyBalanceData &&
                  companyBalanceData.length > 0 &&
                  !companyBalanceError ? (
                    <CompanyBalance
                      value={companyBalanceData}
                      onChange={setCompanyBalanceData}
                    />
                  ) : (
                    <h6>No Statement Data Available.</h6>
                  )
                ) : (
                  <Alerts
                    color="primary"
                    className="fas fa-spin fa-spinner"
                    messageId={'LOADING'}
                    label=""
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </CustomForm>
    </Breadcrumb>
  );
};
