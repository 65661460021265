import React, { useState } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import { useAuth } from 'react-admin-base';
import { SingleFilePicker } from 'react-admin-base-bootstrap';
import { Breadcrumb } from 'react-admin-base-nazox';
import { FormattedMessage } from 'react-intl';
import { useFormSubmit, CustomForm } from '../../../Components/CustomForm';
import { useNavigate } from 'react-router-dom';

export const Bill = () => {
  const [api] = useAuth();
  const [done, setDone] = useState(false);
  const navigate = useNavigate();

  const defaultNewState = {
    documents: [
      {
        financialFile: null,
      },
    ],
  };

  const onSubmit = async () => {
    await api.tokenized.post(
      `/process/inovice/${optionsState.documents[0].financialFile.id}`
    );

    setDone(true);
  };
  const validateOptions = (values) => {
    const error = {};

    values.documents.forEach((v, index) => {
      error[index] = {};

      if (!v.financialFile) {
        error[index]['financialFile'] = 'REQUIRED';
      }
    });

    return error;
  };
  const {
    loading,
    newSave,
    setOptionState,
    errors,
    optionsState,
    handleFilePicker,
    setError,
  } = useFormSubmit(onSubmit, validateOptions, defaultNewState);

  const goPage = () => {
    navigate(
      '/bill/' +
        window.btoa(optionsState.documents[0].financialFile.id) +
        '/summary'
    );
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  if (done) {
    setTimeout(() => {
      goPage();
    }, 2000);
  }
  return (
    <Breadcrumb
      data={[
        {
          href: '/bill/create',
          name: 'Fatura',
        },
      ]}
    >
      <CustomForm
        submit={newSave}
        errors={errors}
        loading={loading}
        done={done}
        id="REDIRECT_SUMMARY"
        setError={setError}
      >
        {optionsState &&
          optionsState.documents &&
          optionsState.documents.map((row, i) => (
            <div key={`document-${i}`}>
              <FormGroup className={'mt-3'}>
                <Label> {<FormattedMessage id="ADD_BILL" />}</Label>
                <SingleFilePicker
                  accepts=".pdf,.xlsx,.png,.jpg"
                  value={row.financialFile}
                  onChange={(a) => handleFilePicker(a, i)}
                />
                {errors.validation &&
                  errors.validation[i] &&
                  errors.validation[i].financialFile && (
                    <p
                      style={{
                        color: 'red',
                        opacity: 0.6,
                        position: 'absolute',
                        fontSize: '12px',
                      }}
                    >
                      {<FormattedMessage id={'financialFile'} />}{' '}
                      {
                        <FormattedMessage
                          id={errors.validation[i]['financialFile']}
                        />
                      }
                    </p>
                  )}
              </FormGroup>

              {/* {row && row.financialFile && i <= 0 && (
                <Button
                  style={{ marginTop: "2em" }}
                  color="success"
                  type="button"
                  onClick={(a) => {
                    setOptionState((prev) => ({
                      ...prev,
                      documents: [...prev.documents, { ...defaultDocument }],
                    }));
                  }}
                >
                  <i className="fas fa-plus" />{" "}
                  <FormattedMessage id="ADD_DOCUMENT" />
                </Button>
              )} */}
              {row.financialFile && (
                <Button
                  style={{
                    marginTop: '2em',
                  }}
                  type="button"
                  color="danger"
                  onClick={() => {
                    setOptionState((prev) => ({
                      ...prev,
                      documents: [{ financialFile: null }],
                    }));
                  }}
                >
                  <i className="fas fa-trash" />{' '}
                  <FormattedMessage id="DELETE_DOCUMENT" />
                </Button>
              )}
            </div>
          ))}
      </CustomForm>
    </Breadcrumb>
  );
};
