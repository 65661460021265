import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, CardHeader, Table } from 'reactstrap';
import FirmLoadingAlert from './Alerts';

export const DupontTable = ({ categories, disabled }) => {
  return (
    <Table striped responsive bordered hover size="sm">
      <thead>
        <tr>
          <th>
            <FormattedMessage id="SUBTAB_1_3_TITLE1" />
          </th>
          <th>
            <FormattedMessage id="SUBTAB_1_3_TITLE2" />
          </th>
          {categories[0].values.map((val, i) => (
            <th key={i}>{val.year}</th>
          ))}
          {!disabled && (
            <th>
              <FormattedMessage id="SUBTAB_1_3_TITLE5" />
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {categories.map((val, index) => (
          <tr key={index}>
            <td>
              <p style={{ fontWeight: index === 0 ? 'bolder' : 'normal' }}>
                {val.name}
              </p>
            </td>
            <td>
              <p style={{ fontWeight: index === 0 ? 'bolder' : 'normal' }}>
                {val.formula}
              </p>
            </td>
            <td>
              <p style={{ fontWeight: index === 0 ? 'bolder' : 'normal' }}>
                {val.values[0].value.toLocaleString()}
              </p>
            </td>
            <td>
              <p style={{ fontWeight: index === 0 ? 'bolder' : 'normal' }}>
                {val.values[1].value.toLocaleString()}
              </p>
            </td>
            {!disabled && (
              <td>
                <p style={{ fontWeight: index === 0 ? 'bolder' : 'normal' }}>
                  {val.description}
                </p>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export const DupontTableCard = ({ categories }) => {
  if (!categories) return <FirmLoadingAlert />;

  return (
    <Card className={'mt-2'}>
      <CardHeader>
        <FormattedMessage id="SUBTAB_DUPONT_TITLE" />
      </CardHeader>
      <div className={'responsive-smilar'}>
        <DupontTable categories={categories} disabled={false} />
      </div>
    </Card>
  );
};

export default DupontTableCard;
