import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-admin-base';
function useFetchInternal(axios, url, params = null, body = null) {
  const tokenRef = useRef();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const update = useCallback(
    async function (signal) {
      setLoading(true);
      setError(null);
      try {
        let response;
        if (!body) {
          response = await axios.get(url, { params: params, signal: signal });
        } else {
          response = await axios.post(url, body, { params, signal: signal });
        }
        setData(response.data);
        setLoading(false);
      } catch (e) {
        if (!signal.aborted) {
          setLoading(false);
          setError(e);
        }
      }
    },
    [axios, url, params, body, setData, setError, setLoading]
  );
  useEffect(
    function () {
      if (!url) {
        setData(null);
        setLoading(false);
        setError(null);
      } else {
        const ab = new AbortController();

        tokenRef.current = ab;
        update(ab.signal);
        return function () {
          if (tokenRef.current) {
            tokenRef.current.abort();
          }
        };
      }
    },
    [tokenRef, update, setData, setLoading, setError]
  );
  //if (error)
  //  throw error;
  return [data, loading, error, update];
}
export default function useFetch(url, params = null, body = null) {
  const [api] = useAuth();
  return useFetchInternal(api.tokenized, url, params, body);
}
export function useFreeFetch(url, params = null, body = null) {
  const [api] = useAuth();
  return useFetchInternal(api.free, url, params, body);
}
