import React from 'react';
import { Breadcrumb } from 'react-admin-base-nazox';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody } from 'reactstrap';
import { PreviousAnalysisTable } from '../../../Components/tables/previous-analysis/PreviousAnalysisTable';

const params = { sort: 'id', desc: true };

export const PreviousAnalysisPage = () => {
  return (
    <Breadcrumb
      title={<FormattedMessage id="COMPLETED_ANALYZES_TITLE" />}
      data={[
        {
          href: '/analyzes',
          name: <FormattedMessage id="PREVIOUS_ANALYZES_TITLE" />,
        },
      ]}
    >
      <Card>
        <CardBody>
          <PreviousAnalysisTable />
        </CardBody>
      </Card>
    </Breadcrumb>
  );
};
