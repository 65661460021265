import React, { useState } from 'react';
import { Col, Row, Table } from 'reactstrap';
import FirmLoadingAlert from '../Components/Alerts';
import { Tab, Tabs } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { ExcelImportFromTable } from '../Components/DownloadExcelFromTable';
import { DownloadFileButton } from '../Components/DownloadFileButton';

export function AktarmaArindirma({ value, slug, companyCode, isConsolidated }) {
  const [key4, setKey] = useState(slug + 0);
  const intl = useIntl();

  if (!value) return <FirmLoadingAlert />;

  const excelProps = value.map((e, i) => {
    return { id: `aktarma${i}-to-xls`, sheetName: e.name };
  });
  return (
    <div className={'mt-2'}>
      <Row className="mb-3">
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '1vw ',
          }}
        >
          <button
            onClick={() => ExcelImportFromTable(excelProps, intl)}
            className="font-xl btn btn-primary btn-block"
            type="button"
          >
            <FormattedMessage id="TAB_4_FINANCIAL_TABLES" />{' '}
            <FormattedMessage id="DOWNLOAD" />
          </button>
          {(companyCode === 'param' || companyCode === 'socar') && (
            <DownloadFileButton
              messageId={`Aktarma-Arındırma
                
 ${intl.formatMessage({ id: 'DOWNLOAD' })}`}
              url={`analyze/transferPurificationSheet/${slug}`}
              dname={
                companyCode === 'aktifbank' || companyCode === 'teb'
                  ? 'Spread'
                  : 'Aktarma-Arındırma'
              }
              className={'btn btn-info btn btn-secondary'}
              method="get"
            />
          )}
        </div>
      </Row>
      <Tabs activeKey={key4} onSelect={(key4) => setKey(key4)}>
        {value.map((row, i) => (
          <Tab eventKey={slug + i} title={row.name} key={slug + i}>
            <Table
              id={'aktarma' + i + '-to-xls'}
              striped
              responsive
              bordered
              key={i}
              size="sm"
            >
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="SUBTAB_2_1_TITLE1" />
                  </th>
                  {row.dataList &&
                    row.dataList[0] &&
                    row.dataList[0].titles &&
                    row.dataList[0].titles[0] &&
                    row.dataList[0].titles[0].values.map((a) => (
                      <>
                        <th>{a.year}</th>
                      </>
                    ))}
                </tr>
              </thead>
              <tbody>
                {row.dataList &&
                  row.dataList[0] &&
                  row.dataList[0].titles &&
                  row.dataList.map((b, index) => (
                    <>
                      {b.titles
                        .filter(
                          (r) =>
                            r.values[0].value !== 0 || r.values[1].value !== 0
                        )
                        .map((a, j) => (
                          <tr key={j}>
                            <td key={j}>
                              <p>{a.title}</p>
                            </td>
                            {a.values.map((c) => (
                              <>
                                <td>
                                  {c.year.toString().indexOf('vs') === -1 &&
                                  c.year.toString().indexOf('Dikey') === -1
                                    ? ''
                                    : c.value === 0
                                      ? ''
                                      : '%'}
                                  {c.year.toString().indexOf('vs') === -1 &&
                                  c.year.toString().indexOf('Dikey') === -1 ? (
                                    c.value === 0 ? (
                                      '0'
                                    ) : (
                                      <FormattedNumber value={c.value} />
                                    )
                                  ) : c.value === 0 ? (
                                    ''
                                  ) : (
                                    <FormattedNumber value={c.value * 100} />
                                  )}
                                </td>
                              </>
                            ))}
                          </tr>
                        ))}
                    </>
                  ))}
              </tbody>
            </Table>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}
