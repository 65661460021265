import React from 'react';
import Chart, {
  CommonSeriesSettings,
  Legend,
  Series,
  Label,
  Format,
  Tooltip,
} from 'devextreme-react/chart';
import { Col, Row } from 'reactstrap';
import { FaSquare } from 'react-icons/fa';

function getText(item, text) {
  return `${item.seriesName}: ${text}`;
}
function customizeTooltip(arg) {
  return {
    text: getText(arg, arg.valueText),
  };
}
function customizeText(arg) {
  return getText(arg.seriesName, arg.valueText);
}

export function CustomBarChart({
  title,

  array,
  type,
}) {
  var year_1 = -1;
  var year_2 = -1;
  var value_1 = -1;
  var value_2 = -1;
  var avgValue_1 = -1;
  var avgValue_2 = -1;
  if (title !== 'Finansman Giderleri') {
    if (array) {
      year_1 = array[0].year;
      year_2 = array[1].year;
      value_1 = parseFloat(array[0].value.toLocaleString().split(',')[0]);
      value_2 = parseFloat(array[1].value.toLocaleString().split(',')[0]);
      avgValue_1 = parseFloat(
        array[0].sectorAverageValue.toLocaleString().split(',')[0]
      );
      avgValue_2 = parseFloat(
        array[1].sectorAverageValue.toLocaleString().split(',')[0]
      );
    }
  } else {
    if (array) {
      year_1 = array[0].year;
      year_2 = array[1].year;
      value_1 = array[0].value * 100;
      value_2 = array[1].value * 100;
    }
  }

  var dataArray_y1;
  var dataArray_y2;
  if (title != 'Finansman Giderleri') {
    dataArray_y1 = [
      {
        state:
          year_1.toLocaleString().replace('.', '').replace(',', '') + ' (%)',
        v1: value_1,
        v2: avgValue_1,
      },
    ];
    dataArray_y2 = [
      {
        state:
          year_2.toLocaleString().replace('.', '').replace(',', '') + ' (%)',
        v1: value_2,
        v2: avgValue_2,
      },
    ];
  } else {
    dataArray_y1 = [
      {
        state:
          year_1.toLocaleString().replace('.', '').replace(',', '') + ' (%)',
        v1: value_1,
      },
    ];
    dataArray_y2 = [
      {
        state:
          year_2.toLocaleString().replace('.', '').replace(',', '') + ' (%)',
        v1: value_2,
      },
    ];
  }

  return (
    <div>
      {type !== 'print' ? (
        <>
          <Row>
            <Col style={{ float: 'left' }}>
              <Chart
                id="chart"
                dataSource={dataArray_y1}
                style={{ height: '200px', width: '200px' }}
              >
                <Tooltip
                  enabled={true}
                  shared={false}
                  customizeTooltip={customizeTooltip}
                />

                <CommonSeriesSettings
                  argumentField="state"
                  type="bar"
                  hoverMode="allArgumentPoints"
                  selectionMode="allArgumentPoints"
                >
                  <Label visible={false} customizeText="valueText">
                    <Format type="fixedPoint" precision={0} />
                  </Label>
                </CommonSeriesSettings>

                <Series
                  argumentField="state"
                  barWidth={20}
                  valueField="v1"
                  name="Şirket Değeri"
                  // color={"#221982"}
                  color={'#F18733'}
                />
                {title !== 'Finansman Giderleri' && (
                  <Series
                    barWidth={20}
                    argumentField="state"
                    valueField="v2"
                    name="Sektör Ortalaması"
                    color={'#221982'}
                  />
                )}

                <Legend
                  customizeText={customizeText}
                  verticalAlignment="bottom"
                />
              </Chart>
            </Col>
            <Col style={{ float: 'right' }}>
              {' '}
              <Chart
                id="chart"
                dataSource={dataArray_y2}
                style={{ height: '200px', width: '200px' }}
              >
                <Tooltip
                  enabled={true}
                  shared={false}
                  customizeTooltip={customizeTooltip}
                />

                <CommonSeriesSettings
                  argumentField="state"
                  type="bar"
                  hoverMode="allArgumentPoints"
                  selectionMode="allArgumentPoints"
                >
                  <Label visible={false}>
                    <Format type="fixedPoint" precision={0} />
                  </Label>
                </CommonSeriesSettings>

                <Series
                  argumentField="state"
                  barWidth={20}
                  valueField="v1"
                  name="Şirket Değeri"
                  // color={"#221982"}
                  color={'#F18733'}
                />
                {title !== 'Finansman Giderleri' && (
                  <Series
                    barWidth={20}
                    argumentField="state"
                    valueField="v2"
                    name="Sektör Ortalaması"
                    color={'#221982'}
                  />
                )}

                <Legend visible={false}></Legend>
              </Chart>
            </Col>
          </Row>
        </>
      ) : (
        <>
          {title !== 'Finansman Giderleri' ? (
            <Row style={{ fontWeight: 'light', fontSize: '10px' }}>
              <Col md="6" style={{ float: 'left' }}>
                <FaSquare style={{ color: '#F18733' }} /> Şirket Değeri: %{' '}
                {array[0] && array[0].value.toFixed(2)}
                <br />
                <FaSquare style={{ color: '#221982' }} /> Sektör Ortalaması: %{' '}
                {array[0] && array[0].sectorAverageValue.toFixed(2)}
              </Col>
              <Col md="6" style={{ float: 'right' }}>
                <FaSquare style={{ color: '#F18733' }} /> Şirket Değeri: %{' '}
                {array[1] && array[1].value.toFixed(2)}
                <br />
                <FaSquare style={{ color: '#221982' }} /> Sektör Ortalaması: %{' '}
                {array[1] && array[1].sectorAverageValue.toFixed(2)}
              </Col>
            </Row>
          ) : (
            <div style={{ textAlign: 'center', fontSize: '10px' }}>
              <Row>
                <Col md="6" style={{ float: 'left' }}>
                  {' '}
                  <FaSquare style={{ color: '#F18733' }} /> Şirket Değeri: %{' '}
                  {value_1.toFixed(3)}
                </Col>
                <Col md="6" style={{ float: 'right' }}>
                  {' '}
                  <FaSquare style={{ color: '#F18733' }} /> Şirket Değeri: %{' '}
                  {value_2.toFixed(3)}
                </Col>
              </Row>
            </div>
          )}

          <Row>
            <Col style={{ float: 'left' }}>
              <Chart
                id="chart"
                style={{ height: '150px', width: '250px' }}
                dataSource={dataArray_y1}
              >
                <CommonSeriesSettings
                  type="bar"
                  hoverMode="allArgumentPoints"
                  selectionMode="allArgumentPoints"
                ></CommonSeriesSettings>
                <Legend visible={false}></Legend>
                <Series
                  argumentField="state"
                  barWidth={20}
                  valueField="v1"
                  name="Şirket Değeri"
                  // color={"#221982"}
                  color={'#F18733'}
                />
                {title !== 'Finansman Giderleri' && (
                  <Series
                    barWidth={20}
                    argumentField="state"
                    valueField="v2"
                    name="Sektör Ortalaması"
                    color={'#221982'}
                  />
                )}
                {/* <Label visible={true}>
                  <Format type="fixedPoint" precision={0} />
                </Label> */}
              </Chart>
            </Col>
            <Col style={{ float: 'right' }}>
              {' '}
              <Chart
                id="chart"
                dataSource={dataArray_y2}
                style={{ height: '150px', width: '250px' }}
              >
                <CommonSeriesSettings
                  type="bar"
                  hoverMode="allArgumentPoints"
                  selectionMode="allArgumentPoints"
                >
                  {' '}
                  <Label visible={false}>
                    <Format type="fixedPoint" precision={0} />
                  </Label>
                </CommonSeriesSettings>
                <Legend visible={false}></Legend>
                <Series
                  argumentField="state"
                  barWidth={20}
                  valueField="v1"
                  name="Şirket Değeri"
                  // color={"#221982"}
                  color={'#F18733'}
                />
                {title !== 'Finansman Giderleri' && (
                  <Series
                    barWidth={20}
                    argumentField="state"
                    valueField="v2"
                    name="Sektör Ortalaması"
                    color={'#221982'}
                  />
                )}
              </Chart>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
