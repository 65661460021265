import React from 'react';
import config from '../package.json';
import { GoToTop } from 'react-admin-base-bootstrap';
import { Card, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useFetch } from 'react-admin-base';
import { useUser } from './Components/UserProvider';

export default function Footer() {
  const user = useUser();
  const [company] = useFetch('/company/get/user/' + user.id);

  const companyCode = company && company.code;

  return (
    <footer
      style={{
        padding: '0px 12px 0px 12px',
        marginTop: '-40px',

        bottom: 0,
      }}
    >
      {companyCode !== 'ta3meed' && companyCode !== 'ta3meedbnpl' && (
        <div className=" text-sm text-justify">
          <Card>
            <CardBody>
              <h6>
                <FormattedMessage id="INFORMATION_TITLE" />
              </h6>
              <p>
                <FormattedMessage id="INFORMATION_TEXT" />
              </p>

              <div className="text-end">
                <a
                  href="https://f-rayscoring.com/sorumluluk-reddi-beyani/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage id="DISCLAIMER_TITLE" />
                </a>
              </div>
            </CardBody>
          </Card>
        </div>
      )}
      <div className="container-fluid">
        <div className="row text-muted">
          <div className="col-6 text-left">
            <p className="mb-0">
              <span>
                {config.contributors[1].name} © {new Date().getFullYear()}
              </span>
              <span>.</span>
              <span> All rights reserved.</span>
            </p>
          </div>
          <div className="col-6 text-end">
            <ul className="list-inline">
              <li className="list-inline-item">
                <a
                  href={
                    'http://www.google.com.tr/search?q=' +
                    config.contributors[1].name
                  }
                  className="text-muted"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-google fa-sm"></i>
                </a>{' '}
                -
              </li>
              <li className="list-inline-item">
                <a
                  className="text-muted"
                  target="_blank"
                  href={config.contributors[0].web}
                  rel="noreferrer"
                >
                  {config.contributors[0].name}
                </a>
              </li>
              <li className="list-inline-item">
                <small>
                  <b>V-</b>
                  <span>{config.version}</span>
                </small>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <GoToTop />
    </footer>
  );
}
