import React from 'react';
import { useFetch } from 'react-admin-base';
import { CardBody, Card } from 'reactstrap';
import { Tab, Tabs } from 'react-bootstrap';
import { ModuleKrediTab } from '../../Common/ModuleKrediTab';

import { Alerts } from '../../Components/Alerts';
import 'beautiful-react-diagrams/styles.css';
import './energy.scss';

import { FormattedMessage } from 'react-intl';
import {
  CombinedScore,
  Observation,
  Penalty,
  Qualitative,
} from '../../Common/ScoringTab';
import { Pdf } from '../../pages/PdfPage/Pdf';

export const SummaryStandartNoDocument = ({ firminfo, companyCode }) => {
  const [statement] = useFetch('/analyze/statement/' + firminfo); //Tablolar_formul
  const [taemedStatementNames] = useFetch('/analyze/details/' + firminfo); //Grafikler
  const [customer] = useFetch('analyze/get/' + firminfo);
  const [scoring] = useFetch('/analyze/scoring/' + firminfo);
  const [scoringVal] = useFetch('/analyze/scoringpoints/');
  const [qualitative] = useFetch('/survey/qualitative?documentId=' + firminfo);
  const [penalty] = useFetch('/survey/penalty?documentId=' + firminfo);

  const [observationC1] = useFetch(
    '/survey/observation?documentId=' + firminfo
  );
  const [observationC2] = useFetch(
    '/bankstatement/bankStatementInfo?documentId=' + firminfo
  );
  const [observationC3] = useFetch(
    '/bankstatement/bankStatementRatio?documentId=' + firminfo
  );

  const [combinedScore] = useFetch(
    '/survey/combinedScore?documentId=' + firminfo
  );
  const [logo] = useFetch('/customizations/report/logo');

  const ta3meedCode = companyCode === 'ta3meed';

  const formattedMessageId = ta3meedCode ? 'COMBINED_SCORE' : 'FINANCIAL_SCORE';

  if (penalty) {
    for (const item in penalty) {
      if (
        item === 'numberOfDelayedPaymentsOnCreditCards' ||
        item === 'numberOfDelayedPaymentsOnLoans'
      ) {
        penalty[item].forEach((e, i) => {
          if (e.days !== 'No delay' && e.number === '0') {
            delete penalty[item][i];
          } else if (e.days !== 'No delay' && e.number !== '0') {
            delete penalty[item][0];
          }
        });
      }
    }
  }

  if (!customer || !statement)
    return (
      <Alerts
        color="primary"
        className="fas fa-spin fa-spinner"
        messageId={'LOADING'}
        label=""
      />
    );
  var dname = customer && customer.customer && customer.customer.fullName;
  return (
    firminfo && (
      <>
        <Card>
          <CardBody>
            <div style={{ textAlign: 'right', marginBottom: '1em' }}>
              <Pdf
                scoringVal={scoringVal}
                scoring={scoring}
                companyCode={companyCode}
                combinedScore={combinedScore}
                customer={customer}
                noDocument={true}
              />
            </div>
            <Tabs id="controlled-tab-example">
              <Tab
                eventKey={'1'}
                title={
                  <FormattedMessage id="TAB_1_FINANCIAL_ANALYSIS_AND_SCORING" />
                }
              >
                <div className="my-3">
                  <Tabs id="controlled-tab-example">
                    {scoring && (
                      <Tab
                        eventKey={'9'}
                        title={
                          <FormattedMessage id="SUBTAB_1_6_CREDIT_SCORING_AND_SUMMARY" />
                        }
                      >
                        <div className="my-3">
                          <>
                            <Tabs id="CREDIT-SCORING-tab">
                              {qualitative && (
                                <Tab
                                  eventKey={'991'}
                                  title={'A. Qualitative Analysis Questions'}
                                >
                                  <Qualitative qualitative={qualitative} />
                                </Tab>
                              )}
                              {penalty && (
                                <Tab
                                  eventKey={'992'}
                                  title={'B. Penalty Questions'}
                                >
                                  <Penalty penalty={penalty} disable={false} />
                                </Tab>
                              )}
                              {observationC1 &&
                                observationC2 &&
                                observationC3 && (
                                  <Tab
                                    eventKey={'993'}
                                    title={
                                      'C. Observation Questions (Not for Scoring)'
                                    }
                                  >
                                    <Observation
                                      observationC1={observationC1}
                                      observationC2={observationC2}
                                      observationC3={observationC3}
                                      disabled={false}
                                      companyCode={companyCode}
                                    />
                                  </Tab>
                                )}
                              <Tab
                                eventKey={'994'}
                                title={
                                  <FormattedMessage id={formattedMessageId} />
                                }
                              >
                                <ModuleKrediTab
                                  formattedMessageId={formattedMessageId}
                                  scoring={scoring}
                                  scoringVal={scoringVal}
                                  key={firminfo + 'ModuleKredi'}
                                />
                                {ta3meedCode && (
                                  <CombinedScore
                                    combinedScore={combinedScore}
                                  />
                                )}
                              </Tab>
                            </Tabs>
                          </>
                        </div>
                      </Tab>
                    )}
                  </Tabs>
                </div>
              </Tab>
            </Tabs>
          </CardBody>
        </Card>
      </>
    )
  );
};

export default SummaryStandartNoDocument;
