import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Breadcrumb } from 'react-admin-base-nazox';
import { GroupTable } from './GroupTable';
import { GroupTable_ } from '../../Components/tables/GroupTable';

export const GroupTablePage = () => {
  return (
    <Breadcrumb
      data={[
        {
          href: '/groups',
          name: <FormattedMessage id="Groups" />,
        },
      ]}
    >
      <Card>
        <CardBody>
          <GroupTable_ />
        </CardBody>
      </Card>
    </Breadcrumb>
  );
};
