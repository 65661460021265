import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { VolumeTable } from '../../Components/tables/volume/VolumeTable';

const headersMapping = {
  accountCode: 'ACCOUNT_CODE',
  accountName: 'ACCOUNT_NAME',
  debtBalance: 'DEBT_BALANCE',
};

export const DebtVolumeTable = ({ volume }) => {
  console.log('debt: ', volume);
  return (
    <Card>
      <CardBody>
        <VolumeTable
          data={volume}
          headersMapping={headersMapping}
          numberFormatKey={'debtBalance'}
        />
      </CardBody>
    </Card>
  );
};
