import React, { useState } from 'react';
import GoogleChart from '../../../Components/GoogleChart';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { AsyncPaginateInput_ } from '../../../Components/Selects';

export const sum2 = (array, sectorsLetters) => {
  const sum = {};
  array.forEach((v) => {
    sectorsLetters.map((s) => {
      if (!sum[s]) sum[s] = 0;
      if (v[s]) sum[s] += v[s];
    });
  });
  return sum;
};

export const FinancialScoring = ({ financialScoring }) => {
  const [sector, setSector] = useState({
    code: '-',
    name: 'Hepsi',
    enName: 'All',
  });
  const sectorsLetters = Object.keys(Object.values(financialScoring)[0]);
  const sumOfScores = sum2(Object.values(financialScoring), sectorsLetters);
  const scoringWithAll = {
    ...financialScoring,
    ['-']: { ...sumOfScores },
  };

  return (
    <>
      <Row style={{ marginBottom: '1em' }}>
        <AsyncPaginateInput_
          value={sector}
          onChange={(a) =>
            setSector(a ? a : { code: '-', name: 'Hepsi', enName: 'All' })
          }
          url="/sector/get/paginate/all"
          filterResponse={(res) => res.data}
          getOptionLabel={(a) =>
            `${a.code}-${
              localStorage.frayjet_frayjet_language === 'en' ? a.enName : a.name
            }`
          }
          getOptionValue={(a) => <FormattedMessage id={a.code} />}
          isMulti={false}
          filter={(e) => financialScoring[e.code]}
        />

        {/* <Col md={{ offset: 9 }}>
          <Input
            type="select"
            onChange={(a) => setSector(a.currentTarget.value)}
            style={{ width: "20%" }}
            value={sector}
          >
            <option value={"All"}>
              <FormattedMessage id={"All"} />
            </option>
            {keys.map((y) => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
          </Input>
        </Col> */}
      </Row>
      <Row style={{ marginBottom: '1em' }}>
        <Col>
          {Object.keys(scoringWithAll).length > 0 && (
            <GoogleChart
              pieHole={0.4}
              title={<FormattedMessage id={'SCORING_DISTRIBUTON'} />}
              data={
                scoringWithAll[sector?.code]
                  ? [
                      ['Sector Code', 'Count'],
                      ...Object.entries(scoringWithAll[sector?.code]),
                    ]
                  : []
              }
              chartType={'PieChart'}
            />
          )}
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <Label>
            <FormattedMessage id={"SCORING_MATRIX"} />
          </Label>
          <Table striped responsive bordered hover>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id={"SECTORS"} />
                </th>

                {sectorsLetters.map((s, k) => {
                  return <th key={k}>{s}</th>;
                })}
                <th>
                  <FormattedMessage id="TOPLAM" />
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.values(financialScoring).map((v, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <FormattedMessage id={keys[i]} />
                    </td>
                    {Object.values(v).map((e, l) => {
                      return <td key={l}> {e}</td>;
                    })}
                    <td>{sum(Object.values(v))}</td>
                  </tr>
                );
              })}
              <tr>
                <td>
                  <FormattedMessage id="TOPLAM" />
                </td>
                {Object.values(sumOfScores).map((v, c) => (
                  <td key={c}>{v}</td>
                ))}
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row> */}
    </>
  );
};

export default FinancialScoring;
