import React, { useCallback, useEffect, useState } from 'react';
import { useAuth, useLogin } from 'react-admin-base';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { useFormik } from 'formik';
import Layout from '../../Common/Layout';
import { Loading } from '../../Components/Loading';
import swal from 'sweetalert2';

const error_messages = {
  invalid_grant: 'INVALID_PASSWORD',
};
const defaultState = {
  email: '',
  password: '',
  loading: false,
  error: null,
};

export default function Login() {
  const [api] = useAuth();
  const login = useLogin();
  const intl = useIntl();
  const navigate = useNavigate();
  //const { code } = useQueryParams();
  // const loginByCode = useCallback(
  //   async function () {
  //     if (code) {
  //       setLoading(true);
  //       try {
  //         await api.log_in_by_code(code, window.location.origin + login.login);
  //       } catch (e) {
  //         setError(e);
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //   },
  //   [setLoading, setError, code]
  // );
  // useEffect(
  //   function () {
  //     loginByCode();
  //   },
  //   [loginByCode]
  // );
  //   const handleSubmit = useCallback(
  //     async function (e) {
  //       e.preventDefault();
  //       if (loading) return false;
  //       setError(null);
  //       setLoading(true);
  //       try {
  //         await api.log_in(username, password);
  //       } catch (e) {
  //         console.error(e);
  //         const msg = error_messages[(e && e.message) || "-"];
  //         if (msg) {
  //           setError(new Error(intl.formatMessage({ id: msg })));
  //         } else {
  //           setError(e);
  //         }
  //       } finally {
  //         setLoading(false);
  //       }
  //     },
  //     [username, password, error, loading, intl, error_messages]
  //   );

  const formik = useFormik({
    initialValues: {
      ...defaultState,
    },
    validateOnChange: true,
    validate: (data) => {
      const errors = {};

      if (data.email.length === 0) errors.email = 'FILL_EMAIL';
      // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email))
      //   errors.email = "VALID_EMAIL";

      if (data.password.length === 0) {
        errors.password = 'NO_PASSWORD';
      }
      // else if (data.password) {
      //   if (data.password.length < 8) {
      //     formik.errors.password = "PASSWORD_EIGHT";
      //   } else if (!/[A-Z]/.test(data.password) || !/\d/.test(data.password)) {
      //     formik.errors.password = "PASSWORD_LEAST";
      //   }
      // }

      return errors;
    },

    onSubmit: async (data, { resetForm }) => {
      formik.setFieldValue('loading', true);

      try {
        await api.log_in(data.email, data.password);
      } catch (e) {
        const msg = error_messages[e && e.message] || 'ERROR_MESSAGE_AUTH';
        swal.fire({
          icon: 'error',
          title: intl.formatMessage({ id: msg }),
          showConfirmButton: false,
          width: '300px',
        });
      } finally {
        formik.setFieldValue('loading', false);
      }
    },
  });

  const getFormErrorMessage = (name) => {
    if (formik.touched[name] && formik.errors[name]) {
      return (
        <span
          style={{
            color: '#ff0000',
            position: 'absolute',
            fontSize: '12px',
          }}
        >
          {<FormattedMessage id={formik.errors[name]} />}
        </span>
      );
    }

    return null;
  };
  //   if (code) {
  //     if (formik.values.loading) {
  //       return React.createElement(Layout, null);
  //     }
  //   }

  return (
    <Layout>
      <Form onSubmit={formik.handleSubmit}>
        <div className="text-center">
          <div className="mb-3">
            <h2>
              <FormattedMessage id="LOGIN_HEAD" />
            </h2>

            {/* {login.register && (
              <p>
                <Link className="text-decoration-none" to={login.register}>
                  <Button
                    type="button"
                    color="primary"
                    block
                    outline
                    className="mt-3"
                  >
                    <FormattedMessage id="NEW_ACCOUNT" />
                  </Button>
                </Link>
              </p>
            )} */}
          </div>
          {/* {children && (
            <div className="d-grid mb-4">
              <div className="auth-buttons">{children}</div>
              <span className="text-muted mt-3">
                <FormattedMessage id="OR" />
              </span>
            </div>
          )} */}
        </div>
        <FormGroup className="auth-form-group-custom">
          <i className="ri-user-2-line auti-custom-input-icon" />
          <Label>
            <FormattedMessage id="EMAIL" />
          </Label>
          <Input
            type="text"
            value={formik.values.email}
            onChange={formik.handleChange}
            name="email"
          />
          {getFormErrorMessage('email')}
        </FormGroup>
        <FormGroup className="auth-form-group-custom">
          <i className="ri-lock-2-line auti-custom-input-icon" />
          <Label>
            <FormattedMessage id="PASSWORD" />
          </Label>
          <Input
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            name="password"
          />
          {getFormErrorMessage('password')}
        </FormGroup>
        {/* {error && (
          <Alert color="danger" toggle={() => setError(null)}>
            {error.message}
          </Alert>
        )} */}
        <div className="mt-4 text-center">
          <Button
            type="submit"
            color="primary"
            onMouseDown={(e) => e.preventDefault()}
            disabled={formik.values.loading}
            loading={true}
          >
            <FormattedMessage id="LOGIN" />
          </Button>
        </div>
        {formik.values.loading && <Loading />}
        {login.reset && (
          <div className="mt-4 text-center">
            <Link className="form-label-link mb-0 text-muted" to={login.reset}>
              <i className="mdi mdi-lock me-1" />
              <FormattedMessage id="FORGOT_PASSWORD" />
            </Link>
          </div>
        )}
      </Form>
    </Layout>
  );
}
