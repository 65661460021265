import React from 'react';

import { Card, CardBody, Col, Row, Label, FormGroup } from 'reactstrap';
import { downloadFile } from '../../Components/DownloadFileButton';
import { Link } from 'react-router-dom';
import { useAuth } from 'react-admin-base';
import { Loading } from '../../Components/Loading';
import { useIntl } from 'react-intl';

export const SpkMasak = () => {
  const [API] = useAuth();
  const intl = useIntl();
  const [loading, setLoading] = React.useState(false);
  const method = 'put';
  const url = 'file/download?actionMethod=1';
  const url2 = 'file/download?actionMethod=2';
  const dname = 'SPK';
  const dname2 = 'MASAK';

  return (
    <Card>
      <CardBody>
        <FormGroup>
          {loading && <Loading />}
          <Row>
            <Col>
              <Label>
                Sermaye Piyasası Kurulu (SPK) İşlem Yasakları Listesini İndirmek
                İçin{' '}
                <Link
                  onClick={(e) => {
                    e.preventDefault();

                    downloadFile(setLoading, method, url, dname, intl);
                  }}
                  to={''}
                >
                  Tıklayınız.
                </Link>
              </Label>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          {' '}
          <Row>
            <Col>
              <Label>
                Masak-BKK ve Cumhurbaşkanı Kararı ile Mal varlıkları
                Dondurulanları İndirmek İçin{' '}
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    downloadFile(setLoading, method, url2, dname2, intl);
                  }}
                  to={''}
                >
                  Tıklayınız.
                </Link>
              </Label>
            </Col>
          </Row>
        </FormGroup>
      </CardBody>
    </Card>
  );
};
