import React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

export const findColorAndRisk = (gauge, point) => {
  let color = '#63e02d';
  let text = (
    <FormattedMessage id="SUBTAB_MANUFACTURING_GRAPHS_FIRSTYEAR_LOW" />
  );

  if (gauge === 50) {
    color = '#FFCA28';
    text = <FormattedMessage id="SUBTAB_MANUFACTURING_GRAPHS_FIRSTYEAR_AVG" />;
  } else if (80 === gauge) {
    color = '#D41B1B';
    text = <FormattedMessage id="SUBTAB_MANUFACTURING_GRAPHS_FIRSTYEAR_HIGH" />;
  }

  return (
    <p
      style={{
        color: color,
        textAlign: 'center',
        fontSize: '20px',
        margin: '-6em 0 0 0 ',
      }}
    >
      {text}: <FormattedNumber value={point} />
    </p>
  );
};

export const isText = (val) => {
  return /[a-zA-Z]/.test(val);
};

export const orcSecData = {
  false: [
    'Risk Category/Manufacturing',
    "Z' Score Manufacturing",
    "Altman Z' Puan-İmalatçı Firmalar",
    "Altman Z' Puan Riski-İmalatçı Firmalar",
  ],
  true: [
    'Risk Category/Non-Manufacturing',
    "Z' Score Non-Manufacturing",
    "Altman Z' Puan-İmalat Harici Firmalar",
    "Altman Z' Puan Riski-İmalat Harici Firmalar",
  ],
};

export const findColor = (val) => {
  return isText(val)
    ? val === 'Düşük İflas Riski' || val === 'Low Risk'
      ? '#63e02d'
      : val === 'Average Risk' || val === 'Ortalama İflas Riski'
        ? '#FFCA28'
        : '#D41B1B'
    : '';
};

export const enumTitle = {
  'Risk Category/Non-Manufacturing': 'SUBTAB_NON-MANUFACTORING_TITLE',
  "Z' Score Non-Manufacturing": 'SUBTAB_NON-MANUFACTORING_TITLE',
  "Z' Score Manufacturing": 'SUBTAB_MANUFACTORING_TITLE',
  'Risk Category/Manufacturing': 'SUBTAB_MANUFACTORING_TITLE',
};
