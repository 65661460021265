import * as XLSX from 'xlsx';

export const ExcelImportFromTable = (excelProps, intl) => {
  const wb = { SheetNames: [], Sheets: {} };

  excelProps.map((e) => {
    const wscols = [{ width: 30 }];
    wb.SheetNames.push(e.sheetName);
    wb.Sheets[e.sheetName] = XLSX.utils.table_to_book(
      document.getElementById(e.id),
      { raw: true }
    ).Sheets.Sheet1;
    const sheet = wb.Sheets[wb.SheetNames[0]];
    var range = XLSX.utils.decode_range(sheet['!ref']);
    const columnLength = range?.e?.c;
    for (var i = 0; i < columnLength; i++) {
      wscols.push({ width: 20 });
    }

    wb.Sheets[e.sheetName]['!cols'] = [...wscols];
  });

  const wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'binary',
    cellStyles: true,
  });

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
  const blob = new Blob([s2ab(wbout)], {
    type: 'application/octet-stream',
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `${intl.formatMessage({ id: 'TAB_4_FINANCIAL_TABLES' })}.xlsx`
  );
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
