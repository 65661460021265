import React, { useCallback, useState } from 'react';
import { Alert, Button, Card, CardBody, Col, Form, Row } from 'reactstrap';
import { ValidatorProvider } from 'react-admin-base';
import useFetch from '../../Hooks/useFetch';

import { Breadcrumb } from 'react-admin-base-nazox';
import { Link, Route, Routes } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useUser } from '../../Components/UserProvider';
import { Ta3meed } from './AnalysisTaemeed';
import { AnalysisAktif } from './AnalysisAktif';
import { AnalysisGlobal } from './AnalysisGlobal2';
import AnalysisEdit from './AnalysisEdit';
import { AktifAnalysisEdit } from './AktifAnalysisEdit';
import { AnalysisStandart } from './AnalysisStandart';
import { AnalysisSocar } from './AnalysisSocar';
import { Alerts } from '../../Components/Alerts';
import Analysis from './Analysis';
import { BnplEdit } from './BnplEdit';

// export type AnalysisEntityProps = {
//   isCustomerPage?: boolean;
// };

// export type BreadCrumpProps = {
//   isCustomerPage?: boolean;
//   children: React.ReactNode;
// };

const Breadcrumb_ = (props) => {
  const { children, isCustomerPage } = props;
  return isCustomerPage ? (
    children
  ) : (
    <Breadcrumb
      title={<FormattedMessage id="NEW_ANALYSIS_TITLE" />}
      data={[
        {
          href: '/analysis/create',
          name: <FormattedMessage id="NEW_ANALYSIS_TITLE" />,
        },
      ]}
    >
      {children}
    </Breadcrumb>
  );
};

function useFormSubmit(save) {
  const [saveError403, setOnSaveError403] = useState(false);
  const [saveError400, setOnSaveError400] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const newSave = useCallback(
    async function (e) {
      e.preventDefault();

      setLoading(true);
      try {
        await save(e);

        document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } catch (e) {
        if (e.response) {
          if (e.response.status === 403) {
            setOnSaveError403(true);
          }
          if (e.response.status === 400) {
            setOnSaveError400(true);
          }
        }

        setError(e);

        throw e;
      } finally {
        setLoading(false);
      }
    },
    [save, setOnSaveError403, setOnSaveError400, setError]
  );

  return [loading, saveError403, saveError400, newSave, error];
}

export function CustomForm({ onSubmit, children }) {
  const [loading, saveError403, saveError400, submit, error] =
    useFormSubmit(onSubmit);

  if (saveError400) {
    return (
      <Card>
        <CardBody>
          <Alert color={'danger'}>
            <FormattedMessage id="START_NEW_ANL_ERROR_1" />
          </Alert>
          <p>
            {' '}
            <a href={'mailto:info@f-rayscoring.com'}>
              <FormattedMessage id="YOU_CAN_CONTACT_US" />
            </a>{' '}
          </p>
        </CardBody>
      </Card>
    );
  }

  return (
    <ValidatorProvider>
      <Form onSubmit={submit} disabled={loading}>
        {error && (
          <Alert color="danger">
            <i className="fas fa-times-circle" /> {error && error.message}
          </Alert>
        )}
        {loading && (
          <Alert color="primary">
            <i className="fas fa-spin fa-spinner" />
            <FormattedMessage id="LOADING" />
          </Alert>
        )}
        {children}
        <Row>
          <Col md="4">
            <Button
              disabled={loading}
              type="submit"
              className="d-block righted"
              color="primary"
            >
              <FormattedMessage id="SEND" /> <i className="fas fa-save fa-lg" />
            </Button>
          </Col>
        </Row>
      </Form>
    </ValidatorProvider>
  );
}

export default function AnalysisEntity(props) {
  const { isCustomerPage, customer, sector } = props;
  const user = useUser();
  const [company, loading] = useFetch('/company/get/user/' + user.id);
  const userType = user && user.type;

  if (loading) {
    return (
      <Alerts
        color="primary"
        className="fas fa-spin fa-spinner"
        messageId={'LOADING'}
        label=""
      />
    );
  }
  if (company) {
    if (company.code === 'ta3meed') {
      return (
        <Routes>
          <Route path=":id/edit/:bid" element={<AnalysisEdit />} />
          <Route
            path="*"
            element={
              <Breadcrumb_ isCustomerPage={isCustomerPage}>
                <Ta3meed />
              </Breadcrumb_>
            }
          />
        </Routes>
      );
    } else if (company.code === 'aktifbank' || company.code === 'teb') {
      return (
        <Routes>
          <Route path=":id/edit" element={<AktifAnalysisEdit />} />
          <Route
            path="*"
            element={
              <Breadcrumb_ isCustomerPage={isCustomerPage}>
                <AnalysisAktif
                  userType={userType}
                  customer={customer}
                  sector={sector}
                />
              </Breadcrumb_>
            }
          ></Route>
        </Routes>
      );
    } else if (company.code === 'global') {
      return (
        <Breadcrumb_ isCustomerPage={isCustomerPage}>
          <AnalysisGlobal />
        </Breadcrumb_>
      );
    } else if (company.code === 'socar') {
      return (
        <Breadcrumb_ isCustomerPage={isCustomerPage}>
          <AnalysisSocar />
        </Breadcrumb_>
      );
    } else if (company.code === 'ta3meedbnpl') {
      return (
        <Routes>
          <Route path=":id/edit" element={<BnplEdit />} />
          <Route
            path="*"
            element={
              <Breadcrumb_ isCustomerPage={isCustomerPage}>
                <Analysis />
              </Breadcrumb_>
            }
          />
        </Routes>
      );
    } else if (
      company.code === 'param' ||
      company.code === 'worqcompany' ||
      company.code === 'tofas' ||
      company.code === 'usulventures' ||
      company.code === 'tbaydin' ||
      company.code === 'tai'
    ) {
      return (
        <Routes>
          <Route path=":id/edit" element={<AktifAnalysisEdit />} />
          <Route
            path="*"
            element={
              <Breadcrumb_ isCustomerPage={isCustomerPage}>
                <AnalysisAktif userType={512} />
              </Breadcrumb_>
            }
          ></Route>
        </Routes>
      );
    } else {
      return (
        <Breadcrumb_ isCustomerPage={isCustomerPage}>
          <AnalysisStandart />
        </Breadcrumb_>
      );
    }
  } else {
    return <h6>Şirket bulunamadı</h6>;
  }
}
