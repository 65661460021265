import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, Col, Row } from 'reactstrap';

export const ModuleCredi = ({ scoring, scoringVal, formattedMessageId }) => {
  const reversedData =
    scoringVal &&
    scoringVal.dataList &&
    scoringVal.dataList.sort((a, b) => a.upperLimit - b.upperLimit);

  return (
    <>
      <Row>
        <Col />
        <Col>
          <div className="scoreBox">
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                padding: '20px',
                margin: 0,
              }}
            >
              <FormattedMessage id={formattedMessageId} />
            </p>
            <hr style={{ margin: 0 }} />
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                padding: '20px',
              }}
            >
              {scoring.scorePoint}
            </p>
          </div>
        </Col>
        <Col />
      </Row>
      <br />
      <hr />
      <br />
      {scoringVal && scoringVal.dataList && (
        <>
          <Row>
            {reversedData.map((ab, i) => (
              <Col
                style={{
                  backgroundColor: `#${ab?.colorCode?.slice(2)}`,
                  borderRadius:
                    i === 0
                      ? '10px 0 0 10px'
                      : i === scoringVal.dataList.length - 1
                        ? '0 10px 10px 0'
                        : '',
                }}
                key={i}
              >
                {Math.ceil(scoring.scorePoint) >= ab.lowerLimit &&
                  Math.ceil(scoring.scorePoint) <= ab.upperLimit && (
                    <div
                      style={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          fontWeight: 'bold',
                          top: '-20px',
                        }}
                      >
                        {scoring.scorePoint}
                      </div>
                      <div
                        style={{
                          width: '5px',
                          height: '40px',
                          backgroundColor: `black`,
                          borderRadius: '5px',
                        }}
                      ></div>{' '}
                      <div
                        style={{
                          position: 'absolute',
                          bottom: '-25px',
                          width: '200px',
                        }}
                      >
                        {ab.combined}
                      </div>
                    </div>
                  )}
              </Col>
            ))}
          </Row>
          <Row style={{ marginTop: '60px' }}>
            <Col />
            <Col />
            <Col />
            <Col
              style={{
                border: '2px solid rgba(0,0,0,.1)',
                paddingLeft: '15px',
              }}
            >
              {reversedData.map((ab, i) => {
                return (
                  <Row
                    style={{
                      alignItems: 'center',
                    }}
                    key={i}
                  >
                    <Col
                      sm="1"
                      style={{
                        backgroundColor: `#${ab?.colorCode?.slice(2)}`,
                        borderRadius: '5px',
                        width: '12px',
                        height: '12px',
                      }}
                    ></Col>
                    <Col style={{ fontSize: '12px' }}>{ab.combined}</Col>
                    <Col style={{ fontSize: '12px' }}>
                      {ab.lowerLimit}-{ab.upperLimit}
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
