import React from 'react';
import { Alert, Label } from 'reactstrap';
import { useUser } from './UserProvider';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export function DemoAlert() {
  const user = useUser();

  if (user.type !== 1) return null;

  return (
    <Alert color="warning">
      <i className="fas fa-info-circle me-2" />
      Bu demo hesabıdır. Bu hesap kısıtlı erişim imkanına sahiptir. Varsa
      hesabınız <Link to="/logout">çıkış yap</Link>arak gerçek hesaba giriş
      yapabilirsiniz.
    </Alert>
  );
}

export function FirmAlert() {
  return (
    <Alert color="secondary">
      <i className="fas fa-spinner fa-spin me-2" />
      Firma Bilgileri Yüklenmektedir...
    </Alert>
  );
}

export function FirmErrorAlert() {
  return (
    <Alert color="secondary">
      <i className="fas fa-times me-2" />
      Firma Yakın Zamanda Sisteme Eklenecektir.
    </Alert>
  );
}

export default function FirmLoadingAlert() {
  return (
    <Alert color="secondary">
      <i className="fas fa-spin fa-spinner " />
      {'  '}Yükleniyor...
    </Alert>
  );
}

export const Alerts = ({ color, className, messageId, label }) => {
  return (
    <Alert color={color}>
      {className && <i className={className} style={{ marginRight: '2px' }} />}
      {messageId && <FormattedMessage id={messageId} />}
      {label && <Label>{label}</Label>}
    </Alert>
  );
};
