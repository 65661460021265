import React from 'react';
import { ErrorPageLayout } from '../pages/Error/Error';
import { useNavigate } from 'react-router';
import { Button } from 'reactstrap';
export const ErrorPageProducer = ({ resetErrorBoundary }) => {
  const navigate = useNavigate();
  return (
    <ErrorPageLayout>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '20vh',
          gap: '3vh',
          height: '100%',
          width: '100%',
          color: '#252b3b',
        }}
      >
        <h1
          style={{
            fontWeight: 'bold',
            color: '#252b3b',
          }}
        >
          HATA
        </h1>

        <h1 style={{ color: '#252b3b' }}>Bir şeyler ters gitti.</h1>
        <Button
          onClick={() => {
            resetErrorBoundary();
            navigate('/');
          }}
          style={{
            padding: '1vh 2vw',
            color: '#252b3b',
          }}
        >
          Ana Sayfaya Dön
        </Button>
      </div>
    </ErrorPageLayout>
  );
};
