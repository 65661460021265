import Swal from 'sweetalert2';

export const fireSwal = (props) => {
  Swal.fire({
    icon: props.icon,
    customClass: 'swal-class',
    background: props.background,
    timer: props.timer || 2000,
    showConfirmButton: false,
    backdrop: false,
    text: props.text || '',
    html: props.html || '',
  });
};
