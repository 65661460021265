import React from 'react';
import { Row, Label } from 'reactstrap';
import useFetch from '../../../Hooks/useFetch';
import { BeneishMScoreRatiosTable } from './BeneishMScoreRatiosTable';
import { BeneishMScoreVariablesTable } from './BeneishMScoreVariablesTable';
import { Alerts } from '../../../Components/Alerts';

export const BeneishMScoreTables = ({ firmInfo }) => {
  const [beneishMScore, loading] = useFetch(
    '/analyze/beneishMScore/' + firmInfo
  );
  if (loading)
    return (
      <Alerts
        color="primary"
        className="fas fa-spin fa-spinner"
        messageId={'LOADING'}
        label=""
      />
    );

  const beneishMScoringValues =
    beneishMScore && beneishMScore.beneishMScoringValues;
  const beneishMScoringVariables =
    beneishMScore && beneishMScore.beneishMScoringVariables;

  return (
    <>
      <Row>
        {beneishMScoringValues ? (
          <BeneishMScoreRatiosTable
            beneishMScoringValues={beneishMScoringValues}
          />
        ) : (
          <Label>No Data Available.</Label>
        )}
      </Row>

      <Row>
        {beneishMScoringVariables ? (
          <BeneishMScoreVariablesTable
            beneishMScoringVariables={beneishMScoringVariables}
          />
        ) : (
          <h6>No Data Available.</h6>
        )}
      </Row>
    </>
  );
};
