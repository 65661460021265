import React from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  Row,
  Card,
  CardBody,
  CardHeader,
  Label,
  ModalFooter,
  Input,
  Col,
} from 'reactstrap';
import { formatNumber } from '../../Util/formatNumber';
import { FormattedMessage } from 'react-intl';
import { useAuth } from 'react-admin-base';
import { showSwal2 } from '../../Components/CustomForm';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../Components/Loading';
const formatDataForSubmit = (columns) => {
  const result = [];

  columns.forEach((item, index) => {
    const { column, documentId, tableType } = item;

    for (let i = index + 1; i < columns.length; i++) {
      if (
        columns[i].documentId === documentId &&
        columns[i].tableType === tableType
      ) {
        result.push({
          tableType,
          documentId,
          prevYearColumnNumber: Math.min(
            Number(column),
            Number(columns[i].column)
          ),
          mainYearColumnNumber: Math.max(
            Number(column),
            Number(columns[i].column)
          ),
        });
      }
    }
  });
  return result;
};

export const AktifErrorContent = ({ setError, content, companyCode }) => {
  const message = content.message;
  const data = content.data.auditDetails;
  const mainDocumentId = content.data.mainDocumentId;
  const canSeeEditPage = [
    'aktifbank',
    'param',
    'worqcompany',
    'tofas',
    'teb',
    'usulventures',
    'tbaydin',
    'tai',
  ].includes(companyCode);

  const cardData = [];

  data.forEach((item) => {
    const documentId = item.documentId;
    const documentName = item.documentName;
    const tableType = item.tableType;
    const tableData = item.table;

    const entry = cardData.find((item) => item.documentId === documentId);

    if (entry) {
      entry.items.push({ tableType, data: tableData });
    } else {
      cardData.push({
        documentId,
        documentName,
        items: [{ tableType, data: tableData }],
      });
    }
  });

  return (
    <Modal
      isOpen
      toggle={() =>
        setError((prev) => {
          return { ...prev, ['message']: '' };
        })
      }
      size="xl"
    >
      <ModalHeader
        toggle={() =>
          setError((prev) => {
            return { ...prev, ['message']: '' };
          })
        }
      >
        {message}
      </ModalHeader>
      <ModalBody>
        <CardsAndButtonContent
          data={cardData}
          mainDocumentId={mainDocumentId}
          canSeeEditPage={canSeeEditPage}
        />
      </ModalBody>
    </Modal>
  );
};

const CardsAndButtonContent = ({ data, mainDocumentId, canSeeEditPage }) => {
  const [columns, setColumns] = React.useState([]);
  const [API] = useAuth();
  const intl = useIntl();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const tableCount = data.reduce((acc, e) => {
    return acc + e.items.length;
  }, 0);

  const allTablesHas2Columns = (columns) => {
    const columnsLength = columns.length;

    if (tableCount * 2 === columnsLength) {
      return true;
    }
    return false;
  };

  const submitHandler = async () => {
    setLoading(true);
    const formattedData = formatDataForSubmit(columns);

    try {
      const result = await API.tokenized.post('/statement/auditColumn', {
        identifierDetails: formattedData,
        mainDocumentId,
      });

      if (result.status === 200) {
        canSeeEditPage
          ? navigate('/analysis/' + window.btoa(mainDocumentId) + '/edit')
          : navigate('/report/' + window.btoa(mainDocumentId) + '/summary');
      } else throw new Error();
    } catch (e) {
      showSwal2(
        `<p>${intl.formatMessage({ id: 'ERROR_MESSAGE_AUTH' })} </p>`,
        intl,
        ''
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row>
        {loading && <Loading />}
        {data.map((e, i) => {
          return (
            <CardContent
              key={i}
              data={e}
              columns={columns}
              setColumns={setColumns}
            />
          );
        })}
      </Row>
      <Row xs="11" className="offset-md-11">
        <button
          className="btn btn-primary"
          onClick={submitHandler}
          disabled={!allTablesHas2Columns(columns)}
        >
          <FormattedMessage id="SEND" />
        </button>
      </Row>
    </>
  );
};

const CardContent = ({ data, columns, setColumns }) => {
  const documentId = data.documentId;
  const documentName = data.documentName;

  const checkTAbleHasTwoCheck = (tableType, documentId) => {
    return (
      columns.filter(
        (e) => e.tableType === tableType && e.documentId === documentId
      ).length === 2
    );
  };

  return (
    <Card>
      <CardHeader>
        <h6>
          <a
            href={`${process.env.REACT_APP_ENDPOINT}uploads/${documentId}/${documentName}`}
            target="_blank"
            rel="noreferrer"
          >
            {documentName}
          </a>
        </h6>
      </CardHeader>
      <CardBody>
        {data.items.map((item, key) => {
          const tableData = item.data;
          const headers = Object.keys(tableData[0]);
          const tableType = item.tableType;

          return (
            <Row key={key}>
              <Row>
                <Label>{tableType}</Label>
              </Row>
              <Row>
                <Table key={key} bordered size="sm" responsive>
                  <thead>
                    <tr>
                      {headers.map((h, key) => {
                        const checkExists = (e) => {
                          return (
                            e.column === h &&
                            e.documentId === documentId &&
                            e.tableType === tableType
                          );
                        };

                        const handleChange = (e) => {
                          if (
                            e.target.checked &&
                            !checkTAbleHasTwoCheck(tableType, documentId)
                          ) {
                            setColumns((prev) => [
                              ...prev,
                              {
                                column: h,
                                documentId: documentId,
                                tableType: tableType,
                              },
                            ]);
                          } else {
                            setColumns((prev) => {
                              return prev.filter((e) => !checkExists(e));
                            });
                          }
                        };
                        return (
                          <th key={key}>
                            {key !== 0 && (
                              <>
                                {h}
                                <Input
                                  type="checkbox"
                                  onChange={(e) => handleChange(e)}
                                  checked={
                                    columns.filter((e) => checkExists(e))
                                      .length > 0
                                  }
                                  disabled={
                                    checkTAbleHasTwoCheck(
                                      tableType,
                                      documentId
                                    ) &&
                                    columns.filter((e) => checkExists(e))
                                      .length <= 0
                                  }
                                  style={{
                                    marginLeft: '3px',
                                  }}
                                />
                              </>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((e, i) => {
                      return (
                        <tr key={i}>
                          {headers.map((e2, i2) => {
                            return (
                              <td key={i2}>
                                {i2 === 0 ? e[e2] : formatNumber(e[e2])}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Row>
            </Row>
          );
        })}
      </CardBody>
    </Card>
  );
};
