import React from 'react';
import { Card, CardBody, CardFooter, Row, Col } from 'reactstrap';
import ReactSpeedometer from 'react-d3-speedometer';
import { FormattedMessage } from 'react-intl';

export const ModuleMakineData = ({
  prediction,
  containerRef13,
  companyCode,
}) => {
  const score = prediction && prediction.score;

  var liquidity_value = 0;

  if (score == 0) {
    liquidity_value = 100;
  } else if (score == 1) {
    liquidity_value = 500;
  } else {
    liquidity_value = 900;
  }

  let likit = '';
  let oran = '';
  let color = 'black';
  if (liquidity_value < 250) {
    likit = 'Likit Dışı';
    oran = '(Cari Oran<1)';
    color = '#D41B1B';
  } else if (250 < liquidity_value && liquidity_value < 750) {
    likit = 'Sınırlı Likit';
    oran = '(Cari Oran ~ 1)';
    color = '#FFCA28';
  } else if (750 < liquidity_value && liquidity_value < 1000) {
    likit = 'Likit';
    oran = '(Cari Oran>1)';
    color = '#63E02D';
  }

  return (
    <div
      style={{
        textAlign: 'center',
      }}
    >
      <Row>
        <Col>
          <div ref={containerRef13} id="tableElement13">
            <div
              style={{
                backgroundColor: '#F3F3F5',
              }}
            >
              <Row>
                <div
                  style={{
                    marginBottom: '-40px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <ReactSpeedometer
                    width={550}
                    height={350}
                    needleHeightRatio={0.7}
                    value={liquidity_value}
                    customSegmentStops={[0, 250, 750, 1000]}
                    segmentColors={['#D41B1B', '#FFCA28', '#63E02D']}
                    currentValueText={`${likit} - ${oran}`}
                    textColor={color}
                    customSegmentLabels={[
                      {
                        text: 'Likit Dışı (Cari Oran<1)',
                        position: 'INSIDE',
                        color: '#303030',
                      },
                      {
                        text: 'Sınırlı Likit (Cari Oran ~ 1)',
                        position: 'INSIDE',
                        color: '#303030',
                      },
                      {
                        text: 'Likit (Cari Oran>1)',
                        position: 'INSIDE',
                        color: '#303030',
                      },
                    ]}
                    ringWidth={47}
                    needleTransitionDuration={0}
                    needleTransition="easeElastic"
                    needleColor={'#795D9C'}
                  />
                </div>
              </Row>
              <Row
                style={{
                  backgroundColor: '#fff',
                  height: '6px',
                }}
              ></Row>
              {containerRef13 && companyCode !== 'ta3meed' && (
                <Row>
                  <Col>
                    <div
                      style={{
                        padding: '5px',
                      }}
                    >
                      <h4
                        style={{
                          alignItems: 'left',
                          textAlign: 'left',
                          color: 'rgba(28, 69, 98, 1)',
                        }}
                      >
                        <FormattedMessage id="SUBTAB_WARNING_TITLE" />
                      </h4>
                      <p
                        style={{
                          textAlign: 'justify',
                          color: 'rgba(28, 69, 98, 1)',
                        }}
                      >
                        <FormattedMessage id="SUBTAB_WARNING_TEXT" />
                      </p>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export function ModuleMakine({ prediction }) {
  return (
    <Card>
      <CardBody
        style={{
          textAlign: 'center',
        }}
      >
        <ModuleMakineData prediction={prediction} />
      </CardBody>
      <CardFooter>
        <h4>
          <FormattedMessage id="SUBTAB_WARNING_TITLE" />:
        </h4>
        <p style={{ textAlign: 'justify', marginBottom: '50px' }}>
          <FormattedMessage id="SUBTAB_WARNING_TEXT" />
        </p>
      </CardFooter>
    </Card>
  );
}

export default ModuleMakine;
