import React from 'react';
import { Input, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { AsyncPaginate } from 'react-select-async-paginate';
import PhoneInput from 'react-phone-input-2';
import { AsyncPaginateInput_ } from './Selects';
import { UncontrolledTooltip } from 'reactstrap';

export const InputField = ({
  name,
  id,
  onChange,
  validation,
  type,
  value,
  children,
  placeHolder,
  disabled,
  label,
  tooltip,
  tooltipId,
  ...props
}) => {
  return (
    <>
      <ErrorAndLabelText
        id={id}
        name={name}
        label={label}
        validation={validation}
        tooltip={tooltip}
        tooltipId={tooltipId}
      >
        <Input
          disabled={disabled}
          style={{
            WebkitAppearance: 'none',
            border: validation && validation[name] && '1px solid #ff0000',
          }}
          {...props}
          name={name}
          value={value}
          type={type || ''}
          onChange={onChange}
          placeholder={placeHolder}
          autoComplete="off"
        >
          {children}
        </Input>
      </ErrorAndLabelText>
    </>
  );
};

export const PhoneNumberInput = ({
  name,
  id,
  onChange,
  value,
  label,
  country,
  validation,
  ...props
}) => {
  return (
    <>
      <ErrorAndLabelText
        id={id}
        name={name}
        label={label}
        validation={validation}
      >
        <PhoneInput
          country={country}
          specialLabel=""
          onChange={onChange}
          value={value}
          {...props}
        />
      </ErrorAndLabelText>
    </>
  );
};

export const AsyncPaginateInput = ({
  value,
  name,
  onChange,
  api,
  validation,
  id,
  label,
  children,
  url,
  groups,
  ...props
}) => {
  const url_ = url || '/customer/get/all';
  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const res = await api?.tokenized.get(
      `${url_}?page=${page}&query=${searchQuery}`
    );
    const options =
      res &&
      res.data &&
      res.data.data &&
      res.data.data
        .filter((e) =>
          groups
            ? e.isGroupCompany === true
            : !e.isGroupCompany || e.isGroupCompany === false
        )
        .map((d) => {
          return {
            label: `${d.tradeRegistrationNumber || ''}-${d.name}`,
            value: d.id,
            target: {
              name: name,
              value: {
                value: d.id,
                label: `${d.tradeRegistrationNumber || ''}-${d.name}`,
              },
            },
            sector: d.sector,
            sapNo: d.number,
            taxNo: d.taxNumber,
          };
        });

    return {
      options: options || [],
      hasMore: options.length >= 1,
      additional: {
        page: page + 1,
      },
    };
  };
  return (
    <>
      <ErrorAndLabelText
        id={id}
        name={name}
        label={label}
        validation={validation}
      >
        {children}
        <AsyncPaginate
          name={name}
          value={value}
          onChange={onChange}
          loadOptions={loadOptions}
          additional={{
            page: 1,
          }}
          placeholder={<FormattedMessage id={'NEW_ANL_PLACEHOLDER_TEXT'} />}
          {...props}
          isClearable
          loadingMessage={() => <FormattedMessage id={'LOADING'} />}
          noOptionsMessage={() => <FormattedMessage id={'NO_OPTIONS'} />}
          //isDisabled={props.disabled}
        />
      </ErrorAndLabelText>
    </>
  );
};

export const ErrorAndLabelText = ({
  id,
  name,
  label,
  children,
  validation,
  tooltip,
  tooltipId,
}) => {
  return (
    <>
      {id && <Label for={name}>{<FormattedMessage id={id} />}</Label>}
      {label && <Label for={name}>{label}</Label>}
      {tooltip && (
        <i
          className="fa fa-info-circle"
          id={id || tooltipId}
          style={{ marginLeft: '0.5em' }}
        >
          <UncontrolledTooltip
            placement="right"
            target={id || tooltipId}
            style={{
              backgroundColor: '#505d69',
            }}
          >
            <span>{tooltip}</span>
          </UncontrolledTooltip>
        </i>
      )}

      {children}
      {validation && validation[name] && (
        <p
          style={{
            color: '#ff0000',
            opacity: 0.6,
            position: 'absolute',
            fontSize: '12px',
          }}
        >
          {id && <FormattedMessage id={id} />}{' '}
          {<FormattedMessage id={validation[name]} />}
        </p>
      )}
    </>
  );
};

export const CategorySelectInput = ({
  value,
  onChange,
  id,
  name,
  validation,
  label,
  tooltip,
  disabled,
}) => {
  return (
    <ErrorAndLabelText
      id={id}
      name={name}
      label={label}
      validation={validation}
      tooltip={tooltip}
    >
      <AsyncPaginateInput_
        value={value}
        onChange={onChange}
        filterResponse={(res) => res.data}
        url="/sector/get/paginate/all"
        getOptionLabel={(a) =>
          `${a.code}-${
            localStorage.frayjet_frayjet_language === 'en' ? a.enName : a.name
          }`
        }
        getOptionValue={(a) => <FormattedMessage id={a.code} />}
        isMulti={false}
        disabled={disabled}
      />
    </ErrorAndLabelText>
  );
};

export default InputField;
