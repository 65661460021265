import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, Col, Row } from 'reactstrap';
import '../pages/Summarys/energy.scss';
import { ModuleCredi } from './ModuleCredi';

export function ModuleKrediTab({ scoring, scoringVal, formattedMessageId }) {
  return (
    <Row className="mt-2">
      <ModuleCredi
        scoring={scoring}
        scoringVal={scoringVal}
        formattedMessageId={formattedMessageId}
      />
    </Row>
  );
}
