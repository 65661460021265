import React from 'react';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Col, Collapse, Label, Row } from 'reactstrap';
import { kobiOptions } from './CustomerForm';
import Analysis from '../Analysis/AnalysisEntity';
import Swal from 'sweetalert2';
import { useIntl } from 'react-intl';
import { Card, CardBody, CloseButton } from 'reactstrap';
import { Button_ } from '../../Components/ActionButtons/ActionButtons';
import { icons } from '../../Icons/icons';
import {
  LinkButton,
  DeleteButton,
} from '../../Components/ActionButtons/ActionButtons';
import { fireSwal } from '../../Components/SwalFire';
import { useAuth } from 'react-admin-base';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../Components/UserProvider';
import { useFetch } from 'react-admin-base';
import { formatNumber } from '../../Util/formatNumber';

const CustomerInfo2 = (props) => {
  const customer = props.customer;
  const location = useLocation();
  const intl = useIntl();
  const [API] = useAuth();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const startAnalysis_ = queryParams.get('startAnalysis') === 'true';
  const [startAnalysis, setStartAnalysis] = React.useState(false);

  React.useEffect(() => {
    setStartAnalysis(startAnalysis_);
  }, [startAnalysis_]);

  const toggle = () => setStartAnalysis(!startAnalysis);
  const user = useUser();
  const params = useParams();
  const [company] = useFetch('/company/get/user/' + user.id);
  const companyCode = company && company.code;
  const isAktifBank = companyCode === 'aktifbank';
  const isGroup = params['*']?.includes('group');

  const onClose = async () => {
    Swal.fire({
      title: intl.formatMessage({
        id: 'APPROVE_TEXT',
      }),
      text: intl.formatMessage({
        id: 'DATA_ENTERED_LOSE',
      }),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: intl.formatMessage({
        id: 'YES_AGREE',
      }),
      cancelButtonText: intl.formatMessage({
        id: 'NO_DONT_AGREE',
      }),
    }).then((result) => {
      if (result.isConfirmed) {
        toggle();
      }
    });
  };
  const handleDelete = async () => {
    const val = await Swal.fire({
      title: intl.formatMessage({ id: 'ACTIONS.DELETE.TITLE' }),
      text: intl.formatMessage({ id: 'ACTIONS.DELETE.TEXT' }),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: intl.formatMessage({ id: 'ACTIONS.DELETE.CONFIRM' }),
    });
    if (val.value) {
      try {
        await API.tokenized.delete('/customer/delete/' + customer.id);
        fireSwal({
          background: '#90EE90',
          icon: 'success',
          text: intl.formatMessage({ id: 'SAVED' }),
        });
      } catch (e) {
        console.log('delete: ', e);
        fireSwal({
          background: '#ffd8df',
          icon: 'error',
          text: e.response.data.message
            ? localStorage.frayjet_frayjet_language === 'en'
              ? e.response.data.message.enn
              : e.response.data.message.tr
            : 'Bir hata meydana geldi.',
          timer: 5000,
        });
      } finally {
        navigate('/customers');
      }
    }
  };
  const editUrl = customer.isGroupCompany
    ? '/group/' + customer.id + '/edit'
    : '/customer/' + customer.id + '/edit';

  return (
    <>
      <Row>
        <div
          className="flex justify-end gap-2 mb-2"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '0.5rem',
            marginBottom: '0.5rem',
          }}
        >
          <LinkButton
            url={editUrl}
            text={intl.formatMessage({ id: 'EDIT' })}
            size="sm"
            color="secondary"
            iconClassName={icons.edit}
          />
          <DeleteButton
            text={intl.formatMessage({ id: 'DELETE_USER' })}
            size="sm"
            color="secondary"
            iconClassName={icons.delete}
            onClick={handleDelete}
          />
        </div>
      </Row>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <Label
                style={{
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                <FormattedMessage id="analysisCustomerId" />
              </Label>
              : {customer.name}
            </Col>
            <Col>
              <Label
                style={{
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                <FormattedMessage id="SECTOR_TYPE" />
              </Label>
              :{' '}
              {customer.sector
                ? `${customer.sector.code}-${customer.sector.name}`
                : '-'}
            </Col>
          </Row>
          <Row>
            <Col>
              <Label
                style={{
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                <FormattedMessage id="ADDRESS" />
              </Label>
              : {customer.fullAddress}
            </Col>
            <Col>
              <Label
                style={{
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                <FormattedMessage id="GROUP" />
              </Label>
              :{' '}
              {customer.isGroupCompany === true ? (
                'Grup Lideri'
              ) : customer.isGroupCompany === false ? (
                customer.groupId.label ? (
                  <LinkButton
                    url={'/group/' + customer.groupId.value + '/profile'}
                    text={customer.groupId.label}
                    size="xs"
                    color="success"
                    iconClassName={icons.profile}
                    buttonStyle={{
                      padding: '0px 2px',
                    }}
                  />
                ) : (
                  '-'
                )
              ) : (
                'Grup Müşterisi Değil'
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Label
                style={{
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                <FormattedMessage id="ADD_USER_EMAIL" />
              </Label>
              : {customer.email}
            </Col>
            <Col>
              <Label
                style={{
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                <FormattedMessage id="PHONE_NUMBER" />
              </Label>
              : {customer.phoneNumber || '-'}
            </Col>
          </Row>
          <Row>
            <Col>
              <Label
                style={{
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                <FormattedMessage id="COUNTRY_NAME" />
              </Label>
              : {customer.countryName}
            </Col>
            <Col>
              <Label
                style={{
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                <FormattedMessage id="CITY_NAME" />
              </Label>
              : {customer.cityName}
            </Col>
          </Row>
          <Row>
            <Col>
              <Label
                style={{
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                <FormattedMessage id="CUSTOMER_NUMBER" />
              </Label>
              : {customer.number || '-'}
            </Col>
            <Col>
              <Label
                style={{
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                <FormattedMessage id="KOBI_CLASS" />
              </Label>
              :{' '}
              {localStorage && localStorage.frayjet_frayjet_language === 'en'
                ? kobiOptions[customer.kobiClass]?.enName
                : kobiOptions[customer.kobiClass]?.name || '-'}
            </Col>
          </Row>
          {customer.isGroupCompany === false && (
            <>
              <Row>
                <Col>
                  <Label
                    style={{
                      fontWeight: 'bold',
                      fontSize: '1rem',
                    }}
                  >
                    <FormattedMessage id="CURRENCY" />
                  </Label>
                  : {customer.currency || '-'}
                </Col>
                <Col>
                  <Label
                    style={{
                      fontWeight: 'bold',
                      fontSize: '1rem',
                    }}
                  >
                    <FormattedMessage id="TRADE_REGISTRATION_NUMBER" />
                  </Label>
                  : {customer.tradeRegistrationNumber || '-'}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label
                    style={{
                      fontWeight: 'bold',
                      fontSize: '1rem',
                    }}
                  >
                    <FormattedMessage id="SITE_OF_ASSETS" />
                  </Label>
                  :{' '}
                  {customer.siteofAssets
                    ? formatNumber(customer.siteofAssets)
                    : '-'}
                </Col>

                <Col>
                  <Label
                    style={{
                      fontWeight: 'bold',
                      fontSize: '1rem',
                    }}
                  >
                    <FormattedMessage id="taxNo" /> / TCKN
                  </Label>
                  : {customer.taxNumber || '-'}
                </Col>
              </Row>
            </>
          )}
          <Row>
            {customer.isGroupCompany === false ? (
              <Col>
                <Label
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                  }}
                >
                  <FormattedMessage id="NUMBER_OF_EMPLOYEES" />
                </Label>
                : {customer.numberOfEmployees || '-'}
              </Col>
            ) : (
              ''
            )}

            {customer.isGroupCompany === false ? (
              <Col>
                <Label
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                  }}
                >
                  <FormattedMessage id="TURN_OVER" />
                </Label>
                : {customer.turnOver ? formatNumber(customer.turnOver) : '-'}
              </Col>
            ) : (
              ''
            )}
          </Row>
        </CardBody>
      </Card>

      {isAktifBank && !customer.isGroupCompany && (
        <Row>
          <Row className="mb-2">
            <Col>
              <Button_
                color="primary"
                size="sm"
                onClick={toggle}
                iconClassName={icons.startAnalysis}
                text={intl.formatMessage({
                  id: 'NEW_ANALYSIS_TITLE',
                })}
                disabled={startAnalysis}
              />
            </Col>
          </Row>
          <Collapse isOpen={startAnalysis}>
            <Card>
              <CardBody>
                <Row className="flex justify-end">
                  <CloseButton onClick={() => onClose()} className="text-xl" />
                </Row>
                <Row>
                  <Analysis
                    isCustomerPage={true}
                    sector={customer.sector}
                    customer={{
                      value: customer.id,
                      label: customer.name,
                    }}
                  />
                </Row>
              </CardBody>
            </Card>
          </Collapse>
        </Row>
      )}
    </>
  );
};
export const CustomerInfo = React.memo(CustomerInfo2);
