import React from 'react';
import { Spinner } from 'reactstrap';
import frayLogo from '../FileTemplatesAndImages/cropped.png';

export const Loading = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        color: '#0d6efd',
      }}
    >
      <Spinner color="primary"></Spinner>
    </div>
  );
};
