import React, { useCallback } from 'react';

export default function DisableClipoard({ children }) {
  const prevent = useCallback(function (e) {
    e.preventDefault();
  }, []);
  return React.cloneElement(children, {
    onCut: prevent,
    onCopy: prevent,
    onContextMenu: prevent,
  });
}
