import React from 'react';
import { TradeRegistryTable } from '../Components/tables/trade-registry/TradeRegistryTable';
import { TradeRegistryTableInfo } from '../Components/summary/TradeRegistryInfo';
import { Tab, Tabs } from 'react-bootstrap';

export const TradeRegistry = (props) => {
  const { tradeRegistry } = props;
  return (
    <Tabs
      id="controlled-tab-example0"
      unmountOnExit={true}
      mountOnEnter={true}
      transition={false}
      className="my-2"
    >
      <Tab eventKey="Tablo" title={'İlanlar'}>
        <TradeRegistryTable tradeRegistry={tradeRegistry} />
      </Tab>
      <Tab eventKey="New Feature" title={'Detaylar'}>
        <TradeRegistryTableInfo />
      </Tab>
    </Tabs>
  );
};
