import React from 'react';
import { Image, StyleSheet, Text, View, Page } from '@react-pdf/renderer';
import { FormattedMessage } from 'react-intl';
import { colors, fontSizes, padding } from '../Common';
import frayLogo from '../../../FileTemplatesAndImages/f-ray.png';
import aktiflogo from '../../../FileTemplatesAndImages/aktifbank_logo.png';

export const PdfPageForAktif = ({ children, orientation }) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      fontFamily: 'Open Sans',
    },
  });

  return (
    <Page size="A4" style={styles.page} orientation={orientation || 'portrait'}>
      <PageHeader />
      <View style={{ marginTop: '40px', padding: '0 30px' }}> {children}</View>
    </Page>
  );
};

export const PageHeader = () => {
  const styles = StyleSheet.create({
    header: {
      height: '95px',
      width: '100%',
      backgroundColor: colors.primary,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      padding: '0 35px',
    },
    logo: {
      width: '150px',
      heigth: '50px',
    },
    logo2: {
      width: '60px',
      height: '20px',
    },
  });
  const logo = aktiflogo;
  const logo2 = frayLogo;

  return (
    <View
      style={{
        flexDirection: 'column',
        height: '130px',
      }}
    >
      <View style={styles.header}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image src={logo} style={styles.logo} />
          <View
            style={{
              border: '1px solid',
              borderColor: '#FFFFFF',
              backgroundColor: '#FFFFFF',
              height: '40px',
              width: '0.5px',
            }}
          ></View>
          <Image src={logo2} style={styles.logo2} />
        </View>
        <View
          style={{
            color: colors.white,
            height: '39px',
          }}
        >
          <Text
            style={{
              fontWeight: 700,
              fontSize: fontSizes.header,
              textTransform: 'capitalize',
              textAlign: 'center',
            }}
          >
            <FormattedMessage id="FINANCIAL_ANALYSIS">
              {(text) => {
                return (
                  <>
                    {text[0].toLocaleUpperCase(
                      localStorage?.getItem('frayjet_frayjet_language') === 'tr'
                        ? 'tr-TR'
                        : 'en-EN'
                    )}
                  </>
                );
              }}
            </FormattedMessage>
          </Text>
          <Text
            style={{
              fontWeight: 700,
              fontSize: fontSizes.header,
              textTransform: 'capitalize',
              textAlign: 'center',
            }}
          >
            <FormattedMessage id="DETECTION_REPORT">
              {(text) => {
                return (
                  <>
                    {text[0].toLocaleUpperCase(
                      localStorage?.getItem('frayjet_frayjet_language') === 'tr'
                        ? 'tr-TR'
                        : 'en-EN'
                    )}
                  </>
                );
              }}
            </FormattedMessage>
          </Text>
        </View>
      </View>
    </View>
  );
};
