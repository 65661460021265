import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { enumTitle } from '../../Util/module_altman';
import { ModuleAltmanTable } from '../../Components/tables/ModuleAltmanTable';
import { ModuleAltmanChartContent } from '../../Components/ModuleAltmanChartContent';

export function ModuleAltmanTaemed({ altmanzscoring, incomestatement }) {
  let alt_puan_1 = '';
  let alt_puan_2 = '';

  let gauge_1;
  let gauge_2;

  let title = '';

  const findGauge = (val) => {
    let gauge = 80;
    if (val === 'Düşük İflas Riski' || val === 'Low Risk') gauge = 10;
    else if (val === 'Average Risk' || val === 'Ortalama İflas Riski')
      gauge = 50;
    return gauge;
  };

  if (altmanzscoring && altmanzscoring.titles) {
    const azTitlesLength = altmanzscoring && altmanzscoring.titles.length;
    title = altmanzscoring.titles[0].title;

    for (var i = 0; i < azTitlesLength; i++) {
      if (
        altmanzscoring.titles[i].title === "Z' Score Manufacturing" ||
        altmanzscoring.titles[i].title === "Z' Score Non-Manufacturing"
      ) {
        alt_puan_1 = altmanzscoring.titles[i].values[0].value.toString();
        alt_puan_2 = altmanzscoring.titles[i].values[1].value.toString();
      } else if (
        altmanzscoring.titles[i].title === 'Risk Category/Non-Manufacturing' ||
        altmanzscoring.titles[i].title === 'Risk Category/Manufacturing'
      ) {
        gauge_1 = findGauge(
          altmanzscoring.titles[i].values[0].value.toString()
        );
        gauge_2 = findGauge(
          altmanzscoring.titles[i].values[1].value.toString()
        );
      }
    }
  }

  const metricValue_1 = gauge_1;
  const metricValue_2 = gauge_2;

  const pointerAngle_1 = (metricValue_1 * 180) / 100;
  const pointerAngle_2 = (metricValue_2 * 180) / 100;

  const y1 = incomestatement
    ? incomestatement.titles[0].values[0].year.toString()
    : '';
  const y2 = incomestatement
    ? incomestatement.titles[0].values[1].year.toString()
    : '';

  const firmData = {
    title: title,
    data: [
      {
        year: y1,
        pointerAngle: pointerAngle_1,
        gauge: gauge_1,
        alt_puan: alt_puan_1,
      },
      {
        year: y2,
        pointerAngle: pointerAngle_2,
        gauge: gauge_2,
        alt_puan: alt_puan_2,
      },
    ],
  };

  return (
    <Card className={'mt-2'}>
      <CardHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <h4>
            <FormattedMessage id={enumTitle[firmData.title]} />
          </h4>
        </div>
      </CardHeader>
      <CardBody>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <Row>
            <Col md="1"></Col>
            {firmData.data.map((e, i) => {
              return (
                <ModuleAltmanChartContent
                  key={i}
                  y={e.year}
                  pointerAngle={e.pointerAngle}
                  gauge={e.gauge}
                  alt_puan={e.alt_puan}
                />
              );
            })}
            <Col md="1"></Col>
          </Row>
        </div>

        {altmanzscoring && altmanzscoring.titles && (
          <ModuleAltmanTable data={altmanzscoring.titles} y1={y1} y2={y2} />
        )}
      </CardBody>
    </Card>
  );
}
