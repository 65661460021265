import React from 'react';

import { FormattedMessage } from 'react-intl';
import { icons } from '../../Icons/icons';
import { LinkButton } from '../../Components/ActionButtons/ActionButtons';
import { useAuth } from 'react-admin-base';
import { useIntl } from 'react-intl';
import { useUser } from '../../Components/UserProvider';
import { useFetch } from 'react-admin-base';
import { Alerts } from '../../Components/Alerts';
import { InputField } from '../Input';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';

const url = 'customer/get/groups';

export const GroupTable_ = () => {
  const intl = useIntl();
  const user = useUser();
  const [api] = useAuth();
  const navigate = useNavigate();

  const user_id = user.id;
  const [company, companyLoading] = useFetch('/company/get/user/' + user_id);

  const companyCode = company && company.code;

  const [lazyParams, setLazyParams] = React.useState({
    first: 1,
    rows: 10,
    page: 1,
    sortField: 'id',
    sortOrder: 1,
    filters: {},
    multiSortMeta: [],
  });
  const [globalFilterValue, setGlobalFilterValue] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [customers, setCustomers] = React.useState(null);

  const onPage = (event) => {
    setLazyParams({ ...event, page: event.page + 1 });
  };

  const onFilter = (event) => {
    console.log('event', event);
    setLazyParams(event);
  };

  const onSort = (event) => {
    setLazyParams((prev) => ({
      ...event,
      page: 1,
    }));
  };

  React.useEffect(() => {
    fetchData();
  }, [lazyParams, globalFilterValue]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.tokenized.get(
        `/${url}?page=${lazyParams.page}&itemPerPage=${lazyParams.rows}&query=${globalFilterValue}&sort=${lazyParams.sortField}&desc=${lazyParams.sortOrder === 1 ? true : false}`
      );
      setCustomers(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const totalRecords = customers ? customers.itemCount : 0;

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;

    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <InputField
          name="globalFilterValue"
          type="text"
          onChange={onGlobalFilterChange}
          value={globalFilterValue}
          placeHolder={intl.formatMessage({ id: 'SEARCH' })}
          style={{ width: '15%' }}
        />
        <LinkButton
          url={'/group/add'}
          text={intl.formatMessage({
            id: 'ADD_NEW_GROUP',
          })}
          size="sm"
          color="success"
          iconClassName={icons.add}
        />
      </div>
    );
  };

  const footer = (
    <span
      style={{
        position: 'absolute',
        bottom: '20px',
        right: '5px',
      }}
    >
      <FormattedMessage id="CUSTOMER_COUNT" />: {totalRecords}
    </span>
  );

  if (companyLoading)
    return (
      <Alerts
        color="primary"
        className="fas fa-spin fa-spinner"
        messageId={'LOADING'}
        label=""
      />
    );
  return (
    <DataTable
      value={customers ? [...customers.data] : []}
      className="mt-3"
      scrollable
      loading={loading}
      paginator
      rowsPerPageOptions={[5, 10, 25, 50]}
      onPage={onPage}
      first={lazyParams.first}
      rows={lazyParams.rows}
      totalRecords={totalRecords}
      onSort={onSort}
      sortField={lazyParams.sortField}
      sortOrder={lazyParams.sortOrder}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
      currentPageReportTemplate=" "
      emptyMessage={intl.formatMessage({ id: 'NO_DATA_IS_AVAILABLE' })}
      lazy
      header={renderHeader}
      onGlobalFilterChange={onFilter}
      footer={footer}
      onRowClick={(e) => {
        console.log('test: ,', e);
        companyCode !== 'ta3meed' &&
          navigate('/group/' + e.data.id + '/profile');
      }}
      selectionMode="single"
    >
      <Column field="id" header={<i className="fas fa-hashtag"></i>} />
      <Column
        field="name"
        header={<FormattedMessage id={'name'} />}
        sortField="name"
        sortable
      />
      <Column
        field="email"
        header={<FormattedMessage id={'ADD_USER_EMAIL'} />}
        sortField="email"
        sortable
      />
      <Column
        header={<FormattedMessage id={'SECTOR_TYPE'} />}
        body={(rowData) => {
          return <span>{rowData?.sector?.name}</span>;
        }}
      />

      <Column
        header={<i className="fas fa-water" />}
        body={(rowData) => {
          return (
            companyCode !== 'ta3meed' && (
              <LinkButton
                url={'/group/' + rowData.id + '/profile'}
                text={intl.formatMessage({
                  id: 'PROFILE',
                })}
                size="sm"
                color="info"
                iconClassName={icons.profile}
              />
            )
          );
        }}
        style={{
          width: '10%',
        }}
      />
    </DataTable>
  );
};
