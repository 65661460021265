import { Button } from 'reactstrap';
import React, { useCallback, useRef } from 'react';
import { FaPrint } from 'react-icons/fa';

import { Legend, PieChart, Pie, Cell } from 'recharts';
import { Row, Col } from 'reactstrap';

import '../pages/Summarys/energy.scss';

import { FormattedMessage } from 'react-intl';
import { FinancialAnalyseDetectionReport } from '../Components/FinancialAnalysesDetectionReport';
import { FinancialRatios } from '../Components/FinancialRatiosReport';
import { DupontTable } from '../Components/DupontTableReportsAndTabs';
import { ModuleGraficReport } from '../Components/ModuleGraficReport';
import {
  IncomeStatementDataSocar,
  AssetsAndResourcesDataSocar,
} from '../Socar/ModuleGrafiklerSocar';

import { CreditAndCombinedScoring } from '../Components/CreditScoringAndCombinedScoringReport';
import { ModuleMakineData } from '../Common/ModuleMakine';

import ReactSpeedometer from 'react-d3-speedometer';

import { findColorAndRisk } from '../Util/module_altman';

export const SessionTableSocar = ({
  scoringVal,

  scoring,
  altmanzscoring,
  dupont,
  assessment,
  incomestatement,
  dname,
  assets,
  resources,
  prediction,
  type,
  trialBalance,
}) => {
  const ref = useRef();

  const print = useCallback(
    function () {
      const win = window.open(
        'about:blank',
        '',
        'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000'
      );

      const styleSheet = document.createElement('style');
      styleSheet.type = 'text/css';
      styleSheet.innerHTML = `
     
            @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&display=swap');
            html{
              background: url("https://f-rayscoring.com/wp-content/uploads/2022/01/bgPDF.png");
              height: 95.6%;
              background-position: center;
              background-size: cover; 
              padding-top:  50px;
              background-repeat: no-repeat;
              position: absolute;
              font-family: 'IBM Plex Sans', sans-serif;
            } 
            header {font-size: 12px; font-weight: bold;color: #1A1A27;text-align: center;position: fixed;top: 0;}
              body {margin:5mm; display: inline-block}
              @media print{ @page {size: A4; margin: 2mm;} }
              .comma > span:not(:last-child)::after { content: ', '; }
              h2, h3, h4 { text-align: center }
            
              h5 {text-align: center; margin-top: -100px}
              button { display: none }
              table { border-collapse: collapse; width: 100%; }
              td, th { border: solid 1px #000; padding: 5px 5px; }
              .min { width: 20%; }
              .min2 { width: 1% !important; } 
              
              .row:after {content: ""; display: table; clear: both; }
           
         
            
            .pageBreakBefore {
          
                    display: block;        
                  break-before: always !important;
                page-break-before: always !important;

                                         

                
           
          
          }
          img {
            height: 150px;
            width: 700px;
         
        

          }
          
          .custom_title{
              
            margin-top: 200px;
        

            text-align: center;
            justify-content: center;
         
          }
            
        `;

      win.document.head.appendChild(styleSheet);
      win.document.body.innerHTML = ref.current.innerHTML;
      win.print();
    },
    [ref]
  );

  const y2 = incomestatement
    ? incomestatement.titles[0].values[1].year.toString()
    : 'no date';

  const AssetsAndResourcesTables = ({ assets, resources }) => {
    const COLORS = ['#0088FE', '#00C49F', '#FF8042'];
    const [data01, data02, data03, data04, y1, y2] =
      AssetsAndResourcesDataSocar({
        assets,
        resources,
      });
    return (
      <>
        <Row>
          <h4>
            <b>
              <u>
                <FormattedMessage id="SUBTAB_ASSET_DISTRIBUTION_TITLE" />
              </u>
            </b>
          </h4>
        </Row>
        <Row>
          <Col style={{ float: 'left', marginLeft: '8em' }}>
            <u>
              <b>{y1}</b>
            </u>
          </Col>
          <Col style={{ float: 'right', marginRight: '10em' }}>
            <u>
              <b>{y2}</b>
            </u>
          </Col>
        </Row>
        <Row>
          <Col style={{ float: 'left' }}>
            <PieChart width={330} height={230}>
              <Pie
                isAnimationActive={false}
                data={data01}
                cx={150}
                cy={100}
                innerRadius={60}
                outerRadius={80}
                fill="#237f19"
                paddingAngle={5}
                cornerRadius={20}
                dataKey="value"
              >
                {data01.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Legend
                wrapperStyle={{ position: 'relative' }}
                align="center"
                payload={data01.map((item, index) => ({
                  // key:index
                  id: item.name,
                  type: 'square',
                  value: `${item.name} ${item.value.toFixed(2)}`,
                  color: COLORS[index],
                }))}
              />
            </PieChart>
          </Col>

          <Col style={{ float: 'right' }}>
            <PieChart width={330} height={230}>
              <Pie
                isAnimationActive={false}
                data={data02}
                cx={150}
                cy={100}
                innerRadius={60}
                outerRadius={80}
                fill="#237f19"
                paddingAngle={5}
                cornerRadius={20}
                dataKey="value"
              >
                {data02.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Legend
                wrapperStyle={{ position: 'relative' }}
                payload={data02.map((item, index) => ({
                  id: item.name,
                  type: 'square',
                  value: `${item.name} ${item.value.toFixed(2)}`,
                  color: COLORS[index],
                }))}
              />
            </PieChart>
          </Col>
        </Row>
        <Row>
          <h4>
            <b>
              <u>
                <FormattedMessage id="SUBTAB_RESOURCES_DISTRIBUTION" />
              </u>
            </b>
          </h4>
        </Row>
        <Row>
          <Col style={{ float: 'left' }}>
            <PieChart width={330} height={230}>
              <Pie
                isAnimationActive={false}
                data={data03}
                cx={150}
                cy={75}
                innerRadius={60}
                outerRadius={80}
                fill="#237f19"
                paddingAngle={5}
                cornerRadius={20}
                dataKey="value"
              >
                {data03.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Legend
                wrapperStyle={{ position: 'relative' }}
                payload={data03.map((item, index) => ({
                  id: item.name,
                  type: 'square',
                  value: `${item.name} ${item.value.toFixed(2)}`,
                  color: COLORS[index],
                }))}
              />
            </PieChart>
          </Col>
          <Col style={{ float: 'right' }}>
            <PieChart width={330} height={230}>
              <Pie
                isAnimationActive={false}
                data={data04}
                cx={150}
                cy={75}
                innerRadius={60}
                outerRadius={80}
                fill="#237f19"
                paddingAngle={5}
                cornerRadius={20}
                dataKey="value"
              >
                {data04.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Legend
                wrapperStyle={{ position: 'relative' }}
                payload={data04.map((item, index) => ({
                  id: item.name,
                  type: 'square',
                  value: `${item.name} ${item.value.toFixed(2)}`,
                  color: COLORS[index],
                }))}
              />
            </PieChart>
          </Col>
        </Row>
      </>
    );
  };
  const Header = ({ logo, type }) => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return (
      <>
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <b>
            <FormattedMessage id="DATE" />: {date}/{month}/{year}
          </b>
        </div>

        <div className="custom_title">
          {logo == 'argeteknokent' && (
            <img src="https://f-rayscoring.com/wp-content/uploads/2022/01/disi-logo-e1641909656297.png"></img>
          )}
          <img src="https://f-rayscoring.com/wp-content/uploads/2021/06/fray-logo.png"></img>
          <h2>
            {dname} - {y2} -{' '}
            {type == 'mizan' ? (
              <FormattedMessage id="TRIAL_BALANCE_ANAYSIS" />
            ) : (
              <FormattedMessage id="DECLERATION_ANALYSIS" />
            )}
          </h2>
        </div>

        <div style={{ marginTop: '500px' }}>
          {type !== 'beyanname' && (
            <>
              <h4>
                <FormattedMessage id="INFORMATION_TITLE" />
              </h4>
              <p>
                <FormattedMessage id="INFORMATION_TEXT_2" />
              </p>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <Button
        type="button"
        className="text-white"
        style={{ background: '#795D9C' }}
        onClick={print}
      >
        <FaPrint style={{ marginRight: '10px' }} />{' '}
        <FormattedMessage id="SUBTAB_PRINT_FINANCIAL_ANALYSIS_REPORT" />
      </Button>

      <div style={{ display: 'none' }} ref={ref}>
        <Row className="pageBreakBefore">
          <Header type={type} />
        </Row>
        <body>
          <Row className="pageBreakBefore">
            {(trialBalance || assessment) && (
              <FinancialAnalyseDetectionReport
                assessment={assessment}
                trialBalance={trialBalance}
                type={type}
                scoring={scoring}
              />
            )}
          </Row>
          <Row className="pageBreakBefore">
            {incomestatement && (
              <ModuleGraficReport
                incomestatement={incomestatement.titles}
                dataAnalyseForCompany={IncomeStatementDataSocar}
              />
            )}
          </Row>
          {assets && resources && (
            <Row className="pageBreakBefore">
              <AssetsAndResourcesTables assets={assets} resources={resources} />
            </Row>
          )}

          <Row className="pageBreakBefore" style={{ marginTop: '10em' }}>
            <h4>
              <b>
                <u>
                  <FormattedMessage id="SUBTAB_DUPONT_TITLE" />
                </u>
              </b>
            </h4>

            {dupont && (
              <DupontTable categories={dupont.categories} disabled={false} />
            )}
          </Row>
          <Row className="pageBreakBefore">
            {altmanzscoring && (
              <AltmanBrPrediction altmanzscoring={altmanzscoring} />
            )}
          </Row>
          <Row className="pageBreakBefore" style={{ marginTop: '10em' }}>
            {scoring && scoringVal && (
              <CreditAndCombinedScoring
                scoring={scoring}
                scoringVal={scoringVal}
                combinedScore={''}
                socar
              />
            )}
          </Row>
          {prediction && (
            <Row className="pageBreakBefore">
              <h4>
                <b>
                  <u>
                    <FormattedMessage id="SUBTAB_1_5_MACHINE_LEARNING" />
                  </u>
                </b>
              </h4>
              <ModuleMakineData prediction={prediction} />
              <div style={{ marginTop: '500px' }}>
                <h4>
                  <FormattedMessage id="SUBTAB_WARNING_TITLE" />:
                </h4>
                <p>
                  <FormattedMessage id="SUBTAB_WARNING_TEXT" />
                </p>
              </div>
            </Row>
          )}

          <Row className="pageBreakBefore">
            {scoring && (
              <FinancialRatios
                scoring={scoring}
                companyCode="socar"
                disabled={false}
              />
            )}
          </Row>

          <Row className="pageBreakBefore" style={{ marginTop: '100px' }}>
            <div>
              <h4>
                <b>
                  <u>
                    <FormattedMessage id="INFORMATION_TITLE" />
                  </u>
                </b>
              </h4>
              <p style={{ textAlign: 'justify' }}>
                <FormattedMessage id="INFORMATION_TEXT" />
              </p>
              <p style={{ textAlign: 'justify' }}>
                <FormattedMessage id="INFORMATION_TEXT_3" />
              </p>
            </div>
          </Row>
        </body>
      </div>
    </>
  );
};

export default SessionTableSocar;

const AltmanBrPrediction = ({ altmanzscoring }) => {
  const currentValueText = '';
  const findGauge = (val) => {
    let gauge = 80;
    if (val === 'Düşük İflas Riski' || val === 'Low Risk') gauge = 10;
    else if (val === 'Average Risk' || val === 'Ortalama İflas Riski')
      gauge = 50;
    return gauge;
  };

  const y1 = altmanzscoring && altmanzscoring.titles[0].values[0].year;
  const y2 = altmanzscoring && altmanzscoring.titles[0].values[1].year;

  let alt_puan_1 = '';
  let alt_puan_2 = '';
  let alt_puan_3 = '';
  let alt_puan_4 = '';

  let gauge_1;
  let gauge_2;
  let gauge_3;
  let gauge_4;

  if (altmanzscoring && altmanzscoring.titles) {
    const azTitlesLength = altmanzscoring && altmanzscoring.titles.length;
    for (var i = azTitlesLength - 1; i >= azTitlesLength - 4; i--) {
      const title = altmanzscoring.titles[i].title;
      if (
        title === "Altman Z' Puan-İmalatçı Firmalar" ||
        title === "Z' Score Manufacturing"
      ) {
        alt_puan_1 = altmanzscoring.titles[i].values[0].value.toString();
        alt_puan_2 = altmanzscoring.titles[i].values[1].value.toString();
      } else if (
        title === "Altman Z' Puan-İmalat Harici Firmalar" ||
        title === "Z' Score Non-Manufacturing"
      ) {
        alt_puan_3 = altmanzscoring.titles[i].values[0].value.toString();
        alt_puan_4 = altmanzscoring.titles[i].values[1].value.toString();
      } else if (
        title === "Altman Z' Puan Riski-İmalatçı Firmalar" ||
        title === 'Risk Category/Manufacturing'
      ) {
        gauge_1 = findGauge(
          altmanzscoring.titles[i].values[0].value.toString()
        );
        gauge_2 = findGauge(
          altmanzscoring.titles[i].values[1].value.toString()
        );
      } else if (
        title === "Altman Z' Puan Riski-İmalat Harici Firmalar" ||
        title === 'Risk Category/Non-Manufacturing'
      ) {
        gauge_3 = findGauge(
          altmanzscoring.titles[i].values[0].value.toString()
        );
        gauge_4 = findGauge(
          altmanzscoring.titles[i].values[1].value.toString()
        );
      }
    }
  }

  return (
    <>
      <h4>
        <b>
          <u>
            <FormattedMessage id="SUBTAB_1_4_ALTMAN_BANKRUPTCY_FORECAST" />
          </u>
        </b>
      </h4>

      <h4>
        <b>
          <FormattedMessage id="SUBTAB_MANUFACTORING_TITLE" />
        </b>
      </h4>

      <Row>
        <Col style={{ float: 'left', marginLeft: '8em' }}>
          <u>
            <b>{y1}</b>
          </u>
        </Col>
        <Col style={{ float: 'right', marginRight: '8em' }}>
          <u>
            <b>{y2}</b>
          </u>
        </Col>
      </Row>
      <Row>
        <Col md={6} style={{ float: 'left' }}>
          <ReactSpeedometer
            needleHeightRatio={0.7}
            ringWidth={10}
            minValue={0}
            maxValue={100}
            segments={3}
            value={gauge_1}
            needleTransition="easeElastic"
            needleTransitionDuration={3000}
            segmentColors={['#63E02D', '#FFCA28', '#D41B1B']}
            textColor="#ffff"
            currentValueText={currentValueText}
          />
          {findColorAndRisk(gauge_1, alt_puan_1)}
        </Col>
        <Col md={6} style={{ float: 'right' }}>
          <ReactSpeedometer
            needleHeightRatio={0.7}
            ringWidth={10}
            minValue={0}
            maxValue={100}
            segments={3}
            value={gauge_2}
            needleTransition="easeElastic"
            needleTransitionDuration={3000}
            segmentColors={['#63E02D', '#FFCA28', '#D41B1B']}
            textColor="#ffff"
            currentValueText={currentValueText}
          />
          {findColorAndRisk(gauge_2, alt_puan_2)}
        </Col>
      </Row>
      <Row>
        <h4>
          <b>
            <FormattedMessage id="SUBTAB_NON_MANUFACTORING_TITLE" />
          </b>
        </h4>
      </Row>
      <Row>
        <Col style={{ float: 'left' }}>
          <ReactSpeedometer
            needleHeightRatio={0.7}
            ringWidth={10}
            minValue={0}
            maxValue={100}
            segments={3}
            value={gauge_3}
            needleTransition="easeElastic"
            needleTransitionDuration={3000}
            segmentColors={['#63E02D', '#FFCA28', '#D41B1B']}
            textColor="#ffff"
            currentValueText={currentValueText}
          />
          {findColorAndRisk(gauge_3, alt_puan_3)}
        </Col>
        <Col style={{ float: 'right' }}>
          <ReactSpeedometer
            style={{ bottom: '-100px' }}
            needleHeightRatio={0.7}
            ringWidth={10}
            minValue={0}
            maxValue={100}
            segments={3}
            value={gauge_4}
            needleTransition="easeElastic"
            needleTransitionDuration={3000}
            segmentColors={['#63E02D', '#FFCA28', '#D41B1B']}
            currentValueText={currentValueText}
            textColor="#ffff"
          />
          {findColorAndRisk(gauge_4, alt_puan_4)}
        </Col>
      </Row>
    </>
  );
};
