import React from 'react';
import { useFetch } from 'react-admin-base';
import { useUser } from './UserProvider';
import { useLanguage } from 'react-admin-base-bootstrap';

export const ChangeLanguageOption = () => {
  const user = useUser();
  const [company] = useFetch('/company/get/user/' + user.id);

  const companyCode = company && company.code;

  const [, , setLanguage] = useLanguage();

  const isGlobal = companyCode === 'global';
  React.useEffect(() => {
    if (isGlobal) {
      setLanguage('en');
    }
  }, [company]);
};
