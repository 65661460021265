import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { colors, fontSizes, padding, margin, cellHeight } from '../Common';
import { FormattedMessage } from 'react-intl';
import { TableHeader } from './TableHeader';

export const CombinedScore = ({ combinedScore }) => {
  const styles = StyleSheet.create({
    table: {
      border: 'none',
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginTop: margin,
      minHeight: cellHeight,
      alignItems: 'stretch',
      pageBreakInside: 'avoid',
    },
    tableCol: {
      minHeight: cellHeight,
      marginRight: margin,
      display: 'flex',
      textAlign: 'left',
      justifyContent: 'center',
    },
    tableCell: {
      padding: padding,
      fontSize: fontSizes.title,
      color: colors.primary,
    },
    content: {
      color: colors.primary,
      fontSize: fontSizes.content,
      position: 'relative',
      padding: padding,
    },
    icon: {
      width: '20px',
      height: '20px',
    },
  });

  const dupontTitles = [
    'Combined Score',
    'Scoring Weights',
    'Weighted Score',
    'Score Before Weight',
  ];

  return (
    <View style={styles.table}>
      <TableHeader
        title=<FormattedMessage id="COMBINED_SCORE" />
        styles={{
          ...styles,
          tableCell: { ...styles.tableCell, fontSize: fontSizes.header },
        }}
        title2=""
      />
      <View style={styles.tableRow}>
        {dupontTitles.map((e, i) => {
          return (
            <View
              style={[
                styles.tableCol,
                {
                  backgroundColor: i % 2 === 0 ? colors.cell2 : colors.cell1,
                  flex: i === 0 ? 2 : 1,
                  marginRight: i === dupontTitles.length - 1 ? 0 : '5px',

                  fontWeight: 600,
                },
              ]}
              key={i}
            >
              <Text style={[styles.tableCell]}>{e}</Text>
            </View>
          );
        })}
      </View>
      {combinedScore.map((e, i) => {
        return (
          <>
            {combinedScore &&
              combinedScore.map((ab, index) => (
                <tr key={index}>
                  <td>{ab['scoreName']}</td>
                  <td>{ab['scoringWeight']}</td>
                  <td>{ab['weightedScore']}</td>
                  <td>{ab['scoreWithoutWeight']}</td>
                </tr>
              ))}
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor: colors.cell1,
                    flex: 2,
                  },
                ]}
              >
                <Text style={styles.tableCell}>{e.scoreName}</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor: colors.cell2,
                    flex: 1,
                  },
                ]}
              >
                <Text style={styles.tableCell}>{e.scoringWeight}</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor: colors.cell2,
                    flex: 1,
                  },
                ]}
              >
                <Text style={styles.tableCell}>{e.weightedScore}</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor: colors.cell2,
                    flex: 1,
                    marginRight: 0,
                  },
                ]}
              >
                <Text style={styles.tableCell}>{e.scoreWithoutWeight}</Text>
              </View>
            </View>
          </>
        );
      })}
    </View>
  );
};
