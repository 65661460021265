/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Table,
  Form,
  Row,
  FormGroup,
  Label,
  Button,
  Col,
} from 'reactstrap';
import { Breadcrumb } from 'react-admin-base-nazox';
import { Input } from 'reactstrap';
import { months2 } from '../../Util/Constants';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useFetch } from 'react-admin-base';
import { Alerts } from '../../Components/Alerts';
import { fireSwal } from '../../Components/SwalFire';
import { useAuth } from 'react-admin-base';
import { Loading } from '../../Components/Loading';

export const Rates = () => {
  const [addedYear, setAddedYear] = useState();
  const [years, setYears] = useState([]);
  const [year, setYear] = useState('');
  const [filtered, setFiltered] = useState({});
  const [currencies, loading, , update] = useFetch('/currency/get/all');
  const [postLoading, setPostLoading] = useState(false);
  const [api] = useAuth();
  const intl = useIntl();
  const onBlur = (e, index) => {
    const { name } = e.currentTarget;
    if (!e.target.value) {
      setFiltered((prev) => ({
        ...prev,
        [year]: prev[year].map((e, i) =>
          i === index ? { ...e, [name]: 0 } : e
        ),
      }));
    }
  };
  useEffect(() => {
    if (currencies) {
      const yearss = [...new Set(currencies.map((e) => e.year))];
      setYears(yearss);
      setYear(Number(yearss[0]));
      const filtered = {};
      yearss.forEach((e) => {
        filtered[e] = currencies.filter((c) => c.year === e);
      });
      setFiltered(filtered);
    }
  }, [currencies]);

  const handleChangeRates = (e, index) => {
    const { name, value } = e.currentTarget;
    const list = { ...filtered };
    const newValue =
      value === ',' ? value : +value.replace(/\./g, '').replace(/\,/g, '.');
    list[year][index][name] = newValue;
    setFiltered(list);
  };

  const submitHandle = async (e) => {
    e.preventDefault();

    setPostLoading(true);
    const data = filtered[year].map((e) => ({
      ...e,
      year: year,
      usdMonthlyAverage: +e.usdMonthlyAverage,
      usdYearlyAverage: +e.usdYearlyAverage,
      eurMonthlyAverage: +e.eurMonthlyAverage,
      eurYearlyAverage: +e.eurYearlyAverage,
    }));
    try {
      const response = await api.tokenized.post('/currency/add/', data);
      if (response.status === 200) {
        update();
        fireSwal({
          background: '#90EE90',
          icon: 'success',
          text: intl.formatMessage({ id: 'SAVED' }),
        });
      }
    } catch (e) {
      let messageId = 'SOMETHING_WRONG';
      if (e.response.status === 403) {
        messageId = 'NO_AUTHORIZATION';
      }
      fireSwal({
        background: '#ffd8df',
        icon: 'error',
        text: intl.formatMessage({ id: messageId }),
      });
    }

    setPostLoading(false);
  };

  const handleDeleteYear = async (year) => {
    setPostLoading(true);

    try {
      const response = await api.tokenized.delete(
        `/currency/delete/yearly/${year}`
      );
      if (response.status === 200) {
        const filteredYears = years.filter((e) => e !== year);
        setYears(filteredYears);
        const filteredData = { ...filtered };
        delete filteredData[year];
        setFiltered(filteredData);
        setYear(Number(filteredYears[0]) || '');
        fireSwal({
          background: '#90EE90',
          icon: 'success',
          text: intl.formatMessage({ id: 'SAVED' }),
        });
      }
    } catch (e) {
      let messageId = 'SOMETHING_WRONG';
      if (e.response.status === 403) {
        messageId = 'NO_AUTHORIZATION';
      }
      fireSwal({
        background: '#ffd8df',
        icon: 'error',
        text: intl.formatMessage({ id: messageId }),
      });
    }
    setPostLoading(false);
  };

  return (
    <Breadcrumb
      title=<FormattedMessage id={'RATES'} />
      data={[
        {
          href: '/rates',
          name: <FormattedMessage id={'RATES'} />,
        },
      ]}
    >
      <Form onSubmit={submitHandle}>
        <Card>
          <CardBody>
            {!loading ? (
              <>
                <Row>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id={'Year'} />
                      <Input
                        name="year"
                        value={addedYear || ''}
                        onChange={(e) => {
                          setAddedYear(Number(e.currentTarget.value));
                        }}
                        type="number"
                        onKeyPress={(event) => {
                          if (
                            event.key === 'e' ||
                            event.key === '.' ||
                            event.key === '-' ||
                            event.key === ',' ||
                            event.key === '+'
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onPaste={(event) => {
                          let pastedValue = event.clipboardData.getData('text');
                          if (
                            pastedValue?.includes('e') ||
                            pastedValue?.includes('.') ||
                            pastedValue?.includes('-') ||
                            pastedValue?.includes(',') ||
                            pastedValue?.includes('+')
                          ) {
                            event.preventDefault();
                          }
                        }}
                        min={0}
                      />
                    </Label>
                    <Button
                      color="primary"
                      style={{ marginLeft: '1rem' }}
                      onClick={() => {
                        if (years.includes(Number(addedYear))) {
                          fireSwal({
                            background: '#ffd8df',
                            icon: 'error',
                            text: 'Eklemeye çalıştığınız yıl mevcut.',
                          });
                        } else {
                          setYears((prev) => [...prev, addedYear]);
                          setAddedYear('');
                          setYear(Number(addedYear));
                          const defaultState = {
                            year: year,
                            month: 1,
                            usdMonthlyAverage: 0,
                            usdYearlyAverage: 0,
                            eurMonthlyAverage: 0,
                            eurYearlyAverage: 0,
                          };
                          const defaultData = Array.from(
                            { length: 12 },
                            (_, index) => ({
                              ...defaultState,
                              month: index + 1,
                            })
                          );
                          setFiltered((prev) => ({
                            ...prev,
                            [addedYear]: defaultData,
                          }));
                        }
                      }}
                      disabled={!addedYear}
                    >
                      {' '}
                      <FormattedMessage id={'ADD'} />
                    </Button>
                  </FormGroup>
                </Row>

                <Row>
                  {years.length > 0 ? (
                    <>
                      <FormGroup
                        style={{ display: 'flex', justifyContent: 'end' }}
                      >
                        {' '}
                        <Input
                          type="select"
                          style={{ width: '20%' }}
                          onChange={(e) => setYear(e.currentTarget.value)}
                          value={year}
                          validation={year}
                        >
                          {years.map((y, k) => (
                            <option value={y} key={k}>
                              {y}
                            </option>
                          ))}
                        </Input>
                        {year && (
                          <Button
                            type="button"
                            color="danger"
                            outline={true}
                            style={{ marginLeft: '1rem' }}
                            onClick={() => handleDeleteYear(Number(year))}
                          >
                            <i className="fa fa-trash"></i>
                          </Button>
                        )}
                      </FormGroup>
                      <FormGroup>
                        {' '}
                        <Table striped responsive bordered hover size="sm">
                          <thead>
                            <tr style={{ border: '1px solid black' }}>
                              <th></th>
                              <th colSpan={2} className="text-center">
                                {<FormattedMessage id={'MONTHLY_RATES'} />}
                              </th>
                            </tr>
                            <tr style={{ border: '1px solid black' }}>
                              <th className="text-center">
                                {<FormattedMessage id={'Month'} />}
                              </th>
                              <th className="text-center">USD</th>
                              <th className="text-center">EUR</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filtered[year] &&
                              filtered[year].map((e, i) => (
                                <tr
                                  key={i}
                                  style={{ border: '1px solid black' }}
                                >
                                  <td className="text-center">
                                    <FormattedMessage
                                      id={months2[e.month - 1]}
                                    />
                                  </td>
                                  <td>
                                    <NumberFormat
                                      name="usdValue"
                                      allowNegative={false}
                                      className="form-control"
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      value={e.usdValue || ''}
                                      style={{
                                        height: '10px',
                                        width: '130px',
                                        margin: '0 auto',
                                      }}
                                      decimalScale={4}
                                      onChange={(e) => handleChangeRates(e, i)}
                                      onBlur={(e) => onBlur(e, i)}
                                    />
                                  </td>
                                  <td>
                                    <NumberFormat
                                      name="eurValue"
                                      allowNegative={false}
                                      className="form-control"
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      value={e.eurValue || ''}
                                      style={{
                                        height: '10px',
                                        width: '130px',
                                        margin: '0 auto',
                                      }}
                                      decimalScale={4}
                                      onChange={(e) => handleChangeRates(e, i)}
                                      onBlur={(e) => onBlur(e, i)}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </FormGroup>
                    </>
                  ) : (
                    <Label>
                      <FormattedMessage id="SEE_CONTENT" />
                    </Label>
                  )}
                </Row>
              </>
            ) : (
              <Alerts
                color="primary"
                className="fas fa-spin fa-spinner"
                messageId={'LOADING'}
                label=""
              />
            )}
          </CardBody>
        </Card>
        <Row>
          <Col md="4">
            <Button
              disabled={postLoading || !year || !years.length}
              type="submit"
              className="d-block righted"
              color="primary"
            >
              <FormattedMessage id="ADD_RATES_SAVE" />{' '}
              <i className="fas fa-save fa-lg" />
            </Button>
          </Col>
        </Row>
        {postLoading && <Loading />}
      </Form>
    </Breadcrumb>
  );
};
