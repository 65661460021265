import React, { useState } from 'react';
import {
  Card,
  CardBody,
  Row,
  Label,
  Col,
  Button,
  Input,
  CardHeader,
  Form,
  Modal,
  ModalBody,
  Table,
  ModalHeader,
} from 'reactstrap';
import { Tab, Tabs } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useAuth } from 'react-admin-base';
import { Alerts } from '../../Components/Alerts';
import { SingleFilePicker } from 'react-admin-base-bootstrap';
import { useFetch } from 'react-admin-base';
import { fireSwal } from '../../Components/SwalFire';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { Loading } from '../../Components/Loading';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import { taxNoCheck, validTCKN } from '../../Util/Validations';
//import 'bootstrap/dist/css/bootstrap.min.css';
import {
  formContentParams,
  formAttendanceListParams,
  typeEndPoints,
} from './Constants';
import { formatNumber } from '../../Util/formatNumber';

export const CustomerFilesContent = (props) => {
  const { id } = props;
  const intl = useIntl();

  const [API] = useAuth();

  const [fileType, setFileType] = useState(18);

  const [file, setFile] = useState(null);
  const [fileUploadVisible, setFileUploadVisible] = useState(false);
  const [identityCard, loading1, , refetchIdentityCard] = useFetch(
    '/customer/identityCard/' + id
  );
  const [taxBoard, loading2, , refetchTaxBoard] = useFetch(
    '/customer/taxBoard/' + id
  );
  const [authorizedSignatures, loading3, , refetchAuthorizedSignatures] =
    useFetch('/customer/authorizedSignatures/' + id);

  const [operatingCertificate, loading4, , refetchOperatingCertificate] =
    useFetch('/customer/operatingCertificate/' + id);

  const [attendanceList, loading5, , refetchAttendanceList] = useFetch(
    '/customer/attendanceList/' + id
  );

  const [submitLoading, setSubmitLoading] = useState(false);

  const refetchAll = () => {
    refetchIdentityCard();
    refetchTaxBoard();
    refetchAuthorizedSignatures();
    refetchOperatingCertificate();
    refetchAttendanceList();
  };

  const handleDocument = async () => {
    try {
      setSubmitLoading(true);
      await API.tokenized.post(`/customer/add/customerDocument`, {
        customerId: id,
        documentId: file.id,
        type: fileType,
        fileExtension: file.name.match(/\.(....?)$/)[1], // . olacaksa [1] kismi [0] olacak
      });
      fireSwal({
        background: '#90EE90',
        icon: 'success',
        text: intl.formatMessage({ id: 'SAVED' }),
        timer: 3000,
      });
    } catch (e) {
      fireSwal({
        background: '#ffd8df',
        icon: 'error',
        text:
          localStorage.frayjet_frayjet_language === 'en'
            ? e.response.data.message.eng
            : e.response.data.message.tr || '',
        timer: 5000,
      });
    } finally {
      refetchAll();
      setFileUploadVisible(false);
      setFile(null);
      setSubmitLoading(false);
    }
  };

  const toggle = () => setFileUploadVisible(!fileUploadVisible);

  if (loading1 || loading2 || loading3 || loading4 || loading5) {
    return (
      <Alerts
        color="primary"
        className="fas fa-spin fa-spinner"
        messageId="LOADING"
        label=""
      />
    );
  }
  return (
    <>
      {submitLoading && <Loading />}
      {fileUploadVisible && (
        <Modal isOpen toggle={toggle} size="xl">
          <ModalHeader toggle={toggle}>Dosya Yükleme</ModalHeader>
          <ModalBody>
            <Row>
              <Label
                style={{
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                Dosya Yükleme
              </Label>
            </Row>
            <Row>
              <Col>
                <Input
                  type="select"
                  value={fileType}
                  onChange={(e) => setFileType(e.target.value)}
                >
                  <option value="18">TC Kimlik Kartı</option>
                  <option value="19">Vergi Levhası</option>
                  <option value="20">İmza Sirküsü</option>
                  <option value="21">Faaliyet Belgesi</option>
                  <option value="22">Hazirun Belgesi</option>
                </Input>
              </Col>
              <Col>
                <SingleFilePicker value={file} onChange={(e) => setFile(e)} />
              </Col>
            </Row>
            <Row>
              <div
                style={{
                  justifyContent: 'flex-end',
                  display: 'flex',
                }}
              >
                <Button
                  className="d-block righted"
                  color="primary"
                  onClick={handleDocument}
                  disabled={!file}
                  loading={submitLoading ? 'true' : 'false'}
                >
                  Dosyayı Kaydet
                </Button>
              </div>
            </Row>
          </ModalBody>
        </Modal>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          size="sm"
          onClick={() => setFileUploadVisible(true)}
          style={{
            display: 'flex',
          }}
          color="primary"
        >
          {' '}
          Dosya Yükle
        </Button>
      </div>
      <Tabs
        id="controlled-tab-example1"
        className="mb-3"
        unmountOnExit={true}
        mountOnEnter={true}
        transition={false}
      >
        <Tab eventKey="identity-card" title="TC Kimlik Kartı">
          {identityCard && identityCard.length > 0 ? (
            identityCard.map((item, i) => {
              return (
                <DocumentCards
                  data={item}
                  refetchAll={refetchAll}
                  key={i}
                  editable
                  erasable
                />
              );
            })
          ) : (
            <Label>
              <FormattedMessage id="FILE_NOT_FOUND" />
            </Label>
          )}
        </Tab>
        <Tab eventKey="tax-board" title="Vergi Levhası">
          {taxBoard && taxBoard.length > 0 ? (
            taxBoard.map((item, i) => {
              return (
                <DocumentCards
                  data={item}
                  refetchAll={refetchAll}
                  key={i}
                  editable
                  erasable
                />
              );
            })
          ) : (
            <Label>
              <FormattedMessage id="FILE_NOT_FOUND" />
            </Label>
          )}
        </Tab>
        <Tab eventKey="authorized-signatures" title=" İmza Sirküsü">
          {authorizedSignatures && authorizedSignatures.length > 0 ? (
            authorizedSignatures.map((item, i) => {
              return (
                <DocumentCards
                  data={item}
                  refetchAll={refetchAll}
                  key={i}
                  editable
                  erasable
                />
              );
            })
          ) : (
            <Label>
              <FormattedMessage id="FILE_NOT_FOUND" />
            </Label>
          )}
        </Tab>
        <Tab eventKey="operating-certificate" title="Faaliyet Belgesi">
          {operatingCertificate && operatingCertificate.length > 0 ? (
            operatingCertificate.map((item, i) => {
              return (
                <DocumentCards
                  data={item}
                  refetchAll={refetchAll}
                  key={i}
                  editable
                  erasable
                />
              );
            })
          ) : (
            <Label>
              <FormattedMessage id="FILE_NOT_FOUND" />
            </Label>
          )}
        </Tab>
        <Tab eventKey="attendance-list" title="Hazirun Belgesi">
          {attendanceList ? (
            <DocumentCards
              data={attendanceList}
              refetchAll={refetchAll}
              editable={false}
              erasable={false}
            />
          ) : (
            <Label>
              <FormattedMessage id="FILE_NOT_FOUND" />
            </Label>
          )}
        </Tab>
      </Tabs>
    </>
  );
};

const DocumentCards = ({ data, refetchAll, editable, erasable }) => {
  const [formIsVisible, setFormIsVisible] = useState(false);
  const [API] = useAuth();
  const intl = useIntl();

  const cardContent = (e) => {
    const value = data[e.name]
      ? e.type === 'date'
        ? data[e.name].split('T')[0]
        : data[e.name]
      : '-';
    return (
      <Col md="4">
        <Label>
          <FormattedMessage id={e.languageId} />
        </Label>
        : {value}
      </Col>
    );
  };

  const handleDelete = async (id, type) => {
    const val = await Swal.fire({
      title: intl.formatMessage({ id: 'ACTIONS.DELETE.TITLE' }),
      text: intl.formatMessage({ id: 'ACTIONS.DELETE.TEXT' }),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: intl.formatMessage({ id: 'ACTIONS.DELETE.CONFIRM' }),
    });

    if (val.value) {
      try {
        await API.tokenized.post(`/customer/disable/customerDocument`, {
          documentId: id,
          dataProvider: type,
        });
      } catch (e) {
        fireSwal({
          background: '#ffd8df',
          icon: 'error',
          text: e.response.data.message
            ? localStorage.frayjet_frayjet_language === 'en'
              ? e.response.data.message.enn
              : e.response.data.message.tr
            : 'Bir hata meydana geldi.',
          timer: 5000,
        });
      } finally {
        refetchAll();
      }
    }
  };

  const toggle = () => setFormIsVisible(!formIsVisible);

  return (
    <Card>
      <CardHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
            }}
          >
            {editable && (
              <Button
                outline
                className="btn btn-outline-primary"
                style={{
                  width: '10px',
                  height: '15px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '5px',
                  border: 0,
                }}
                onClick={toggle}
              >
                <i className="fa fa-pencil-alt" />{' '}
              </Button>
            )}
            <Button
              outline
              className="btn btn-outline-danger"
              style={{
                width: '10px',
                height: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '5px',
                border: 0,
              }}
              onClick={() => erasable && handleDelete(data.id, data.type)}
            >
              <i className="fa fa-trash" />{' '}
            </Button>
          </div>
        </div>
      </CardHeader>

      <CardBody>
        <Row>
          {/* for attendance list */}
          {typeof data === 'object' && data !== null && Array.isArray(data) ? (
            <AttendanceListContent data={data} />
          ) : (
            formContentParams[data.type].map((e, i) => {
              return cardContent(e);
            })
          )}
        </Row>
      </CardBody>
      {formIsVisible && (
        <FormModal
          toggle={toggle}
          data={data}
          refetchAll={refetchAll}
          API={API}
        />
      )}
    </Card>
  );
};

const FormModal = ({ data, refetchAll, toggle, API }) => {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  const formik = useFormik({
    initialValues: {
      ...data,
    },
    validateOnChange: true,
    validate: (data) => {
      const errors = {};

      if (data.taxIdentityNumber && !taxNoCheck(data.taxIdentityNumber)) {
        errors.taxIdentityNumber = 'VALID';
      }
      if (data.tckn && !validTCKN(data.tckn)) {
        errors.tckn = 'VALID';
      }
      if (data.tcIdentityNo && !validTCKN(data.tcIdentityNo)) {
        errors.tcIdentityNo = 'VALID';
      }
      if (data.tcK_No && !validTCKN(data.tcK_No)) {
        errors.tcK_No = 'VALID';
      }
      return errors;
    },
    onSubmit: async () => {
      try {
        setLoading(true);
        const response = await API.tokenized.put(
          `${typeEndPoints[data.type]}`,

          formik.values
        );

        if (response.status === 200) {
          fireSwal({
            background: '#90EE90',
            icon: 'success',
            text: intl.formatMessage({ id: 'SAVED' }),
            timer: 3000,
          });
        } else throw new Error('Bir Hata Meydana Geldi');
      } catch (e) {
        fireSwal({
          background: '#ffd8df',
          icon: 'error',
          text: e?.response?.data.message || 'Bir Hata Meydana Geldi',
          timer: 5000,
        });
      } finally {
        refetchAll();
        toggle();
        setLoading(false);
      }
    },
  });

  const getErrorMessage = (messageId) => {
    return (
      <p
        style={{
          color: 'red',
          opacity: 0.6,
          position: 'absolute',
          fontSize: '12px',
        }}
      >
        {<FormattedMessage id={messageId} />}{' '}
        {<FormattedMessage id={'REQUIRED'} />}
      </p>
    );
  };

  if (loading) return <Loading />;

  return (
    <Modal isOpen toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="EDIT" />
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={formik.handleSubmit}>
          <Row className="mb-2">
            {formContentParams[data.type].map((item, i) => (
              <Col md="4" className="mb-3" key={i}>
                <Label>
                  <FormattedMessage id={item.languageId} />
                </Label>{' '}
                {item.type === 'number' ? (
                  <NumberFormat
                    value={formik.values[item.name]}
                    onChange={formik.handleChange}
                    name={item.name}
                    maxlength="256"
                    className="form-control"
                    {...item.props}
                  />
                ) : (
                  <Input
                    name={item.name}
                    type={item.type}
                    value={formik.values[item.name]}
                    onChange={formik.handleChange}
                    maxlength="256"
                    open={true}
                  >
                    {item.options &&
                      item.options.map((e, i) => (
                        <option value={e.value} key={i}>
                          {e.label}
                        </option>
                      ))}
                  </Input>
                )}
                {item.validation &&
                  formik.touched[item.name] &&
                  formik.errors[item.name] &&
                  getErrorMessage(item.languageId)}
              </Col>
            ))}
          </Row>

          <Row>
            <Col>
              <Button
                type="submit"
                className="d-block righted"
                color="primary"
                loading={formik.isSubmitting ? 'true' : 'false'}
                disabled={formik.isSubmitting}
              >
                <FormattedMessage id="ADD_USER_SAVE" />{' '}
                <i className="fas fa-save fa-lg" />
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

const AttendanceListContent = ({ data }) => {
  if (!data || data.length === 0)
    return (
      <Label>
        <FormattedMessage id="FILE_NOT_FOUND" />
      </Label>
    );

  return data.map((e, j) => {
    return (
      <Table striped responsive bordered hover size="sm" key={j}>
        <thead>
          <tr>
            {formAttendanceListParams.map((e, i) => (
              <th key={i}>
                <FormattedMessage id={e.languageId} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {e.map((e2, i) => {
            return (
              <tr key={i}>
                {formAttendanceListParams.map((e3, i) => (
                  <td key={i}>
                    {e3.format === true
                      ? formatNumber(e2[e3.name])
                      : e2[e3.name]}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  });
};
