import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Breadcrumb } from 'react-admin-base-nazox';
import { FormattedMessage } from 'react-intl';
import { ConsolidationTable } from '../../Components/tables/consolidation/ConsolidationTable';

export const ConsolidationPage = () => {
  return (
    <Breadcrumb
      data={[
        {
          href: '/consolidation',
          name: <FormattedMessage id="CONSOLIDATION" />,
        },
      ]}
    >
      <Card>
        <CardBody>
          <ConsolidationTable />
        </CardBody>
      </Card>
    </Breadcrumb>
  );
};
