import React, { useState } from 'react';
import GoogleChart from '../../../Components/GoogleChart';
import { Row, Input, Col } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

export const TotalAnalyzes = ({ totalAnalyzes }) => {
  const firstYear = Object.keys(totalAnalyzes)[0];
  const [year, setYear] = useState(firstYear);
  const intl = useIntl();
  const keys = Object.keys(totalAnalyzes);
  const newTotalAnalyzes = {};
  if (totalAnalyzes) {
    Object.keys(totalAnalyzes).map((k) => {
      newTotalAnalyzes[k] = {};
      Object.keys(totalAnalyzes[k]).map((k2) => {
        newTotalAnalyzes[k][intl.formatMessage({ id: k2 })] =
          totalAnalyzes[k][k2];
      });
    });
  }

  return (
    <Row>
      <Col md={{ offset: 9 }}>
        <Input
          type="select"
          onChange={(a) => setYear(a.currentTarget.value)}
          style={{ width: '20%' }}
          value={year}
        >
          {keys.map((y) => (
            <option key={y} value={y}>
              {y}
            </option>
          ))}
        </Input>
      </Col>

      <Col>
        <GoogleChart
          title={<FormattedMessage id="TOPLAM_ANALİZ_DURUMU" />}
          data={[
            [
              intl.formatMessage({ id: 'MONTHS' }),
              intl.formatMessage({ id: 'NUMBER_OF_ANALYSIS' }),
            ],
            ...Object.entries(newTotalAnalyzes[year]),
          ]}
          chartType={'BarChart'}
        />
      </Col>
    </Row>
  );
};

export default TotalAnalyzes;
