import React, { useState, useEffect } from 'react';
import {
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';
import { useAuth } from 'react-admin-base';
import { SingleFilePicker } from 'react-admin-base-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useFormSubmit, CustomForm } from '../../../src/Components/CustomForm';
import { useNavigate } from 'react-router-dom';
import { InputField, AsyncPaginateInput } from '../../Components/Input';
import { years } from '../../Util/Constants';
import { checkFileIsPdf } from '../../Util/checkFileIsPdf';

export function AnalysisGlobal() {
  const [api] = useAuth();
  const [done, setDone] = useState(false);
  const navigate = useNavigate();
  const defaultNewState = {
    year: '',
    analysisDetail: {
      analysisCustomerId: '',
    },
    documents: [
      {
        financialFile: null,
        type: '1',
        useAsMain: false,
        pgnum_first: '',
        pgnum_last: '',
        documentLanguage: 'Turkish',
        year: '',
      },
      {
        financialFile: null,
        type: '1',
        useAsMain: true,
        pgnum_first: '',
        pgnum_last: '',
        documentLanguage: 'Turkish',
        year: '',
      },
      {
        financialFile: null,
        type: '7',
        useAsMain: false,
        pgnum_first: '',
        pgnum_last: '',
        documentLanguage: 'Turkish',
        year: '',
      },
      {
        financialFile: null,
        type: '3',
        useAsMain: false,
        pgnum_first: '',
        pgnum_last: '',
        documentLanguage: 'Turkish',
        year: '',
      },
    ],
  };

  const onSubmit = async () => {
    const newDocuments = optionsState.documents.filter(
      (d) => d.financialFile !== null
    );
    const newState = { ...optionsState, ['documents']: newDocuments };

    await api.tokenized.post('/statement', {
      analysisDetail: {
        analysisCustomerId: newState.analysisDetail.analysisCustomerId.value,
      },
      documents: newState.documents.map((a) => ({
        documentId: a.financialFile.id,
        fileExtension: a.financialFile.name.match(/\.(....?)$/)[1], // . olacaksa [1] kismi [0] olacak
        type: +a.type,
        useAsMain: a.useAsMain,
        pageNums:
          !a.pgnum_first || !a.pgnum_last
            ? null
            : a.pgnum_first + '-' + a.pgnum_last, //"3-5",

        documentLanguage: a.documentLanguage || '',
        year: a.year,
      })),
    });
    setDone(true);
  };
  const validateOptions = (values) => {
    const error = {};

    if (!values.year) {
      error.year = 'REQUIRED';
    }

    if (!values.analysisDetail.analysisCustomerId) {
      error.analysisCustomerId = 'REQUIRED';
    }

    values.documents.forEach((v, index) => {
      error[index] = {};
      if (!v.financialFile && index === 1) {
        error[index]['financialFile'] = 'REQUIRED';
      }
      if (
        v.type === '9' &&
        (!v.pgnum_first || !v.pgnum_last) &&
        v.financialFile &&
        checkFileIsPdf(v.financialFile)
      ) {
        error[index]['number'] = 'REQUIRED';
      }
    });
    return error;
  };
  const {
    loading,
    newSave,
    errors,
    optionsState,
    handleChangeDocuments,
    handleFilePicker,
    setError,
    handleYearChange,
    handleFieldsValue,
    handleChangeSector,
  } = useFormSubmit(onSubmit, validateOptions, defaultNewState);

  const goPage = () => {
    const newDocuments = optionsState.documents.filter(
      (d) => d.financialFile !== null
    );
    const documentAsMain = newDocuments.find((e) => e.useAsMain);

    navigate(
      '/report/' + window.btoa(documentAsMain.financialFile.id) + '/summary'
    );
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  if (done) {
    setTimeout(() => {
      goPage();
    }, 2000);
  }

  useEffect(() => {
    const aTags = document.getElementsByClassName(
      'btn btn-sm btn-outline-primary'
    );
    for (var i = 0; i < aTags.length; i++) {
      if (aTags[i].parentElement.lastChild) {
        aTags[i].parentElement.lastChild.style.display = 'none';
      }
      aTags[i].textContent = aTags[i]?.textContent?.substring(0, 8) + '...';
    }
  });

  return (
    <CustomForm
      submit={newSave}
      errors={errors}
      loading={loading}
      id={'REDIRECT_SUMMARY'}
      done={done}
      setError={setError}
    >
      <FormGroup className={'mt-3'}>
        <Row>
          <Col sm="8">
            <AsyncPaginateInput
              id="analysisCustomerId"
              name="analysisCustomerId"
              value={optionsState['analysisCustomerId']}
              onChange={(e) => {
                handleFieldsValue(
                  e
                    ? e
                    : { target: { name: 'analysisCustomerId', value: null } },
                  'analysisDetail'
                );
                e && e.sector
                  ? handleChangeSector(e.sector, 'sectorCode')
                  : handleChangeSector(null, 'sectorCode');
              }}
              api={api}
              validation={errors.validation}
              label=""
            >
              <Button
                style={{ marginLeft: '1em' }}
                size="sm"
                color="success"
                className="fas fa-plus"
                onClick={() => navigate('/customer/add')}
              />
            </AsyncPaginateInput>
          </Col>
          <Col sm="4">
            <InputField
              name="year"
              id="Year"
              type="select"
              value={optionsState.year}
              onChange={(e) => handleYearChange(e)}
              placeHolder="seçiniz"
              validation={errors.validation}
            >
              <option value="" hidden>
                <FormattedMessage id="NEW_ANL_PLACEHOLDER_TEXT" />
              </option>
              {years.map((e, i) => (
                <option key={i}>{e}</option>
              ))}
            </InputField>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup style={{ marginTop: '2rem' }}>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h6
                  style={{ display: 'flex', fontSize: '16px', color: '343a40' }}
                >
                  {optionsState.documents[0].year}{' '}
                  <FormattedMessage id="PREVIOUS_YEAR" /> (
                  <div style={{ color: 'blue' }}>
                    <FormattedMessage id="OPTIONAL" />
                  </div>
                  )
                </h6>
              </CardHeader>
              <CardBody>
                <Row className="mb-2">
                  <Col sm="10">
                    <FormattedMessage id={'Kurumlar Vergisi Beyannamesi'} />
                  </Col>
                  <Col>
                    {' '}
                    <InputField
                      type="checkbox"
                      name="type"
                      value="1"
                      checked={optionsState.documents[0].type === '1'}
                      onChange={(e) => {
                        handleChangeDocuments(e, 0);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm="10">
                    {' '}
                    <FormattedMessage id={'Bağımsız Denetim Raporu'} />
                  </Col>
                  <Col>
                    <InputField
                      type="checkbox"
                      name="type"
                      value="9"
                      checked={optionsState.documents[0].type === '9'}
                      onChange={(e) => {
                        handleChangeDocuments(e, 0);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <SingleFilePicker
                    accepts=".pdf,.xlsx,.png,.jpg"
                    value={optionsState.documents[0]?.financialFile}
                    onChange={(a) => {
                      handleFilePicker(a, 0);
                    }}
                  />
                </Row>
                {optionsState.documents[0].type === '9' &&
                  optionsState.documents[0].financialFile && (
                    <>
                      <Row className="mt-4">
                        {' '}
                        <Label>
                          <FormattedMessage id="DOCUMENT_LANGUAGE" />
                        </Label>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <FormattedMessage id="BAĞIMSIZ_DENETİM_RAPOR_TURKISH" />
                          <Input
                            style={{ marginLeft: '1em' }}
                            name="documentLanguage"
                            type="checkbox"
                            value="Turkish"
                            checked={
                              optionsState.documents[0].documentLanguage ===
                              'Turkish'
                            }
                            onChange={(e) => handleChangeDocuments(e, 0)}
                          />
                        </Col>

                        <Col sm={6}>
                          <FormattedMessage id="BAĞIMSIZ_DENETİM_RAPOR_ENGLISH" />
                          <Input
                            style={{ marginLeft: '1em' }}
                            name="documentLanguage"
                            type="checkbox"
                            value="English"
                            checked={
                              optionsState.documents[0].documentLanguage ===
                              'English'
                            }
                            onChange={(e) => handleChangeDocuments(e, 0)}
                          />
                        </Col>
                      </Row>
                      {checkFileIsPdf(
                        optionsState.documents[0].financialFile
                      ) && (
                        <>
                          <Row className="mt-4">
                            <Label>
                              <FormattedMessage id="FINANCIAL_FILE_PAGE_NUMBERS" />
                            </Label>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              <Label>
                                <FormattedMessage id="START" />
                              </Label>
                            </Col>
                            <Col>
                              <Input
                                bsSize="sm"
                                name="pgnum_first"
                                value={optionsState.documents[0].pgnum_first}
                                onChange={(e) => handleChangeDocuments(e, 0)}
                                type="number"
                                onKeyPress={(event) => {
                                  if (
                                    event.key === 'e' ||
                                    event.key === '.' ||
                                    event.key === '-' ||
                                    event.key === ',' ||
                                    event.key === '+'
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onPaste={(event) => {
                                  let pastedValue =
                                    event.clipboardData.getData('text');
                                  if (
                                    pastedValue?.includes('e') ||
                                    pastedValue?.includes('.') ||
                                    pastedValue?.includes('-') ||
                                    pastedValue?.includes(',') ||
                                    pastedValue?.includes('+')
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                min={0}
                                max={300}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              <Label>
                                <FormattedMessage id="END" />
                              </Label>
                            </Col>

                            <Col>
                              <Input
                                bsSize="sm"
                                name="pgnum_last"
                                value={optionsState.documents[0].pgnum_last}
                                onChange={(e) => handleChangeDocuments(e, 0)}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                      {errors.validation &&
                        errors.validation[0] &&
                        errors.validation[0].number && (
                          <p
                            style={{
                              color: 'red',
                              opacity: 0.6,
                              fontSize: '12px',
                            }}
                          >
                            <FormattedMessage id="FINANCIAL_FILE_PAGE_NUMBERS" />{' '}
                            <FormattedMessage
                              id={errors.validation[0].number}
                            />
                          </p>
                        )}
                    </>
                  )}
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader>
                <h6
                  style={{ display: 'flex', fontSize: '16px', color: '343a40' }}
                >
                  {' '}
                  {optionsState.documents[1].year}{' '}
                  <FormattedMessage id="STATEMENT_YEAR" /> (
                  <div style={{ color: 'red' }}>
                    <FormattedMessage id="REQUIRE" />
                  </div>
                  )
                </h6>
              </CardHeader>
              <CardBody>
                <Row className="mb-2">
                  <Col sm="10">
                    {' '}
                    <FormattedMessage id={'Kurumlar Vergisi Beyannamesi'} />
                  </Col>
                  <Col>
                    {' '}
                    <InputField
                      type="checkbox"
                      name="type"
                      value="1"
                      checked={optionsState.documents[1].type === '1'}
                      onChange={(e) => {
                        handleChangeDocuments(e, 1);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm="10">
                    {' '}
                    <FormattedMessage id={'Bağımsız Denetim Raporu'} />
                  </Col>
                  <Col>
                    <InputField
                      type="checkbox"
                      name="type"
                      value="9"
                      checked={optionsState.documents[1].type === '9'}
                      onChange={(e) => {
                        handleChangeDocuments(e, 1);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <SingleFilePicker
                    accepts=".pdf,.xlsx,.png,.jpg"
                    value={optionsState.documents[1]?.financialFile}
                    onChange={(a) => {
                      handleFilePicker(a, 1);
                    }}
                  />
                </Row>
                <Row>
                  {errors.validation &&
                    errors.validation[1] &&
                    errors.validation[1].financialFile && (
                      <p
                        style={{
                          color: 'red',
                          opacity: 0.6,
                          position: 'absolute',
                          fontSize: '12px',
                        }}
                      >
                        <FormattedMessage id="financialFile" />{' '}
                        <FormattedMessage
                          id={errors.validation[1]?.financialFile}
                        />
                      </p>
                    )}
                </Row>

                {optionsState.documents[1]?.type === '9' &&
                  optionsState.documents[1].financialFile && (
                    <>
                      <Row className="mt-4">
                        {' '}
                        <Label>
                          <FormattedMessage id="DOCUMENT_LANGUAGE" />
                        </Label>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <FormattedMessage id="BAĞIMSIZ_DENETİM_RAPOR_TURKISH" />
                          <Input
                            style={{ marginLeft: '1em' }}
                            name="documentLanguage"
                            type="checkbox"
                            value="Turkish"
                            checked={
                              optionsState.documents[1].documentLanguage ===
                              'Turkish'
                            }
                            onChange={(e) => handleChangeDocuments(e, 1)}
                          />
                        </Col>

                        <Col sm={6}>
                          <FormattedMessage id="BAĞIMSIZ_DENETİM_RAPOR_ENGLISH" />
                          <Input
                            style={{ marginLeft: '1em' }}
                            name="documentLanguage"
                            type="checkbox"
                            value="English"
                            checked={
                              optionsState.documents[1].documentLanguage ===
                              'English'
                            }
                            onChange={(e) => handleChangeDocuments(e, 1)}
                          />
                        </Col>
                      </Row>
                      {checkFileIsPdf(
                        optionsState.documents[1].financialFile
                      ) && (
                        <>
                          <Row className="mt-4">
                            <Label>
                              <FormattedMessage id="FINANCIAL_FILE_PAGE_NUMBERS" />
                            </Label>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              <FormattedMessage id="START" />
                            </Col>
                            <Col>
                              <Input
                                bsSize="sm"
                                name="pgnum_first"
                                value={optionsState.documents[1].pgnum_first}
                                onChange={(e) => handleChangeDocuments(e, 1)}
                                type="number"
                                onKeyPress={(event) => {
                                  if (
                                    event.key === 'e' ||
                                    event.key === '.' ||
                                    event.key === '-' ||
                                    event.key === ',' ||
                                    event.key === '+'
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onPaste={(event) => {
                                  let pastedValue =
                                    event.clipboardData.getData('text');
                                  if (
                                    pastedValue?.includes('e') ||
                                    pastedValue?.includes('.') ||
                                    pastedValue?.includes('-') ||
                                    pastedValue?.includes(',') ||
                                    pastedValue?.includes('+')
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                min={0}
                                max={300}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              <FormattedMessage id="END" />
                            </Col>

                            <Col>
                              <Input
                                bsSize="sm"
                                name="pgnum_last"
                                value={optionsState.documents[1].pgnum_last}
                                onChange={(e) => handleChangeDocuments(e, 1)}
                                type="number"
                                onKeyPress={(event) => {
                                  if (
                                    event.key === 'e' ||
                                    event.key === '.' ||
                                    event.key === '-' ||
                                    event.key === ',' ||
                                    event.key === '+'
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onPaste={(event) => {
                                  let pastedValue =
                                    event.clipboardData.getData('text');
                                  if (
                                    pastedValue?.includes('e') ||
                                    pastedValue?.includes('.') ||
                                    pastedValue?.includes('-') ||
                                    pastedValue?.includes(',') ||
                                    pastedValue?.includes('+')
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                          {errors.validation &&
                            errors.validation[1] &&
                            errors.validation[1].number && (
                              <p
                                style={{
                                  color: 'red',
                                  opacity: 0.6,
                                  fontSize: '12px',
                                }}
                              >
                                <FormattedMessage id="FINANCIAL_FILE_PAGE_NUMBERS" />{' '}
                                <FormattedMessage
                                  id={errors.validation[1].number}
                                />
                              </p>
                            )}
                        </>
                      )}
                    </>
                  )}
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader>
                <h6
                  style={{ display: 'flex', fontSize: '16px', color: '343a40' }}
                >
                  {optionsState.documents[2].year}{' '}
                  <FormattedMessage id="MIDTERM" /> (
                  <div style={{ color: 'blue' }}>
                    <FormattedMessage id="OPTIONAL" />
                  </div>
                  )
                </h6>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="mb-2">
                    <Row className="mb-2">
                      {' '}
                      <FormattedMessage id={'Mizan'} />
                    </Row>
                    <Row>
                      <SingleFilePicker
                        accepts=".pdf,.xlsx,.png,.jpg"
                        className="p-0"
                        value={optionsState.documents[2]?.financialFile}
                        onChange={(a) => {
                          handleFilePicker(a, 2);
                        }}
                      />
                    </Row>
                  </Col>

                  <Col className="mb-2">
                    <Row className="mb-2">
                      <FormattedMessage id="ARA_DONEM_KVB" />
                    </Row>
                    <Row>
                      <SingleFilePicker
                        className="p-0"
                        accepts=".pdf,.xlsx,.png,.jpg"
                        value={optionsState.documents[3]?.financialFile}
                        onChange={(a) => {
                          handleFilePicker(a, 3);
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </FormGroup>
    </CustomForm>
  );
}
