import React, { useCallback, useEffect, useState } from 'react';
import { Header } from 'react-admin-base-nazox';
import { useAuth, useFetch } from 'react-admin-base';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { LanguageSwitcher } from 'react-admin-base-bootstrap';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { useUser } from './Components/UserProvider';

export function ShowExpiration({ withText, credit }) {
  if (credit) {
    return withText ? withText + credit.creditAmount : credit.creditAmount;
  }

  return 'Sınırsız Üyelik';
}

function UserPart() {
  const user = useUser();
  const [api] = useAuth();
  const [show, setShow] = useState(false);
  const intl = useIntl();
  const user_id = user.id;
  const [company, loading1] = useFetch('/company/get/user/' + user_id);
  const [credit, loading2] = useFetch('/credit/get');

  useEffect(() => {
    const element = document.getElementsByClassName('btn btn-ghost-secondary');
    if (element && element[0] && element[0].style) {
      element[0].style.display = 'none';
    }
  }, []);

  const logout = useCallback(
    function () {
      api.logout();
    },
    [api]
  );
  if (loading1 || loading2) return;

  return (
    <Dropdown isOpen={show} toggle={() => setShow(!show)}>
      <DropdownToggle nav caret>
        <FormattedMessage id="USER_GREET" />{' '}
        <b>
          {user.first_name ? user.first_name : user.email} {user.last_name}
        </b>
      </DropdownToggle>
      <DropdownMenu>
        {user.type !== 1 && (
          <DropdownItem tag={Link} to="/profile">
            <FormattedMessage id="MY_PROFILE" />
          </DropdownItem>
        )}
        <DropdownItem divider />
        <DropdownItem onClick={logout}>
          <FormattedMessage id="USER_LOGOUT_CHOICE" />
        </DropdownItem>
        <DropdownItem divider />
        {company && company.paymentType !== 0 && (
          <DropdownItem style={{ pointerEvents: 'none' }}>
            <ShowExpiration
              withText={intl.formatMessage({ id: 'AVAILABLE_CREDIT' })}
              credit={credit}
            />
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}

export default function MenuHeader() {
  useEffect(() => {
    const logoWrapper = document.getElementsByClassName('navbar-brand-box');
    const logo = document.getElementsByClassName('navbar-brand-logo');

    if (logoWrapper && logoWrapper[0]) {
      logoWrapper[0].style.display = 'flex';
      logoWrapper[0].style.justifyContent = 'center';
    }
    if (logo && logo[0]) {
      logo[0].style.maxWidth = '9rem';
    }
  }, []);
  return (
    <Header>
      <div className="flag-remove">
        <LanguageSwitcher />
      </div>
      <UserPart />
    </Header>
  );
}
