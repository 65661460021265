export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const months2 = [
  'January',
  'February',
  'March',
  'April',
  'May2',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const defaultMonthsState = {
  Jan: 0,
  Feb: 0,
  Mar: 0,
  Apr: 0,
  May: 0,
  Jun: 0,
  Jul: 0,
  Aug: 0,
  Sept: 0,
  Oct: 0,
  Nov: 0,
  Dec: 0,
};

export const defaultScoringState = {
  'Çok Zayıf -': '',
  'Çok Zayıf': '',
  'Zayıf -': '',
  Zayıf: '',
  Orta: '',
  'Orta +': '',
  'İyi -': '',
  İyi: '',
  'İyi +': '',
  'Çok iyi +': '',
};

export const getMonth = (date) => {
  return months[new Date(date).getMonth()];
};

export const getYear = (date) => {
  return new Date(date).getFullYear();
};

export const documentOptions = {
  1: 'Kurumlar Vergisi Beyannamesi',
  3: 'Kurumlar Vergisi Ara Dönem Beyannamesi',
  7: 'Mizan',
  9: 'Bağımsız Denetim Raporu',
};

export const companyOptions = [
  'STAR Rafineri A.Ş.',
  'SOCAR Turkey Petrol Tic. A.Ş.',
  'PETKİM',
  'TÜM',
  'Gas Business Unit',
];

export const segmentOptions = [
  'LPG Satışları',
  'Deniz Satışları',
  'Kostik',
  'Akaryakıt Satışları',
  'Havacılık Satışları',
  'STAR Rafineri A.Ş.',
  'TÜM',
  'Gas Business Unit',
];

export const countryList = [
  { countryEn: 'Türkiye', countryTr: 'Türkiye', iso: 'TR' },
  { countryEn: 'Afghanistan', countryTr: 'Afganistan', iso: 'AF' },
  { countryEn: 'Albania', countryTr: 'Arnavutluk', iso: 'AL' },
  { countryEn: 'Algeria', countryTr: 'Cezayir', iso: 'DZ' },
  { countryEn: 'Andorra', countryTr: 'Andorra', iso: 'AD' },
  { countryEn: 'Angola', countryTr: 'Angola', iso: 'AO' },
  { countryEn: 'Argentina', countryTr: 'Arjantin', iso: 'AR' },
  { countryEn: 'Armenia', countryTr: 'Ermenistan', iso: 'AM' },
  { countryEn: 'Australia', countryTr: 'Avustralya', iso: 'AU' },
  { countryEn: 'Austria', countryTr: 'Avusturya', iso: 'AT' },
  { countryEn: 'Azerbaijan', countryTr: 'Azerbaycan', iso: 'AZ' },
  { countryEn: 'Bahamas', countryTr: 'Bahamalar', iso: 'BS' },
  { countryEn: 'Bahrain', countryTr: 'Bahreyn', iso: 'BH' },
  { countryEn: 'Bangladesh', countryTr: 'Bangladeş', iso: 'BD' },
  { countryEn: 'Barbados', countryTr: 'Barbados', iso: 'BB' },
  { countryEn: 'Belarus', countryTr: 'Beyaz Rusya', iso: 'BY' },
  { countryEn: 'Belgium', countryTr: 'Belçika', iso: 'BE' },
  { countryEn: 'Belize', countryTr: 'Belize', iso: 'BZ' },
  { countryEn: 'Benin', countryTr: 'Benin', iso: 'BJ' },
  { countryEn: 'Bhutan', countryTr: 'Butan', iso: 'BT' },
  { countryEn: 'Bolivia', countryTr: 'Bolivya', iso: 'BO' },
  {
    countryEn: 'Bosnia and Herzegovina',
    countryTr: 'Bosna-Hersek',
    iso: 'BA',
  },
  { countryEn: 'Botswana', countryTr: 'Botsvana', iso: 'BW' },
  { countryEn: 'Brazil', countryTr: 'Brezilya', iso: 'BR' },
  { countryEn: 'Brunei', countryTr: 'Brunei', iso: 'BN' },
  { countryEn: 'Bulgaria', countryTr: 'Bulgaristan', iso: 'BG' },
  { countryEn: 'Burkina Faso', countryTr: 'Burkina Faso', iso: 'BF' },
  { countryEn: 'Burundi', countryTr: 'Burundi', iso: 'BI' },
  { countryEn: 'Cabo Verde', countryTr: 'Yeşil Burun Adaları', iso: 'CV' },
  { countryEn: 'Cambodia', countryTr: 'Kamboçya', iso: 'KH' },
  { countryEn: 'Cameroon', countryTr: 'Kamerun', iso: 'CM' },
  { countryEn: 'Canada', countryTr: 'Kanada', iso: 'CA' },
  {
    countryEn: 'Central African Republic',
    countryTr: 'Orta Afrika Cumhuriyeti',
    iso: 'CF',
  },
  { countryEn: 'Chad', countryTr: 'Çad', iso: 'TD' },
  { countryEn: 'Chile', countryTr: 'Şili', iso: 'CL' },
  { countryEn: 'China', countryTr: 'Çin', iso: 'CN' },
  { countryEn: 'Colombia', countryTr: 'Kolombiya', iso: 'CO' },
  { countryEn: 'Comoros', countryTr: 'Komorlar', iso: 'KM' },
  {
    countryEn: 'Congo (Congo-Brazzaville)',
    countryTr: 'Kongo (Brazzaville)',
    iso: 'CG',
  },
  { countryEn: 'Congo (DRC)', countryTr: 'Kongo (DRC)', iso: 'CD' },
  { countryEn: 'Costa Rica', countryTr: 'Kosta Rika', iso: 'CR' },
  { countryEn: 'Croatia', countryTr: 'Hırvatistan', iso: 'HR' },
  { countryEn: 'Cuba', countryTr: 'Küba', iso: 'CU' },
  { countryEn: 'Cyprus', countryTr: 'Kıbrıs', iso: 'CY' },
  { countryEn: 'Czechia', countryTr: 'Çekya', iso: 'CZ' },
  { countryEn: 'Denmark', countryTr: 'Danimarka', iso: 'DK' },
  { countryEn: 'Djibouti', countryTr: 'Cibuti', iso: 'DJ' },
  { countryEn: 'Dominica', countryTr: 'Dominika', iso: 'DM' },
  {
    countryEn: 'Dominican Republic',
    countryTr: 'Dominik Cumhuriyeti',
    iso: 'DO',
  },
  { countryEn: 'Ecuador', countryTr: 'Ekvador', iso: 'EC' },
  { countryEn: 'Egypt', countryTr: 'Mısır', iso: 'EG' },
  { countryEn: 'El Salvador', countryTr: 'El Salvador', iso: 'SV' },
  { countryEn: 'Equatorial Guinea', countryTr: 'Ekvator Ginesi', iso: 'GQ' },
  { countryEn: 'Eritrea', countryTr: 'Eritre', iso: 'ER' },
  { countryEn: 'Estonia', countryTr: 'Estonya', iso: 'EE' },
  { countryEn: 'Eswatini', countryTr: 'Esvatini', iso: 'SZ' },
  { countryEn: 'Ethiopia', countryTr: 'Etiyopya', iso: 'ET' },
  { countryEn: 'Fiji', countryTr: 'Fiji', iso: 'FJ' },
  { countryEn: 'Finland', countryTr: 'Finlandiya', iso: 'FI' },
  { countryEn: 'France', countryTr: 'Fransa', iso: 'FR' },
  { countryEn: 'Gabon', countryTr: 'Gabon', iso: 'GA' },
  { countryEn: 'Gambia', countryTr: 'Gambiya', iso: 'GM' },
  { countryEn: 'Georgia', countryTr: 'Gürcistan', iso: 'GE' },
  { countryEn: 'Germany', countryTr: 'Almanya', iso: 'DE' },
  { countryEn: 'Ghana', countryTr: 'Gana', iso: 'GH' },
  { countryEn: 'Greece', countryTr: 'Yunanistan', iso: 'GR' },
  { countryEn: 'Grenada', countryTr: 'Grenada', iso: 'GD' },
  { countryEn: 'Guatemala', countryTr: 'Guatemala', iso: 'GT' },
  { countryEn: 'Guinea', countryTr: 'Gine', iso: 'GN' },
  { countryEn: 'Guinea-Bissau', countryTr: 'Gine-Bissau', iso: 'GW' },
  { countryEn: 'Guyana', countryTr: 'Guyana', iso: 'GY' },
  { countryEn: 'Haiti', countryTr: 'Haiti', iso: 'HT' },
  { countryEn: 'Honduras', countryTr: 'Honduras', iso: 'HN' },
  { countryEn: 'Hungary', countryTr: 'Macaristan', iso: 'HU' },
  { countryEn: 'Iceland', countryTr: 'İzlanda', iso: 'IS' },
  { countryEn: 'India', countryTr: 'Hindistan', iso: 'IN' },
  { countryEn: 'Indonesia', countryTr: 'Endonezya', iso: 'ID' },
  { countryEn: 'Iran', countryTr: 'İran', iso: 'IR' },
  { countryEn: 'Iraq', countryTr: 'Irak', iso: 'IQ' },
  { countryEn: 'Ireland', countryTr: 'İrlanda', iso: 'IE' },
  { countryEn: 'Israel', countryTr: 'İsrail', iso: 'IL' },
  { countryEn: 'Italy', countryTr: 'İtalya', iso: 'IT' },
  { countryEn: 'Ivory Coast', countryTr: 'Fildişi Sahili', iso: 'CI' },
  { countryEn: 'Jamaica', countryTr: 'Jamaika', iso: 'JM' },
  { countryEn: 'Japan', countryTr: 'Japonya', iso: 'JP' },
  { countryEn: 'Jordan', countryTr: 'Ürdün', iso: 'JO' },
  { countryEn: 'Kazakhstan', countryTr: 'Kazakistan', iso: 'KZ' },
  { countryEn: 'Kenya', countryTr: 'Kenya', iso: 'KE' },
  { countryEn: 'Kiribati', countryTr: 'Kiribati', iso: 'KI' },
  { countryEn: 'Kuwait', countryTr: 'Kuveyt', iso: 'KW' },
  { countryEn: 'Kyrgyzstan', countryTr: 'Kırgızistan', iso: 'KG' },
  { countryEn: 'Laos', countryTr: 'Laos', iso: 'LA' },
  { countryEn: 'Latvia', countryTr: 'Letonya', iso: 'LV' },
  { countryEn: 'Lebanon', countryTr: 'Lübnan', iso: 'LB' },
  { countryEn: 'Lesotho', countryTr: 'Lesoto', iso: 'LS' },
  { countryEn: 'Liberia', countryTr: 'Liberya', iso: 'LR' },
  { countryEn: 'Libya', countryTr: 'Libya', iso: 'LY' },
  { countryEn: 'Liechtenstein', countryTr: 'Lihtenştayn', iso: 'LI' },
  { countryEn: 'Lithuania', countryTr: 'Litvanya', iso: 'LT' },
  { countryEn: 'Luxembourg', countryTr: 'Lüksemburg', iso: 'LU' },
  { countryEn: 'Madagascar', countryTr: 'Madagaskar', iso: 'MG' },
  { countryEn: 'Malawi', countryTr: 'Malavi', iso: 'MW' },
  { countryEn: 'Malaysia', countryTr: 'Malezya', iso: 'MY' },
  { countryEn: 'Maldives', countryTr: 'Maldivler', iso: 'MV' },
  { countryEn: 'Mali', countryTr: 'Mali', iso: 'ML' },
  { countryEn: 'Malta', countryTr: 'Malta', iso: 'MT' },
  { countryEn: 'Marshall Islands', countryTr: 'Marshall Adaları', iso: 'MH' },
  { countryEn: 'Mauritania', countryTr: 'Moritanya', iso: 'MR' },
  { countryEn: 'Mauritius', countryTr: 'Mauritius', iso: 'MU' },
  { countryEn: 'Mexico', countryTr: 'Meksika', iso: 'MX' },
  { countryEn: 'Micronesia', countryTr: 'Mikronezya', iso: 'FM' },
  { countryEn: 'Moldova', countryTr: 'Moldova', iso: 'MD' },
  { countryEn: 'Monaco', countryTr: 'Monako', iso: 'MC' },
  { countryEn: 'Mongolia', countryTr: 'Moğolistan', iso: 'MN' },
  { countryEn: 'Montenegro', countryTr: 'Karadağ', iso: 'ME' },
  { countryEn: 'Morocco', countryTr: 'Fas', iso: 'MA' },
  { countryEn: 'Mozambique', countryTr: 'Mozambik', iso: 'MZ' },
  { countryEn: 'Myanmar', countryTr: 'Myanmar', iso: 'MM' },
  { countryEn: 'Namibia', countryTr: 'Namibya', iso: 'NA' },
  { countryEn: 'Nauru', countryTr: 'Nauru', iso: 'NR' },
  { countryEn: 'Nepal', countryTr: 'Nepal', iso: 'NP' },
  { countryEn: 'Netherlands', countryTr: 'Hollanda', iso: 'NL' },
  { countryEn: 'New Zealand', countryTr: 'Yeni Zelanda', iso: 'NZ' },
  { countryEn: 'Nicaragua', countryTr: 'Nikaragua', iso: 'NI' },
  { countryEn: 'Niger', countryTr: 'Nijer', iso: 'NE' },
  { countryEn: 'Nigeria', countryTr: 'Nijerya', iso: 'NG' },
  { countryEn: 'North Korea', countryTr: 'Kuzey Kore', iso: 'KP' },
  { countryEn: 'North Macedonia', countryTr: 'Kuzey Makedonya', iso: 'MK' },
  { countryEn: 'Norway', countryTr: 'Norveç', iso: 'NO' },
  { countryEn: 'Oman', countryTr: 'Umman', iso: 'OM' },
  { countryEn: 'Pakistan', countryTr: 'Pakistan', iso: 'PK' },
  { countryEn: 'Palau', countryTr: 'Palau', iso: 'PW' },
  { countryEn: 'Palestine', countryTr: 'Filistin', iso: 'PS' },
  { countryEn: 'Panama', countryTr: 'Panama', iso: 'PA' },
  { countryEn: 'Papua New Guinea', countryTr: 'Papua Yeni Gine', iso: 'PG' },
  { countryEn: 'Paraguay', countryTr: 'Paraguay', iso: 'PY' },
  { countryEn: 'Peru', countryTr: 'Peru', iso: 'PE' },
  { countryEn: 'Philippines', countryTr: 'Filipinler', iso: 'PH' },
  { countryEn: 'Poland', countryTr: 'Polonya', iso: 'PL' },
  { countryEn: 'Portugal', countryTr: 'Portekiz', iso: 'PT' },
  { countryEn: 'Qatar', countryTr: 'Katar', iso: 'QA' },
  { countryEn: 'Romania', countryTr: 'Romanya', iso: 'RO' },
  { countryEn: 'Russia', countryTr: 'Rusya', iso: 'RU' },
  { countryEn: 'Rwanda', countryTr: 'Ruanda', iso: 'RW' },
  {
    countryEn: 'Saint Kitts and Nevis',
    countryTr: 'Saint Kitts ve Nevis',
    iso: 'KN',
  },
  { countryEn: 'Saint Lucia', countryTr: 'Saint Lucia', iso: 'LC' },
  {
    countryEn: 'Saint Vincent and the Grenadines',
    countryTr: 'Saint Vincent ve Grenadinler',
    iso: 'VC',
  },
  { countryEn: 'Samoa', countryTr: 'Samoa', iso: 'WS' },
  { countryEn: 'San Marino', countryTr: 'San Marino', iso: 'SM' },
  {
    countryEn: 'Sao Tome and Principe',
    countryTr: 'São Tomé ve Príncipe',
    iso: 'ST',
  },
  { countryEn: 'Saudi Arabia', countryTr: 'Suudi Arabistan', iso: 'SA' },
  { countryEn: 'Senegal', countryTr: 'Senegal', iso: 'SN' },
  { countryEn: 'Serbia', countryTr: 'Sırbistan', iso: 'RS' },
  { countryEn: 'Seychelles', countryTr: 'Seyşeller', iso: 'SC' },
  { countryEn: 'Sierra Leone', countryTr: 'Sierra Leone', iso: 'SL' },
  { countryEn: 'Singapore', countryTr: 'Singapur', iso: 'SG' },
  { countryEn: 'Slovakia', countryTr: 'Slovakya', iso: 'SK' },
  { countryEn: 'Slovenia', countryTr: 'Slovenya', iso: 'SI' },
  { countryEn: 'Solomon Islands', countryTr: 'Solomon Adaları', iso: 'SB' },
  { countryEn: 'Somalia', countryTr: 'Somali', iso: 'SO' },
  { countryEn: 'South Africa', countryTr: 'Güney Afrika', iso: 'ZA' },
  { countryEn: 'South Korea', countryTr: 'Güney Kore', iso: 'KR' },
  { countryEn: 'South Sudan', countryTr: 'Güney Sudan', iso: 'SS' },
  { countryEn: 'Spain', countryTr: 'İspanya', iso: 'ES' },
  { countryEn: 'Sri Lanka', countryTr: 'Sri Lanka', iso: 'LK' },
  { countryEn: 'Sudan', countryTr: 'Sudan', iso: 'SD' },
  { countryEn: 'Suriname', countryTr: 'Surinam', iso: 'SR' },
  { countryEn: 'Sweden', countryTr: 'İsveç', iso: 'SE' },
  { countryEn: 'Switzerland', countryTr: 'İsviçre', iso: 'CH' },
  { countryEn: 'Syria', countryTr: 'Suriye', iso: 'SY' },
  { countryEn: 'Taiwan', countryTr: 'Tayvan', iso: 'TW' },
  { countryEn: 'Tajikistan', countryTr: 'Tacikistan', iso: 'TJ' },
  { countryEn: 'Tanzania', countryTr: 'Tanzanya', iso: 'TZ' },
  { countryEn: 'Thailand', countryTr: 'Tayland', iso: 'TH' },
  { countryEn: 'Togo', countryTr: 'Togo', iso: 'TG' },
  { countryEn: 'Tokelau', countryTr: 'Tokelau', iso: 'TK' },
  { countryEn: 'Tonga', countryTr: 'Tonga', iso: 'TO' },
  {
    countryEn: 'Trinidad and Tobago',
    countryTr: 'Trinidad ve Tobago',

    iso: 'TT',
  },
  { countryEn: 'Tunisia', countryTr: 'Tunus', iso: 'TN' },
  {
    countryEn: 'Turkmenistan',
    countryTr: 'Türkmenistan',

    iso: 'TM',
  },
  {
    countryEn: 'Turks and Caicos Islands',
    countryTr: 'Turks ve Caicos Adaları',

    iso: 'TC',
  },
  { countryEn: 'Tuvalu', countryTr: 'Tuvalu', code: '688', iso: 'TV' },
  {
    countryEn: 'U.S. Virgin Islands',
    countryTr: 'ABD Virjin Adaları',

    iso: 'VI',
  },
  { countryEn: 'Uganda', countryTr: 'Uganda', iso: 'UG' },
  { countryEn: 'Ukraine', countryTr: 'Ukrayna', iso: 'UA' },
  {
    countryEn: 'United Arab Emirates',
    countryTr: 'Birleşik Arap Emirlikleri',

    iso: 'AE',
  },
  {
    countryEn: 'United Kingdom',
    countryTr: 'Birleşik Krallık',

    iso: 'GB',
  },
  {
    countryEn: 'United States',
    countryTr: 'Amerika Birleşik Devletleri',

    iso: 'US',
  },
  { countryEn: 'Uruguay', countryTr: 'Uruguay', iso: 'UY' },
  { countryEn: 'Uzbekistan', countryTr: 'Özbekistan', iso: 'UZ' },
  { countryEn: 'Vanuatu', countryTr: 'Vanuatu', iso: 'VU' },
  { countryEn: 'Vatican', countryTr: 'Vatikan', iso: 'VA' },
  { countryEn: 'Venezuela', countryTr: 'Venezuela', iso: 'VE' },
  { countryEn: 'Vietnam', countryTr: 'Vietnam', iso: 'VN' },
  {
    countryEn: 'Wallis and Futuna',
    countryTr: 'Wallis ve Futuna',
    iso: 'WF',
  },
  {
    countryEn: 'Western Sahara',
    countryTr: 'Batı Sahra',
    iso: 'EH',
  },
  { countryEn: 'Yemen', countryTr: 'Yemen', iso: 'YE' },
  { countryEn: 'Zambia', countryTr: 'Zambiya', iso: 'ZM' },
  { countryEn: 'Zimbabwe', countryTr: 'Zimbabve', iso: 'ZW' },
];
export const cityOfTurkey = [
  'Adana',
  'Adıyaman',
  'Afyon',
  'Ağrı',
  'Amasya',
  'Ankara',
  'Antalya',
  'Artvin',
  'Aydın',
  'Balıkesir',
  'Bilecik',
  'Bingöl',
  'Bitlis',
  'Bolu',
  'Burdur',
  'Bursa',
  'Çanakkale',
  'Çankırı',
  'Çorum',
  'Denizli',
  'Diyarbakır',
  'Edirne',
  'Elazığ',
  'Erzincan',
  'Erzurum',
  'Eskişehir',
  'Gaziantep',
  'Giresun',
  'Gümüşhane',
  'Hakkari',
  'Hatay',
  'Isparta',
  'İçel (Mersin)',
  'İstanbul',
  'İzmir',
  'Kars',
  'Kastamonu',
  'Kayseri',
  'Kırklareli',
  'Kırşehir',
  'Kocaeli',
  'Konya',
  'Kütahya',
  'Malatya',
  'Manisa',
  'Kahramanmaraş',
  'Mardin',
  'Muğla',
  'Muş',
  'Nevşehir',
  'Niğde',
  'Ordu',
  'Rize',
  'Sakarya',
  'Samsun',
  'Siirt',
  'Sinop',
  'Sivas',
  'Tekirdağ',
  'Tokat',
  'Trabzon',
  'Tunceli',
  'Şanlıurfa',
  'Uşak',
  'Van',
  'Yozgat',
  'Zonguldak',
  'Aksaray',
  'Bayburt',
  'Karaman',
  'Kırıkkale',
  'Batman',
  'Şırnak',
  'Bartın',
  'Ardahan',
  'Iğdır',
  'Yalova',
  'Karabük',
  'Kilis',
  'Osmaniye',
  'Düzce',
];

export const currencyOptions = ['TRY', 'USD', 'EUR', 'SAR'];

export const years = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023];

export const roles = {
  1: '1_ROLES',
  64: '64_ROLES',
  128: '128_ROLES',
  256: '256_ROLES',
  512: '512_ROLES',
};

const pattern = /^B\d+$/;
const pattern2 = /^B\d+\.\d+$/;
const pattern3 = /^B\d+\.\d+\.\d+$/;

const boldWords = ['TOPLAM', 'TOPLAMI', 'Toplam', 'Toplamı', 'TOTAL', 'Total'];
const boldWords2 = [
  'FİNANS SEKTÖRÜ FAALİYETLERİNDEN BRÜT KAR (ZARAR)',
  'C. Net Satışlar',
  'Brüt Satış Karı veya Zararı',
  'Faaliyet Karı veya Zararı',
  'Olağan Kar veya Zarar',
  'Dönem Net Karı veya Zararı',
];

export const specifyFontWeight = (e1, e2) => {
  if (boldWords2.includes(e1.title)) return 'bold';
  else if (
    boldWords.some((e) => e1.title && e1.title.includes(e)) &&
    e1.title !== 'Toplam Finansal Borçlar'
  )
    return 'bold';
  else if (!e1 || !e2 || !e1.collapsableInfo || !e2.collapsableInfo)
    return 'normal';
  else if (e1.collapsableInfo.length < e2.collapsableInfo.length) return 'bold';
  else return 'normal';
};

export const specifyColor = (e1, e2) => {
  if (boldWords2.includes(e1.title)) return '#d2dae0';
  if (!e1 || !e2 || !e1.collapsableInfo || !e2.collapsableInfo) return '';
  else if (
    e1.collapsableInfo.length < e2.collapsableInfo.length &&
    e1.collapsableInfo.match(pattern)
  )
    return '#a4b5c0';
  else if (
    e1.collapsableInfo.length < e2.collapsableInfo.length &&
    e1.collapsableInfo.match(pattern2)
  )
    return '#d2dae0';
  else if (
    e1.collapsableInfo.length < e2.collapsableInfo.length &&
    e1.collapsableInfo.match(pattern3)
  )
    return '#e9edf0';
  else return '';
};
export const providerTypeNums = [1, 3, 7, 9, 10, 14, 15, 11, 13, 16];
