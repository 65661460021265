export const colors = {
  primary: '#1C4562',
  secondary: '#2EBC91',
  white: '#FFFFFF',
  black: 'black',
  text: '#272B3E',
  cell1: '#F3F3F5',
  cell2: '#E9E7E8',
};

export const margin = '5px';
export const cellHeight = '38px';
export const fontSizes = {
  header: '16px',
  title: '12px',
  content: '12px',
  title2: '10px',
};
export const padding = '3px 7px';

export const filteredTitlesForTaemed = ['Liquidity', 'Solvency', 'Efficiency'];

export const percentageTitlesForTeamed = ['Profitability Ratios'];

export const percentageNamesForTeamed = ['Financial Liabilities Percentage'];
