/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Breadcrumb } from 'react-admin-base-nazox';
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
  Form,
} from 'reactstrap';
import { useAuth } from 'react-admin-base';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { roles } from '../../Util/Constants';
import { ApiSelect } from 'react-admin-base-bootstrap';
import { fireSwal } from '../../Components/SwalFire';
import { Loading } from '../../Components/Loading';
import { Alerts } from '../../Components/Alerts';
import { AsyncPaginateInput_ } from '../../Components/Selects';

const regex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const defaultState = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  type: 1,
  isCompanyAdmin: false,
  expires_at: '',
  company: null,
};

export const User = () => {
  const { id } = useParams();
  const [api] = useAuth();
  const intl = useIntl();
  const [passwordType, setPasswordType] = useState('password');
  const [postLoading, setLoading] = useState(false);
  const [loading, setLoading2] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading2(true);
      await api.tokenized.get(`/user/${id}`).then((e) => {
        if (e.status === 200) {
          const user = e.data;
          formik.setValues({
            company: { id: user.companyId, fullName: user.companyName },
            isCompanyAdmin: user.isCompanyAdmin,
            expires_at:
              user.type >= 256
                ? user.expires_at
                  ? user.expires_at.split('T')[0]
                  : ''
                : '',
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            password: user.password || '',
            type: user.type,
            id: user.id,
          });
        }
        setLoading2(false);
      });
    };
    api && id && fetchData();
  }, [id, api]);

  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
    } else setPasswordType('password');
  };
  const formik = useFormik({
    initialValues: {
      ...defaultState,
    },
    validateOnChange: true,
    validate: (data) => {
      const errors = {};

      if (!data.company) errors.company = 'FILL_COMPANY';
      if (data.type >= 256 && !data.expires_at)
        errors.expires_at = 'FILL_EXPIRES_AT';
      if (!data.first_name) errors.first_name = 'FILL_NAME';
      else if (data.first_name && data.first_name.length <= 2)
        errors.first_name = 'VALID_NAME';

      if (!data.last_name) errors.last_name = 'FILL_SURNAME';
      else if (data.last_name && data.last_name.length <= 2)
        errors.last_name = 'VALID_SURNAME';

      if (!data.email) errors.email = 'FILL_EMAIL';
      else if (regex.test(data.email) === false) errors.email = 'VALID_EMAIL';

      if (!data.password) {
        errors.password = 'NO_PASSWORD';
      } else if (data.password) {
        if (data.password.length < 8) {
          errors.password = 'PASSWORD_EIGHT';
        } else if (!/[A-Z]/.test(data.password) || !/\d/.test(data.password)) {
          errors.password = 'PASSWORD_LEAST';
        }
      }
      if (data.type >= 256 && !data.expires_at) {
        errors.expires_at = 'FILL_EXPIRES_AT';
      }

      return errors;
    },

    onSubmit: async (data, { resetForm }) => {
      setLoading(true);
      try {
        let response;
        const newData = {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          password: data.password,
          type: data.type,
          isCompanyAdmin: data.isCompanyAdmin,
          expires_at: data.expires_at,
          companyId: data.company && data.company.id,
        };
        if (id) {
          response = await api.tokenized.patch(`/user/${id}`, newData);
        } else {
          response = await api.tokenized.put('/user', newData);
        }
        if (response.status === 200) {
          fireSwal({
            icon: 'success',
            text: intl.formatMessage({ id: 'SAVED' }),
          });
          resetForm();
        } else throw new Error();
      } catch (error) {
        const data = error?.response?.data;
        const status = error?.response?.status;

        if (status === 400) {
          const id_ = data.split('Kullanıcı id:')[1];
          console.log(id_);
          await api.tokenized.get(`/user/${id_}`).then((e) => {
            if (e.status === 200) {
              const user = e.data;
              const { email, first_name } = user;
              const message = () => {
                return `  <div>
                  <p>${intl.formatMessage({ id: 'MEMBER_EXIST' })}</p>
                  <p>
                    ${intl.formatMessage({ id: 'EMAIL' })} : ${email}
                  </p>
                   <p>
                    ${intl.formatMessage({ id: 'PROFILE_NAME' })} : ${first_name}
                  </p>
                  <p>
                    ${intl.formatMessage({ id: 'MEMBER_ID' })} : ${id_}
                  </p>
                </div>`;
              };

              fireSwal({
                icon: 'error',
                html: message(),
              });
            } else {
              fireSwal({
                icon: 'error',
                text: intl.formatMessage({ id: 'ERROR' }),
              });
            }
          });
        } else
          fireSwal({
            icon: 'error',
            text: data || intl.formatMessage({ id: 'ERROR' }),
          });
      } finally {
        setLoading(false);
      }
    },
  });
  const getFormErrorMessage = (name) => {
    if (formik.touched[name] && formik.errors[name]) {
      return (
        <span
          style={{
            color: 'red',
            opacity: 0.8,
            position: 'absolute',
          }}
        >
          {<FormattedMessage id={formik.errors[name]} />}
        </span>
      );
    }

    return null;
  };

  const handleTypeChange = (e) => {
    formik.setFieldValue('type', e.target.value);
    if (e.target.value === 1 || e.target.value === 128) {
      formik.setFieldValue('expires_at', '');
    }
  };

  return (
    <Breadcrumb
      title={<FormattedMessage id="ADD_USER_TITLE" />}
      data={[
        {
          href: '/user',
          name: <FormattedMessage id="ADD_USER_TITLE" />,
        },
        formik.values.id && {
          href: '/user/' + formik.values.id + '/edit',
          name: formik.values.first_name,
        },
        !formik.values.id && {
          href: '/user/create',
          name: <FormattedMessage id="CREATE" />,
        },
      ]}
    >
      {' '}
      <Form onSubmit={formik.handleSubmit}>
        <Card>
          <CardBody>
            {loading ? (
              <Alerts
                color="primary"
                className="fas fa-spin fa-spinner"
                messageId={'LOADING'}
                label=""
              />
            ) : (
              <>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="first_name">
                        <FormattedMessage id="PROFILE_NAME" />
                      </Label>
                      <Input
                        type="text"
                        name="first_name"
                        id="first_name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                      />
                      {getFormErrorMessage('first_name')}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="surname">
                        <FormattedMessage id="PROFILE_SURNAME" />
                      </Label>
                      <Input
                        type="text"
                        name="last_name"
                        id="last_name"
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                      />
                      {getFormErrorMessage('last_name')}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="email">
                        <FormattedMessage id="EMAIL" />
                      </Label>
                      <Input
                        name="email"
                        id="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />

                      {getFormErrorMessage('email')}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <Label for="password">
                      {' '}
                      <FormattedMessage id="PASSWORD" />
                    </Label>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ width: '100%' }}>
                        <Input
                          type={passwordType}
                          name="password"
                          id="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          autoComplete="new-password"
                          maxLength={32}
                        />
                        {getFormErrorMessage('password')}
                      </div>

                      {passwordType === 'password' ? (
                        <i
                          className="bi bi-eye-slash"
                          onClick={togglePassword}
                          style={{
                            position: 'absolute',
                            cursor: 'pointer',
                            right: '20px',
                          }}
                        ></i>
                      ) : (
                        <i
                          className="bi bi-eye"
                          onClick={togglePassword}
                          style={{
                            position: 'absolute',
                            cursor: 'pointer',
                            right: '20px',
                          }}
                        ></i>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Label for="type">
                      {' '}
                      <FormattedMessage id="ADD_USER_TYPE_OF_MEMBERSHIP" />
                    </Label>
                    <Input
                      type="select"
                      name="type"
                      id="type"
                      value={formik.values.type}
                      onChange={(e) => handleTypeChange(e)}
                      placeholder="seçiniz"
                    >
                      {Object.entries(roles).map(([role, name]) => (
                        <option value={role} key={id}>
                          {<FormattedMessage id={name} />}
                        </option>
                      ))}
                    </Input>
                    {getFormErrorMessage('type')}
                  </Col>
                  <Col md={6}>
                    <Label>
                      {' '}
                      {<FormattedMessage id="analysisCompanyTitle" />}
                    </Label>
                    {/* <ApiSelect
                      placeholder={
                        <FormattedMessage id="NEW_ANL_PLACEHOLDER_TEXT" />
                      }
                      url="/company/get/all"
                      getOptionValue={(a) => a.id}
                      getOptionLabel={(a) => a.fullName}
                      value={formik.values.company}
                      onChange={(e) => {
                        formik.setFieldValue(
                          'company',
                          e ? { id: e.id, fullName: e.fullName } : null
                        );
                      }}
                    /> */}
                    <AsyncPaginateInput_
                      value={formik.values.company}
                      isMulti={false}
                      onChange={(e) => {
                        formik.setFieldValue(
                          'company',
                          e ? { id: e.id, fullName: e.fullName } : null
                        );
                      }}
                      getOptionValue={(a) => a.id}
                      getOptionLabel={(a) => a.fullName}
                      filterResponse={(res) => res.data}
                      url="/company/get/all"
                    />
                    {getFormErrorMessage('company')}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <Label>
                      <FormattedMessage id="SEE_ALL_ANALYSIS" />
                    </Label>
                    <div>
                      <Label for="isCompanyAdmin">
                        <FormattedMessage id="YES" />
                      </Label>{' '}
                      <Input
                        name="isCompanyAdmin"
                        type="checkbox"
                        id="isCompanyAdmin"
                        checked={formik.values.isCompanyAdmin === true}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          formik.setFieldValue('isCompanyAdmin', checked);
                        }}
                      />
                      {getFormErrorMessage('isCompanyAdmin')}
                    </div>
                  </Col>

                  {+formik.values.type >= 256 && (
                    <Col md={6}>
                      <FormGroup>
                        <Label for="expires_at">
                          {' '}
                          <FormattedMessage id="ADD_ADMIN_FINISH_DATE" />
                        </Label>
                        <Input
                          type="date"
                          name="expires_at"
                          id="expires_at"
                          value={formik.values.expires_at}
                          onChange={formik.handleChange}
                        />
                        {getFormErrorMessage('expires_at')}
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </>
            )}
          </CardBody>
        </Card>

        {!loading && (
          <Row>
            <Col md="4">
              <Button
                type="submit"
                className="d-block righted"
                color="primary"
                loading={formik.isSubmitting ? 'true' : 'false'}
                disabled={formik.isSubmitting}
              >
                <FormattedMessage id="SEND" />{' '}
                <i className="fas fa-save fa-lg" />
              </Button>
            </Col>
          </Row>
        )}
      </Form>
      {postLoading && <Loading />}
    </Breadcrumb>
  );
};
