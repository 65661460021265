import React, { useState } from 'react';
import FirmLoadingAlert from '../Components/Alerts';
import {
  CardBody,
  Nav,
  NavItem,
  Table,
  NavLink,
  Row,
  Col,
  Card,
} from 'reactstrap';
import DisableClipoard from '../Components/DisableClipoard';
import { Tab, Tabs } from 'react-bootstrap';
import SessionTableCustomButton from '../pages/Summarys/SessionTableCustom';
import { FormattedMessage } from 'react-intl';
import { DownloadFileButton } from '../Components/DownloadFileButton';
import { formatNumber } from '../Util/formatNumber';

export function CustomFinansalOranlar({
  value,
  slug,
  assessment,
  dname,
  logo,
}) {
  const [key4, setKey] = useState(slug + 0);
  const [index, setIndex] = useState(0);

  // let result = axios
  //   .post(url, {
  //     method: "GET",
  //     responseType: "blob", // important
  //   })
  //   .then((response) => {
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", `${Date.now()}.xlsx`);
  //     document.body.appendChild(link);
  //     link.click();
  //   });
  // Authorization: `Bearer ${localStorage.getItem("fray_access_token")}`,
  // const [exportTable] = useFetch("/analyze/sap/" + slug);

  var aktiv;
  if (assessment && assessment.titles) {
    aktiv = assessment.titles && assessment.titles[index];
  }
  if (!value || !value.ratios) return <FirmLoadingAlert />;

  if (!assessment) return <FirmLoadingAlert />;
  if (!aktiv) {
    return <FirmLoadingAlert />;
  }
  var ratios = value.ratios;

  return (
    <div className={'mt-2'}>
      <DisableClipoard>
        <CardBody>
          <DownloadFileButton
            style={{ float: 'right' }}
            dname={dname}
            messageId={<FormattedMessage id="SUBTAB_3_DOWNLOAD_SAP_FORMAT" />}
            url={`analyze/sap/${slug}`}
            className=""
            method="get"
          />
          <Tabs activeKey={key4} onSelect={(key4) => setKey(key4)}>
            {ratios.map((row, i) => (
              <Tab
                eventKey={slug + i}
                title={
                  row.title === 'Toplam Skorlar'
                    ? 'Toplam Skorlar ve Analiz Raporu'
                    : row.title
                }
                key={slug + i}
              >
                {row.subRatios.length > 0 && (
                  <Table striped responsive bordered hover key={i} size="sm">
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage id="SUBTAB_4_1_TITLE1" />
                        </th>
                        {row.subRatios &&
                          row.subRatios[0] &&
                          row.subRatios[0].values.map((a) => (
                            <>
                              <th>{a.year}</th>
                            </>
                          ))}
                        <th>Ağırlık</th>
                        {row.subRatios &&
                          row.subRatios[0] &&
                          row.subRatios[0].values.map((a, i) => (
                            <>
                              {i === 0 && (
                                <>
                                  <th>{a.year} Skor</th>
                                  <th>{a.year} Ağırlıklı Skor</th>
                                </>
                              )}
                              {i === 1 && (
                                <>
                                  <th>{a.year} Skor</th>
                                  <th>{a.year} Ağırlıklı Skor</th>
                                </>
                              )}
                              {i === 2 && (
                                <>
                                  <th>{a.year} Skor</th>
                                  <th>{a.year} Ağırlıklı Skor</th>
                                </>
                              )}
                            </>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {row.subRatios.map((a, i) => (
                        <tr key={i}>
                          <td key={i}>{a.name}</td>
                          {a.values.map((b) => (
                            <>
                              <td style={{ color: '' }}>
                                {formatNumber(b.yearValue, true)}
                              </td>
                            </>
                          ))}
                          {a.values.map((b, i) => (
                            <>
                              {i == 0 && (
                                <>
                                  <td>{formatNumber(b.weight, true)}</td>
                                  <td>{formatNumber(b.score, true)}</td>
                                  <td>{formatNumber(b.weightedScore, true)}</td>
                                </>
                              )}
                              {i == 1 && (
                                <>
                                  <td>{formatNumber(b.score, true)}</td>
                                  <td>{formatNumber(b.weightedScore, true)}</td>
                                </>
                              )}
                              {i == 2 && (
                                <>
                                  <td>{formatNumber(b.score, true)}</td>
                                  <td>{formatNumber(b.weightedScore, true)}</td>
                                </>
                              )}
                            </>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                {row.title === 'Toplam Skorlar' && (
                  <div>
                    <br />
                    <Card>
                      <Row>
                        <Col sm="3">
                          {' '}
                          <Nav pills className="flex-column">
                            {assessment &&
                              assessment.titles.map((x, t) => (
                                <NavItem key={x.name}>
                                  <NavLink
                                    href="#"
                                    key={t}
                                    active={t === index}
                                    onClick={() => setIndex(t)}
                                  >
                                    {x.name}
                                  </NavLink>
                                </NavItem>
                              ))}
                          </Nav>
                        </Col>
                        <Col sm="9">
                          {/* <p>
                          <strong>{aktiv.name}</strong>
                        </p> */}

                          {aktiv.subTitles.map((b) => (
                            <>{b.description}</>
                          ))}
                        </Col>
                      </Row>
                    </Card>
                  </div>
                )}
              </Tab>
            ))}
          </Tabs>
          <hr />

          <br />
          <div
            style={{
              textAlign: 'center',
              alignContent: 'center',
              alignItems: 'center',
              marginLeft: '35%',
            }}
          >
            <SessionTableCustomButton
              slug={slug}
              customAssessment={assessment}
              logo={logo}
              dname={dname}
              value={value}
            />
          </div>
        </CardBody>
      </DisableClipoard>
    </div>
  );
}
