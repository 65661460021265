import React from 'react';
import { Row, Label } from 'reactstrap';
import { ScoringTable } from './CycleScoringsTable';
import { BoundriesTable } from './CycleBoundriesTable';
import { Alerts } from '../../../Components/Alerts';
import useFetch from '../../../Hooks/useFetch';

export const CashConversionTables = ({ firmInfo }) => {
  const [cashConversionCycyle, loading] = useFetch(
    '/analyze/cashConversionCycyle/' + firmInfo
  );

  if (loading)
    return (
      <Alerts
        color="primary"
        className="fas fa-spin fa-spinner"
        messageId={'LOADING'}
        label=""
      />
    );
  const cycleScoringDatas =
    cashConversionCycyle && cashConversionCycyle.cashConversionCycleScorings;
  const cycleBoundriesDatas =
    cashConversionCycyle && cashConversionCycyle.cashConversionCycleBoundries;

  return (
    <>
      <Row>
        {cycleScoringDatas ? (
          <ScoringTable cycleScorings={cycleScoringDatas} />
        ) : (
          <Label>No Data Available.</Label>
        )}
      </Row>

      <Row>
        {cycleBoundriesDatas ? (
          <BoundriesTable cycleBoundries={cycleBoundriesDatas} />
        ) : (
          <h6>No Data Available.</h6>
        )}
      </Row>
    </>
  );
};
