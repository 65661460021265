import React from 'react';
import { Input, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const visibleColumnCount = (visibleColumns) => {
  return visibleColumns.filter((column) => column.visible).length;
};

export const RadioButtonGroups = (props) => {
  const { visibleColumns, setVisibleColumns } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
      }}
    >
      <Label>
        <FormattedMessage id="HIDE_PERIODS" />
      </Label>
      {visibleColumns.map((column) => (
        <Input
          key={column.id}
          type="checkbox"
          checked={column.visible}
          onChange={() => {
            if (visibleColumnCount(visibleColumns) > 1 || !column.visible) {
              const newState = [...visibleColumns];
              newState.map((col) =>
                col.year === column.year
                  ? (col.visible = !col.visible)
                  : col.visible
              );

              setVisibleColumns(newState);
            }
          }}
        />
      ))}
    </div>
  );
};
