// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import {
  ActionsColumn,
  BootstrapDataTable,
  Column,
} from 'react-admin-base-bootstrap';
import useFetch from '../../Hooks/useFetch';
import { useUser } from '../../Components/UserProvider';
import { Breadcrumb } from 'react-admin-base-nazox';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Button, Card, CardBody } from 'reactstrap';
import { Alerts } from '../../Components/Alerts';
import { DownloadFileButton } from '../../Components/DownloadFileButton';
import { useIntl } from 'react-intl';
import { LinkButton } from '../../Components/ActionButtons/ActionButtons';
import { icons } from '../../Icons/icons';
import { providerTypeNums } from '../../Util/Constants';
const params = { sort: 'id', desc: true };

const canSeeEditButton = (company) => {
  return (
    company &&
    (company.code === 'ta3meed' ||
      company.code === 'aktifbank' ||
      company.code === 'ta3meedbnpl' ||
      company.code === 'param' ||
      company.code === 'worqcompany' ||
      company.code === 'tofas' ||
      company.code === 'teb' ||
      company.code === 'usulventures' ||
      company.code === 'tbaydin' ||
      company.code === 'tai')
  );
};

const summaryPageUrl = (code, documentId) => {
  const url =
    code !== 'ta3meed'
      ? '/analysis/' + window.btoa(documentId) + '/edit'
      : '/analysis/' + window.btoa(documentId) + '/edit/empty';

  return url;
};

export default function Analysis() {
  const user = useUser();
  const intl = useIntl();

  const [company, loading] = useFetch(`/company/get/user/${user.id}`);

  const isTofas = company?.code === 'tofas';
  const companyId = user?.companyId;
  const buttonText = `${intl.formatMessage({
    id: 'COMPLETED_ANALYZES_TITLE',
  })} ${intl.formatMessage({ id: 'DOWNLOAD' })}`;
  const excelName = intl.formatMessage({ id: 'ANALYSES' });

  return !loading ? (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '10px',
        }}
      >
        {isTofas && (
          <DownloadFileButton
            messageId={buttonText}
            url={`report/detailedAnalysisReport/excelFile/${companyId}`}
            dname={excelName}
            className={'btn btn-info btn btn-secondary'}
            method="get"
          />
        )}
      </div>
      <BootstrapDataTable
        noStrip
        url="/analyze/get"
        add={company?.code === 'ta3meedbnpl' ? false : '/analysis/create'}
        defaultParams={params}
      >
        <thead>
          <tr>
            <Column sort="customer.fullName">
              <FormattedMessage id="analysisCustomerId" />
            </Column>
            <Column sort="customer.statementYear">
              <FormattedMessage id="PREV_ANL_DECLARATION_YEAR_TITLE" />
            </Column>
            <Column sort="customer.isConsolidated">
              <FormattedMessage id="GROUP/SOLO" />
            </Column>
            <Column sort="createdAt">
              <FormattedMessage id="PREV_ANL_ANALYSIS_DATE" />
            </Column>
            <Column sort="providerType">
              {' '}
              <FormattedMessage id="companyAnalysisType" />
            </Column>{' '}
            <ActionsColumn />
          </tr>
        </thead>
        <tbody>
          {(row) => {
            return (
              <tr key={row.id}>
                <td>
                  {row.customer ? (
                    row.customer.fullName
                  ) : (
                    <FormattedMessage id="COMPANY_NOTFOUND" />
                  )}
                </td>
                <td>
                  {row.customer
                    ? `${row.customer.statementYear}/${
                        row.customer.statementMonth || ''
                      }`
                    : ''}
                </td>
                <td>
                  {row.isConsolidated ? (
                    <FormattedMessage id="GROUP" />
                  ) : (
                    <FormattedMessage id="SOLO" />
                  )}
                </td>
                <td>
                  <FormattedDate value={row.createdAt} />
                </td>

                <td>
                  {providerTypeNums.includes(row.providerType) ? (
                    <FormattedMessage id={row.providerType} />
                  ) : (
                    ''
                  )}
                </td>
                <td className="min">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '0.5rem',
                    }}
                  >
                    <LinkButton
                      url={
                        '/report/' + window.btoa(row.documentId) + '/summary'
                      }
                      text={intl.formatMessage({
                        id: 'TO_ANALYSIS',
                      })}
                      size="sm"
                      color="primary"
                      iconClassName={icons.startAnalysis}
                    />
                    {canSeeEditButton(company) && (
                      <LinkButton
                        url={summaryPageUrl(company.code, row.documentId)}
                        text={intl.formatMessage({
                          id: 'EDIT',
                        })}
                        size="sm"
                        color="secondary"
                        iconClassName={icons.edit}
                      />
                    )}
                  </div>
                </td>
              </tr>
            );
          }}
        </tbody>
      </BootstrapDataTable>
    </>
  ) : (
    <Alerts
      color="primary"
      className="fas fa-spin fa-spinner"
      messageId={'LOADING'}
      label=""
    />
  );
}
