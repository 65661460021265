import React, { useRef } from 'react';

import { useFetch } from 'react-admin-base';
import { CardBody, Card, Row } from 'reactstrap';
import { Tab, Tabs } from 'react-bootstrap';
import { FinansalTablolar } from '../../Common/ModuleFinansalOranlar';
import 'beautiful-react-diagrams/styles.css';
import './energy.scss';
import { TemelAnaliz } from '../../Common/ModuleAnaliz';
import { ModuleGrafiklerSocar } from '../../Socar/ModuleGrafiklerSocar';
import { DupontTableCard } from '../../Components/DupontTableReportsAndTabs';
import { ModuleAltmanTaemed } from './ModuleAltmanTaemed';
import { ModuleMakine } from '../../Common/ModuleMakine';
import { ModuleKrediTab } from '../../Common/ModuleKrediTab';
import { TradeRegistry } from '../../Common/TradeRegistry';
import { DetayliFinansalTablolar } from '../../Common/DetayliFinansalOranlar';
import { AktarmaArindirma } from '../../Common/AktirmaArindirma';
import { CustomFinansalOranlar } from '../../Common/CustomFinansalOranlar';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModuleGrafiklerTaemed } from '../../Taemed/ModuleGrafiklerTaemed';
import {
  CombinedScore,
  Observation,
  Penalty,
  Qualitative,
  SurveyResult,
} from '../../Common/ScoringTab';
import SessionTableButton from '../../pages/Summarys/SessionTable';
import { AktifDetectionSummary } from '../../pages/Summarys/AktifDetectionSummary';
import { AktifDetectionSummary2 } from '../../pages/Summarys/AktifDetectionSummary2';
import { DeficitBalance } from '../../pages/Summarys/DeficitBalance';
import { Alerts } from '../../Components/Alerts';
import { DebtVolumeTable } from '../../pages/Summarys/DebtVolumeTable';
import { AbsoluteVolumeTable } from '../../pages/Summarys/AbsoluteVolume';
import { FinansalOranlar } from '../../Common/FinansalOranlar';
import { BeneishMScoreTables } from './BeneishMScores/BeneishMScoreTables';
import { CashConversionTables } from './CashConversionScycle/CashConversionTables';
import { QualitativeBnpl } from '../../pages/Summarys/Bnpl/QualitativeBnpl';
import { PenaltyBnpl } from '../../pages/Summarys/Bnpl/PenaltyBnpl';
import { ObservationBnpl } from '../../pages/Summarys/Bnpl/ObservationBnpl';
import { FinancialScore } from '../../pages/Summarys/Bnpl/FinancialScore';
import { DownloadFileButton } from '../../Components/DownloadFileButton';
import { ModuleAltman } from '../../Common/ModuleAltman';
import { PdfAktif } from '../../pages/PdfPage/PdfForAktif';
import { Pdf } from '../../pages/PdfPage/Pdf';
import { AllAnalysisButton } from '../../pages/Summarys/AllAnalysisButton';
import { FootnoteProcess } from '../../Components/summary/Footnote/FootnoteProcess';

export default function SummaryStandart({ firminfo, noDocument, companyCode }) {
  const [altmanzscoring, l1] = useFetch('/analyze/altmanzscoring/' + firminfo);
  const [dupont, l2] = useFetch('/analyze/dupont/' + firminfo);
  const [assessment, l3] = useFetch('/analyze/assessment/' + firminfo);
  const [trialBalance, l4] = useFetch(
    '/analyze/assessment/trialBalance/' + firminfo
  );
  const [statement, l5] = useFetch('/analyze/statement/' + firminfo); //Tablolar_formul
  const [adjustment, l6] = useFetch(
    '/analyze/statement/adjustments/' + firminfo
  );
  console.log('firminfo:', firminfo);
  const [customRatios, l7] = useFetch('/analyze/custom/ratios/' + firminfo);

  const [detailedstatement, l8] = useFetch(
    '/analyze/statement/detailed/' + firminfo
  ); //Tablolar_formul
  const [incomestatement, l9] = useFetch(
    '/analyze/incomestatement/' + firminfo
  ); //Grafikler
  const [taemedStatementNames, l10] = useFetch('/analyze/details/' + firminfo); //Grafikler

  const [customer, l11] = useFetch('analyze/get/' + firminfo);
  const [assets, l12] = useFetch('/analyze/assets/' + firminfo); //Varlıklar
  const [resources, l13] = useFetch('/analyze/resources/' + firminfo); //Kaynaklar
  const [prediction, l14] = useFetch('/analyze/liquidity/' + firminfo);
  const [customAssessment, l15] = useFetch(
    '/analyze/assessment/custom/' + firminfo
  );

  const [scoring, l16] = useFetch('/analyze/scoring/' + firminfo);
  const [scoringVal, l17] = useFetch('/analyze/scoringpoints/');
  const [qualitative, l18] = useFetch(
    '/survey/qualitative?documentId=' + firminfo
  );
  const [penaltyData, l19] = useFetch('/survey/penalty?documentId=' + firminfo);
  const [tradeRegistry, l20] = useFetch(`/analyze/traderegistry/${firminfo}`);

  const [observationC1, l21] = useFetch(
    '/survey/observation?documentId=' + firminfo
  );
  const [observationC2, l22] = useFetch(
    '/bankstatement/bankStatementInfo?documentId=' + firminfo
  );
  const [observationC3, l23] = useFetch(
    '/bankstatement/bankStatementRatio?documentId=' + firminfo
  );

  const [combinedScore, l24] = useFetch(
    '/survey/combinedScore?documentId=' + firminfo
  );

  const [logo, l25] = useFetch('/customizations/report/logo');

  const [surveyResult, l26] = useFetch(
    '/survey/surveyResult?documentId=' + firminfo
  );
  const [activeStatus, l27] = useFetch('/analyze/activeStatus/' + firminfo);
  const [currencyInfo, l28] = useFetch('/analyze/currencyInfo/' + firminfo);
  const [volume, l29] = useFetch('/detect/trialbalance/volume/' + firminfo);
  const [analysisDocuments, l30] = useFetch(
    '/analyze/get/analyzeDocuments/' + firminfo
  );

  const debtVolume = volume && volume.requestModel && volume.requestModel[0];
  const absoluteVolume =
    volume && volume.requestModel && volume.requestModel[1];

  const isTaemed = companyCode === 'ta3meed';
  const isBnpl = companyCode === 'ta3meedbnpl';
  const isAktif = companyCode === 'aktifbank';
  const isGlobal = companyCode === 'global';
  const formattedMessageId =
    isBnpl || isTaemed ? 'COMBINED_SCORE' : 'FINANCIAL_SCORE';

  const intl = useIntl();
  const isLoading =
    l1 ||
    l2 ||
    l3 ||
    l4 ||
    l5 ||
    l6 ||
    l7 ||
    l9 ||
    l8 ||
    l10 ||
    l11 ||
    l12 ||
    l13 ||
    l14 ||
    l15 ||
    l16 ||
    l17 ||
    l18 ||
    l19 ||
    l20 ||
    l21 ||
    l22 ||
    l23 ||
    l24 ||
    l25 ||
    l26 ||
    l27 ||
    l28 ||
    l29 ||
    l30;

  const penalty = penaltyData && { ...penaltyData };

  if (penalty) {
    for (const item in penalty) {
      if (
        item === 'numberOfDelayedPaymentsOnCreditCards' ||
        item === 'numberOfDelayedPaymentsOnLoans'
      ) {
        penalty[item].forEach((e, i) => {
          if (e.days !== 'No delay' && e.number === '0') {
            delete penalty[item][i];
          } else if (e.days !== 'No delay' && e.number !== '0') {
            delete penalty[item][0];
          }
        });
      }
    }
  }
  var dname = customer && customer.customer && customer.customer.fullName;
  const isConsolidated = customer && customer.isConsolidated;
  const isDetection = customer && customer.isTBDetection;
  const sectorCode = customer && customer.sectorCode;
  const providerType = customer && customer.providerType;
  const isFinancialStatementTurkish = providerType === 9;

  return (
    <Card>
      <CardBody>
        {!isLoading && statement && customer ? (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5em',
              }}
            >
              {analysisDocuments && analysisDocuments.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <AllAnalysisButton analysisDocuments={analysisDocuments} />
                </div>
              )}
              <div style={{ textAlign: 'right' }}>
                {!isAktif ? (
                  <Pdf
                    assessment={assessment}
                    scoringVal={scoringVal}
                    scoring={scoring}
                    dupont={dupont}
                    incomestatement={incomestatement}
                    assets={assets}
                    resources={resources}
                    altmanzscoring={altmanzscoring}
                    prediction={prediction}
                    customer={customer}
                    statement={statement}
                    companyCode={companyCode}
                    combinedScore={combinedScore}
                    surveyResult={surveyResult}
                  />
                ) : (
                  <PdfAktif
                    assessment={assessment}
                    scoringVal={scoringVal}
                    scoring={scoring}
                    dupont={dupont}
                    altmanzscoring={altmanzscoring}
                  />
                )}
              </div>
            </div>

            <Tabs
              id="controlled-tab-example"
              unmountOnExit={true}
              mountOnEnter={true}
              transition={false}
              defaultActiveKey={1}
            >
              <Tab
                eventKey={'1'}
                title={
                  <FormattedMessage id="TAB_1_FINANCIAL_ANALYSIS_AND_SCORING" />
                }
              >
                <div className="mt-2">
                  <Tabs
                    id="controlled-tab-example"
                    unmountOnExit={true}
                    mountOnEnter={true}
                    transition={false}
                    defaultActiveKey={1.1}
                  >
                    <Tab
                      eventKey={'1.1'}
                      title={
                        <FormattedMessage id="SUBTAB_1_1_FINANCIAL_ANALYSIS_AND_DETECTION_REPORT" />
                      }
                    >
                      {assessment && (
                        <TemelAnaliz
                          noDocument={noDocument}
                          taemedStatementNames={taemedStatementNames}
                          code={companyCode}
                          value={assessment.titles}
                          key={firminfo + 'TemelAnaliz'}
                          trialBalance={trialBalance}
                          id={firminfo}
                          firminfo={firminfo}
                          scoringVal={scoringVal}
                          scoring={scoring}
                          altmanzscoring={altmanzscoring}
                          dupont={dupont}
                          assessment={assessment}
                          incomestatement={incomestatement}
                          dname={dname}
                          assets={assets}
                          resources={resources}
                          prediction={prediction}
                          logo={logo}
                          combinedScore={combinedScore}
                          observationC1={observationC1}
                          observationC2={observationC2}
                          observationC3={observationC3}
                          qualitative={qualitative}
                          penalty={penalty}
                          customer={customer}
                          surveyResult={surveyResult}
                          statement={statement}
                          companyCode={companyCode}
                        />
                      )}
                    </Tab>

                    <Tab
                      eventKey={'1.2'}
                      title={
                        <FormattedMessage id="SUBTAB_1_2_FINANCIAL_GRAPHS" />
                      }
                    >
                      {companyCode === 'ta3meed' ? (
                        <ModuleGrafiklerTaemed
                          incomestatement={incomestatement}
                          key={firminfo + 'ModuleFinansalGrafikler'}
                        />
                      ) : (
                        <ModuleGrafiklerSocar
                          incomestatement={incomestatement}
                          assets={assets}
                          resources={resources}
                          key={firminfo + 'ModuleFinansalGrafikler'}
                        />
                      )}
                    </Tab>

                    <Tab
                      eventKey={'1.3'}
                      title={
                        <FormattedMessage id="SUBTAB_1_3_DUPONT_ANALYSIS" />
                      }
                    >
                      {dupont && (
                        <DupontTableCard
                          categories={dupont.categories}
                          key={firminfo + 'DupontTable'}
                        />
                      )}
                    </Tab>
                    {companyCode === 'ta3meedbnpl' && (
                      <Tab eventKey={'1.4'} title={'Beneish-M Score'}>
                        <Row className="mt-2">
                          <BeneishMScoreTables firmInfo={firminfo} />
                        </Row>
                      </Tab>
                    )}
                    {companyCode === 'ta3meedbnpl' && (
                      <Tab eventKey={'1.5'} title={'Cash Conversion Cycle'}>
                        <Row className="mt-2">
                          <CashConversionTables firmInfo={firminfo} />
                        </Row>
                      </Tab>
                    )}

                    {altmanzscoring && incomestatement && (
                      <Tab
                        eventKey={'1.6'}
                        title={
                          <FormattedMessage id="SUBTAB_1_4_ALTMAN_BANKRUPTCY_FORECAST" />
                        }
                      >
                        {companyCode === 'ta3meed' ||
                        companyCode === 'ta3meedbnpl' ? (
                          <ModuleAltmanTaemed
                            altmanzscoring={altmanzscoring}
                            incomestatement={incomestatement}
                            key={firminfo + 'ModuleAltman'}
                          />
                        ) : (
                          <ModuleAltman
                            altmanzscoring={altmanzscoring}
                            incomestatement={incomestatement}
                            key={firminfo + 'ModuleAltman'}
                          />
                        )}
                      </Tab>
                    )}
                    {prediction && (
                      <Tab
                        eventKey={'1.7'}
                        title={
                          <FormattedMessage id="SUBTAB_1_5_MACHINE_LEARNING" />
                        }
                      >
                        <ModuleMakine
                          prediction={prediction}
                          key={firminfo + 'ModuleMakine'}
                        />
                      </Tab>
                    )}
                    {scoring && (
                      <Tab
                        eventKey={'1.8'}
                        title={
                          <FormattedMessage id="SUBTAB_1_6_CREDIT_SCORING_AND_SUMMARY" />
                        }
                      >
                        <div className="my-3">
                          <>
                            <Tabs
                              id="controlled-tab-example"
                              unmountOnExit={true}
                              mountOnEnter={true}
                              transition={false}
                              defaultActiveKey={'1.8.4'}
                            >
                              {companyCode === 'ta3meedbnpl' ? (
                                <Tab
                                  title={'A. Qualitative Analysis Questions'}
                                  eventKey={'1.8.1'}
                                >
                                  <Row className="mt-2">
                                    <QualitativeBnpl firmInfo={firminfo} />
                                  </Row>
                                </Tab>
                              ) : (
                                qualitative &&
                                qualitative.length > 0 && (
                                  <Tab
                                    title={'A. Qualitative Analysis Questions'}
                                    eventKey={'1.8.1'}
                                  >
                                    <Qualitative
                                      qualitative={qualitative}
                                      disable={false}
                                    />
                                  </Tab>
                                )
                              )}

                              {companyCode === 'ta3meedbnpl' ? (
                                <Tab
                                  title={'B. Penalty Questions'}
                                  eventKey={'1.8.2'}
                                >
                                  <Row className="mt-2">
                                    <PenaltyBnpl firmInfo={firminfo} />
                                  </Row>
                                </Tab>
                              ) : (
                                penalty && (
                                  <Tab
                                    eventKey={'1.8.2'}
                                    title={'B. Penalty Questions'}
                                  >
                                    <Penalty
                                      penalty={penalty}
                                      disable={false}
                                    />
                                  </Tab>
                                )
                              )}
                              {companyCode === 'ta3meedbnpl' ? (
                                <Tab
                                  eventKey={'1.8.3'}
                                  title={
                                    'C. Observation Questions (Not for Scoring)'
                                  }
                                >
                                  <Row className="mt-2">
                                    <ObservationBnpl firmInfo={firminfo} />
                                  </Row>
                                </Tab>
                              ) : (
                                observationC1 &&
                                observationC2 &&
                                observationC3 && (
                                  <Tab
                                    eventKey={'1.8.3'}
                                    title={
                                      'C. Observation Questions (Not for Scoring)'
                                    }
                                  >
                                    <Observation
                                      observationC1={observationC1}
                                      observationC2={observationC2}
                                      observationC3={observationC3}
                                      disabled={false}
                                      companyCode={companyCode}
                                    />
                                  </Tab>
                                )
                              )}
                              {companyCode === 'ta3meedbnpl' ? (
                                <Tab
                                  eventKey={'1.8.4'}
                                  title={
                                    <FormattedMessage id={formattedMessageId} />
                                  }
                                >
                                  <Row className="mt-2">
                                    <FinancialScore
                                      firmInfo={firminfo}
                                      id={formattedMessageId}
                                    />
                                  </Row>
                                </Tab>
                              ) : (
                                <Tab
                                  eventKey={'1.8.4'}
                                  title={
                                    <FormattedMessage id={formattedMessageId} />
                                  }
                                >
                                  <ModuleKrediTab
                                    formattedMessageId={formattedMessageId}
                                    scoring={scoring}
                                    scoringVal={scoringVal}
                                    key={firminfo + 'ModuleKredi'}
                                  />
                                  {isTaemed && (
                                    <>
                                      <CombinedScore
                                        combinedScore={combinedScore}
                                      />
                                      <SurveyResult
                                        surveyResult={surveyResult}
                                      />
                                    </>
                                  )}
                                </Tab>
                              )}
                            </Tabs>
                          </>
                        </div>
                      </Tab>
                    )}
                    {volume && (
                      <Tab
                        eventKey={'1.9'}
                        title={
                          <FormattedMessage id="ACCOUNT_RECEIVABLES_ANALYSIS" />
                        }
                      >
                        <div className="my-3">
                          <>
                            <div
                              style={{
                                textAlign: 'right',
                              }}
                            >
                              <DownloadFileButton
                                messageId={`${intl.formatMessage({
                                  id: 'ACCOUNT_RECEIVABLES_ANALYSIS',
                                })} ${intl.formatMessage({
                                  id: 'DOWNLOAD',
                                })}`}
                                url={`detect/trialbalance/volumeFile/${firminfo}`}
                                dname={`${intl.formatMessage({
                                  id: 'ACCOUNT_RECEIVABLES_ANALYSIS',
                                })}`}
                                className={'btn btn-info btn btn-secondary'}
                                style={{ marginBottom: '1em' }}
                                method="get"
                              />
                              {/* <DownloadExcelFromUrlButton
                                text={intl.formatMessage({
                                  id: 'ACCOUNT_RECEIVABLES_ANALYSIS',
                                })}
                                size="sm"
                                iconClassName={icons.download}
                                url={`detect/trialbalance/volumeFile/${firminfo}`}
                                filterData={(data, header) => data}
                                fileName={intl.formatMessage({
                                  id: 'ACCOUNT_RECEIVABLES_ANALYSIS',
                                })}
                              /> */}
                            </div>
                            <Tabs id="alicilar-hesap-tab">
                              {debtVolume &&
                                debtVolume.volume &&
                                debtVolume.volume.length > 0 && (
                                  <Tab
                                    eventKey={'111'}
                                    title={
                                      <FormattedMessage id="HIGHEST_DEBT" />
                                    }
                                  >
                                    <DebtVolumeTable
                                      volume={debtVolume.volume}
                                    />
                                  </Tab>
                                )}
                              {absoluteVolume &&
                                absoluteVolume.volume &&
                                absoluteVolume.volume.length > 0 && (
                                  <Tab
                                    eventKey={'112'}
                                    title={
                                      <FormattedMessage id="HIGHEST_VOLUME" />
                                    }
                                  >
                                    <AbsoluteVolumeTable
                                      volume={absoluteVolume.volume}
                                    />
                                  </Tab>
                                )}
                            </Tabs>
                          </>
                        </div>
                      </Tab>
                    )}
                  </Tabs>
                </div>
              </Tab>
              {scoring && scoring.ratios && scoring.ratios.length > 0 && (
                <Tab
                  eventKey={'2'}
                  title={<FormattedMessage id="TAB_2_RATIO_ANALYSIS" />}
                >
                  <FinansalOranlar
                    value={scoring}
                    slug={firminfo}
                    key={firminfo + 'FinansalOranlar'}
                    companyCode={companyCode}
                    sectorCode={sectorCode}
                  />
                </Tab>
              )}
              {customRatios && (
                <Tab
                  eventKey={'3'}
                  title={
                    <FormattedMessage id="TAB_3_CUSTOMIZED_RATIO_ANALYSIS" />
                  }
                >
                  <CustomFinansalOranlar
                    value={customRatios}
                    slug={firminfo}
                    key={firminfo + 'CustomFinansalOranlar'}
                    assessment={customAssessment}
                    dname={dname}
                    logo={logo}
                  />
                </Tab>
              )}
              {statement && statement.mainList && (
                <Tab
                  eventKey={'4'}
                  title={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <FormattedMessage id="TAB_4_FINANCIAL_TABLES" />
                    </div>
                  }
                >
                  <FinansalTablolar
                    value={statement.mainList}
                    slug={firminfo}
                    key={firminfo + 'FinansalTablolar'}
                    companyCode={companyCode}
                    isConsolidated={isConsolidated}
                    activeStatus={activeStatus}
                    currencyInfo={currencyInfo}
                  />
                </Tab>
              )}
              {detailedstatement && (
                <Tab
                  eventKey={'5'}
                  title={
                    <FormattedMessage id="TAB_5_DETAILLED_FINACIAL_TABLES" />
                  }
                >
                  <DetayliFinansalTablolar
                    value={detailedstatement.mainList}
                    slug={firminfo}
                    key={firminfo + 'DetaylıFinansalTablolar'}
                    currencyInfo={currencyInfo}
                  />
                </Tab>
              )}
              {adjustment && (
                <Tab
                  eventKey={'8'}
                  title={<FormattedMessage id="TAB_6_TRANSFER_PURIFICATION" />}
                >
                  <AktarmaArindirma
                    value={adjustment.mainList}
                    slug={firminfo}
                    key={firminfo + 'AktarmaArındırma'}
                    companyCode={companyCode}
                    isConsolidated={isConsolidated}
                  />
                </Tab>
              )}

              {isDetection && (
                <Tab
                  eventKey={'6'}
                  title={<FormattedMessage id={'DETECTİON_TAB'} />}
                >
                  <div className="my-3">
                    <>
                      <Tabs
                        id="controlled-tab-example"
                        unmountOnExit={true}
                        mountOnEnter={true}
                        transition={false}
                        defaultActiveKey={'6.1'}
                      >
                        <Tab
                          eventKey={'6.1'}
                          title={'Borç ve Alacak Bakiyesi Hareketsiz'}
                        >
                          <AktifDetectionSummary id={firminfo} />
                        </Tab>

                        <Tab
                          eventKey={'6.2'}
                          title={'Alacak Bakiyesi Hareketsiz'}
                        >
                          {' '}
                          <AktifDetectionSummary2 id={firminfo} />
                        </Tab>
                      </Tabs>
                    </>
                  </div>
                </Tab>
              )}

              {(companyCode === 'aktifbank' ||
                companyCode === 'param' ||
                companyCode === 'teb' ||
                companyCode === 'usulventures' ||
                companyCode === 'tbaydin' ||
                companyCode === 'tai') &&
                isDetection && (
                  <Tab eventKey={'7'} title={'Ters Bakiye'}>
                    <DeficitBalance id={firminfo} />
                  </Tab>
                )}

              {tradeRegistry && (
                <Tab
                  eventKey={'9'}
                  title=<FormattedMessage id="TRADE_REGISTRY_GAZETTE" />
                >
                  <TradeRegistry tradeRegistry={tradeRegistry} />
                </Tab>
              )}
              {/* {!(isTaemed || isGlobal) && isFinancialStatementTurkish && (
                <Tab eventKey={'10'} title="Dipnotlar" className="mt-2">
                  <FootnoteProcess firmInfo={firminfo} />
                </Tab>
              )} */}
            </Tabs>
          </>
        ) : (
          <Alerts
            color="primary"
            className="fas fa-spin fa-spinner"
            messageId={'LOADING'}
            label=""
          />
        )}
      </CardBody>
    </Card>
  );
}
