import React from 'react';

export const ErrorPageLayout = ({ children }) => {
  return (
    <div
      style={{
        height: '100vh',
        background: '#f1f5f7',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );
};
