import React from 'react';
import { Breadcrumb } from 'react-admin-base-nazox';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
  Input,
  Label,
  Button,
  FormGroup,
} from 'reactstrap';
import { useFormik } from 'formik';
import { useAuth, useFetch } from 'react-admin-base';
import { Alerts } from '../../Components/Alerts';
import { fireSwal } from '../../Components/SwalFire';

const defaultState = {
  name: '',
  number: '',
  tradeRegistrationNumber: '',
  isGroupCompany: false,
};

export const TaemedCustomerForm = () => {
  const params = useParams();
  const id = params && params.id;
  const isGroup = params['*']?.includes('group');
  const [api] = useAuth();
  const intl = useIntl();

  const [customerLoading, setCustomerLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      setCustomerLoading(true);
      await api.tokenized.get(`/customer/get/${id}`).then((a) => {
        const data = a.data;
        formik.setValues({
          name: data.name,
          number: data.number,
          tradeRegistrationNumber: data.tradeRegistrationNumber,
          isGroupCompany: isGroup === undefined ? false : isGroup,
        });
      });
      setCustomerLoading(false);
    };
    if (id) fetchData();
  }, [id]);

  const formik = useFormik({
    initialValues: {
      ...defaultState,
    },
    validateOnChange: true,
    validate: (data) => {
      const errors = {};

      if (!data.name) {
        errors.name = 'REQUIRED';
      }
      if (data.name?.length > 150) {
        errors.name = 'VALID150';
      }

      return errors;
    },
    onSubmit: async (data) => {
      try {
        await api.tokenized.post('/customer/add', {
          ...data,
          id,
        });

        fireSwal({
          background: '#90EE90',
          icon: 'success',
          text: intl.formatMessage({ id: 'SAVED' }),
        });
      } catch (e) {
        fireSwal({
          background: '#ffd8df',
          icon: 'error',
          text: e?.response?.data.message || 'Bir Hata Meydana Geldi',
        });
      }
    },
  });

  const getFormErrorMessage = (name, id) => {
    return (
      formik.touched[name] &&
      formik.errors[name] && (
        <p
          style={{
            color: 'red',
            opacity: 0.6,
            position: 'absolute',
            fontSize: '12px',
          }}
        >
          {<FormattedMessage id={id} />}{' '}
          {<FormattedMessage id={formik.errors[name]} />}
        </p>
      )
    );
  };
  return (
    <Breadcrumb
      title={
        id ? (
          isGroup ? (
            <FormattedMessage id="EDIT_GROUP" />
          ) : (
            <FormattedMessage id="EDIT_CUSTOMER" />
          )
        ) : isGroup ? (
          <FormattedMessage id="ADD_NEW_GROUP" />
        ) : (
          <FormattedMessage id="ADD_NEW_CUSTOMER" />
        )
      }
      data={[
        {
          href: isGroup ? '/groups' : '/customers',
          name: isGroup ? (
            <FormattedMessage id="Groups" />
          ) : (
            <FormattedMessage id="CUSTOMERS" />
          ),
        },
        {
          href: id
            ? isGroup
              ? `/group/${id}/edit`
              : `/customer/${id}/edit`
            : isGroup
              ? '/group/add'
              : '/customer/add',
          name: id ? (
            isGroup ? (
              <FormattedMessage id="EDIT_GROUP" />
            ) : (
              <FormattedMessage id="EDIT_CUSTOMER" />
            )
          ) : isGroup ? (
            <FormattedMessage id="ADD_NEW_GROUP" />
          ) : (
            <FormattedMessage id="ADD_NEW_CUSTOMER" />
          ),
        },
      ]}
    >
      {' '}
      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            {!customerLoading ? (
              <>
                <Row>
                  <Col>
                    <Label>
                      <FormattedMessage id="analysisCustomerId" />
                    </Label>
                    <Input
                      name="name"
                      type="text"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />

                    {getFormErrorMessage('name', 'analysisCustomerId')}
                  </Col>

                  <Col>
                    <Label>
                      <FormattedMessage id="CUSTOMER_NUMBER" />
                    </Label>

                    <Input
                      name="number"
                      onChange={(e) => {
                        if (!/\D/g.test(e.target.value)) {
                          formik.handleChange(e);
                        }
                      }}
                      value={formik.values.number}
                    />
                    {getFormErrorMessage('number', 'CUSTOMER_NUMBER')}
                  </Col>

                  <Col>
                    <Label>
                      <FormattedMessage id="TRADE_REGISTRATION_NUMBER" />
                    </Label>
                    <Input
                      name="tradeRegistrationNumber"
                      onChange={(e) => {
                        if (/^[0-9-]*$/.test(e.target.value)) {
                          formik.handleChange(e);
                        }
                      }}
                      value={formik.values.tradeRegistrationNumber}
                    />

                    {getFormErrorMessage(
                      'tradeRegistrationNumber',
                      'TRADE_REGISTRATION_NUMBER'
                    )}
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col md="4">
                    <Button
                      type="submit"
                      className="d-block righted"
                      color="primary"
                      loading={formik.isSubmitting ? 'true' : 'false'}
                      disabled={formik.isSubmitting}
                    >
                      <FormattedMessage id="SEND" />{' '}
                      <i className="fas fa-save fa-lg" />
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <Alerts
                color="primary"
                className="fas fa-spin fa-spinner"
                messageId="LOADING"
                label=""
              />
            )}
          </Form>
        </CardBody>
      </Card>
    </Breadcrumb>
  );
};
