import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormattedMessage, useIntl } from 'react-intl';

const gazzetteLinkBody = (data) => {
  return (
    <a target="_blank" rel="noreferrer" href={data}>
      {data}
    </a>
  );
};

const getColumns = (data) => {
  const keys = Object.keys(data[0]);

  const columns = keys.map((e, index) => {
    const isGazetteLink = e === 'gazetteLink';
    const isRecordDate = e === 'recordDate';
    return (
      <Column
        field={e}
        sortField={e}
        sortable={!(isRecordDate || isGazetteLink)}
        header={<FormattedMessage id={e} />}
        key={index}
        body={(rowData) =>
          isGazetteLink ? gazzetteLinkBody(rowData[e]) : rowData[e]
        }
      />
    );
  });

  return columns;
};

export const TradeRegistryTable = (props) => {
  const { tradeRegistry } = props;
  const intl = useIntl();

  return (
    <DataTable
      value={tradeRegistry}
      className="mt-3"
      scrollable
      emptyMessage={intl.formatMessage({ id: 'NO_DATA_IS_AVAILABLE' })}
      pt={{
        root: {
          style: {
            height: '60vh',
          },
        },
      }}
    >
      {getColumns(tradeRegistry)}
    </DataTable>
  );
};
