/* eslint-disable no-useless-escape */

import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { useFetch } from 'react-admin-base';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Breadcrumb } from 'react-admin-base-nazox';
import { useUser } from '../../Components/UserProvider';
import { CustomerForm } from '../../Components/customer-form/CustomerForm';
import { Alerts } from '../../Components/Alerts';

const getBreadCrumbProps = (id, isGroup) => {
  if (id) {
    return isGroup
      ? {
          title: <FormattedMessage id="EDIT_GROUP" />,
          data: [
            {
              href: '/groups',
              name: <FormattedMessage id="Groups" />,
            },
            {
              href: `/group/${id}/edit`,
              name: <FormattedMessage id="EDIT_GROUP" />,
            },
          ],
        }
      : {
          title: <FormattedMessage id="EDIT_CUSTOMER" />,
          data: [
            {
              href: '/customers',
              name: <FormattedMessage id="CUSTOMERS" />,
            },
            {
              href: `/customer/${id}/edit`,
              name: <FormattedMessage id="EDIT_CUSTOMER" />,
            },
          ],
        };
  }

  return isGroup
    ? {
        title: <FormattedMessage id="ADD_NEW_GROUP" />,
        data: [
          {
            href: '/groups',
            name: <FormattedMessage id="Groups" />,
          },
          {
            href: '/group/add',
            name: <FormattedMessage id="ADD_NEW_GROUP" />,
          },
        ],
      }
    : {
        title: <FormattedMessage id="ADD_NEW_CUSTOMER" />,
        data: [
          {
            href: '/customers',
            name: <FormattedMessage id="CUSTOMERS" />,
          },
          {
            href: '/customer/add',
            name: <FormattedMessage id="ADD_NEW_CUSTOMER" />,
          },
        ],
      };
};

export const CustomerFormPage = () => {
  const params = useParams();
  const paramsId = params && params.id;
  const isGroup = params['*']?.includes('group');
  const user = useUser();
  const userId = user && user.id;
  const [company, companyLoading] = useFetch('/company/get/user/' + userId);
  const companyCode = company && company.code;

  return (
    <Breadcrumb {...getBreadCrumbProps(paramsId, isGroup)}>
      {companyLoading ? (
        <Alerts
          color="primary"
          className="fas fa-spin fa-spinner"
          messageId="LOADING"
          label=""
        />
      ) : (
        <CustomerForm
          id={paramsId}
          companyCode={companyCode}
          isGroup={isGroup}
        />
      )}
    </Breadcrumb>
  );
};
