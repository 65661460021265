import React, { useMemo } from 'react';
import { ValidatorOptionProvider } from 'react-admin-base';
import { FormattedMessage } from 'react-intl';

export default function ValidatorSettings({ children, messages }) {
  const settings = useMemo(
    () => ({
      messages: {
        email: 'Mail Adresi giriniz.',
        in: 'Şifreler uyuşmamaktadır.',
        ...(messages || ''),
      },
      validators: {
        upload: {
          // name the rule
          message: <FormattedMessage id="PLEASE_UPLOAD_PDF_FILE" />,
          rule: (val, params, validator) => {
            return (
              val &&
              validator.helpers.testRegex(val.name, /.pdf|.xlsx|.png|.jpg$/i)
            );
          },
          required: true, // optional
        },
      },
    }),
    [messages]
  );

  return (
    <ValidatorOptionProvider value={settings}>
      {children}
    </ValidatorOptionProvider>
  );
}
