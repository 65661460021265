import React from 'react';
import useFetch from '../../../Hooks/useFetch';
import { Qualitative } from '../../../Common/ScoringTab';
import { Alerts } from '../../../Components/Alerts';

export const QualitativeBnpl = ({ firmInfo }) => {
  const [qualitative, loading] = useFetch('/bnpl/qualitative/' + firmInfo);

  return !loading ? (
    qualitative && qualitative.length > 0 ? (
      <Qualitative qualitative={qualitative} disable={false} />
    ) : (
      <h6>No Data Available.</h6>
    )
  ) : (
    <Alerts
      color="primary"
      className="fas fa-spin fa-spinner"
      messageId={'LOADING'}
      label=""
    />
  );
};
