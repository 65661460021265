import React from 'react';
import {
  Input,
  Label,
  Card,
  CardBody,
  Form,
  FormGroup,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { SingleFilePicker } from 'react-admin-base-bootstrap';
import { useFormik } from 'formik';
import { fireSwal } from '../../Components/SwalFire';
import { useAuth } from 'react-admin-base';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../Components/Loading';
import { Alerts } from '../../Components/Alerts';
import { Breadcrumb } from 'react-admin-base-nazox';

const defaultState = {
  financialFile: null,
};

export const BillAnalysis = () => {
  const intl = useIntl();
  const [api] = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [done, setDone] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      ...defaultState,
    },
    validateOnChange: true,
    validate: (data) => {
      const errors = {};

      if (!data.financialFile) {
        errors.financialFile = 'REQUIRED';
      }

      return errors;
    },
    onSubmit: async (data) => {
      try {
        setLoading(true);
        const response = await api.tokenized.post(
          `/process/inovice/${formik.values.financialFile.id}`
        );
        if (response.status === 200) {
          setDone(true);
        } else throw new Error('Bir Hata Meydana Geldi');
      } catch (e) {
        fireSwal({
          background: '#ffd8df',
          icon: 'error',
          text: e?.response?.data.message || 'Bir Hata Meydana Geldi',
        });
      } finally {
        setLoading(false);
      }
    },
  });

  const getFormErrorMessage = (name) => {
    return (
      formik.touched[name] &&
      formik.errors[name] && (
        <p
          style={{
            color: 'red',
            opacity: 0.6,
            position: 'absolute',
          }}
        >
          {<FormattedMessage id={name} />}{' '}
          {<FormattedMessage id={'REQUIRED'} />}
        </p>
      )
    );
  };
  const goPage = () => {
    navigate(
      '/misyon/bill/' + window.btoa(formik.values.financialFile.id) + '/summary'
    );
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  if (done) {
    setTimeout(() => {
      goPage();
    }, 2000);
  }
  return (
    <Breadcrumb
      data={[
        {
          href: '/misyon/bill/create',
          name: 'Fatura',
        },
      ]}
    >
      {' '}
      <Form onSubmit={formik.handleSubmit}>
        <Card>
          <CardBody>
            <FormGroup>
              <Label> {<FormattedMessage id="ADD_BILL" />}</Label>
              <SingleFilePicker
                accepts=".pdf,.xlsx,.png,.jpg"
                value={formik.values.financialFile}
                onChange={(e) => formik.setFieldValue('financialFile', e)}
              />
              {getFormErrorMessage('financialFile')}
            </FormGroup>
          </CardBody>
        </Card>
        <Row className="mb-2">
          <Col md="4">
            <Button
              type="submit"
              className="d-block righted"
              color="primary"
              loading={formik.isSubmitting ? 'true' : 'false'}
              disabled={formik.isSubmitting}
            >
              <FormattedMessage id="SEND" /> <i className="fas fa-save fa-lg" />
            </Button>
          </Col>
        </Row>
        {loading && <Loading />}
        {done && (
          <Alerts
            className="fas fa-spin fa-spinner"
            color="success"
            messageId={'REDIRECT_SUMMARY'}
            label=""
          />
        )}
      </Form>
    </Breadcrumb>
  );
};
