import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useUser } from './Components/UserProvider';
import { UserProfile } from './pages/Profile/Profile';
import ExtraPage from './pages/User/ExtraPage';
import { useFetch } from 'react-admin-base';
import User from './pages/User/List';
import Analysis from './pages/Analysis/Analysis';
import ReportEntity from './pages/Summarys/ReportEntity';
import AnalysisEntity from './pages/Analysis/AnalysisEntity';
import { CustomerFormGeneral } from './pages/Customer/CustomerFormGeneral';
import { GroupTablePage } from './pages/Groups/GroupTablePage';
import PreviousAnalysis from './pages/Analysis/Prev/prevAnalyzes';
import { AktifDetection } from './pages/Analysis/Detection/AktifDetection';
import { ConsolidationTable } from './pages/Consolidation/ConsolidationTable';
import { Bill } from './pages/Analysis/Bill/Bill';
import { BillSummary } from './pages/Summarys/BillSummary';
import { Rates } from './pages/Rates/Rates';
import { BillAnalysis } from './pages/Analysis/BillAnalysisDemo';
import { BillSummaryMisyon } from './pages/Summarys/BillSummaryMisyon';
import { CustomerProfile } from './pages/Customer/CustomerProfile';
import { SpkMasak } from './pages/SpkMasak/SpkMasak';
import { Loading } from './Components/Loading';
import { CustomersPage } from './pages/Customer/CustomersPage';
import { AllAnalysisPage } from './pages/Analysis/AllAnalysis';
import { FilterModule } from './pages/Analysis/FilterModule';
import { StartupScoringPage } from './pages/Startup-Scoring/StartupScoringPage';
import { PublicCompaniesPage } from './pages/Public-Companies/PublicCompanies';
import { PreviousAnalysisPage } from './pages/Analysis/Previous-analysis/PreviousAnalysisPage';
import { ConsolidationPage } from './pages/Consolidation/ConsolidationPage';

export default function Router() {
  const user = useUser();
  const userId = user.id;
  const [company, loading] = useFetch('/company/get/user/' + userId);

  if (loading || !company) return <Loading />;

  const companyCode = company && company.code;

  return (
    <Routes>
      {user.type >= 256 && <Route path="/user/*" element={<User />} />}
      {user.type !== 1 && <Route path="/profile" element={<UserProfile />} />}
      <Route
        path="/customer/add"
        element={<CustomerFormGeneral companyCode={companyCode} />}
      />
      {companyCode !== 'ta3meed' && (
        <>
          <Route path="/customer/:id/profile" element={<CustomerProfile />} />
          <Route path="/group/:id/profile" element={<CustomerProfile />} />
        </>
      )}

      <Route
        path="/customer/:id/edit"
        element={<CustomerFormGeneral companyCode={companyCode} />}
      />
      <Route
        path="/group/:id/edit"
        element={<CustomerFormGeneral companyCode={companyCode} />}
      />
      <Route
        path="/group/add"
        element={<CustomerFormGeneral companyCode={companyCode} />}
      />
      <Route path="/customers" element={<CustomersPage />} />
      <Route path="/groups" element={<GroupTablePage />} />
      <Route path="/consolidation" element={<ConsolidationPage />} />

      {user.type === 512 && (
        <Route path="/extra-page" element={<ExtraPage />} />
      )}
      <Route path="/analysis/*" element={<AnalysisEntity />} />
      <Route path="/analyzes/*" element={<PreviousAnalysisPage />} />
      {user.type >= 128 && (
        <>
          <Route path="/analyzes-admin/*" element={<AllAnalysisPage />} />
          {companyCode === 'aktifbank' && (
            <Route path="/analyzes-admin/filter" element={<FilterModule />} />
          )}
        </>
      )}
      <Route path="/report/:Id/*" element={<ReportEntity />} />
      {/* <Route path="/kpmg-report/:Id/*" element={<KpmgSummary />} /> */}
      <Route path="/prev-analyzes" element={<PreviousAnalysis />} />
      <Route path="/detection/create" element={<AktifDetection />} />
      {/* <Route path="/kpmg-test" element={<Kpmg />} /> */}
      <Route path="/bill/create" element={<Bill />} />
      <Route path="/bill/:Id/*" element={<BillSummary />} />
      <Route path="/rates" element={<Rates />} />
      <Route path="/misyon/bill/create" element={<BillAnalysis />} />
      <Route path="/misyon/bill/:Id/*" element={<BillSummaryMisyon />} />
      {(companyCode === 'socar' || companyCode === 'param') && (
        <Route path="spk-masak" element={<SpkMasak />} />
      )}
      {/* <Route path="/public-companies" element={<PublicCompaniesPage />} /> */}

      <Route path="/startup-scoring" element={<StartupScoringPage />} />

      <Route
        path="*"
        element={
          companyCode === 'ta3meedbnpl' ? (
            <Navigate to="/analyzes" />
          ) : companyCode === 'aktifbank' ? (
            <Navigate to="/customers" />
          ) : (
            <Navigate to="/analysis/create" />
          )
        }
      />
    </Routes>
  );
}
