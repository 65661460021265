import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Breadcrumb } from 'react-admin-base-nazox';
import { TableContent } from '../../Components/Table';
import { Card, CardBody } from 'reactstrap';
import { useFetch } from 'react-admin-base';
import { useUser } from '../../Components/UserProvider';
import { Alerts } from '../../Components/Alerts';
import { DataTable_ } from '../../Components/DataTable';

export const AllAnalysisPage = () => {
  const user = useUser();
  const [company, loadingCompany] = useFetch(`/company/get/user/${user.id}`);

  if (loadingCompany)
    return (
      <Alerts
        color="primary"
        className="fas fa-spin fa-spinner"
        messageId={'LOADING'}
        label=""
      />
    );
  const companyCode = company?.code;
  return (
    <Breadcrumb
      data={[
        {
          href: '/analyzes-admin',
          name: <FormattedMessage id="COMPLETED_ALL_ANALYZES_TITLE" />,
        },
      ]}
    >
      <Card>
        <CardBody>
          {company && (
            <DataTable_
              code={companyCode}
              hasDownloadButton
              values={null}
              isButtonCkicked={false}
              hasDeleteButton={true}
              hasEditButton={true}
              hasCustomerPageButton={false}
            />
          )}
        </CardBody>
      </Card>
    </Breadcrumb>
  );
};
