import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import {
  ActionsColumn,
  BootstrapDataTable,
  Column,
} from 'react-admin-base-bootstrap';
import { useUser } from '../../UserProvider';
import { useFetch } from 'react-admin-base';
import { useIntl } from 'react-intl';
import { providerTypeNums } from '../../../Util/Constants';
import { InputField } from '../../../Components/Input';
import { DataTable } from 'primereact/datatable';
import { LinkButton } from '../../../Components/ActionButtons/ActionButtons';
import { icons } from '../../../Icons/icons';
import { canSeeEditButton } from '../previous-analysis/PreviousAnalysisTable';

const summaryPageUrl = (code, documentId) => {
  const url =
    code !== 'ta3meed'
      ? '/analysis/' + window.btoa(documentId) + '/edit'
      : '/analysis/' + window.btoa(documentId) + '/edit/empty';

  return url;
};

const CustomerAnalysisTable2 = ({ id }) => {
  const user = useUser();
  const [company, companyLoading] = useFetch(`/company/get/user/${user.id}`);
  const intl = useIntl();
  const companyCode = company && company.code;
  const [lazyParams, setLazyParams] = React.useState({
    first: 1,
    rows: 10,
    page: 1,
    sortField: 'id',
    sortOrder: 1,
    filters: {},
    multiSortMeta: [],
  });
  const [globalFilterValue, setGlobalFilterValue] = React.useState('');

  const [analysis, analysisLoading] = useFetch(
    `/analyze/get/customer/${id}?page=${lazyParams.page}&itemPerPage=${lazyParams.rows}&query=${globalFilterValue}&sort=${lazyParams.sortField}&desc=${lazyParams.sortOrder === 1 ? true : false}`
  );

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;

    setGlobalFilterValue(value);
  };

  const onPage = (event) => {
    setLazyParams({ ...event, page: event.page + 1 });
  };

  const onFilter = (event) => {
    setLazyParams(event);
  };

  React.useEffect(() => {
    const tableHeader = document.getElementsByClassName('card-header');
    if (tableHeader.length > 0) {
      tableHeader[0].style.display = 'none';
    }
  }, []);

  const onSort = (event) => {
    setLazyParams((prev) => ({
      ...event,
      page: 1,
    }));
  };

  const totalRecords = analysis ? analysis.itemCount : 0;
  const footer = (
    <span
      style={{
        position: 'absolute',
        bottom: '20px',
        right: '5px',
      }}
    >
      <FormattedMessage id="FILTERED_ANALYSIS_NUMBER" />: {totalRecords}
    </span>
  );

  const renderHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <InputField
          name="globalFilterValue"
          type="text"
          onChange={onGlobalFilterChange}
          value={globalFilterValue}
          placeHolder={intl.formatMessage({ id: 'SEARCH' })}
          style={{ width: '15%' }}
        />
      </div>
    );
  };

  return (
    <DataTable
      value={analysis ? [...analysis.data] : []}
      className="mt-3"
      scrollable
      loading={companyLoading || analysisLoading}
      paginator
      rowsPerPageOptions={[5, 10, 25, 50]}
      onPage={onPage}
      first={lazyParams.first}
      rows={lazyParams.rows}
      totalRecords={totalRecords}
      onSort={onSort}
      sortField={lazyParams.sortField}
      sortOrder={lazyParams.sortOrder}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
      currentPageReportTemplate=" "
      emptyMessage={intl.formatMessage({ id: 'NO_DATA_IS_AVAILABLE' })}
      lazy
      header={renderHeader}
      onGlobalFilterChange={onFilter}
      footer={footer}
    >
      <Column
        header={<FormattedMessage id="PREV_ANL_DECLARATION_YEAR_TITLE" />}
        sortField="customer.statementYear"
        sortable
        body={(rowData) => {
          return rowData.customer
            ? `${rowData.customer.statementYear}/${
                rowData.customer.statementMonth || ''
              }`
            : '';
        }}
      />
      <Column
        header={<FormattedMessage id="GROUP/SOLO" />}
        sortField="isConsolidated"
        sortable
        body={(rowData) => {
          return rowData.isConsolidated ? (
            <FormattedMessage id="GROUP" />
          ) : (
            <FormattedMessage id="SOLO" />
          );
        }}
      />

      <Column
        field="createdAt"
        header={<FormattedMessage id="PREV_ANL_ANALYSIS_DATE" />}
        sortField="createdAt"
        sortable
        body={(rowData) => {
          return <FormattedDate value={rowData.createdAt} />;
        }}
      />

      <Column
        header={<FormattedMessage id="companyAnalysisType" />}
        body={(rowData) => {
          return rowData.providerType &&
            providerTypeNums.includes(rowData.providerType) ? (
            <span>{<FormattedMessage id={rowData.providerType} />}</span>
          ) : (
            ''
          );
        }}
        sortField="providerType"
        field="providerType"
        sortable
      />

      <Column
        header={<i className="fas fa-water" />}
        body={(rowData) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5rem',
              }}
            >
              <LinkButton
                url={'/report/' + window.btoa(rowData.documentId) + '/summary'}
                text={intl.formatMessage({
                  id: 'TO_ANALYSIS',
                })}
                size="sm"
                color="primary"
                iconClassName={icons.startAnalysis}
              />
              {canSeeEditButton(company) && (
                <LinkButton
                  url={summaryPageUrl(companyCode, rowData.documentId)}
                  text={intl.formatMessage({
                    id: 'EDIT',
                  })}
                  size="sm"
                  color="secondary"
                  iconClassName={icons.edit}
                />
              )}
            </div>
          );
        }}
        style={{
          width: '10%',
        }}
      />
    </DataTable>
  );
};

export const CustomerAnalysisTable = React.memo(CustomerAnalysisTable2);
