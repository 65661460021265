import React from 'react';
import useFetch from '../../../Hooks/useFetch';
import { Penalty } from '../../../Common/ScoringTab';
import { Alerts } from '../../../Components/Alerts';

export const PenaltyBnpl = ({ firmInfo }) => {
  const [penalty, loading] = useFetch('/bnpl/penalty/' + firmInfo);

  return !loading ? (
    penalty ? (
      <Penalty penalty={penalty} disable={false} />
    ) : (
      <h6>No Data Available.</h6>
    )
  ) : (
    <Alerts
      color="primary"
      className="fas fa-spin fa-spinner"
      messageId={'LOADING'}
      label=""
    />
  );
};
