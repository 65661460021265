import React from 'react';
import { Image, StyleSheet, Text, View, Page } from '@react-pdf/renderer';
import { FormattedMessage } from 'react-intl';
import { colors, fontSizes, padding } from '../Common';
import frayLogo from '../../../FileTemplatesAndImages/f-ray.png';
import tamedLogo from '../../../FileTemplatesAndImages/ta3meed-logo.png';

import { useCompanyCode } from '../context/CompanyCodeProvider';

export const PdfPage = ({ children, documentName, orientation }) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      fontFamily: 'Open Sans',
    },
  });

  return (
    <Page size="A4" style={styles.page} orientation={orientation || 'portrait'}>
      <PageHeader documentName={documentName} />
      <View style={{ marginTop: '40px', padding: '0 30px' }}> {children}</View>
    </Page>
  );
};

export const PageHeader = ({ documentName }) => {
  const styles = StyleSheet.create({
    header: {
      height: '95px',
      width: '100%',
      backgroundColor: colors.primary,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      padding: '0 35px',
    },
    logo: {
      width: '150px',
      heigth: '50px',
    },
  });
  const { companyCode } = useCompanyCode(); // Context hook'u kullanarak companyCode ve setCompanyCode'a erişim
  const logo = companyCode === 'ta3meed' ? tamedLogo : frayLogo;

  return (
    <View
      style={{
        flexDirection: 'column',
        height: '130px',
      }}
    >
      <View style={styles.header}>
        <Image src={logo} style={styles.logo} />
        <View
          style={{
            color: colors.white,
            height: '39px',
          }}
        >
          <Text
            style={{
              fontWeight: 700,
              fontSize: fontSizes.header,
              textTransform: 'capitalize',
              textAlign: 'center',
            }}
          >
            <FormattedMessage id="FINANCIAL_ANALYSIS">
              {(text) => {
                return (
                  <>
                    {text[0].toLocaleUpperCase(
                      localStorage?.getItem('frayjet_frayjet_language') === 'tr'
                        ? 'tr-TR'
                        : 'en-EN'
                    )}
                  </>
                );
              }}
            </FormattedMessage>
          </Text>
          <Text
            style={{
              fontWeight: 700,
              fontSize: fontSizes.header,
              textTransform: 'capitalize',
              textAlign: 'center',
            }}
          >
            <FormattedMessage id="DETECTION_REPORT">
              {(text) => {
                return (
                  <>
                    {text[0].toLocaleUpperCase(
                      localStorage?.getItem('frayjet_frayjet_language') === 'tr'
                        ? 'tr-TR'
                        : 'en-EN'
                    )}
                  </>
                );
              }}
            </FormattedMessage>
          </Text>
        </View>
      </View>
      <View style={{ marginTop: '95px' }}>
        <View
          style={{
            height: '10px',
          }}
        ></View>
        <View
          style={{
            height: '5px',
            backgroundColor: colors.secondary,
            width: '100%',
          }}
        ></View>
        <View
          style={{
            height: '31px',
            backgroundColor: colors.secondary,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            right: 0,
            marginRight: '35px',
            padding: padding,
          }}
        >
          <Text
            style={{
              color: colors.white,
              margin: '0 auto',
              textAlign: 'center',

              fontWeight: 600,
              fontSize: fontSizes.title,
            }}
          >
            {documentName ? (
              <FormattedMessage id={documentName} />
            ) : companyCode === 'ta3meed' ? (
              'Qualitative Analysis'
            ) : (
              '-'
            )}
          </Text>
        </View>
      </View>
    </View>
  );
};
