import React from 'react';
import {
  Document,
  Text,
  View,
  StyleSheet,
  Image,
  PDFDownloadLink,
  Font,
  Svg,
  Path,
  pdf,
} from '@react-pdf/renderer';

import icon6 from '../../FileTemplatesAndImages/financial_charts.png';
import icon7 from '../../FileTemplatesAndImages/distribution.png';

import icon10 from '../../FileTemplatesAndImages/altman_1.png';
import icon11 from '../../FileTemplatesAndImages/altman_2.png';
import icon12 from '../../FileTemplatesAndImages/machine.png';
import { ModuleGrafiklerSocar } from '../../Socar/ModuleGrafiklerSocar';
import html2canvas from 'html2canvas';
import { FormattedMessage, useIntl } from 'react-intl';
import { languages } from '../../App';
import { LanguageProvider } from 'react-admin-base-bootstrap';
import { AltmanBrPrediction } from '../../Components/AltmanzScoringReport';
import { ModuleMakineData } from '../../Common/ModuleMakine';
import { Button } from 'react-bootstrap';
import { Loading } from '../../Components/Loading';
import { formatNumber, formatPercentage } from '../../Util/formatNumber';
import { filterTitles } from '../../Common/ModuleFinansalOranlar';
import { FormattedNumber } from 'react-intl';
import {
  colors,
  margin,
  cellHeight,
  fontSizes,
  padding,
  filteredTitlesForTaemed,
  percentageTitlesForTeamed,
  percentageNamesForTeamed,
} from '../Pdf/Common';
import { PdfPage } from '../Pdf/components/PdfPage';
import { PdfFirstPage } from '../Pdf/components/PdfFirstPage';
import { PdfLastPage } from '../Pdf/components/PdfLastPage';
import { TableHeader } from '../Pdf/components/TableHeader';
import { CombinedScore } from '../Pdf/components/TaemedCombinedScore';
import { SurveyResult } from '../Pdf/components/TaemedSurveyResult';
import { CompanyCodeProvider } from '../Pdf/context/CompanyCodeProvider';
import OpenSansRegular from '../Pdf/assets/fonts/open-sans.regular.ttf';
import OpenSansBold from '../Pdf/assets/fonts/open-sans.bold.ttf';
import OpenSansSemiBold from '../Pdf/assets/fonts/open-sans.semibold.ttf';
import { Button_ } from '../../Components/ActionButtons/ActionButtons';
import { icons } from '../../Icons/icons';

export const documentOptions = {
  1: 'Kurumlar Vergisi Beyannamesi',
  7: 'Mizan Analizi',
  9: 'Bağımsız Denetim Raporu',
  10: 'Bağımsız Denetim Raporu',
  14: 'Konsolidasyon Analizi',
  15: 'Mizan Analizi',
  11: 'Financial Statement',
  13: 'Simah Statement',
  16: 'BNPL Statement',
};

export const PdfFinancialEvaluationTable = ({ data, companyCode }) => {
  const styles = StyleSheet.create({
    table: {
      border: 'none',
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginTop: margin,
      minHeight: cellHeight,
      alignItems: 'stretch',
    },
    tableCol: {
      minHeight: cellHeight,
      marginRight: margin,
      display: 'flex',
      textAlign: 'left',
      justifyContent: 'center',
    },
    tableCell: {
      padding: padding,
    },
    content: {
      color: colors.primary,
      fontSize: fontSizes.content,
      position: 'relative',
      padding: padding,
      minHeight: '20px',
    },
    icon: {
      width: '20px',
      height: '20px',
    },
  });

  const title = data.name;
  const tableData = data.subTitles;

  const configureData = (data) => {
    if (!data.includes('\n')) {
      const splittedData = data.split(': ');
      return [splittedData[0], splittedData[1]];
    } else {
      const splittedData = data.split('\n');

      return [
        `${splittedData[0].split(': ')[0]}\n${splittedData[1].split(': ')[0]}`,
        `${splittedData[0].split(': ')[1]}\n ${splittedData[1].split(': ')[1]}`,
      ];
    }
  };
  return (
    <View style={styles.table}>
      <TableHeader title={title} styles={styles} title2="" />
      {tableData.map((e, i) => {
        return (
          <React.Fragment key={i}>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor: '#E9E7E8',
                    width: cellHeight,
                    alignItems: 'center',
                  },
                ]}
              >
                <Svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="chevron-left"
                  class="svg-inline--fa fa-chevron-left fa-w-10"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512" // Buradaki değeri güncelleyebilirsiniz
                  width="20" // SVG genişliği
                  height="20" // SVG yüksekliği
                >
                  <Path
                    fill={colors.primary}
                    d="M267.5 256 119 107.5c-4.7-4.7-12.3-4.7-17 0L89.2 120.3c-4.7 4.7-4.7 12.3 0 17L203.4 256 89.2 370.2c-4.7 4.7-4.7 12.3 0 17l12.8 12.8c4.7 4.7 12.3 4.7 17 0L267.5 256z"
                  ></Path>
                </Svg>
              </View>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor: '#F3F3F5',
                    flex: 1,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.tableCell,
                    {
                      fontSize: fontSizes.title,
                      color: colors.primary,
                    },
                  ]}
                >
                  {e.name ? configureData(e.name)[0] : '-'}
                </Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor: '#E9E7E8',
                    flex: 1,
                    marginRight: 0,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.tableCell,
                    {
                      color: colors.primary,
                      fontSize: fontSizes.title,
                      fontWeight: 700,
                      fontFamily: 'Open Sans',
                    },
                  ]}
                >
                  {e.name ? configureData(e.name)[1] : '-'}
                </Text>
              </View>
            </View>
            {((companyCode === 'ta3meed' && title !== 'Profitability') ||
              companyCode !== 'ta3meed') &&
              e.description && (
                <View
                  style={[
                    styles.tableRow,
                    {
                      minHeight: '20px',
                    },
                  ]}
                >
                  <View
                    style={[
                      styles.tableCol,
                      {
                        width: cellHeight,
                        minHeight: '20px',
                      },
                    ]}
                  ></View>

                  <View
                    style={[
                      styles.tableCol,
                      {
                        marginRight: 0,
                        flex: 1,
                        minHeight: '20px',
                      },
                    ]}
                  >
                    <Text style={styles.content}>{e.description}</Text>
                  </View>
                </View>
              )}
          </React.Fragment>
        );
      })}
    </View>
  );
};

export const PdfFinancialRatiosTable = ({ data, companyCode }) => {
  const styles = StyleSheet.create({
    table: {
      display: 'table',
      border: 'none',
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginTop: margin,
      minHeight: cellHeight,
      alignItems: 'stretch',

      pageBreakInside: 'avoid',
    },
    tableCol: {
      minHeight: cellHeight,
      marginLeft: margin,
      display: 'flex',
      textAlign: 'left',
      justifyContent: 'center',
    },
    tableCell: {
      padding: padding,
      fontSize: fontSizes.title,
      color: colors.primary,
    },
    content: {
      color: colors.primary,
      fontSize: fontSizes.content,
      position: 'relative',
      padding: padding,
    },
    icon: {
      width: '20px',
      height: '20px',
    },
  });
  const isCompanySee =
    companyCode === 'standart' ||
    companyCode === 'socar' ||
    companyCode === 'aktifbank' ||
    companyCode === 'teb' ||
    companyCode === 'usulventures' ||
    companyCode === 'tbaydin' ||
    companyCode === 'tai';

  const title = data.title;
  const tableData = data.subRatios;

  return (
    <View style={styles.table}>
      <TableHeader
        title={<FormattedMessage id="FINANCIAL_RATIOS" />}
        styles={{
          ...styles,
          tableCell: { ...styles.tableCell, fontSize: fontSizes.header },
        }}
        title2=""
      />
      <View style={styles.tableRow}>
        <View
          style={[
            styles.tableCol,
            {
              backgroundColor: colors.cell2,
              fontSize: fontSizes.title,
              flex: 2,
            },
          ]}
        >
          <Text style={[styles.tableCell, { fontWeight: 600 }]}>{title}</Text>
        </View>
        {title !== 'Growth' ? (
          tableData &&
          tableData[0] &&
          tableData[0].values.map((a, i) => (
            <React.Fragment key={i}>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor: colors.cell1,
                    flex: 1,
                  },
                ]}
              >
                <Text style={[styles.tableCell, { fontWeight: 600 }]}>
                  {a.year}
                </Text>
              </View>
              {isCompanySee && (
                <View
                  style={[
                    styles.tableCol,
                    {
                      backgroundColor: colors.cell2,
                      flex: 1,
                    },
                  ]}
                >
                  <Text style={[styles.tableCell, { fontWeight: 600 }]}>
                    {a.year} <FormattedMessage id="SUBTAB_2_1_TITLE3" />
                  </Text>
                </View>
              )}
            </React.Fragment>
          ))
        ) : (
          <View
            style={[
              styles.tableCol,
              {
                backgroundColor: colors.cell1,
                flex: 1,
                marginRight: 0,
              },
            ]}
          >
            <Text style={[styles.tableCell, { fontWeight: 600 }]}>
              {data.subRatios[0].values[1].year +
                ' vs ' +
                data.subRatios[0].values[0].year}
            </Text>
          </View>
        )}
      </View>

      {tableData.map((a, i) => (
        <View style={styles.tableRow} key={i}>
          <View
            style={[
              styles.tableCol,
              {
                backgroundColor: colors.cell2,
                fontSize: fontSizes.title,
                flex: 2,
              },
            ]}
          >
            <Text style={styles.tableCell}>{a.name}</Text>
          </View>
          {title !== 'Growth' ? (
            a.values.map((b, v) => {
              const isTaemedAndPercentage =
                companyCode === 'ta3meed' &&
                (percentageTitlesForTeamed.includes(title) ||
                  percentageNamesForTeamed.includes(a.name));

              return (
                <React.Fragment key={v}>
                  <View
                    style={[
                      styles.tableCol,
                      {
                        backgroundColor: colors.cell1,
                        fontSize: fontSizes.title,
                        flex: 1,
                      },
                    ]}
                  >
                    <Text style={styles.tableCell}>
                      {isTaemedAndPercentage
                        ? formatPercentage(b.yearValue)
                        : formatNumber(b.yearValue)}
                    </Text>
                  </View>
                  {isCompanySee && (
                    <View
                      style={[
                        styles.tableCol,
                        {
                          backgroundColor: colors.cell2,
                          fontSize: fontSizes.title,
                          flex: 1,
                        },
                      ]}
                    >
                      <Text style={styles.tableCell}>
                        {formatNumber(b.q2Value)}
                      </Text>
                    </View>
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <View
              style={[
                styles.tableCol,
                {
                  backgroundColor: colors.cell2,
                  fontSize: fontSizes.title,
                  flex: 1,
                },
              ]}
            >
              <Text style={styles.tableCell}>
                {companyCode === 'ta3meed'
                  ? formatPercentage(a.values[0].yearValue)
                  : formatNumber(a.values[0].yearValue)}
              </Text>
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

export const DupontTable = ({ dupont, disable }) => {
  const intl = useIntl();
  const styles = StyleSheet.create({
    table: {
      border: 'none',
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginTop: margin,
      minHeight: cellHeight,
      alignItems: 'stretch',
      pageBreakInside: 'avoid',
    },
    tableCol: {
      minHeight: cellHeight,
      marginRight: margin,
      display: 'flex',
      textAlign: 'left',
      justifyContent: 'center',
    },
    tableCell: {
      padding: padding,
      fontSize: fontSizes.title,
      color: colors.primary,
    },
    content: {
      color: colors.primary,
      fontSize: fontSizes.content,
      position: 'relative',
      padding: padding,
    },
    icon: {
      width: '20px',
      height: '20px',
    },
  });

  const dupontTitles = [
    intl.formatMessage({ id: 'SUBTAB_1_3_TITLE1' }),

    intl.formatMessage({ id: 'SUBTAB_1_3_TITLE2' }),
    ...dupont[0].values.map((e) => e.year),
    intl.formatMessage({ id: 'SUBTAB_1_3_TITLE5' }),
  ];

  disable && dupontTitles.pop();

  return (
    <View style={styles.table}>
      <TableHeader
        title=<FormattedMessage id="SUBTAB_DUPONT_TITLE" />
        styles={{
          ...styles,
          tableCell: { ...styles.tableCell, fontSize: fontSizes.header },
        }}
        title2=""
      />
      <View style={styles.tableRow}>
        {dupontTitles.map((e, i) => {
          return (
            <View
              style={[
                styles.tableCol,
                {
                  backgroundColor: i % 2 === 0 ? colors.cell2 : colors.cell1,
                  flex:
                    (i === dupontTitles.length - 1 && !disable) || i === 1
                      ? 2
                      : 1,
                  marginRight: i === dupontTitles.length - 1 ? 0 : '5px',

                  fontWeight: 600,
                },
              ]}
              key={i}
            >
              <Text style={[styles.tableCell]}>{e}</Text>
            </View>
          );
        })}
      </View>
      {dupont.map((e, i) => {
        return (
          <>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor: colors.cell1,
                    flex: 1,
                  },
                ]}
              >
                <Text style={styles.tableCell}>{e.name}</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor: colors.cell2,
                    flex: 2,
                  },
                ]}
              >
                <Text style={styles.tableCell}>{e.formula}</Text>
              </View>
              {e.values.map((a, i) => {
                return (
                  <View
                    style={[
                      styles.tableCol,
                      {
                        backgroundColor:
                          i % 2 !== 0 ? colors.cell2 : colors.cell1,
                        flex: 1,
                        marginRight: i === e.values.length - 1 ? 0 : '5px',
                      },
                    ]}
                    key={i}
                  >
                    <Text style={styles.tableCell}>
                      {formatNumber(a.value)}
                    </Text>
                  </View>
                );
              })}

              {!disable && (
                <View
                  style={[
                    styles.tableCol,
                    {
                      marginRight: 0,
                      flex: 2,
                      backgroundColor: colors.cell1,
                    },
                  ]}
                >
                  <Text style={styles.tableCell}>{e.description}</Text>
                </View>
              )}
            </View>
          </>
        );
      })}
    </View>
  );
};

export const FinancialTablePages = ({ data, documentName }) => {
  const tableData = data.dataList;
  const name = data.name;

  const years = tableData[0].titles[0].values.map((e) => e.year);

  const styles = StyleSheet.create({
    table: {
      display: 'table',
      border: 'none',
    },
    tableRow: {
      display: 'block',
      flexDirection: 'row',
      width: '100%',
      marginTop: margin,
      minHeight: cellHeight,
      alignItems: 'stretch',
      pageBreakInside: 'avoid',
    },
    tableCol: {
      minHeight: cellHeight,
      marginLeft: margin,
      display: 'flex',
      textAlign: 'left',
      justifyContent: 'center',
    },
    tableCell: {
      padding: padding,
      fontSize: years.length > 5 ? fontSizes.title2 : fontSizes.title,
      color: colors.primary,
    },
    content: {
      color: colors.primary,
      fontSize: fontSizes.content,
      position: 'relative',
      padding: padding,
    },
    icon: {
      width: '20px',
      height: '20px',
    },
  });

  const splitDataIntoPages = (tableData, rowsPerPage) => {
    const pages = {};
    let page = 1;

    tableData.map((e) => {
      //filter for all 0
      const filteredTitles = filterTitles(e.titles);

      const titles = filteredTitles.map((e2) => {
        return { mainEntry: e.mainEntry, ...e2 };
      });

      if (!pages[page]) pages[page] = [];

      for (let i = 0; i < titles.length; ) {
        const length = pages[page].length;
        if (pages[page].length === rowsPerPage) {
          page++;
          pages[page] = titles.slice(i, i + rowsPerPage);
          i = i + rowsPerPage;
        } else {
          pages[page].push(...titles.slice(i, i + rowsPerPage - length));
          i = i + rowsPerPage - length;
        }
      }
    });

    return pages;
  };

  const pages = splitDataIntoPages(tableData, 5);

  return Object.entries(pages).map(([e2, e], i) => {
    let main2 = '';

    return (
      <PdfPage documentName={documentName} key={i} orientation="landscape">
        <View style={styles.table}>
          {i === 0 && (
            <>
              <TableHeader
                title={<FormattedMessage id="TAB_4_FINANCIAL_TABLES" />}
                styles={{
                  ...styles,
                  tableCell: {
                    ...styles.tableCell,
                    fontSize: fontSizes.header,
                  },
                }}
                title2={name}
              />

              <View style={styles.tableRow} id="break">
                <View
                  style={[
                    styles.tableCol,
                    {
                      backgroundColor: colors.cell2,
                      fontSize: fontSizes.title,
                      flex: 1.8,
                    },
                  ]}
                >
                  <Text style={[styles.tableCell, { fontWeight: 600 }]}>
                    <FormattedMessage id="SUBTAB_2_1_TITLE1" />
                  </Text>
                </View>

                {years.map((b, v) => (
                  <View
                    style={[
                      styles.tableCol,
                      {
                        backgroundColor: colors.cell1,
                        flex:
                          b &&
                          (b.includes('Dikey') ||
                            b.includes('Dikey') ||
                            b.includes('vs'))
                            ? 1
                            : 1.8,
                      },
                    ]}
                    key={v}
                  >
                    <Text style={[styles.tableCell, { fontWeight: 600 }]}>
                      {b.endsWith('/0') || b.includes('/0 vs')
                        ? b.replaceAll('/0', '')
                        : b}
                    </Text>
                  </View>
                ))}
              </View>
            </>
          )}

          {e.map((a, i) => {
            const copyMain = main2;
            if (main2 !== a.mainEntry) main2 = a.mainEntry;
            return (
              <>
                {a.mainEntry !== copyMain && (
                  <View
                    style={[
                      styles.tableRow,
                      {
                        justifyContent: 'center',
                      },
                    ]}
                    id="break"
                  >
                    <View
                      style={[
                        styles.tableCol,
                        {
                          fontWeight: 600,
                        },
                      ]}
                    >
                      {' '}
                      <Text style={styles.tableCell}>{a.mainEntry}</Text>
                    </View>
                  </View>
                )}
                <View style={styles.tableRow} id="break">
                  <View
                    style={[
                      styles.tableCol,
                      {
                        backgroundColor:
                          i % 2 !== 0 ? colors.cell2 : colors.cell1,
                        flex: 1.8,
                      },
                    ]}
                    key={i}
                  >
                    <Text
                      style={[
                        styles.tableCell,
                        {
                          color: a.title.includes('(-)')
                            ? 'red'
                            : colors.primary,
                        },
                      ]}
                    >
                      {a.title}
                    </Text>
                  </View>
                  {a.values.map((b, v) => (
                    <View
                      style={[
                        styles.tableCol,
                        {
                          backgroundColor: colors.cell2,
                          flex:
                            b.year.includes('Dikey') ||
                            b.year.includes('Dikey') ||
                            b.year.includes('vs')
                              ? 1
                              : 1.8,
                        },
                      ]}
                      key={v}
                    >
                      <Text style={[styles.tableCell]}>
                        {b.year.toString().indexOf('vs') === -1 &&
                        b.year.toString().indexOf('Dikey') === -1 &&
                        b.year.toString().indexOf('Common') === -1 ? (
                          b.value === 0 ? (
                            '0'
                          ) : (
                            formatNumber(b.value)
                          )
                        ) : b.value === 0 ? (
                          '0'
                        ) : (
                          <FormattedNumber value={b.value} style="percent" />
                        )}
                      </Text>
                    </View>
                  ))}
                </View>
              </>
            );
          })}
        </View>
      </PdfPage>
    );
  });
};

const PdfDocument = ({
  assessment,
  scoringVal,
  scoring,
  dupont,
  screenshotUrl,
  customer,
  statement,
  companyCode,
  combinedScore,
  surveyResult,
  noDocument,
}) => {
  const assesmentData =
    assessment && assessment.titles
      ? companyCode === 'ta3meed'
        ? [
            ...assessment.titles.filter(
              (val) => !filteredTitlesForTaemed.includes(val.name)
            ),
          ]
        : [...assessment.titles]
      : [];
  const ratios = (scoring && scoring.ratios) || '';
  const dopontData = (dupont && dupont.categories) || '';

  const documentName = customer.providerType;

  Font.register({
    family: 'Open Sans',
    fonts: [
      {
        src: OpenSansRegular,
      },
      {
        src: OpenSansBold,
        fontWeight: 600,
      },
      {
        src: OpenSansSemiBold,
        fontWeight: 700,
      },
    ],
  });

  const statementMainList = statement && statement.mainList;
  return (
    <CompanyCodeProvider value={companyCode}>
      <Document
        onRender={() => {
          console.log('Rendered');
        }}
      >
        <PdfFirstPage customer={customer} documentName={documentName} />
        {companyCode === 'ta3meed' && (
          <PdfPage documentName={documentName} orientation="portrait">
            <FinancialScoreChart scoringVal={scoringVal} scoring={scoring} />(
            <CombinedScore combinedScore={combinedScore} />
            {surveyResult && (
              <View style={{ marginTop: '30px' }}>
                <SurveyResult surveyResult={surveyResult} />
              </View>
            )}
            )
          </PdfPage>
        )}

        {!noDocument && (
          <>
            {assesmentData &&
              assesmentData.length > 0 &&
              assesmentData.map((e, key) => {
                return (
                  <PdfPage
                    key={key}
                    documentName={documentName}
                    orientation="portrait"
                  >
                    {key === 0 && companyCode !== 'ta3meed' && (
                      <FinancialScoreChart
                        scoringVal={scoringVal}
                        scoring={scoring}
                      />
                    )}
                    <PdfFinancialEvaluationTable
                      data={e}
                      companyCode={companyCode}
                    />
                    {key === assesmentData.length - 1 &&
                      companyCode !== 'ta3meed' &&
                      screenshotUrl['tableElement'] &&
                      screenshotUrl['tableElement2'] && (
                        <View style={{ marginTop: '30px' }}>
                          <PdfTableForScreenShot
                            screenshotUrl={screenshotUrl}
                            tableElement={'tableElement'}
                            tableElement2={'tableElement2'}
                            width=""
                            height=""
                            title=<FormattedMessage id="SUBTAB_1_2_FINANCIAL_GRAPHS" />
                            title2=""
                            icon={icon6}
                          />
                        </View>
                      )}
                  </PdfPage>
                );
              })}
            {screenshotUrl['tableElement'] &&
              screenshotUrl['tableElement2'] &&
              companyCode === 'ta3meed' && (
                <PdfPage documentName={documentName} orientation="portrait">
                  <PdfTableForScreenShot
                    screenshotUrl={screenshotUrl}
                    tableElement={'tableElement'}
                    tableElement2={'tableElement2'}
                    width=""
                    height=""
                    title=<FormattedMessage id="SUBTAB_1_2_FINANCIAL_GRAPHS" />
                    title2=""
                    icon={icon6}
                  />
                </PdfPage>
              )}
            {screenshotUrl['tableElement3'] &&
              screenshotUrl['tableElement4'] && (
                <PdfPage documentName={documentName} orientation="portrait">
                  <PdfTableForScreenShot
                    screenshotUrl={screenshotUrl}
                    tableElement={'tableElement3'}
                    tableElement2={'tableElement4'}
                    width=""
                    height=""
                    title=<FormattedMessage id="SUBTAB_1_2_FINANCIAL_GRAPHS" />
                    title2=""
                    icon={icon6}
                  />
                </PdfPage>
              )}
            {((screenshotUrl['tableElement5'] &&
              screenshotUrl['tableElement6']) ||
              (screenshotUrl['tableElement7'] &&
                screenshotUrl['tableElement8'])) && (
              <PdfPage documentName={documentName} orientation="portrait">
                <PdfTableForScreenShot
                  screenshotUrl={screenshotUrl}
                  tableElement={'tableElement5'}
                  tableElement2={'tableElement6'}
                  width=""
                  height=""
                  title=<FormattedMessage id="SUBTAB_ASSET_DISTRIBUTION_TITLE" />
                  title2=""
                  icon={icon7}
                />
                <View style={{ marginTop: '30px' }}>
                  <PdfTableForScreenShot
                    screenshotUrl={screenshotUrl}
                    tableElement={'tableElement7'}
                    tableElement2={'tableElement8'}
                    width=""
                    height=""
                    title=<FormattedMessage id="SUBTAB_RESOURCES_DISTRIBUTION" />
                    title2=""
                    icon={icon7}
                  />
                </View>
              </PdfPage>
            )}
            {dopontData && (
              <PdfPage documentName={documentName} orientation="portrait">
                <DupontTable
                  dupont={dopontData}
                  disable={companyCode === 'ta3meed'}
                />
                {screenshotUrl['tableElement9'] &&
                  screenshotUrl['tableElement10'] && (
                    <View style={{ marginTop: '30px' }}>
                      <PdfTableForScreenShot
                        screenshotUrl={screenshotUrl}
                        tableElement={'tableElement9'}
                        tableElement2={'tableElement10'}
                        width=""
                        height=""
                        title=<FormattedMessage id="SUBTAB_1_4_ALTMAN_BANKRUPTCY_FORECAST" />
                        title2=<FormattedMessage id="SUBTAB_MANUFACTORING_TITLE" />
                        icon={icon10}
                      />
                    </View>
                  )}
              </PdfPage>
            )}
            {screenshotUrl['tableElement11'] &&
              screenshotUrl['tableElement12'] && (
                <PdfPage documentName={documentName} orientation="portrait">
                  <PdfTableForScreenShot
                    screenshotUrl={screenshotUrl}
                    tableElement={'tableElement11'}
                    tableElement2={'tableElement12'}
                    width=""
                    height=""
                    title=<FormattedMessage id="SUBTAB_1_4_ALTMAN_BANKRUPTCY_FORECAST" />
                    title2=<FormattedMessage id="SUBTAB_NON_MANUFACTORING_TITLE" />
                    icon={icon11}
                  />
                  {screenshotUrl['tableElement13'] &&
                    companyCode !== 'ta3meedbnpl' &&
                    companyCode !== 'ta3meed' &&
                    companyCode !== 'global' && (
                      <View style={{ marginTop: '30px' }}>
                        <PdfTableForScreenShot
                          screenshotUrl={screenshotUrl}
                          tableElement={'tableElement13'}
                          tableElement2={''}
                          width=""
                          height=""
                          title=<FormattedMessage id="SUBTAB_1_5_MACHINE_LEARNING" />
                          title2=""
                          icon={icon12}
                        />
                      </View>
                    )}
                </PdfPage>
              )}
            {ratios &&
              ratios.length > 0 &&
              ratios.map((e, key) => {
                return (
                  <PdfPage
                    key={key}
                    documentName={documentName}
                    orientation="portrait"
                  >
                    <PdfFinancialRatiosTable
                      data={e}
                      companyCode={companyCode}
                    />
                    {key === ratios.length - 1 && companyCode !== 'ta3meed' && (
                      <View
                        style={{
                          marginTop: '30px',
                          backgroundColor: colors.cell1,
                          padding: '5px',
                        }}
                      >
                        <Text
                          style={{
                            fontSize: fontSizes.title,
                            color: colors.primary,
                          }}
                        >
                          <FormattedMessage id="INFORMATION_TITLE" />
                        </Text>

                        <Text
                          style={{
                            fontSize: fontSizes.content,
                            textAlign: 'justify',
                            color: colors.primary,
                          }}
                        >
                          <FormattedMessage id="INFORMATION_TEXT" />
                        </Text>
                      </View>
                    )}
                  </PdfPage>
                );
              })}
            {statementMainList &&
              statementMainList.map((e, i) => {
                return (
                  <FinancialTablePages
                    data={e}
                    key={i}
                    documentName={documentName}
                  />
                );
              })}
            )
          </>
        )}

        {companyCode !== 'ta3meed' && <PdfLastPage />}
      </Document>
    </CompanyCodeProvider>
  );
};

export const Pdf = ({
  assessment,
  scoringVal,
  scoring,
  dupont,
  incomestatement,
  companyCode,
  assets,
  resources,
  altmanzscoring,
  prediction,
  customer,
  statement,
  combinedScore,
  surveyResult,
  noDocument,
}) => {
  const intl = useIntl();

  const [loadingSs, setLoadingSs] = React.useState(false);

  const [state, setState] = React.useState(false);
  const containerRef = React.useRef(null);
  const containerRef2 = React.useRef(null);
  const containerRef3 = React.useRef(null);
  const containerRef4 = React.useRef(null);
  const containerRef5 = React.useRef(null);
  const containerRef6 = React.useRef(null);
  const containerRef7 = React.useRef(null);
  const containerRef8 = React.useRef(null);
  const containerRef9 = React.useRef(null);
  const containerRef10 = React.useRef(null);
  const containerRef11 = React.useRef(null);
  const containerRef12 = React.useRef(null);
  const containerRef13 = React.useRef(null);

  const [screenshotUrl, setScreenshotUrl] = React.useState({});

  const takeScreenshot = () => {
    const tableElement = containerRef.current;
    const tableElement2 = containerRef2.current;
    const tableElement3 = containerRef3.current;
    const tableElement4 = containerRef4.current;
    const tableElement5 = containerRef5.current;
    const tableElement6 = containerRef6.current;
    const tableElement7 = containerRef7.current;
    const tableElement8 = containerRef8.current;
    const tableElement9 = containerRef9.current;
    const tableElement10 = containerRef10.current;
    const tableElement11 = containerRef11.current;
    const tableElement12 = containerRef12.current;
    const tableElement13 = containerRef13.current;

    const documents = [
      tableElement,
      tableElement2,
      tableElement3,
      tableElement4,
      tableElement5,
      tableElement6,
      tableElement7,
      tableElement8,
      tableElement9,
      tableElement10,
      tableElement11,
      tableElement12,
      tableElement13,
    ];

    const getBlob = async (urls) => {
      const blob = await pdf(
        <LanguageProvider
          defaultLanguage={
            localStorage.getItem('frayjet_frayjet_language') || 'tr'
          }
          languages={languages}
        >
          <PdfDocument
            assessment={assessment}
            scoringVal={scoringVal}
            scoring={scoring}
            dupont={dupont}
            screenshotUrl={urls}
            customer={customer}
            statement={statement}
            companyCode={companyCode}
            combinedScore={combinedScore}
            surveyResult={surveyResult}
            noDocument={noDocument}
          />
        </LanguageProvider>
      ).toBlob();

      return blob;
    };

    const downloadPdf = async (urls) => {
      const pdfBlob = await getBlob(urls);
      const url = URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = intl.formatMessage({ id: 'FINANCIAL_ANALYSIS_REPORT' });
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    };

    const screenshotPromises = [...documents.map((e) => e && html2canvas(e))];
    const urls = {};
    Promise.all(screenshotPromises)
      .then((canvases) => {
        canvases.forEach((canvas, index) => {
          const num = index === 0 ? '' : index + 1;
          const imgData = canvas ? canvas.toDataURL('image/png') : '';

          urls[`tableElement${num}`] = imgData;
        });
      })
      .catch((error) => {
        console.error('Ekran görüntüsü alma hatası:', error);
      })
      .finally(() => {
        setScreenshotUrl(urls);
        downloadPdf(urls);
        setState(true);
        setLoadingSs(false);
      });
  };
  return (
    <div>
      <div className="image-container"></div>
      <div
        style={{
          height: 0,
          overflow: 'hidden',
        }}
      >
        {incomestatement && (
          <ModuleGrafiklerSocar
            incomestatement={
              companyCode === 'ta3meed'
                ? {
                    mainEntry: incomestatement.mainEntry,
                    titles: incomestatement.titles.filter(
                      (t) =>
                        t.title === 'Net Sales (Revenue)' ||
                        t.title === 'Gross Profit' ||
                        t.title === 'Operating Profit' ||
                        t.title === 'Net Profit (Net Earnings)'
                    ),
                  }
                : incomestatement
            }
            assets={assets}
            resources={resources}
            containerRef={containerRef}
            containerRef2={containerRef2}
            containerRef3={containerRef3}
            containerRef4={containerRef4}
            containerRef5={containerRef5}
            containerRef6={containerRef6}
            containerRef7={containerRef7}
            containerRef8={containerRef8}
          />
        )}

        <AltmanBrPrediction
          altmanzscoring={altmanzscoring}
          containerRef9={containerRef9}
          containerRef10={containerRef10}
          containerRef11={containerRef11}
          containerRef12={containerRef12}
        />
        <ModuleMakineData
          prediction={prediction}
          containerRef13={containerRef13}
          companyCode={companyCode}
        />
      </div>
      {loadingSs && <Loading />}
      {state ? (
        <PDFDownloadLink
          document={
            <LanguageProvider
              defaultLanguage={
                localStorage.getItem('frayjet_frayjet_language') || 'tr'
              }
              languages={languages}
            >
              <PdfDocument
                assessment={assessment}
                scoringVal={scoringVal}
                scoring={scoring}
                dupont={dupont}
                screenshotUrl={screenshotUrl}
                customer={customer}
                statement={statement}
                companyCode={companyCode}
                combinedScore={combinedScore}
                surveyResult={surveyResult}
                noDocument={noDocument}
              />
            </LanguageProvider>
          }
          fileName={intl.formatMessage({ id: 'FINANCIAL_ANALYSIS_REPORT' })}
        >
          {({ blob, url, loading, error }) =>
            loading || !blob || !url || loadingSs ? (
              <>
                <Loading />
                <FormattedMessage id="LOADING_DOCUMENT" />
              </>
            ) : (
              <Button_
                text={intl.formatMessage({
                  id: 'SUBTAB_PRINT_FINANCIAL_ANALYSIS_REPORT',
                })}
                buttonStyle={{ background: 'var(--bs-indigo)' }}
                iconClassName={icons.download}
              />
            )
          }
        </PDFDownloadLink>
      ) : loadingSs ? (
        <FormattedMessage id="LOADING_DOCUMENT" />
      ) : (
        <Button_
          text={intl.formatMessage({
            id: 'PREPARE_PDF',
          })}
          onClick={() => {
            setLoadingSs(true);

            setTimeout(() => {
              takeScreenshot();
            }, 1000);
          }}
          buttonStyle={{ background: 'var(--bs-indigo)' }}
          iconClassName={icons.pdf}
        />
      )}
    </div>
  );
};

const FinancialScoreChart = ({ scoringVal, scoring }) => {
  const reversedData =
    scoringVal &&
    scoringVal.dataList &&
    scoringVal.dataList.sort((a, b) => a.upperLimit - b.upperLimit);

  return (
    <View style={{ marginBottom: '100px' }}>
      <Text
        style={{
          fontSize: '25px',
          color: colors.primary,
          marginBottom: '40px',

          fontWeight: 600,
        }}
      >
        <FormattedMessage id="FINANCIAL_SCORE" />
      </Text>

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View style={{ marginRight: '3px', marginBottom: '2px' }}>
          <Text style={{ color: '#FF0000' }}>0</Text>
        </View>
        {reversedData &&
          reversedData.map((ab, i) => {
            return (
              <View
                style={{
                  color: `#${ab?.colorCode?.slice(2)}`,
                  width: '60px',
                  backgroundColor: `#${ab?.colorCode?.slice(2)}`,
                  borderRadius: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  height: '20px',
                  marginRight: '-14px',
                  paddingLeft: i !== reversedData.length - 1 ? '10px' : '16px',
                }}
                key={i}
              >
                <Text
                  style={{
                    color: 'white',
                    fontSize: '14px',
                    marginRight: '20px',
                  }}
                >
                  {ab.rating}
                </Text>
                {Math.round(scoring.scorePoint) >= ab.lowerLimit &&
                  Math.round(scoring.scorePoint) <= ab.upperLimit && (
                    <View
                      style={{
                        position: 'absolute',
                        left: '-25px',
                      }}
                    >
                      <View
                        style={{
                          margin: '50px 0 0 50px',
                        }}
                      >
                        <View
                          style={{
                            width: '3px',
                            height: '35px',
                            backgroundColor: `#${ab?.colorCode?.slice(2)}`,
                            position: 'relative',
                          }}
                        ></View>
                        <View
                          style={{
                            width: '10px',
                            height: '10px',
                            backgroundColor: `#${ab?.colorCode?.slice(2)}`,
                            borderRadius: '50%',
                            position: 'absolute',
                            left: '-3.5px;',
                            top: '25px',
                          }}
                        ></View>
                      </View>

                      <View
                        style={{
                          textAlign: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                          marginTop: '90px',
                          position: 'absolute',
                          width: '100px',
                        }}
                      >
                        <Text
                          style={{
                            color: `#${ab?.colorCode?.slice(2)}`,
                            fontSize: '12px',
                          }}
                        >
                          {scoring.scoreResult} {scoring.scorePoint}
                        </Text>
                      </View>
                    </View>
                  )}

                {i !== reversedData.length - 1 && (
                  <View
                    style={{
                      width: '22px',
                      height: '22px',
                      backgroundColor: 'white',
                      borderRadius: '50px',
                      position: 'absolute',
                      right: -5,
                    }}
                  ></View>
                )}
              </View>
            );
          })}
        <View
          style={{ marginLeft: '15px', marginBottom: '2px', color: '#00B050' }}
        >
          <Text>100</Text>
        </View>
      </View>
    </View>
  );
};

const PdfHeaderForScreenShot = ({ title, title2, icon }) => {
  const styles = StyleSheet.create({
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginTop: margin,
      minHeight: cellHeight,
      alignItems: 'stretch',
      pageBreakInside: 'avoid',
    },
    tableCol: {
      minHeight: cellHeight,
      display: 'flex',
      textAlign: 'left',
      justifyContent: 'center',
    },
    tableCell: {
      padding: padding,
    },
  });
  return (
    <View style={styles.tableRow}>
      <View
        style={[
          styles.tableCol,
          {
            flex: 1,
            minHeight: cellHeight,
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginRight: '5px',
          },
        ]}
      >
        <View
          style={[
            styles.tableCol,
            {
              backgroundColor: colors.secondary,
              width: cellHeight,
              marginRight: '5px',
              alignItems: 'center',
            },
          ]}
        >
          {icon && (
            <Image src={icon} style={{ width: '20px', height: '20px' }} />
          )}
        </View>
        <View
          style={[
            styles.tableCol,
            {
              backgroundColor: colors.primary,
              flex: 1,
            },
          ]}
        >
          <Text
            style={[styles.tableCell, { color: colors.white, fontWeight: 600 }]}
          >
            {title}
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.tableCol,
          {
            backgroundColor: colors.secondary,
            flex: 1,
            margin: 0,
          },
        ]}
      >
        <Text
          style={[styles.tableCell, { color: colors.white, fontWeight: 600 }]}
        >
          {title2}
        </Text>
      </View>
    </View>
  );
};

const PdfTableForScreenShot = ({
  screenshotUrl,
  tableElement,
  tableElement2,
  width,
  height,
  title2,
  title,
  icon,
}) => {
  const styles = StyleSheet.create({
    table: {
      border: 'none',
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginTop: margin,
      minHeight: cellHeight,
      alignItems: 'stretch',
    },
    tableCol: {
      minHeight: cellHeight,
      display: 'flex',
      textAlign: 'left',
      justifyContent: 'center',
      marginRight: margin,
    },
    tableCell: {
      padding: padding,
    },
    content: {
      color: colors.primary,
      fontSize: fontSizes.content,
      position: 'relative',
      padding: padding,
    },
    icon: {
      width: '20px',
      height: '20px',
    },
  });

  return (
    <>
      <PdfHeaderForScreenShot title={title} title2={title2} icon={icon} />
      <View style={styles.tableRow}>
        <View
          style={[
            styles.tableCol,
            { flex: 1, margin: screenshotUrl[tableElement2] ? '0 5px 0 0' : 0 },
          ]}
        >
          {screenshotUrl[tableElement] && (
            <Image
              src={screenshotUrl[tableElement]}
              style={{ width: width, height: height }}
            />
          )}{' '}
        </View>
        {screenshotUrl[tableElement2] && (
          <View
            style={[
              styles.tableCol,
              {
                flex: 1,
                marginRight: 0,
              },
            ]}
          >
            {screenshotUrl[tableElement2] && (
              <Image
                src={screenshotUrl[tableElement2]}
                style={{ width: width, height: height }}
              />
            )}
          </View>
        )}
      </View>
    </>
  );
};
