import React from 'react';
import {
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from 'reactstrap';
import { DocxViewer } from '../DocxViever';
import axios from 'axios';
import { Alerts } from '../../Components/Alerts';

const url = 'https://external.jetscoring.com/traderegistry/gpt/predefined';

export const TradeRegistryTableInfo = () => {
  const [docxData, setDocxData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    const fetchAndConvertDocx = async () => {
      setLoading(true);

      try {
        const response = await axios.get(url);
        if (response && response.status === 200) {
          setDocxData(response.data);
        }
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchAndConvertDocx();
  }, []);

  if (loading)
    return (
      <Alerts
        color="primary"
        className="fas fa-spin fa-spinner"
        messageId={'LOADING'}
        label=""
      />
    );

  return docxData && docxData.length > 0 ? (
    <UncontrolledAccordion flush stayOpen>
      {docxData.map((e, i) => {
        const { text, documentContent, startIndex, endIndex } = e;
        const id = text + i;

        return (
          <AccordionItem key={i} className="mb-2">
            <AccordionHeader targetId={id}>{text}</AccordionHeader>
            <AccordionBody accordionId={id}>
              <DocxViewer
                documentContent={documentContent}
                startIndex={startIndex}
                endIndex={endIndex}
              />
            </AccordionBody>
          </AccordionItem>
        );
      })}
    </UncontrolledAccordion>
  ) : (
    <p>Veri bulunmamaktadır.</p>
  );
};
